import useMaxWidth from 'lib/hooks/useMaxWidth';
import {FileACL, UploadFiles} from 'packages/files/s3files';
import React, {useRef, useState} from 'react';
import {toast} from 'react-toastify';
// models
import {ExternalFile} from '../models';
import UploadProgressToastReact, {UploadProgressToastFunctions} from './UploadProgressToast';
// icons
import {ReactComponent as UploadIcon} from 'src/icons/upload.svg';
// styles
import {css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import {ShowToast} from 'packages/toasts/ShowToast';
import Styles from './UploadFilesBlock.jss';

type UploadOptions = {
    limit?: number;
    skipS3Upload?: boolean;
    accept?: string;
    acceptInfo?: string;
    acl?: FileACL;
};

export default function UploadFilesBlock({
    accept,
    OnLoaded,
    OnUpload,
    options,
}: React.PropsWithChildren<{
    accept?: string;
    OnLoaded?: (files: FileList) => void;
    OnUpload?: (files: ExternalFile[]) => void;
    options?: UploadOptions;
}>) {
    const fileRef = useRef<HTMLInputElement>(null);
    const progressToastRef = useRef<UploadProgressToastFunctions>(null);
    const progressToastID = useRef<string | number>('');

    const [activeDrag, setActiveDrag] = useState<boolean>(false);

    const isSmallerScreen = useMaxWidth(800);

    const uploadFiles = (files: FileList) => {
        if (options && options.limit && files.length > options.limit) {
            return;
        }

        if (accept) {
            for (const file of Array.from(files)) {
                if (
                    !accept
                        .split(',')
                        .map(item => item.trim())
                        .includes(file.type)
                ) {
                    alert('Unable to upload a file of this type');
                    return;
                }
            }
        }

        if (typeof OnLoaded === 'function') {
            OnLoaded(files);
        }

        if (options?.skipS3Upload) {
            return;
        }

        progressToastID.current = ShowToast(<UploadProgressToastReact ref={progressToastRef} />, {
            timeout: false,
        });

        UploadFiles(
            files,
            result => {
                const error = result.some(r => r[1] !== null);

                if (fileRef.current) {
                    fileRef.current.value = '';
                }

                if (error) {
                    progressToastRef.current?.onProgress(0);
                    progressToastRef.current?.onError();
                } else {
                    const resFiles: ExternalFile[] = [];
                    result.forEach(r => {
                        if (r[0] !== null) {
                            resFiles.push(r[0]);
                        }
                    });
                    resFiles.sort((a, b) => {
                        if (a.file_name > b.file_name) {
                            return 1;
                        } else if (a.file_name < b.file_name) {
                            return -1;
                        }

                        return 0;
                    });
                    if (typeof OnUpload === 'function') {
                        OnUpload(resFiles);
                    }
                    toast.dismiss(progressToastID.current);
                }
            },
            p => {
                progressToastRef.current?.onProgress(p);
            },
            options?.acl
        );
    };

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const files = e.target.files;

            uploadFiles(files);
        }
    };

    const onDrop = (event: React.DragEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveDrag(false);

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files = event.dataTransfer.files;

            uploadFiles(files);
        }
    };

    const onDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDragEnter = () => {
        setActiveDrag(true);
    };

    const onDragLeave = () => {
        setActiveDrag(false);
    };

    function OnClick() {
        fileRef.current?.click();
    }

    return (
        <>
            <button
                {...{
                    className: isSmallerScreen
                        ? MakeButton('Outlined-Default')
                        : css(Styles.upload, activeDrag && Styles.uploadDrop),
                    onClick: OnClick,
                    onDrop: onDrop,
                    onDragOver: onDragOver,
                    onDragEnter: onDragEnter,
                    onDragLeave: onDragLeave,
                }}
            >
                {isSmallerScreen ? (
                    <>
                        <span>Upload file</span>
                        <UploadIcon
                            width="16"
                            height="16"
                            role="img"
                            title="Upload"
                        />
                    </>
                ) : (
                    <>
                        <div className={css(Styles.uploadIcon)}>
                            <UploadIcon
                                width="24"
                                height="24"
                                role="img"
                                title="Upload"
                            />
                        </div>
                        <div className={css(Styles.uploadTitle)}>
                            <strong className={css(Styles.uploadTitleStrong)}>Click to Upload</strong> or Drag&Drop
                        </div>
                        {options?.acceptInfo ? (
                            <p className={css(Styles.uploadText)}>Supported formats: {options?.acceptInfo}</p>
                        ) : null}
                    </>
                )}
            </button>
            <input
                {...{
                    style: {
                        display: 'none',
                    },
                    type: 'file',
                    accept: options?.accept || '.tif, .tiff, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .gif',
                    onChange: onSelectFile,
                    ref: fileRef,
                }}
            />
        </>
    );
}
