import GetFileThumbnailURL, {ThumbnailConfig} from '../images/GetFileThumbnailURL';
import GetImageMimeByURL from '../images/GetImageMimeByURL';

export default function PicturePreview({
    image,
    thumbnail,
    picture_tag,
    img_tag,
}: {
    image: {
        file_name: string;
        url: string;
        mime?: string;
    };
    thumbnail?: ThumbnailConfig;
    picture_tag?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLElement>, HTMLElement>;
    img_tag?: Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'alt'>;
}) {
    const file = {...image, mime: image.mime || GetImageMimeByURL(image.url)};

    let url = GetFileThumbnailURL(file, thumbnail);
    let webp = GetFileThumbnailURL(file, thumbnail, 'webp');

    return (
        <picture {...picture_tag}>
            {!!webp && (
                <source
                    key={webp}
                    srcSet={webp}
                    type={`image/webp`}
                />
            )}
            <source
                key={url}
                srcSet={url}
                type={file.mime}
            />
            <img
                {...img_tag}
                src={thumbnail ? url : file.url}
                alt={file.file_name}
            />
        </picture>
    );
}
