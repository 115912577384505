import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export default CreateSheet({
    inputWrapper: {
        fontSize: '1em',
        fontWeight: 500,
        lineHeight: '1.375em',
        color: GetColor('TextColor'),
        display: 'grid',
        gridTemplateColumns: '1em auto',
        gap: '0.75em',
    },
    input: {
        appearance: 'none',
        backgroundColor: '#fff',
        margin: '0',
        font: 'inherit',
        color: 'currentColor',
        width: '1.125em',
        height: '1.125em',
        border: `0.06em solid ${GetColor('Gray')}`,
        borderRadius: '50%',
        transform: 'translateY(0.075em)',
        ':before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'block',
            width: '0.5em',
            height: '0.5em',
            borderRadius: '50%',
            transform: 'scale(0) translate(-50%, -50%)',
            transformOrigin: '0 0',
            transition: '120ms transform ease-in-out',
            backgroundColor: GetColor('PageBg'),
        },
        ':checked': {
            backgroundColor: GetColor('Primary'),
            borderColor: GetColor('Primary'),
            ':before': {
                transform: 'scale(1) translate(-50%, -50%)',
            },
        },
    },
});
