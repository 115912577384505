import {CreateSheet, css} from 'aphrodite';
import {IsValidEmail} from 'lib/helpers/IsValidEmail';
import useSimpleError from 'lib/hooks/useSimpleError';
import {LoadButton, MakeButton} from 'packages/elements/button';
import TextField from 'packages/elements/textfields/TextField';
import {useEffect, useRef, useState} from 'react';
import {SignUpVerificationForm} from 'src/jsx/auth/SignUpVerificationPage';
import {RequestNewEmailAPI} from 'src/runtime/session/API/RequestNewEmailAPI';
import {session} from 'src/runtime/session';
import {SectionsStyles} from './sections.jss';
import PasswordInput from 'packages/elements/textfields/PasswordInput';
import useWaitFor from 'lib/hooks/useWaitFor';
import {UpdatePasswordAPI} from 'src/runtime/session/API/UpdatePasswordAPI';
import {showErrorLightbox} from 'lib/lightbox/warning.react';

type LoginProps = {
    isFocus: boolean;
    setIsFocus: (focus: boolean) => void;
};

export const Login: React.FC<LoginProps> = props => {
    return (
        <div>
            <h2 className={css(SectionsStyles.sectionTitle, props.isFocus && SectionsStyles.outsideFocus)}>Login</h2>
            <Email {...props} />
            <Password {...props} />
        </div>
    );
};

const Email: React.FC<LoginProps> = props => {
    const [isEdit, setIsEdit] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');

    const [hasErr, setErr] = useSimpleError<'email', string>();
    const [isLoading, withLoading] = useWaitFor();

    const triggerEdit = () => setIsEdit(isEdit => !isEdit);

    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErr('email', false);
        setEmail(e.target.value);
    };

    const onSendCode = async () => {
        if (!IsValidEmail(email)) {
            setErr('email', 'Invalid email');
            return;
        }
        const response = await withLoading(RequestNewEmailAPI(email));
        if (response[1]) {
            setErr('email', response[1].message);
            return;
        }

        if (response[0]) {
            setToken(response[0]);
            setIsCodeSent(true);
        }
    };

    const resetState = () => {
        setIsEdit(false);
        setIsCodeSent(false);
        setEmail('');
        setToken('');
        setErr('email', false);
    };

    useEffect(() => {
        props.setIsFocus(isEdit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);

    if (isCodeSent) {
        return (
            <div className={css(SectionsStyles.setting)}>
                <SignUpVerificationForm
                    isEmbedded
                    email={email}
                    token={token}
                    onSuccess={resetState}
                    onCancel={() => {
                        setIsCodeSent(false);
                        triggerEdit();
                    }}
                />
            </div>
        );
    }

    return (
        <div className={css(props.isFocus && !isEdit && SectionsStyles.outsideFocus)}>
            <div className={css(SectionsStyles.setting)}>
                {!isEdit ? (
                    <>
                        <h3 className={css(SectionsStyles.settingInfo)}>
                            Email
                            <span className={css(SectionsStyles.settingInfoDesc)}>{session.user.email}</span>
                        </h3>
                        <button
                            className={MakeButton('Outlined-Default')}
                            onClick={triggerEdit}
                        >
                            Update
                        </button>
                    </>
                ) : (
                    <div className={css(Styles.editContainer)}>
                        <div>
                            <TextField
                                type="text"
                                label="New email"
                                placeholder="Enter new email address"
                                value={email}
                                onChange={onChangeEmail}
                                error={hasErr('email')}
                                styles={{
                                    element: Styles.input,
                                }}
                            />
                        </div>
                        <div className={css(Styles.editControls)}>
                            <button
                                className={MakeButton('FilledPageBg-Default')}
                                onClick={resetState}
                            >
                                Cancel
                            </button>
                            <LoadButton
                                variant="FilledAccent1-Default"
                                loading={isLoading}
                                onClick={onSendCode}
                            >
                                Send code
                            </LoadButton>
                        </div>
                    </div>
                )}
            </div>
            <div className={css(SectionsStyles.divider)}></div>
        </div>
    );
};

const Password: React.FC<LoginProps> = props => {
    const [isEdit, setIsEdit] = useState(false);
    const [hasErr, setErr] = useSimpleError<'currentPassword' | 'newPassword' | 'newPasswordConfirm', string>();

    const currentPasswordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);
    const newPasswordConfirmRef = useRef<HTMLInputElement>(null);

    const [isLoading, withLoading] = useWaitFor();

    const triggerEdit = () => setIsEdit(isEdit => !isEdit);

    const onSave = async (e: React.FormEvent) => {
        e.preventDefault();

        let hasErrors = false;
        const currentPassword = currentPasswordRef.current?.value ?? '';
        const newPassword = newPasswordRef.current?.value ?? '';
        const newPasswordConfirm = newPasswordConfirmRef.current?.value ?? '';

        if (currentPassword === newPassword) {
            setErr('newPassword', 'New password cannot be the same as current password');
            hasErrors = true;
        }

        if (newPassword !== newPasswordConfirm) {
            setErr('newPasswordConfirm', 'Passwords do not match');
            hasErrors = true;
        }

        if (hasErrors) return;

        const [, err] = await withLoading(UpdatePasswordAPI(currentPassword, newPassword));
        if (err) {
            if (err.status_code === 11) {
                setErr('newPassword', err.message);
            } else if (err.status_code === 12) {
                setErr('currentPassword', err.message);
            } else {
                showErrorLightbox('Failed to update password');
            }
            return;
        }

        resetState();
    };

    const resetState = () => {
        triggerEdit();
        setErr('newPassword', false);
        setErr('newPasswordConfirm', false);
    };

    useEffect(() => {
        props.setIsFocus(isEdit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);

    return (
        <div className={css(props.isFocus && !isEdit && SectionsStyles.outsideFocus)}>
            <div className={css(SectionsStyles.setting)}>
                {!isEdit ? (
                    <>
                        <h3 className={css(SectionsStyles.settingInfo)}>Password</h3>
                        <button
                            className={MakeButton('Outlined-Default')}
                            onClick={triggerEdit}
                        >
                            Update
                        </button>
                    </>
                ) : (
                    <form
                        className={css(Styles.editContainer)}
                        onSubmit={onSave}
                    >
                        <div>
                            <PasswordInput
                                label="Current Password"
                                placeholder="Enter current password"
                                inputRef={currentPasswordRef}
                                error={hasErr('currentPassword')}
                                onChange={e => {
                                    setErr('currentPassword', false);
                                }}
                            />
                        </div>
                        <div>
                            <PasswordInput
                                label="New password"
                                placeholder="Enter password"
                                inputRef={newPasswordRef}
                                error={hasErr('newPassword')}
                                onChange={e => {
                                    setErr('newPassword', false);
                                }}
                            />
                        </div>
                        <div>
                            <PasswordInput
                                label="Confirm password"
                                placeholder="Enter password"
                                inputRef={newPasswordConfirmRef}
                                error={hasErr('newPasswordConfirm')}
                                onChange={e => {
                                    setErr('newPasswordConfirm', false);
                                }}
                            />
                        </div>
                        <div className={css(Styles.editControls)}>
                            <button
                                className={MakeButton('FilledPageBg-Default')}
                                onClick={resetState}
                            >
                                Cancel
                            </button>
                            <LoadButton
                                type="submit"
                                variant="FilledAccent1-Default"
                                loading={isLoading}
                            >
                                Save changes
                            </LoadButton>
                        </div>
                    </form>
                )}
            </div>
            <div className={css(SectionsStyles.divider)}></div>
        </div>
    );
};

const Styles = CreateSheet({
    editContainer: {
        maxWidth: 400,

        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        '@media(max-width: 640px)': {
            maxWidth: 'none',
        },
    },
    input: {
        width: '100%',
    },
    editControls: {
        display: 'flex',
        gap: 10,
        justifyContent: 'flex-end',
    },
});
