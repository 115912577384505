import * as r from 'runtypes';
import {DonutChartRuntype} from './DonutChart';
import {UnknownChartRuntype} from './UnknownChart';
import {EventsUsersTableRuntype} from './EventsUsersTable';
import {BarChartRuntype} from './BarChart';
import {AreaChartRuntype} from './AreaChart';

export const ChartRuntype = r.Union(
    DonutChartRuntype,
    BarChartRuntype,
    AreaChartRuntype,
    EventsUsersTableRuntype,
    UnknownChartRuntype
);
export type Chart = r.Static<typeof ChartRuntype>;
