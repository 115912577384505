import {ReactComponent as ChatIcon} from 'src/icons/chat.svg';
import {ReactComponent as OverviewIcon} from 'src/icons/overview.svg';
// import {ReactComponent as ContextIcon} from 'src/icons/context.svg';
// import {ReactComponent as MonetizationIcon} from 'src/icons/monetization.svg';
// import {ReactComponent as RetentionIcon} from 'src/icons/retention.svg';
// import {ReactComponent as PredictiveAnalyticsIcon} from 'src/icons/predictive-analytics.svg';

export type MenuItem = {
    title: string;
    description: string;
    href: (company_uri: string, project_uri: string) => string;
    icon: (className?: string) => React.ReactNode;
};

export const getBasePath = (company_uri: string, project_uri: string) => '/ai/' + company_uri + '/' + project_uri;

export const NON_COLLAPSIBLE_SECTIONS = ['Model Output'];

export const MenuSections: Record<string, MenuItem[]> = {
    '': [
        {
            title: 'Talk to Whitetail',
            description: '',
            href: (company_uri: string, project_uri: string) => getBasePath(company_uri, project_uri) + '/chat',
            icon: className => (
                <ChatIcon
                    width={16}
                    height={16}
                    className={className}
                />
            ),
        },
    ],
    'Model Output': [
        {
            title: 'Overview',
            description: 'High level metrics and WoW comparisons',
            href: (company_uri: string, project_uri: string) => getBasePath(company_uri, project_uri),
            icon: className => (
                <OverviewIcon
                    width={16}
                    height={16}
                    className={className}
                />
            ),
        },
        // {
        //     title: 'Monetization',
        //     description: 'Deep Monetization looks and analysis',
        //     // TODO: change href when page is ready
        //     // href: (company_uri: string, project_uri: string) =>
        //     //     getBasePath(company_uri, project_uri) + '/model-output/monetization',
        //     href: (company_uri: string, project_uri: string) =>
        //         getBasePath(company_uri, project_uri) + '?todo_monetization',
        //     icon: className => (
        //         <MonetizationIcon
        //             width={16}
        //             height={16}
        //             className={className}
        //         />
        //     ),
        // },
        // {
        //     title: 'Retention',
        //     description: 'Deep Retention and engagement analysis',
        //     // TODO: change href when page is ready
        //     // href: (company_uri: string, project_uri: string) =>
        //     //     getBasePath(company_uri, project_uri) + '/model-output/retention',
        //     href: (company_uri: string, project_uri: string) =>
        //         getBasePath(company_uri, project_uri) + '?todo_retention',
        //     icon: className => (
        //         <RetentionIcon
        //             width={16}
        //             height={16}
        //             className={className}
        //         />
        //     ),
        // },
        // {
        //     title: 'Predictive Analytics & Deep Insights',
        //     description: 'Complex analysis and predictive insights',
        //     // TODO: change href when page is ready
        //     // href: (company_uri: string, project_uri: string) =>
        //     //     getBasePath(company_uri, project_uri) + '/model-output/predictive-analytics',
        //     href: (company_uri: string, project_uri: string) =>
        //         getBasePath(company_uri, project_uri) + '?todo_predictive',
        //     icon: className => (
        //         <PredictiveAnalyticsIcon
        //             width={16}
        //             height={16}
        //             className={className}
        //         />
        //     ),
        // },
    ],
};
