import {CreateSheet, GetColor} from 'aphrodite';

export const baseSizes = {
    asideWidth: 18,
    asideShift: 15,
    asidePadding: 10,
};

export default CreateSheet({
    input: {
        height: 50,
        padding: '14px 16px',
        fontFamily: "'Figtree', sans-serif",
        fontSize: '1rem',
        fontWeight: 500,
        color: GetColor('TextColor'),
        borderRadius: 14,
        border: `1px solid ${GetColor('Border')}`,
        background: GetColor('InputBg'),
        transition: 'border 0.2s, background 0.2s, box-shadow 0.2s, color 0.2s',
        ':focus': {
            outline: 0,
            border: `1px solid ${GetColor('Primary')}`,
            boxShadow: `inset 0 0 0 1px  ${GetColor('Primary')}`,
        },
        ':focus-visible': {
            outline: 0,
            border: `1px solid ${GetColor('Primary')}`,
            boxShadow: `inset 0 0 0 1px  ${GetColor('Primary')}`,
        },
        ':hover': {
            border: `1px solid ${GetColor('Primary')}`,
        },
        ':disabled': {
            color: GetColor('Secondary'),
            background: GetColor('LightGrayBg'),
            ':hover': {
                border: `1px solid ${GetColor('Border')}`,
            },
        },
        ':placeholder': {
            color: GetColor('Secondary'),
        },
        ':-webkit-autofill ~ .input-label': {
            fontSize: '0.714rem',
            top: 0,
        },
    },
    textArea: {
        height: 120,
    },
    input_error: {
        border: `1px solid ${GetColor('Danger')}`,
        boxShadow: `inset 0 0 0 1px  ${GetColor('Danger')}`,
    },
    input_active: {
        outline: 0,
        border: `1px solid ${GetColor('Primary')}`,
        boxShadow: `inset 0 0 0 1px  ${GetColor('Primary')}`,
    },
    label: {
        display: 'flex',
        margin: '0 0 5px 0',
        alignItems: 'center',
        pointerEvents: 'none',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.3,
        color: GetColor('TextColor'),
        maxWidth: '100%',
        textTransform: 'none',
        transition: 'top 0.15s, bottom 0.15s, transform 0.15s, left 0.15s, right 0.15s',
        zIndex: 2,
        textAlign: 'left',
    },
    label_hidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        '-webkit-clip-path': 'inset(50%)',
        clipPath: 'inset(50%)',
        height: 1,
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: 1,
        whiteSpace: 'nowrap',
    },
    input_with_aside: {
        width: '100%',
    },
    aside: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        width: baseSizes.asideWidth,
    },
    aside_wrapper: {
        position: 'relative',
    },
    aside_left: {
        left: baseSizes.asideShift,
    },
    aisde_right: {
        right: baseSizes.asideShift,
    },
    aside_error: {
        color: GetColor('Danger'),
    },
    hint: {
        display: 'flex',
        paddingTop: 3,
        fontSize: '0.857rem',
        fontWeight: 500,
    },
    error_element: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        fontSize: '0.857rem',
        fontWeight: 600,
        color: GetColor('Danger'),
    },
    number: {
        display: 'flex',
        alignItems: 'center',
    },
    numberInput: {
        width: 100,
        margin: '0 15px',
        textAlign: 'center',
    },
    numberBtn: {
        display: 'flex',
        width: 34,
        height: 34,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
        border: 0,
        borderRadius: '50%',
        boxShadow: 'none',
        cursor: 'pointer',
        ':hover': {
            background: GetColor('ButtonBg'),
        },
        ':focus': {
            background: GetColor('ButtonBg'),
        },
    },
});

export function SetPadding(padding: string | number) {
    return CreateSheet({root: {padding}}).root;
}
