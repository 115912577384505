import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export default CreateSheet({
    addBtn: {
        whiteSpace: 'nowrap',
    },
    main: {
        paddingTop: 70,
        position: 'relative',
        '@media(max-width: 800px)': {
            paddingTop: 60,
        },
    },
    container: {
        width: '100%',
        maxWidth: 1220,
        margin: '0 auto',
        height: '100%',
        padding: '0 40px',
        '@media(max-width: 800px)': {
            padding: '0 20px',
        },
    },
    header: {
        padding: '30px 0 20px 0',
        '@media(max-width: 720px)': {
            padding: '20px 0 10px 0',
        },
    },
    headerTitle: {
        margin: '0 0 10px 0',
        fontSize: '2.75rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
        '@media(max-width: 720px)': {
            fontSize: '1.75rem',
        },
    },
    headerSubTitle: {
        margin: 0,
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: GetColor('Secondary'),
        '@media(max-width: 720px)': {
            fontSize: '1rem',
        },
    },
    stickyContainer: {
        position: 'sticky',
        top: 0,
        background: GetColor('PageBg'),
        zIndex: 90,
        transition: 'top 0.3s ease',
        '@media(max-width: 800px)': {
            position: 'relative',
        },
    },
    controlsBlock: {
        display: 'flex',
        padding: '10px 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        rowGap: '15px',
        '@media(max-width: 720px)': {
            flexWrap: 'wrap',
        },
    },
    showControls: {
        top: 70,
        boxShadow: '0px 3px 15px 0px rgba(48, 48, 48, 0.07)',
        '@media(max-width: 800px)': {
            boxShadow: 'none',
        },
    },
    count: {
        marginRight: 30,
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: GetColor('Secondary'),
        '@media(max-width: 720px)': {
            width: '100%',
            minWidth: '100%',
            margin: '0 0 10px 0',
        },
    },
    countKey: {
        fontWeight: 600,
    },
    rightControls: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        '@media(max-width: 800px)': {
            display: 'none',
        },
    },
    dropdownBlock: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
        flexShrink: 0,
        ':last-child': {
            marginRight: 0,
        },
        '@media(max-width: 950px)': {
            width: 180,
        },
        '@media(max-width: 640px)': {
            width: 170,
        },
        '@media(max-width: 400px)': {
            width: 140,
        },
    },
    dropdownBlockInput: {
        width: 170,
        cursor: 'pointer',
        '@media(max-width: 400px)': {
            paddingRight: 33,
        },
    },
    dropdownBlockLabel: {
        marginRight: 10,
        color: GetColor('Secondary'),
        fontSize: '14px',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        '@media(max-width: 950px)': {
            display: 'none',
        },
    },
    filterWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'space-between',
        '@media(max-width: 690px)': {
            justifyContent: 'flex-start',
        },
    },
    filterCheckbox: {
        marginRight: 30,
        flexShrink: 0,
    },
    searchInput: {
        width: '100%',
        height: 50,
        padding: '9px 16px',
        borderRadius: 10,
    },
    searchInputAsideWrapper: {
        width: '100%',
        maxWidth: 350,
        '@media(max-width: 490px)': {
            width: 'auto',
            maxWidth: 'auto',
            marginRight: 15,
            flexGrow: 1,
        },
    },
    pageDivider: {
        margin: 0,
        border: 'none',
        borderBottom: `1px solid ${GetColor('StrokeColor')}`,
    },
    dropdownTriggerIcon: {
        width: 16,
        height: 16,
        color: GetColor('Secondary'),
        transform: 'rotate(0)',
        transition: 'transform 0.15s',
    },
    dropdownTriggerIconOpened: {
        transform: 'rotate(-180deg)',
    },
    searchInputIcon: {
        width: 18,
        height: 18,
        color: GetColor('Secondary'),
    },
    searchInputClearIcon: {
        width: 14,
        height: 14,
        padding: 0,
        margin: 0,
        color: GetColor('Secondary'),
        borderRadius: 2,
        ':nth-child(1n) svg': {
            display: 'block',
            width: 14,
            height: 14,
        },
        ':focus': {
            background: 'transparent',
            border: `1px solid ${GetColor('Secondary')}`,
        },
        ':focus:not(:disabled)': {
            background: 'transparent',
        },
    },
    cards: {
        marginTop: 15,
        marginBottom: 50,
    },
    emptyResults: {
        width: '100%',
        marginTop: 120,
        marginBottom: 50,
        textAlign: 'center',
        color: GetColor('Secondary'),
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: '120%',
    },
    single: {
        width: 700,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '20px 0px 50px 0px',
        '@media(max-width: 740px)': {
            padding: '20px 20px 50px 20px',
        },
    },
    singleTitle: {
        margin: '20px 0',
        fontSize: '2.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
        '@media(max-width: 640px)': {
            fontSize: '1.5rem',
        },
    },
    singleImg: {
        width: '100%',
        marginBottom: 20,
        borderRadius: 14,
    },
    singleText: {
        ':nth-child(1n) .DraftEditor-root': {
            fontFamily: 'Figtree',
            fontSize: '1.125rem',
            fontWeight: 400,
            lineHeight: 1.5,
            color: GetColor('TextColor'),
            border: 0,
            padding: 0,
        },
    },
    cardDivider: {
        width: '100%',
        height: 1,
        margin: 0,
        padding: 0,
        border: 0,
        background: GetColor('StrokeColor'),
    },
    bottomDrawerBg: {
        background: 'rgba(0,0,0,0.5)',
    },
    bottomDrawerBox: {
        width: '100%',
    },
    bottomDrawerContent: {
        background: GetColor('PageBg'),
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    drawerTopLine: {
        padding: '10px 20px 0 20px',
    },
    drawerDivider: {
        border: 'none',
        margin: 0,
        borderTop: `1px solid ${GetColor('StrokeColor')}`,
    },
    drawerBottomLine: {
        padding: '15px 20px',
    },
    drawerInnerContainer: {
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
    },
    drawerSaveBtn: {
        width: '100%',
        justifyContent: 'center',
    },
    drawerFilterBlock: {
        padding: '20px 0',
    },
    drawerFilterBlockLabel: {
        color: GetColor('TextColor'),
        display: 'block',
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        marginBottom: 15,
    },
    radioBtn: {
        fontSize: '1rem',
        marginBottom: 20,
    },
    sortBtn: {
        display: 'none',
        height: 48,
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        border: `2px solid ${GetColor('Border')}`,
        background: GetColor('PageBg'),
        color: GetColor('Primary'),
        cursor: 'pointer',
        '@media(max-width: 800px)': {
            display: 'flex',
        },
    },
});
