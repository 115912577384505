import {composeDecorators} from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import '@draft-js-plugins/image/lib/plugin.css';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createAlignmentPlugin from '../utils/AlignmentPlugin.cjs';
import '../utils/AlignmentPlugin.css';
import '../utils/FocusPlugin.css';
import createImagePlugin from '../utils/ImagePlugin.cjs';

import {CreateSheet, css} from 'aphrodite';
import {ContentBlock, ContentState, EditorState} from 'draft-js';
import useSafeState from 'lib/hooks/useSafeState';
import {Body, ComponentProps, Footer, Header, Lightbox, showLightbox} from 'lib/lightbox/lightbox';
import {UploadImageBlock, PreviewFile, PreviewPicture} from 'packages/files/components';
import {ExternalFile} from 'packages/files/models';
import React, {ImgHTMLAttributes, PropsWithChildren, ReactElement, useContext, useRef, useState} from 'react';
import {DraftEditorContext, DraftEditorFunctions} from '../DraftEditor.react';

import {MakeButton} from 'packages/elements/button';
import TextField from 'packages/elements/textfields/TextField';
import {ReactComponent as PencilIcon} from 'src/icons/edit.svg';
import {ReactComponent as AlignBlockCenterIcon} from '../icons/align-block-center.svg';
import {ReactComponent as AlignBlockLeftIcon} from '../icons/align-block-left.svg';

export type Alignment = 'default' | 'center' | 'right';
export type Spacing = {
    top: number;
    right: number;
    bottom: number;
    left: number;
};
export const EmptySpacing = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};

export type ImageData = {
    file?: ExternalFile;
    spacing?: Spacing;
    borderRadius?: number;
    alignment?: Alignment;
    width?: number;
    height?: number;
};
export const EmptyImageData = {
    file: undefined,
    spacing: EmptySpacing,
    width: 40,
};

interface ImageComponentProps extends ImgHTMLAttributes<HTMLImageElement> {
    block: ContentBlock;
    contentState: ContentState;

    //removed props
    blockStyleFn: unknown;
    blockProps: unknown;
    customStyleMap: unknown;
    customStyleFn: unknown;
    decorator: unknown;
    forceSelection: unknown;
    offsetKey: unknown;
    selection: unknown;
    tree: unknown;
    preventScroll: unknown;
    theme: unknown;
}

export const ImageComponent = React.forwardRef<HTMLImageElement, ImageComponentProps>((props, ref): ReactElement => {
    const [isHovered, setIsHovered] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const {functions, readOnly} = useContext(DraftEditorContext);

    const {block, contentState, ...otherProps} = props;
    const {
        blockProps, // eslint-disable-line @typescript-eslint/no-unused-vars
        customStyleMap, // eslint-disable-line @typescript-eslint/no-unused-vars
        customStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
        decorator, // eslint-disable-line @typescript-eslint/no-unused-vars
        forceSelection, // eslint-disable-line @typescript-eslint/no-unused-vars
        offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        selection, // eslint-disable-line @typescript-eslint/no-unused-vars
        tree, // eslint-disable-line @typescript-eslint/no-unused-vars
        blockStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
        preventScroll, // eslint-disable-line @typescript-eslint/no-unused-vars
        theme, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...elementProps
    } = otherProps;

    const onMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        setIsHovered(true);
    };

    const onAlignmentsMouseEnter = () => {
        if (!isHovered) {
            return;
        }

        onMouseEnter();
    };

    const onMouseLeave = () => {
        timeoutRef.current = setTimeout(() => setIsHovered(false), 400);
    };

    const onEditClick = () => {
        const content = functions.getState().getCurrentContent();
        const selection = functions.getState().getSelection();
        const entityKey = content.getBlockForKey(selection.getAnchorKey()).getEntityAt(0);
        if (entityKey) {
            showLightbox({
                selector: 'InsertImage',
                Component: InsertImage,
                content: {
                    imageData: content.getEntity(entityKey).getData() as ImageData,
                    onInsert: data => {
                        functions.onChange(
                            EditorState.set(functions.getState(), {
                                currentContent: content.mergeEntityData(entityKey, data),
                            })
                        );
                    },
                },
            });
        }
    };

    const onAlignmentClick = (alignment: Alignment) => () => {
        if (isAlignmentAllowed) {
            setAlignment(functions, entityKey, alignment);
        }
    };

    const entityKey = block.getEntityAt(0);
    const data = contentState.getEntity(entityKey).getData() as ImageData;

    const ImageWidth = document.getElementById(entityKey)?.getBoundingClientRect().width;
    const alignmentsContainerStyles: React.CSSProperties = {};

    switch (data.alignment || 'default') {
        case 'default':
            alignmentsContainerStyles.left = (ImageWidth || 0) / 2 - 80;
            break;
        case 'center':
            alignmentsContainerStyles.left = 'calc(50% - 80px)';
            break;
        case 'right':
            alignmentsContainerStyles.right = (ImageWidth || 0) / 2 - 80;
            break;
    }

    const isAlignmentAllowed = data.width ? data.width < 100 : true;

    return (
        <ImageRenderer
            {...{
                ref,
                data,
                readOnly,
                entityKey,
                onMouseEnter,
                onMouseLeave,
                elementProps,
            }}
        >
            {!readOnly && (
                <div
                    {...{
                        onMouseEnter: onAlignmentsMouseEnter,
                        onMouseLeave,
                        className: css(Styles.alignmentsContainer, isHovered && Styles.visible),
                        style: alignmentsContainerStyles,
                    }}
                >
                    <div className={css(Styles.alignmentItems)}>
                        <div
                            {...{
                                className: css(
                                    Styles.alignmentItem,
                                    !isAlignmentAllowed && Styles.alignmentItemDisabled
                                ),
                                onClick: onAlignmentClick('default'),
                            }}
                        >
                            <AlignBlockLeftIcon className={css(Styles.alignmentItemIcon)} />
                        </div>
                        <div className={css(Styles.divider)}></div>
                        <div
                            {...{
                                className: css(
                                    Styles.alignmentItem,
                                    !isAlignmentAllowed && Styles.alignmentItemDisabled
                                ),
                                onClick: onAlignmentClick('center'),
                            }}
                        >
                            <AlignBlockCenterIcon className={css(Styles.alignmentItemIcon)} />
                        </div>
                        <div className={css(Styles.divider)}></div>
                        <div
                            {...{
                                className: css(
                                    Styles.alignmentItem,
                                    !isAlignmentAllowed && Styles.alignmentItemDisabled
                                ),
                                onClick: onAlignmentClick('right'),
                            }}
                        >
                            <AlignBlockLeftIcon className={css(Styles.alignmentItemIcon, Styles.reversedIcon)} />
                        </div>
                    </div>
                    <div className={css(Styles.alignmentItems)}>
                        <div
                            {...{
                                className: css(Styles.alignmentItem),
                                onClick: onEditClick,
                            }}
                        >
                            <PencilIcon className={css(Styles.pencilIcon)} />
                        </div>
                    </div>
                </div>
            )}
        </ImageRenderer>
    );
});

export type ImageRendererProps = {
    data: ImageData;
    readOnly?: boolean;
    entityKey?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    elementProps?: any;
};

export const ImageRenderer = React.forwardRef<HTMLImageElement, PropsWithChildren<ImageRendererProps>>(
    ({data, entityKey, readOnly, onMouseEnter, onMouseLeave, elementProps, children}, ref): ReactElement => {
        const additionalStyles: React.CSSProperties = {};

        switch (data.alignment) {
            case 'center':
                additionalStyles.marginLeft = 'auto';
                additionalStyles.marginRight = 'auto';
                break;
            case 'right':
                additionalStyles.marginLeft = 'auto';
                break;
        }

        return (
            <div
                {...{
                    style: {
                        position: 'relative',
                        marginLeft: data.spacing?.left,
                        marginRight: data.spacing?.right,
                        marginTop: data.spacing?.top,
                        marginBottom: data.spacing?.bottom,
                    },
                }}
            >
                {data.file && (
                    <>
                        {readOnly ? (
                            <PreviewPicture
                                image={data.file}
                                thumbnail={{width: 1000, height: 'auto'}}
                                img_tag={{
                                    ref,
                                    ...elementProps,
                                    id: entityKey,
                                    className: elementProps?.className,
                                    onMouseEnter,
                                    onMouseLeave,
                                    style: {
                                        ...elementProps?.style,
                                        ...additionalStyles,
                                        display: 'block',
                                        verticalAlign: 'top',
                                        width: elementProps?.style?.width || (data.width && `${data.width}%`) || '100%',
                                        borderRadius: data.borderRadius,
                                        position: 'relative',
                                        boxShadow: readOnly ? 'none' : undefined,
                                        float: undefined,
                                        maxWidth: '100%',
                                    },
                                }}
                            />
                        ) : (
                            <img
                                {...{
                                    ref,
                                    ...elementProps,
                                    id: entityKey,
                                    src: data.file.url,
                                    alt: data.file.file_name,
                                    className: elementProps?.className,
                                    onMouseEnter,
                                    onMouseLeave,
                                    style: {
                                        ...elementProps?.style,
                                        ...additionalStyles,
                                        display: 'block',
                                        verticalAlign: 'top',
                                        width: elementProps?.style?.width || (data.width && `${data.width}%`) || '100%',
                                        borderRadius: data.borderRadius,
                                        position: 'relative',
                                        boxShadow: readOnly ? 'none' : undefined,
                                        float: undefined,
                                        maxWidth: '100%',
                                    },
                                }}
                            />
                        )}
                    </>
                )}
                {children}
            </div>
        );
    }
);

const FocusPlugin = createFocusPlugin();
export const AlignmentPlugin = createAlignmentPlugin();
const ResizeablePlugin = createResizeablePlugin();
export const ImagePlugin = createImagePlugin({
    decorator: composeDecorators(ResizeablePlugin.decorator, AlignmentPlugin.decorator, FocusPlugin.decorator),
    imageComponent: ImageComponent,
});

export const plugins = [FocusPlugin, AlignmentPlugin, ResizeablePlugin, ImagePlugin];

export const setAlignment = (draft: DraftEditorFunctions, entityKey: string, alignment: Alignment) => {
    const state = draft.getState();
    const content = state.getCurrentContent();

    const newContent = content.mergeEntityData(entityKey, {
        alignment: alignment,
    });

    const newEditorState = EditorState.set(state, {
        currentContent: newContent,
    });

    draft.onChange(newEditorState);
};

export interface InsertImageLightboxProps {
    imageData?: ImageData;
    onInsert: (data: ImageData) => void;
}

export default function InsertImage(data: ComponentProps<InsertImageLightboxProps>) {
    const [imageData, setImageData] = useSafeState<ImageData>(data.content.imageData ?? EmptyImageData);

    const onInsertClick = () => {
        if (imageData) {
            data.content.onInsert(imageData);
            data.close();
        }
    };

    const onSpacingInputChange = (field: keyof Spacing) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value || '0');

        if (!imageData.spacing) {
            setImageData({spacing: {$set: {...EmptySpacing, [field]: value}}});
            return;
        }

        setImageData({spacing: {[field]: {$set: value}}});
    };

    return (
        <Lightbox {...{close: data.close}}>
            <Header>{data.content.imageData ? 'Edit image' : 'Insert image'}</Header>
            <Body>
                {imageData.file?.guid?.length ? (
                    <div>
                        <PreviewFile
                            file={imageData.file}
                            onRemove={() => setImageData({$set: EmptyImageData})}
                        />
                    </div>
                ) : (
                    <>
                        <div className={css(Styles.uploadTitle)}>Please click gray rectangle to upload image.</div>
                        <UploadImageBlock
                            {...{
                                size: 200,
                                OnUpload: file => setImageData({file: {$set: file}}),
                            }}
                        />
                        <div className={css(Styles.spacingTitle)}>Specify image spaces in pixels if needed.</div>
                        <div className={css(Styles.inputs)}>
                            <div className={css(Styles.inputContainer)}>
                                <TextField
                                    {...{
                                        label: 'Top',
                                        defaultValue: imageData.spacing?.top || 0,
                                        onChange: onSpacingInputChange('top'),
                                        styles: {element: Styles.spaceInput},
                                    }}
                                />
                            </div>
                            <div className={css(Styles.inputContainer)}>
                                <TextField
                                    {...{
                                        label: 'Left',
                                        defaultValue: imageData.spacing?.left || 0,
                                        onChange: onSpacingInputChange('left'),
                                        styles: {element: Styles.spaceInput},
                                    }}
                                />
                            </div>
                            <div className={css(Styles.inputContainer)}>
                                <TextField
                                    {...{
                                        label: 'Bottom',
                                        defaultValue: imageData.spacing?.bottom || 0,
                                        onChange: onSpacingInputChange('bottom'),
                                        styles: {element: Styles.spaceInput},
                                    }}
                                />
                            </div>
                            <div className={css(Styles.inputContainer)}>
                                <TextField
                                    {...{
                                        label: 'Right',
                                        defaultValue: imageData.spacing?.right || 0,
                                        onChange: onSpacingInputChange('right'),
                                        styles: {element: Styles.spaceInput},
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.inputs)}>
                            <div className={css(Styles.inputContainer)}>
                                <TextField
                                    {...{
                                        label: 'Border radius',
                                        defaultValue: imageData.borderRadius || 0,
                                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            setImageData({borderRadius: {$set: parseInt(e.target.value || '0')}});
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Body>
            <Footer
                {...{
                    styles: Styles.footer,
                }}
            >
                <button
                    {...{
                        className: MakeButton('FilledAccent1-Default'),
                        disabled: imageData.file?.guid.length === 0,
                        onClick: onInsertClick,
                    }}
                >
                    {data.content.imageData ? 'Save' : 'Insert'}
                </button>
            </Footer>
        </Lightbox>
    );
}

const Styles = CreateSheet({
    uploadContainer: {
        display: 'flex',
        gap: 20,
    },
    imageUploadContainer: {
        flexShrink: 0,
    },
    uploadTitle: {
        marginBottom: 5,
    },
    spacingTitle: {
        marginTop: 10,
    },
    spaceInput: {
        padding: '5px 10px',
        height: 40,
        width: 100,
    },
    inputs: {
        display: 'flex',
        gap: 15,
        marginBottom: 10,
    },
    inputContainer: {
        flexGrow: 1,
        display: 'flex',
        gap: 5,
        flexDirection: 'column',
        fontSize: '0.75rem',
        color: '#979797',
        fontWeight: 600,
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    alignmentsContainer: {
        position: 'absolute',
        top: -45,
        opacity: 0,
        transition: 'opacity 0.25s ease-in-out',
        display: 'flex',
        gap: 10,
        pointerEvents: 'none',
    },
    visible: {
        opacity: 1,
        pointerEvents: 'auto',
    },
    alignmentItems: {
        display: 'flex',
        height: 36,
        border: `1px solid #ddd`,
        borderRadius: 5,
        overflow: 'hidden',
    },
    alignmentItem: {
        width: 36,
        background: '#F5F5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fill: '#979797',
        ':hover': {
            fill: '#303030',
        },
    },
    alignmentItemDisabled: {
        cursor: 'not-allowed',
        ':hover': {
            fill: '#979797',
        },
    },
    alignmentItemIcon: {
        width: 24,
        height: 24,
    },
    pencilIcon: {
        width: 20,
        height: 20,
    },
    reversedIcon: {
        transform: 'rotate(180deg)',
    },
    divider: {
        width: 1,
        height: '100%',
        background: '#ddd',
    },
});
