import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
// component
import Header from '../header/header';
import WithSpinner from 'packages/spinners/SmartSpinner.react';
import DraftEditorReact from 'packages/draftjs/DraftEditor.react';
import {PreviewPicture} from 'packages/files/components';
import Footer from '../footer/footer';
// icons
import {ReactComponent as ArrowLeftLongIcon} from 'src/icons/arrow-left-long.svg';
// styles
import {css} from 'aphrodite';
import Styles from './blog.jss';
import {PrimaryDraft} from 'packages/draftjs/themes/draftjs';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import {usePostStore} from 'packages/blog/store/single/usePostStore';
import {MakeButton} from 'packages/elements/button';
import {routing} from 'src/runtime/router';

export default function BlogSingle() {
    const {uri} = useParams();
    const [data] = usePostStore(state => [state.data]);
    const [Init] = usePostStore(state => [state.init]);

    useEffect(() => {
        if (uri) Init(uri);
    }, [uri, Init]);

    useEffect(() => {
        if (data.item?.title) {
            document.title = data.item.title;
        }
    }, [data.item?.title]);

    return (
        <>
            <Header />
            <div className={css(Styles.single)}>
                <WithSpinner
                    suspense={data.is_loading}
                    theme={PrimaryTheme}
                    minDuration={0}
                >
                    {data.is_loaded && data.item && (
                        <>
                            <a
                                href="/blog"
                                className={MakeButton('Flat-Flat')}
                                onClick={routing.OpenPage('blog')}
                            >
                                <ArrowLeftLongIcon />
                                <span>Back</span>
                            </a>
                            <h1 className={css(Styles.singleTitle)}>{data.item.title}</h1>
                            {data.item.image && (
                                <>
                                    <PreviewPicture
                                        image={data.item.image}
                                        thumbnail={{width: 700, height: 'auto'}}
                                        img_tag={{className: css(Styles.singleImg)}}
                                    />
                                </>
                            )}

                            <DraftEditorReact
                                {...{
                                    theme: PrimaryDraft,
                                    defaultValue: data.item.body,
                                    readOnly: true,
                                    classNames: {
                                        root: Styles.singleText,
                                    },
                                }}
                            />
                        </>
                    )}
                </WithSpinner>
            </div>
            <Footer />
        </>
    );
}
