import * as runtypes from 'runtypes';

export const PaginationRuntype = runtypes.Record({
    current_page: runtypes.Number,
    first: runtypes.Number,
    last: runtypes.Number,
    next_page: runtypes.String.Or(runtypes.Null),
    page_size: runtypes.Number,
    prev_page: runtypes.String.Or(runtypes.Null),
    total_pages: runtypes.Number,
    total_results: runtypes.Number,
});

export type Pagination = runtypes.Static<typeof PaginationRuntype>;

export function WithPagination<A = unknown>(runtype: runtypes.Runtype<A>) {
    return runtypes.Record({
        list: runtypes.Array(runtype),
        pagination: PaginationRuntype,
    });
}
