export default function GetImageMimeByURL(url: string): string {
    switch (url.split('.').pop()) {
        case 'jpeg':
        case 'jpg':
        case 'jpe':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'webp':
            return 'image/webp';
        case 'gif':
            return 'image/gif';
    }

    return 'application/octet-stream';
}
