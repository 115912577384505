import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export default CreateSheet({
    table: {
        display: 'flex',
        flexDirection: 'column',
        color: GetColor('TextColor'),
    },
    row: {
        display: 'flex',
        width: '100%',
        padding: '0x 5px',
        alignItems: 'stretch',
        borderRadius: 8,
        background: GetColor('PageBg'),
        ':nth-child(odd)': {
            background: GetColor('LightGrayBg'),
        },
    },
    headerRow: {
        padding: '10px 5px 5px 5px',
        borderBottom: `1px solid ${GetColor('StrokeColor')}`,
        background: GetColor('PageBg'),
        borderRadius: 0,
        ':nth-child(odd)': {
            background: GetColor('PageBg'),
        },
    },
    col: {
        display: 'flex',
        padding: 8,
        alignItems: 'center',
        flex: 1,
    },
    headerCol: {
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
    },
    sort: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    sortBtn: {
        display: 'block',
        marginLeft: 10,
        boxShadow: 'none',
        border: 0,
        color: GetColor('TextColor'),
        background: 'transparent',
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
        },
    },
    clickable: {
        cursor: 'pointer',
    },
});
