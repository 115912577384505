type AllowedExtensions = 'jpg' | 'png' | 'gif' | 'webp';

type AllowedSizes =
    | 10
    | 20
    | 30
    | 40
    | 50
    | 60
    | 70
    | 80
    | 90
    | 100
    | 200
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800
    | 900
    | 1000
    | 1100
    | 1200
    | 1300
    | 1400
    | 1500
    | 'auto';

export type ThumbnailConfig = {
    width: AllowedSizes;
    height: AllowedSizes;
    ResizeType?: 'fit' | 'fill' | 'resize'; // default is "fit"
    BackgroundType?: 'black' | 'white' | 'blur' | 'none'; // default is "none" (white for jpg and gif)
};

export default function GetFileThumbnailURL(
    file: {
        url: string;
        mime: string;
    },
    thumbnail_config?: ThumbnailConfig,
    extension?: AllowedExtensions
): string {
    let originalFileExtension = file.url.split('.').pop() as AllowedExtensions;

    if (!CanMakeThumbnail(file.mime)) {
        return file.url;
    } else if (file.url.length === 0) {
        return file.url;
    } else if (!thumbnail_config && !extension) {
        return file.url;
    } else if (extension === originalFileExtension && !thumbnail_config) {
        return file.url;
    }

    let url = new URL(file.url, !file.url.startsWith('http') ? window.location.origin : undefined);
    if (url.origin !== FILES_CDN_URL) {
        if (extension !== originalFileExtension) {
            return '';
        }

        return file.url;
    }

    if (url.pathname.startsWith('/private/')) {
        url.pathname = `/private/thumbnails/${url.pathname.slice('/private/'.length)}`;
    } else {
        url.pathname = `/thumbnails${url.pathname}`;
    }

    if (!extension) {
        extension = originalFileExtension;
    }

    if (!thumbnail_config) {
        url.pathname = `${url.pathname}/thumbnail.${extension}`;
    } else {
        url.pathname = `${url.pathname}/thumbnail-${[
            getSizeLine(thumbnail_config),
            thumbnail_config.ResizeType,
            thumbnail_config.BackgroundType,
        ]
            .filter(Boolean)
            .join('-')}.${extension}`;
    }

    return url.toString();
}

function getSizeLine(thumbnail_config: ThumbnailConfig) {
    if (thumbnail_config.width === 'auto' && thumbnail_config.height === 'auto') {
        console.warn("Both width and height are set to 'auto'. This is not supported and will be ignored.");
        return '';
    } else if (thumbnail_config.width === 'auto') {
        return `h${thumbnail_config.height}`;
    } else if (thumbnail_config.height === 'auto') {
        return `w${thumbnail_config.width}`;
    } else {
        return `w${thumbnail_config.width}xh${thumbnail_config.height}`;
    }
}

export function CanMakeThumbnail(mime: string): boolean {
    if (['image/jpeg', 'image/jpg', 'image/jpe', 'image/png', 'image/webp', 'image/gif'].includes(mime)) {
        return true;
    }

    return false;
}
