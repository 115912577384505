import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    file: {
        position: 'relative',
        display: 'inline-block',
        marginTop: 7,
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('Primary'),
        textAlign: 'center',
    },
    fileIcon: {
        margin: '0 auto 10px auto',
    },
    removeFile: {
        display: 'flex',
        position: 'absolute',
        top: 2,
        right: 2,
        width: 16,
        height: 16,
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        border: 0,
        color: GetColor('Gray'),
        background: 'transparent',
        cursor: 'pointer',
        zIndex: 2,
        ':nth-child(1n) svg': {
            display: 'block',
        },
        ':hover': {
            color: GetColor('Danger'),
        },
        ':focus': {
            color: GetColor('Danger'),
        },
    },
    removeBtn: {
        width: '100%',
        marginTop: 5,
        ':hover': {
            color: GetColor('Danger'),
            background: GetColor('PageBg'),
        },
        ':focus': {
            color: GetColor('Danger'),
            background: GetColor('PageBg'),
            outline: 0,
        },
        ':hover:not(:disabled)': {
            color: GetColor('Danger'),
            background: GetColor('PageBg'),
        },
    },
    name: {
        width: 100,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    fileName: {
        width: 100,
    },
    fileSize: {
        marginTop: 5,
        fontSize: '0.8125rem',
        color: GetColor('Secondary'),
    },
    img: {
        maxWidth: '100%',
        height: 100,
        marginBottom: 10,
        borderRadius: 8,
    },
    removeImg: {
        display: 'flex',
        position: 'absolute',
        top: -7,
        right: -7,
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        border: 0,
        borderRadius: 8,
        color: GetColor('WhiteTextColor'),
        background: GetColor('Danger'),
        cursor: 'pointer',
        zIndex: 2,
        ':nth-child(1n) svg': {
            display: 'block',
        },
    },
});
