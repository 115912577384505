import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';
import {css} from 'aphrodite';
import * as r from 'runtypes';

export default function RuntypeGuidlines({runtype}: {runtype: r.Record<any, false>}) {
    return (
        <div className={css(Styles.container)}>
            <div>This chart is expecting to have the following columns in the SQL statement: </div>
            <div className={css(Styles.feilds)}>
                {Object.entries(runtype.fields).map(([key, value]) => (
                    <div key={key}>
                        <strong>{key}</strong> - {(value as any).tag}
                    </div>
                ))}
            </div>
        </div>
    );
}

export const Styles = CreateSheet({
    container: {
        fontSize: 14,
        marginBottom: 15,
    },
    feilds: {
        display: 'inline-block',
        backgroundColor: GetColor('TopLayerObjectBg'),
        padding: 15,
        borderRadius: 14,
        marginTop: 15,
        fontSize: 12,
    },
});
