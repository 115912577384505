import * as EXIF from 'exif-js';

// returns base64 of fixed image
class ImageAdjuster {
    fixImageOrientation(
        originImage: HTMLImageElement,
        square: boolean | undefined,
        callback: (fixedImageBase64: string) => void
    ) {
        // @ts-ignore
        EXIF.getData(originImage, function () {
            let canvas = document.createElement('canvas');

            // @ts-ignore
            canvas.globalCompositeOperation = 'destination-over';
            let context = canvas.getContext('2d');
            let portion = 1;
            if (originImage.width > 3000) {
                portion = originImage.width / 3000;
            }
            let originImageWidth = originImage.width / portion;
            let originImageHeight = originImage.height / portion;
            let fixedImageWidth = originImageWidth;
            let fixedImageHeight = originImageHeight;

            // @ts-ignore
            let imageOrientation = EXIF.getTag(this, 'Orientation');
            if (imageOrientation > 4) {
                // required rotation. should be resized
                let multiplier = originImageHeight / originImageWidth;
                fixedImageHeight = originImageHeight * multiplier;
                fixedImageWidth = originImageHeight;

                canvas.width = square ? fixedImageWidth : fixedImageHeight;
                canvas.height = fixedImageWidth;
            } else {
                canvas.width = originImageWidth;
                canvas.height = square ? originImageWidth : originImageHeight;
            }

            switch (imageOrientation) {
                case 2: // top-right
                    context!.transform(-1, 0, 0, 1, fixedImageWidth, 0);
                    break;
                case 3: // bottom-right
                    context!.transform(-1, 0, 0, -1, fixedImageWidth, fixedImageHeight);
                    break;
                case 4: // bottom-left
                    context!.transform(1, 0, 0, -1, 0, fixedImageHeight);
                    break;
                case 5: // left-top
                    context!.transform(0, 1, 1, 0, 0, 0);
                    break;
                case 6: // right-top
                    context!.transform(0, 1, -1, 0, fixedImageHeight, 0);
                    break;
                case 7: // right-bottom
                    context!.transform(0, -1, -1, 0, fixedImageHeight, fixedImageWidth);
                    break;
                case 8: // left-bottom
                    context!.transform(0, -1, 1, 0, 0, fixedImageWidth);
                    break;
                default:
                    break;
            }

            if (square) {
                context!.drawImage(
                    originImage,
                    canvas.width / 2 - fixedImageWidth / 2,
                    canvas.height / 2 - fixedImageHeight / 2,
                    fixedImageWidth,
                    fixedImageHeight
                );
            } else {
                context!.drawImage(originImage, 0, 0, fixedImageWidth, fixedImageHeight);
            }
            callback(canvas.toDataURL());
        });
    }
}

const instance = new ImageAdjuster();
export default instance;
