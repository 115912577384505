import {jwtAuthenticator} from 'nats.ws';
import InitNATS, {NatsConnection} from 'lib/nats-client/InitNATS';
import Publish from 'lib/nats-client/Publish';
import Request from 'lib/nats-client/Request';
import Subscribe, {Subscription} from 'lib/nats-client/Subscribe';
import {Body} from 'lib/nats-client/model';
import events from 'events';
import GetCookie from 'lib/cookie/GetCookie';
import IsCookieAvailable from 'lib/cookie/IsCookieAvailable';
import {session} from 'src/runtime/session';
import {UserToServerMessages} from './UserToServerMessages';
import {ServerToUserMessages} from './ServerToUserMessages';

type UserToServerSubjectKeys = keyof UserToServerMessages;
type UserToServerMarkerKeys<T extends UserToServerSubjectKeys> = keyof UserToServerMessages[T] extends string
    ? keyof UserToServerMessages[T]
    : never;

type UserToServerMarkerRequest<
    T extends UserToServerSubjectKeys,
    M extends UserToServerMarkerKeys<T>
> = UserToServerMessages[T][M] extends {
    request: infer R;
}
    ? R
    : never;

type UserToServerMarkerResponse<
    T extends UserToServerSubjectKeys,
    M extends UserToServerMarkerKeys<T>
> = UserToServerMessages[T][M] extends {
    response: infer R;
}
    ? R
    : never;

class NATSClient extends events.EventEmitter {
    server: string;
    user_token: string | null;
    sids: Record<string, Subscription>;
    nats?: NatsConnection;
    current_jwt: string = '';
    connection_status: 'connecting' | 'connected' | 'failed' = 'connecting';

    constructor(server: string, user_token: string | null) {
        super();

        this.server = server;
        this.user_token = user_token;

        this.init();

        this.sids = {};
    }

    private getCredentials() {
        if (IsCookieAvailable()) {
            const nats_credentails = GetCookie('whitetail-ai-nats')?.split(':');
            if (nats_credentails && nats_credentails.length === 2) {
                return {
                    seed: nats_credentails[0],
                    jwt: nats_credentails[1],
                };
            }
        }

        return null;
    }

    private getPersonalSubject(): `server.user.personal.${string}` {
        return `server.user.personal.${session.user.nats_user_token}`;
    }

    private async init() {
        await this.setConnection();
    }

    private async setConnection() {
        try {
            const credentails = this.getCredentials();
            if (credentails === null) {
                this.connection_status = 'failed';
                console.log('invalid websocket crendentials');
                return;
            }
            this.connection_status = 'connecting';
            this.current_jwt = '';
            this.nats = await InitNATS(
                this.server,
                this.user_token || 'logged-out-user',
                jwtAuthenticator(credentails.jwt, new TextEncoder().encode(credentails.seed))
            );
            this.connection_status = 'connected';
            this.current_jwt = credentails.jwt;
        } catch (error) {
            this.connection_status = 'failed';
            this.current_jwt = '';
            console.log(error);
        }
    }

    private async wait_connection(): Promise<void> {
        if (this.connection_status !== 'connecting') {
            return;
        }

        return new Promise(resolve => {
            this.ticker(resolve);
        });
    }

    private ticker(resolve: () => void) {
        setTimeout(() => {
            if (this.connection_status !== 'connecting') {
                resolve();
            } else {
                this.ticker(resolve);
            }
        }, 100);
    }

    private async reconnect() {
        if (this.connection_status === 'failed' || (this.nats && this.nats.isClosed())) {
            await this.setConnection();
        }

        if (this.connection_status === 'connecting') {
            await this.wait_connection();
        }
    }

    async PushToSubject<T extends UserToServerSubjectKeys, M extends UserToServerMarkerKeys<T>>(
        subject: T,
        marker: M,
        payload: UserToServerMarkerRequest<T, M>
    ) {
        await this.reconnect();
        if (!this.nats) {
            console.error('No active nats connection');
            return;
        }

        Publish(this.nats, subject, {marker, payload: payload || {}}, this.current_jwt);
    }

    async RequestToSubject<T extends UserToServerSubjectKeys, M extends UserToServerMarkerKeys<T>>(
        subject: T,
        marker: M,
        payload: UserToServerMarkerRequest<T, M>
    ): Promise<UserToServerMarkerResponse<T, M> | null> {
        await this.reconnect();
        if (!this.nats) {
            throw new Error('No active nats connection');
        }

        // reply doesn't work for signed out users due to NATS security
        if (!LOGGED_IN) {
            await this.PushToSubject(subject, marker, payload);
        }

        return (await Request(
            this.nats,
            subject,
            `${this.getPersonalSubject()}.reply`,
            {
                marker,
                payload: payload || {},
            },
            this.current_jwt
        )) as UserToServerMarkerResponse<T, M> | null;
    }

    private onNatsMessage = (subject: string) => (body: Body<any>) => {
        this.emit(subject, body);
    };

    async ListenSubject<T extends keyof ServerToUserMessages>(
        subject: T,
        callback: (message: ServerToUserMessages[T]) => void
    ) {
        await this.reconnect();

        if (this.listenerCount(subject) === 0) {
            if (!this.nats) {
                console.error('No active nats connection');
                return;
            }

            this.sids[subject] = Subscribe(this.nats, subject, this.onNatsMessage(subject));
        }

        this.on(subject, callback);
    }

    StopListenSubject<T extends keyof ServerToUserMessages>(
        subject: T,
        callback: (message: ServerToUserMessages[T]) => void
    ) {
        this.removeListener(subject, callback);

        if (this.nats && this.listenerCount(subject) === 0) {
            if (this.sids[subject]) {
                this.sids[subject].unsubscribe();
                delete this.sids[subject];
            }
        }
    }

    ListenPersonal(callback: (message: ServerToUserMessages['server.user.personal.user_token']) => void) {
        if (session.user.nats_user_token) {
            this.ListenSubject(this.getPersonalSubject(), callback);
        }
    }

    StopListenPersonal(callback: (message: ServerToUserMessages['server.user.personal.user_token']) => void) {
        if (session.user.nats_user_token) {
            this.StopListenSubject(this.getPersonalSubject(), callback);
        }
    }
}

let instance: NATSClient;

function CreateNATSConnection() {
    if (!instance) {
        instance = new NATSClient(NATS_SERVER, session.user.nats_user_token);
    }

    return instance;
}

export default CreateNATSConnection;
