import * as r from 'runtypes';

export const UserMetadataRuntype = r.Record({
    guid: r.String,
    is_verified: r.Boolean,
    email: r.String,
    first_name: r.String,
    last_name: r.String,
    full_name: r.String,
    created_at: r.Number,
});
export type UserMetadata = r.Static<typeof UserMetadataRuntype>;
