import {CreateSheet, css, GetColor} from 'aphrodite';
import {get} from 'lib/fetch';
import {useDetachedEffect} from 'packages/useDetached';
import {useState} from 'react';

type res = Array<{content: string; role: string}>;

export default function DebugPage() {
    const [data, setData] = useState<res>([]);

    useDetachedEffect(() => {
        async function load() {
            const res = await get<res>({url: 'debug'});
            if (res[1]) {
                alert(res[1]);
            } else {
                setData(res[0]);
            }
        }

        load();
    }, []);

    return (
        <div className={css(Styles.page)}>
            {data.length === 0 && <div>Loading</div>}
            {data.map((item, i) => (
                <p
                    key={i}
                    className={css(Styles.message)}
                >
                    <div>
                        <strong>{item.role}</strong>
                    </div>
                    {item.content}
                </p>
            ))}
        </div>
    );
}

const Styles = CreateSheet({
    page: {
        width: '100%',
        background: GetColor('PageBg'),
        padding: 20,
    },
    message: {
        padding: 10,
        maxWidth: 1024,
        whiteSpace: 'pre-line',
        marginBottom: 10,
        border: '1px solid rgba(255, 255, 255, 0.1)',
        borderRadius: 24,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 50px 0px',
    },
});
