import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    container: {
        width: 1200,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '60px 40px',
        '@media(max-width: 640px)': {
            padding: '0 15px',
        },
    },
    contentHeader: {
        marginTop: 50,
        marginBottom: 30,
    },
    contentHeaderTitle: {
        margin: 0,
        fontSize: '2.125rem',
        fontWeight: 700,
        color: GetColor('TextColor'),
    },
    contentHeaderSubTitle: {
        margin: 0,
        fontSize: '1.25rem',
        fontWeight: 600,
        color: GetColor('Primary'),
    },
    mainTitle: {
        margin: '50px 0 0 0',
        fontSize: '2.4286rem',
        fontWeight: 500,
        lineHeight: '2.9286rem',
        '@media(max-width: 900px)': {
            fontSize: '1.5714rem',
            lineHeight: '1.8571rem',
        },
    },
    mainSubTitle: {
        margin: '40px 0 0 0',
        fontSize: '1.7143rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
        '@media(max-width: 900px)': {
            fontSize: '1.2857rem',
            lineHeight: '1.5rem',
        },
    },
    mainSmallTitle: {
        margin: '20px 0 0 0',
        fontSize: '1.2857rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
    },
    strong: {
        fontWeight: 600,
    },
    listItemLink: {
        fontWeight: 500,
    },
    link: {
        color: GetColor('Primary'),
        textDecoration: 'underline',
        wordBreak: 'break-word',
    },
    list: {
        margin: 0,
        paddingLeft: 30,
        listStyle: 'disc',
    },
    listNumbers: {
        listStyle: 'decimal',
    },
    listItem: {
        marginTop: 15,
    },
    textBlock: {
        margin: '15px 0 0 0',
    },
    important: {
        display: 'flex',
        marginTop: 15,
        padding: '15px 25px',
        background: GetColor('LightGrayBg'),
    },
});
