import {post} from 'lib/fetch';

type Input = {
    name: string;
    job_title: string;
    company_name: string;
    message: string;
    email: string;
};

export default async function SendFeedback(input: Input) {
    return await post({
        url: 'feedback/requests',
        body: input,
    });
}
