import {useState} from 'react';
import SafeUpdate, {CustomCommands, Spec} from 'immutability-helper';

export default function useSafeState<S, C extends CustomCommands<object> = never>(
    initialState: S | (() => S)
): [S, ($spec: Spec<S, C>, customIsChanged?: boolean) => void, boolean] {
    const [state, setState] = useState<S>(initialState);
    const [isChanged, setIsChanged] = useState(false);

    function safeSetState($spec: Spec<S, C>, customIsChanged?: boolean) {
        //@ts-ignore
        setState(s => SafeUpdate(s, $spec));
        setIsChanged(typeof customIsChanged !== 'undefined' ? customIsChanged : true);
    }

    return [state, safeSetState, isChanged];
}
