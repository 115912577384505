import {useState} from 'react';

export default function useWaitFor(
    initial_state?: boolean
): [boolean, <R>(trigger: Promise<R>) => Promise<R>, (is_waiting: boolean) => void] {
    const [isWaiting, setIsWaiting] = useState(initial_state || false);

    async function triggerWaiting<R>(trigger: Promise<R>): Promise<R> {
        setIsWaiting(true);
        const res = await trigger;
        setIsWaiting(false);
        return res;
    }

    return [isWaiting, triggerWaiting, setIsWaiting];
}
