import {post} from 'lib/fetch';
import {BlogPostRuntype, CreateBlogPostInput} from '../model';

export default async function CreateBlogPostAPI(input: CreateBlogPostInput) {
    return await post(
        {
            url: `blog`,
            body: input,
        },
        BlogPostRuntype
    );
}
