import {CreateSheet, GetColor, css} from 'aphrodite';
import showWarning, {showErrorLightbox} from 'lib/lightbox/warning.react';
import {useState} from 'react';
import {ProjectStatus} from 'packages/projects';
import {ConnectFacebookUserDataSource} from 'packages/usersdatasources/facebook';
import {useDashboardStore} from 'src/jsx/dashboard/DashboardStore';
import {ReactComponent as DoneIcon} from 'src/icons/done.svg';
import {ReactComponent as FacebookIcon} from 'src/icons/social/facebook.svg';
import Spinner from 'packages/spinners/spinner.react';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import {FacebookAdAccount} from 'packages/usersdatasources/model';

export default function FacebookConnect({
    onConnect,
    ...props
}: {
    onConnect: (user_data_source_guid: string) => void;
    isLoading?: boolean;
}) {
    const [project, reloadProject] = useDashboardStore(s => [s.project, s.reload]);
    const [isLoading, setIsLoading] = useState(false);

    async function onClickConnectFacebook() {
        if (isLoading || props.isLoading) {
            return;
        } else if (project?.status_id === ProjectStatus.Analyzing || project?.status_id === ProjectStatus.Ready) {
            showWarning('Facebook is already connected to the project');
            return;
        }

        setIsLoading(true);
        const res = await ConnectFacebookUserDataSource();
        if (res.error_text) {
            showErrorLightbox(res.error_text);
        } else if (res.payload?.user_data_source_guid) {
            onConnect(res.payload.user_data_source_guid);
            setIsLoading(false);
        } else {
            await reloadProject();
            setIsLoading(false);
        }
    }

    const isProjectPassedCreation = Boolean(
        project?.status_id !== ProjectStatus.Created ||
            project.data_sources.find(ds => ds.user_data_source_type_id === 'facebook')
    );

    return (
        <FacebookConnectButton
            isLoading={isLoading || props.isLoading}
            isConnected={isProjectPassedCreation}
            onClickConnectFacebook={onClickConnectFacebook}
        />
    );
}

export function FacebookConnectButton({
    isLoading,
    isConnected,
    onClickConnectFacebook,
}: {
    isLoading?: boolean;
    isConnected?: boolean;
    onClickConnectFacebook?: () => void;
}) {
    return (
        <div
            className={css(Styles.connect, isConnected && Styles.connectConnected)}
            onClick={onClickConnectFacebook}
        >
            <FacebookIcon
                width="36"
                height="36"
                className={css(Styles.connectIcon)}
            />
            <span>{isConnected ? 'FB account is connected' : 'Connect to FB Ads Manager'}</span>
            <div className={css(Styles.connectSpinner)}>
                {isLoading ? (
                    <Spinner
                        theme={PrimaryTheme}
                        height={24}
                    />
                ) : isConnected ? (
                    <DoneIcon
                        width={24}
                        height={24}
                        className={css(Styles.connectSuccessIcon)}
                    />
                ) : null}
            </div>
        </div>
    );
}

export function FacebookAdAccountPicker({
    ad_accounts,
    selected,
    onSelectAdAccount,
}: {
    ad_accounts: FacebookAdAccount[];
    selected?: FacebookAdAccount;
    onSelectAdAccount: (adAccount: FacebookAdAccount) => void;
}) {
    return (
        <ul className={css(Styles.fbAdAccountsList)}>
            {ad_accounts.map(account => (
                <li
                    key={account.id}
                    className={css(
                        Styles.fbAdAccountsItem,
                        selected?.id === account.id && Styles.fbAdAccountsItemSelected
                    )}
                    onClick={() => {
                        onSelectAdAccount(account);
                    }}
                >
                    {account.name}
                </li>
            ))}
        </ul>
    );
}

const Styles = CreateSheet({
    connect: {
        display: 'flex',
        maxWidth: 300,
        margin: '7px 0 10px 0',
        padding: '10px 20px',
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
        alignItems: 'center',
        borderRadius: 14,
        border: `2px solid ${GetColor('StrokeColor')}`,
        background: GetColor('ButtonBg'),
        cursor: 'pointer',
    },
    connectConnected: {
        border: `2px solid ${GetColor('Success')}`,
        cursor: 'default',
    },
    connectIcon: {
        display: 'block',
        marginRight: 15,
        flexShrink: 0,
    },
    connectSpinner: {
        width: 24,
        height: 24,
        marginLeft: 20,
    },
    connectSuccessIcon: {
        color: GetColor('Success'),
    },
    fbAdAccountsList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    fbAdAccountsItem: {
        margin: '0px 0px 5px 0px',
        padding: '5px 10px',
        borderRadius: 5,
        cursor: 'pointer',
        textDecoration: 'underline',
        ':hover': {
            backgroundColor: GetColor('PageBg'),
        },
    },
    fbAdAccountsItemSelected: {
        backgroundColor: GetColor('PageBg'),
    },
});
