import React, {useState, useRef} from 'react';
import ReactCrop, {type Crop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {css, CreateSheet} from 'aphrodite';
import useOnce from 'lib/hooks/useOnce';
import imageAdjuster from './imageAdjuster';

export type ImageCropperProps = {
    image: File;
    aspect?: number;
    minWidth?: number;
    minHeight?: number;
    onImageCropped: (data: File) => void;
};

export default function ImageCropper({aspect, minWidth, minHeight, image, onImageCropped}: ImageCropperProps) {
    const imageRef = useRef<HTMLImageElement>(null);

    const [crop, setCrop] = useState<Crop>({
        width: 100,
        height: 100,
        x: 0,
        y: 0,
        unit: 'px',
    });
    const [ImageSource, setImageSource] = useState<string>('');
    const [Loading, setLoading] = useState<boolean>(false);

    useOnce(() => {
        const imageItem = new Image();
        imageItem.src = URL.createObjectURL(image);
        imageItem.onload = () => {
            imageAdjuster.fixImageOrientation(imageItem, false, (fixedImageBase64: string) => {
                setLoading(false);
                setImageSource(fixedImageBase64);
            });
        };
    });

    const onCropComplete = (crop: Crop) => {
        makeClientCrop(crop);
    };
    const makeClientCrop = async (crop: Crop) => {
        if (imageRef.current && crop.width && crop.height) {
            makeCroppedImg(imageRef.current, crop);
        }
    };

    const makeCroppedImg = (newImage: HTMLImageElement, crop: Crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = newImage.naturalWidth / newImage.width;
        const scaleY = newImage.naturalHeight / newImage.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d')!;
        ctx.globalCompositeOperation = 'source-over';

        ctx.drawImage(
            document.getElementById('cropped-image') as CanvasImageSource,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        if (typeof canvas.toBlob !== 'function') {
            const dataUrl = canvas.toDataURL(image.type);
            const binary = atob(dataUrl.split(',')[1]);
            const array: number[] = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }

            onImageCropped(blobToFile(new Blob([new Uint8Array(array)], {type: image.type})));
        } else {
            canvas.toBlob(
                blob => {
                    if (blob) {
                        onImageCropped(blobToFile(blob));
                    }
                },
                image.type,
                1
            );
        }
    };

    function blobToFile(blob: Blob): File {
        return new File([blob], image.name, {type: image.type});
    }

    const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const newImage = e.currentTarget;
        let width = newImage.width,
            height = newImage.height;
        if (aspect) {
            [width, height] = getCropperWidthHeight(newImage, aspect);
        }
        const crop: Crop = {
            width: width,
            height: height,
            x: 0,
            y: 0,
            unit: 'px',
        };
        setCrop(crop);
        onCropComplete(crop);
    };

    return (
        <div className={css(Styles.main)}>
            {ImageSource && !Loading && (
                <ReactCrop
                    crop={crop}
                    onChange={setCrop}
                    onComplete={onCropComplete}
                    aspect={aspect}
                    minWidth={minWidth || 30}
                    minHeight={minHeight || 30}
                >
                    <img
                        id="cropped-image"
                        ref={imageRef}
                        src={ImageSource}
                        alt="uploaded file"
                        onLoad={onImageLoaded}
                    />
                </ReactCrop>
            )}
        </div>
    );
}

function getCropperWidthHeight(image: HTMLImageElement, cropper_aspect: number): [number, number] {
    const srcW = image.width;
    const srcH = image.height;

    if (srcW <= 0 || srcH <= 0) {
        return [0, 0];
    }

    const srcAspectRatio = srcW / srcH;

    let newW,
        newH = 0;
    if (cropper_aspect > srcAspectRatio) {
        newW = image.width;
        newH = image.width / cropper_aspect;
    } else {
        newH = image.height;
        newW = image.height * cropper_aspect;
    }

    return [Math.trunc(newW), Math.trunc(newH)];
}

const Styles = CreateSheet({
    main: {
        ':nth-child(1n) .ReactCrop__image': {
            maxHeight: 'initial',
        },
    },
});
