import {useState} from 'react';
import {css} from 'aphrodite';
import SafeUpdate from 'lib/helpers/SafeUpdate';
import {MakeButton} from 'packages/elements/button';
import FloatingDropDown from 'packages/elements/dropdowns';
import {MenuItem} from 'packages/elements/menu';
import TextField from 'packages/elements/textfields/TextField';
import InputStyles from 'packages/elements/textfields/TextField.jss';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {ReactComponent as Arrow} from 'src/icons/arrow-down.svg';
import {ReactComponent as Warning} from 'src/icons/warning.svg';
import Styles from '../common.jss';

export const IndustriesTypes = ['Game', 'Mobile App', 'e-Commerce', 'Other'] as const;
export const CompanySizeTypes = ['1-5', '5-10', '10-25', '25-50', '50-250', '250+'] as const;

type FieldWithError<T> = {
    value: T;
    err: string;
};

export function MakeEmptyFieldWithError<T>(value: T): FieldWithError<T> {
    return {
        value,
        err: '',
    };
}

export type EditFormData = {
    fullName: FieldWithError<string>;
    company: FieldWithError<string>;
    size: FieldWithError<(typeof CompanySizeTypes)[number] | ''>;
    industry: FieldWithError<(typeof IndustriesTypes)[number] | ''>;
};

const FieldsLabelsMap: Record<keyof EditFormData, string> = {
    fullName: 'Full name',
    company: 'Company name',
    size: 'Company size',
    industry: 'Industry',
};

export const EmptyEditFormData: EditFormData = {
    fullName: MakeEmptyFieldWithError(''),
    company: MakeEmptyFieldWithError(''),
    size: MakeEmptyFieldWithError(''),
    industry: MakeEmptyFieldWithError(''),
};

type EditFormProps = {
    data: EditFormData;
    onChange: (data: EditFormData) => void;
    commonErr?: string;
};

export const validateEditForm = (data: EditFormData): EditFormData => {
    let validatedData = data;
    Object.keys(validatedData).forEach((key: string) => {
        const field = key as keyof EditFormData;
        const value = validatedData[field].value;

        if (typeof value === 'string' && value.length === 0) {
            validatedData = SafeUpdate(validatedData, {
                [key]: {err: {$set: `${FieldsLabelsMap[field]} is required`}},
            });
        }
    });

    return validatedData;
};

const EditForm: React.FC<EditFormProps> = ({data, commonErr, onChange}) => {
    const [isIndustryOpen, setIsIndustryOpen] = useState(false);

    const handleChange = (field: keyof EditFormData, value: any) => {
        onChange(SafeUpdate(data, {[field]: {value: {$set: value}, err: {$set: ''}}}));
    };

    return (
        <>
            <div className={css(Styles.inputContainer)}>
                <TextField
                    type="text"
                    label="Full name"
                    placeholder="Enter your full name"
                    value={data.fullName.value}
                    onChange={e => handleChange('fullName', e.target.value)}
                    error={data.fullName.err || undefined}
                    styles={{
                        element: Styles.input,
                    }}
                />
            </div>
            <div className={css(Styles.inputContainer)}>
                <TextField
                    type="text"
                    label="Company name"
                    placeholder="Enter company name"
                    value={data.company.value}
                    onChange={e => handleChange('company', e.target.value)}
                    error={data.company.err || undefined}
                    styles={{
                        element: Styles.input,
                    }}
                />
            </div>
            <div className={css(Styles.inputContainer)}>
                <div className={css(InputStyles.label)}>Industry</div>
                <FloatingDropDown
                    onStatusChange={setIsIndustryOpen}
                    styles={{
                        trigger: [
                            InputStyles.input,
                            Styles.selectInput,
                            data.industry.err ? InputStyles.input_error : null,
                        ],
                        floatingBlock: [Styles.select],
                    }}
                    trigger={
                        <>
                            <span className={css(!data.industry.value && Styles.selectPlaceholder)}>
                                {data.industry.value || 'Select'}
                            </span>
                            {!data.industry.err ? (
                                <Arrow
                                    width="16"
                                    height="16"
                                    role="img"
                                    title="Arrow"
                                    className={css(Styles.selectArrow, isIndustryOpen && Styles.selectArrowOpened)}
                                />
                            ) : (
                                <Warning
                                    width="16"
                                    height="16"
                                    role="img"
                                    title="Error"
                                    className={css(Styles.errorIcon)}
                                />
                            )}
                            {data.industry.err ? (
                                <div className={css(InputStyles.error_element, Styles.inputError)}>
                                    {data.industry.err}
                                </div>
                            ) : null}
                        </>
                    }
                    settings={{
                        closeOn: {
                            clickFloatingBlock: true,
                        },
                        placement: {
                            useSize: true,
                            useWidth: true,
                            useSizeOffset: 30,
                        },
                    }}
                >
                    {IndustriesTypes.map((item, index) => (
                        <MenuItem
                            key={index}
                            settings={{big: true}}
                            onClick={() => handleChange('industry', data.industry.value === item ? '' : item)}
                            state={{active: data.industry.value === item}}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </FloatingDropDown>
            </div>
            <div className={css(Styles.inputContainer, Styles.noPaddingBottom)}>
                <div className={css(InputStyles.label)}>Company size</div>
                <div className={css(Styles.subLabel)}>Number of employees</div>
                <div className={css(Styles.chips)}>
                    {CompanySizeTypes.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            className={MakeButton(
                                data.size.value === item ? 'FilledAccent1-Label' : 'FilledGray-Label',
                                Styles.chip
                            )}
                            onClick={() => handleChange('size', data.size.value === item ? '' : item)}
                        >
                            {item}
                        </button>
                    ))}

                    {data.size.err && <small className={css(InputStyles.error_element)}>{data.size.err}</small>}
                </div>
            </div>

            <SmoothLine>{commonErr ? <div className={css(Styles.errorText)}>{commonErr}</div> : undefined}</SmoothLine>
        </>
    );
};
export default EditForm;
