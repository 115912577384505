import {post} from 'lib/fetch';

export type SignUpAPIResponse = {
    token: string;
};

export default function SignUpAPI(email: string, password: string) {
    return post<SignUpAPIResponse>({
        url: '/session',
        body: {email, password},
    });
}
