import {CreateSheet, css} from 'aphrodite';
import Tooltip from 'packages/tooltip';
import {DraftEditorFunctions} from './DraftEditor.react';
import {Mod, ModsObject} from './Mods.react';
import {DraftDefaultLayout, PrimaryDraft} from './themes/draftjs';

export default function DraftNavigation({
    editorRef,
    Mods,
}: {
    editorRef: React.RefObject<DraftEditorFunctions>;
    Mods: ModsObject;
}) {
    const ModClick = (mod: Mod) => (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (editorRef.current) {
            mod.onClick(editorRef.current);
        }
    };

    return (
        <nav className={css(Styles.nav)}>
            {Object.entries(Mods).map(([key, mod]) => {
                return (
                    <Tooltip
                        {...{
                            key,
                            content: mod.tooltip || '',
                            disabled: !mod.tooltip,
                        }}
                    >
                        <div
                            {...{
                                className: css(Styles.navItem),
                                onMouseDown: ModClick(mod),
                            }}
                        >
                            {mod.component}
                        </div>
                    </Tooltip>
                );
            })}
        </nav>
    );
}

const Styles = CreateSheet({
    nav: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        background: PrimaryDraft.nav_background,
        border: PrimaryDraft.nav_border,
        borderBottom: PrimaryDraft.nav_border_bottom,
        borderTopLeftRadius: DraftDefaultLayout.nav_border_top_left_radius,
        borderTopRightRadius: DraftDefaultLayout.nav_border_top_right_radius,
        borderBottomLeftRadius: DraftDefaultLayout.nav_border_bottom_left_radius,
        borderBottomRightRadius: DraftDefaultLayout.nav_border_bottom_right_radius,
    },
    navItem: {
        color: PrimaryDraft.nav_item_color,
        margin: 3,
        padding: 3,
        cursor: 'pointer',
        background: PrimaryDraft.nav_item_background,
        ':hover': {
            background: PrimaryDraft.nav_item_hover_background,
        },
        ':nth-child(1n) svg': {
            display: 'block',
            fill: PrimaryDraft.nav_item_color,
        },
    },
});
