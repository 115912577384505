import {CreateSheet, GeneratedStyles, GetColor, css} from 'aphrodite';
import {LoadButton} from 'packages/elements/button';
import {useEffect, useRef, useState} from 'react';
import {ReactComponent as SendIcon} from 'src/icons/send.svg';
import TextFieldStyles from 'packages/elements/textfields/TextField.jss';
import {LoadButtonProps} from 'packages/elements/button/LoadButton';

type TextareaProps = Omit<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'ref' | 'className'
> & {
    styles?: GeneratedStyles;
};

export default function ChatTextInput({
    props,
    onSubmit,
    asideLeft,
    styles,
}: {
    styles?: {
        container?: GeneratedStyles[];
    };
    props?: {
        textarea?: TextareaProps;
        button?: Partial<LoadButtonProps>;
    };
    asideLeft?: React.ReactNode;
    onSubmit?: (value: string) => void;
}) {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState<string>(
        typeof props?.textarea?.value === 'string'
            ? props?.textarea?.value
            : typeof props?.textarea?.defaultValue === 'string'
            ? props?.textarea?.defaultValue
            : ''
    );

    function submitNewValue() {
        setValue('');
        onSubmit && onSubmit(value);
        if (textAreaRef.current) {
            const opd = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value');
            const opdSetter = opd?.set;
            if (opdSetter) {
                opdSetter.call(textAreaRef.current, '');
                const event = new Event('input', {bubbles: true});
                textAreaRef.current.dispatchEvent(event);
            }
        }
    }

    function onChangeValue(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setValue(e.target.value);
        props?.textarea?.onChange && props?.textarea?.onChange(e);
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submitNewValue();
        }

        props?.textarea?.onKeyDown && props.textarea.onKeyDown(e);
    }

    function onClickButton(e: React.MouseEvent<HTMLButtonElement>) {
        submitNewValue();
        props?.button?.onClick && props.button.onClick(e);
    }

    useEffect(() => {
        if (typeof props?.textarea?.value === 'string' && props?.textarea?.value !== value) {
            setValue(props?.textarea?.value || '');
        }
    }, [value, props?.textarea?.value]);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const scrollHeight = textAreaRef.current.scrollHeight;
            const newHeight = scrollHeight + 5 < 190 ? scrollHeight + 5 : 190;

            textAreaRef.current.style.height = (newHeight < 50 ? 50 : newHeight) + 'px';
        }
    }, [textAreaRef, value]);

    return (
        <div className={css(Styles.container, styles?.container)}>
            {asideLeft}
            <textarea
                {...{
                    ...props?.textarea,
                    ref: textAreaRef,
                    value: value,
                    onChange: onChangeValue,
                    rows: props?.textarea?.rows || 1,
                    className: css(TextFieldStyles.input, Styles.textarea, props?.textarea?.styles),
                    onKeyDown: onKeyDown,
                }}
            />
            <LoadButton
                {...{
                    ...props?.button,
                    type: props?.button?.type || 'button',
                    loading: props?.button?.loading || false,
                    variant: props?.button?.variant || 'FilledAccent1-Default',
                    onClick: onClickButton,
                    styles: [Styles.button, ...(props?.button?.styles ? props?.button?.styles : [])],
                }}
            >
                <SendIcon
                    width="16"
                    height="16"
                />
            </LoadButton>
        </div>
    );
}

const Styles = CreateSheet({
    container: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    textarea: {
        width: '100%',
        resize: 'none',
        border: `1px solid ${GetColor('StrokeColor')}`,
        lineHeight: 1.4,
        ':disabled': {
            ':hover': {
                border: `1px solid ${GetColor('StrokeColor')}`,
            },
        },
        '::-webkit-scrollbar': {
            width: 0,
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
            borderRadius: 8,
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '1px solid rgba(255, 255, 255, 0.10)',
            borderRadius: 8,
        },
    },
    button: {
        height: 50,
        margin: '0 0 0 15px',
        padding: '14px 16px',
        borderRadius: 14,
        border: 0,
        color: GetColor('Accent1'),
        background: GetColor('ButtonBg'),
        boxShadow: 'none',
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
        },
        ':hover': {
            color: GetColor('Black'),
            background: GetColor('Accent1Hover'),
        },
        ':disabled': {
            color: GetColor('Accent1'),
            background: GetColor('ButtonBg'),
            pointerEvents: 'none',
        },
    },
});
