import {DonutChart, DonutChartValue} from '.';
import PrettyTable, {SortableHeader} from 'lib/tables/PrettyTable.react';
import PrintMoney from 'lib/helpers/PrintMoney';
import {CreateSheet, GetColor} from 'aphrodite';

export default function DonutTable({
    chart,
    activeIndex,
    setActiveIndex,
}: {
    chart: DonutChart;
    activeIndex?: number;
    setActiveIndex?: (index: number) => void;
}) {
    return (
        <PrettyTable<DonutChartValue>
            {...{
                thead: [
                    <SortableHeader
                        {...{
                            key: 'id',
                            direction: 'ASC',
                            onClick: () => {},
                        }}
                    >
                        Label
                    </SortableHeader>,
                    <SortableHeader
                        {...{
                            key: 'name',
                            direction: false,
                            onClick: () => {},
                        }}
                    >
                        Value
                    </SortableHeader>,
                ],
                tbody: chart.data.map(item => {
                    return [item.name || 'N/A', item.value ? PrintMoney(item.value) : '0'];
                }),
                rowsStyles: chart.data.map((item, index) => {
                    if (index === activeIndex) return Styles.activeRow;
                    return undefined;
                }),
                items: chart.data,
                onItemClick: setActiveIndex
                    ? (active, e) => {
                          e.stopPropagation();
                          setActiveIndex(chart.data.findIndex(x => x.name === active.name && x.value === active.value));
                      }
                    : undefined,
            }}
        />
    );
}

const Styles = CreateSheet({
    activeRow: {
        background: GetColor('Accent1Dark'),
        ':nth-child(odd)': {
            background: GetColor('Accent1Dark'),
        },
    },
});
