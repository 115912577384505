import React from 'react';
// styles
import {css} from 'aphrodite';
import Styles from './ChatMessages.jss';
import {ReactComponent as UserIcon} from 'src/icons/user.svg';

export default function ChatUserMessages({children, hideAvatars}: {children: React.ReactNode; hideAvatars?: boolean}) {
    return (
        <div className={css(Styles.message, Styles.messageUser)}>
            <div className={css(Styles.messageImgBlock)}>
                <span>You</span>
                {!hideAvatars && (
                    <div className={css(Styles.messageImg, Styles.messageImgUser)}>
                        <UserIcon
                            width={36}
                            height={36}
                        />
                    </div>
                )}
            </div>
            <div className={css(Styles.messageBodyUser)}>
                <div className={css(Styles.messageName)}>You</div>
                {children}
            </div>
        </div>
    );
}
