import {useDashboardStore} from '../DashboardStore';
import DashboardModelOutputPage from './ModelOutput/DashboardModelOutputPage';
import Onboarding from './Onboarding/OnboardingPage';

export default function DashboardLandingPage() {
    const [isOnboarding] = useDashboardStore(s => [s.isOnboarding]);

    if (isOnboarding) {
        return <Onboarding />;
    } else {
        return <DashboardModelOutputPage />;
    }
}
