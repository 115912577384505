import {create} from 'zustand';
import {General, createGeneral} from './createGeneral';
import {ZustandErrors, createErrors} from 'lib/zustand-extend/createErrors';
import {CreatePostErrorsList} from './model';

export type CreatePostStore = General & ZustandErrors<CreatePostErrorsList>;

export const useCreatePostStore = create<CreatePostStore>((...a) => ({
    ...createGeneral(...a),
    ...createErrors(...a),
}));
