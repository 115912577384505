import update, {Spec} from 'immutability-helper';
import {GetListParams} from 'lib/fetch';
import {Loadable} from 'lib/types/Loadable';
import {Pagination} from 'packages/pagination/pagination';
import {StateCreator} from 'zustand';
import LoadBlogListAPI from '../../API/LoadBlogListAPI';
import {BlogPostMetadata, SortByTitles} from '../../model';
import {BlogStore} from './useBlogStore';

export type Layout = {
    showBottomDrawer: boolean;
};

export type Data = {
    items: BlogPostMetadata[];
    pagination: Pagination;
};

type DataType = Loadable<Data> & {
    is_initialized: boolean;
};

type Query = {
    order_by: keyof typeof SortByTitles;
    order_dir: 'ASC' | 'DESC';
    q: string;
    show_hidden: number;
};

type SetQueryType = (params: Partial<Query>) => void;

export type General = {
    init: (query: Query, setQuery: SetQueryType) => Promise<void>;
    layout: Layout;
    data: DataType;
    setData: (spec: Spec<DataType, never>) => void;
    setLayout: (spec: Spec<Layout, never>) => void;
    loadData: (params: GetListParams) => Promise<void>;
    storeQuery: Query;
    setStoreQuery: SetQueryType;
};

const emptyGeneral = {
    layout: {
        showBottomDrawer: false,
    },
    data: {
        is_loaded: false,
        is_loading: true,
        is_initialized: false,
    },
    storeQuery: {
        order_by: 'published_on',
        order_dir: 'DESC',
        q: '',
        show_hidden: 0,
    },
    setStoreQuery: data => {
        return;
    },
} satisfies Partial<General>;

export const createGeneral: StateCreator<BlogStore, [], [], General> = (set, get) => ({
    ...emptyGeneral,
    init: async (query: Query, setQuery: SetQueryType) => {
        set({
            ...emptyGeneral,
            data: {
                is_loading: true,
                is_loaded: false,
                is_initialized: true,
            },
            storeQuery: query,
            setStoreQuery: data => {
                setQuery(data);
                set({storeQuery: {...get().storeQuery, ...data}});
            },
        });
    },
    setData: spec => {
        set({data: update(get().data, spec)});
    },
    setLayout: spec => {
        set({layout: update(get().layout, spec)});
    },
    loadData: async (params: GetListParams) => {
        const [res, err] = await LoadBlogListAPI(params);
        if (err !== null) {
            alert(err.message);
        } else {
            const currentState = get();
            get().setData({
                $set: {
                    is_loading: false,
                    is_loaded: true,
                    is_initialized: true,
                    items:
                        currentState.data.is_loaded && (params?.page_id ?? 1) > 1
                            ? currentState.data.items.concat(res.list)
                            : res.list,
                    pagination: res.pagination,
                },
            });
        }
    },
});
