import {
    Error,
    UploaderInput,
    SignedInitResponse,
    SignatureResponse,
    Uploader,
    UploaderPart,
    CompleteResponse,
    CompleteResponseRuntypes,
    EmptyUploadPart,
    EmptyUploader,
} from './model';
import {
    ErrMissingUploader,
    ErrCantGetUploadID,
    ErrCantUploadPart,
    ErrCantComplete,
    ErrNotSupported,
    ErrCantSign,
    ErrCantAbort,
    ErrAborted,
} from './errors';
import {post, put, patch, del} from 'lib/fetch';
import axios from 'axios';
import SafeUpdate from 'lib/helpers/SafeUpdate';
import CreateGUID from 'lib/helpers/CreateGUID';
import ExtractImageMetadata from './ImageMetadata';

let Uploaders: Record<string, Uploader> = {};
const InitProgress = 10;
const PartsProgressGap = 80;

export async function InitUploader({
    bucket,
    prefix,
    file,
    file_name,
    part_size,
    on_progress,
    on_complete,
    acl,
}: UploaderInput): Promise<string> {
    if (!IsSupported()) {
        on_complete(OnError('', ErrNotSupported));
        return '';
    }

    let uploader = {
        ...EmptyUploader,
        ...{
            Bucket: bucket,
            File: file,
            FileName: (prefix ? prefix + '/' : '') + file_name,
            OFN: file_name,
            OnProgress: on_progress,
            ACL: acl || EmptyUploader.ACL,
        },
    };

    if (part_size && part_size > 0) {
        uploader = SafeUpdate(uploader, {PartSize: {$set: part_size}});
    }

    const TotalParts = Math.ceil(uploader.File.size / uploader.PartSize) || 1;
    for (let PartNumber = 1; PartNumber <= TotalParts; PartNumber++) {
        const FromByte = (PartNumber - 1) * uploader.PartSize;
        const ToByte = PartNumber * uploader.PartSize;

        const NewParts = SafeUpdate(uploader.Parts, {
            [PartNumber]: {
                $set: {
                    ...EmptyUploadPart,
                    ...{
                        Blob: file.slice(FromByte, ToByte),
                        PartNumber: PartNumber,
                    },
                },
            },
        });

        uploader = SafeUpdate(uploader, {Parts: {$set: NewParts}});
    }

    const GUID = CreateGUID();
    uploader = SafeUpdate(uploader, {GUID: {$set: GUID}});
    Uploaders = SafeUpdate(Uploaders, {[GUID]: {$set: uploader}});

    // start upload
    uploader = await CreateMiltipartUpload(uploader);
    if (uploader.Error !== null) {
        on_complete(OnError(GUID, uploader.Error));
        return '';
    }

    uploader = SafeUpdate(uploader, {Progress: {$set: InitProgress}});
    OnProgress(uploader);

    ProccessUploader(GUID, on_complete);

    return uploader.UploadID;
}

async function ProccessUploader(GUID: string, on_complete: (res: Uploader) => void) {
    let uploader = Uploaders[GUID];
    if (!uploader) {
        on_complete(OnError(GUID, ErrMissingUploader));
        return;
    }

    // upload parts
    for (let [key, part] of Object.entries(uploader.Parts)) {
        //@ts-ignore
        part = await UploadPart(uploader, part);
        uploader = SafeUpdate(uploader, {Parts: {[key]: {$set: part}}});
    }

    // finish upload
    uploader = await CompleteMultipartUpload(uploader);
    if (uploader.Error !== null) {
        on_complete(OnError(GUID, uploader.Error));
        return;
    }

    uploader = SafeUpdate(uploader, {Progress: {$set: 100}});
    OnProgress(uploader);

    on_complete(uploader);
    return;
}

async function CreateMiltipartUpload(uploader: Uploader): Promise<Uploader> {
    const res = await post<SignedInitResponse>({
        url: `files/s3/${uploader.Bucket}`,
        body: {
            file_name: uploader.OFN,
            file_uri: uploader.FileName,
            content_type: uploader.File.type,
            acl: uploader.ACL,
        },
    });

    if (res[1]) {
        const Err = SafeUpdate(ErrCantGetUploadID, {text: {$set: res[1].message}});
        return SafeUpdate(uploader, {Error: {$set: Err}});
    }

    return SafeUpdate(uploader, {
        $merge: {
            UploadID: res[0].upload_id,
            S3Key: res[0].s3_key || '',
            FileGUID: res[0].file_guid || '',
        },
    });
}

async function UploadPart(uploader: Uploader, part: UploaderPart): Promise<UploaderPart> {
    uploader = SafeUpdate(uploader, {Parts: {[part.PartNumber]: {IsProcessing: {$set: true}}}});
    part = SafeUpdate(part, {IsProcessing: {$set: true}});

    const res = await put<SignatureResponse>({
        url: `files/s3/${uploader.Bucket}/requests`,
        body: {
            s3_key: uploader.S3Key,
            upload_id: uploader.UploadID,
            part_number: part.PartNumber,
        },
    });

    if (res[1]) {
        const Err = SafeUpdate(ErrCantSign, {text: {$set: res[1].message}});
        return SafeUpdate(part, {
            $merge: {
                Error: Err,
                IsProcessing: false,
            },
        });
    }

    let ETag = '';
    try {
        const response = await axios.put(res[0].request || '', part.Blob, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                uploader = SafeUpdate(uploader, {
                    Parts: {
                        [part.PartNumber]: {
                            Progress: {
                                $set: Math.floor((progressEvent.loaded * 100) / (progressEvent.total || 0)),
                            },
                        },
                    },
                });
                OnProgress(uploader);
            },
        });

        ETag = response.headers.etag;
    } catch (error) {
        const Err = SafeUpdate(ErrCantUploadPart, {text: {$set: error as string}});
        return SafeUpdate(part, {
            $merge: {
                Error: Err,
                IsProcessing: false,
            },
        });
    }

    return SafeUpdate(part, {
        $merge: {
            ETag: ETag,
            Progress: 100,
            IsFinishing: true,
            IsProcessing: false,
        },
    });
}

async function CompleteMultipartUpload(uploader: Uploader): Promise<Uploader> {
    const res = await patch({
        url: `files/s3/${uploader.Bucket}`,
        body: {
            s3_key: uploader.S3Key,
            upload_id: uploader.UploadID,
            parts: Object.values(uploader.Parts).map((part: UploaderPart) => {
                return {
                    ETag: part.ETag,
                    PartNumber: part.PartNumber,
                };
            }),
        },
    });

    if (res[1]) {
        const Err = SafeUpdate(ErrCantSign, {text: {$set: res[1].message}});
        return SafeUpdate(uploader, {Error: {$set: Err}});
    }

    const imageMetadata = await ExtractImageMetadata(uploader.File);

    const [response, errComplete] = await post<CompleteResponse>({
        url: `files/s3/${uploader.Bucket}/files`,
        body: {
            file_guid: uploader.FileGUID,
            s3_key: uploader.S3Key,
            acl: uploader.ACL,
            ...imageMetadata,
        },
    });
    if (errComplete) {
        const Err = SafeUpdate(ErrCantComplete, {text: {$set: errComplete.message}});
        return SafeUpdate(uploader, {Error: {$set: Err}});
    }

    try {
        CompleteResponseRuntypes.check(response);
    } catch (error) {
        const Err = SafeUpdate(ErrCantComplete, {text: {$set: error as string}});
        return SafeUpdate(uploader, {Error: {$set: Err}});
    }

    uploader = SafeUpdate(uploader, {Result: {$set: response || null}});
    return uploader;
}

export async function AbortMultipartUpload(uploader: Uploader): Promise<Uploader> {
    const res = await del({
        url: `files/s3/${uploader.Bucket}`,
        body: {
            s3_key: uploader.S3Key,
            upload_id: uploader.UploadID,
        },
    });

    if (res[1]) {
        const Err = SafeUpdate(ErrCantAbort, {text: {$set: res[1].message}});
        return SafeUpdate(uploader, {Error: {$set: Err}});
    }

    uploader = SafeUpdate(uploader, {Result: {$set: null}});
    uploader = SafeUpdate(uploader, {Progress: {$set: 0}});
    uploader = SafeUpdate(uploader, {Error: {$set: ErrAborted}});
    OnProgress(uploader);

    return uploader;
}

function OnError(GUID: string, Err: Error | null): Uploader {
    let uploader = Uploaders[GUID];
    if (!uploader) {
        uploader = {...EmptyUploader};
    }

    return SafeUpdate(uploader, {Error: {$set: Err}});
}

function OnProgress(uploader: Uploader) {
    if (uploader.Progress < InitProgress + PartsProgressGap) {
        const TotalParts = Object.values(uploader.Parts).length;
        const ProgressPerPart = PartsProgressGap / TotalParts;
        let CurrentProgress = InitProgress;

        Object.values(uploader.Parts).forEach((p: UploaderPart) => {
            CurrentProgress += Math.ceil(ProgressPerPart * (p.Progress / 100));
        });
        uploader = SafeUpdate(uploader, {Progress: {$set: CurrentProgress}});
    }

    Uploaders = SafeUpdate(Uploaders, {[uploader.GUID]: {$set: uploader}});
    const callback = uploader.OnProgress;
    if (typeof callback === 'function') {
        callback(uploader.Progress);
    }
}

function IsSupported(): boolean {
    return !(
        typeof File === 'undefined' ||
        typeof Blob === 'undefined' ||
        !(
            // prettier-ignore
            //@ts-ignore
            !!Blob.prototype.webkitSlice ||
                //@ts-ignore
                !!Blob.prototype.mozSlice ||
                Blob.prototype.slice
        )
    );
}
