import {NatsConnection, JSONCodec, headers} from 'nats.ws';
import {Body} from './model';

const jc = JSONCodec();

export default function Publish<M extends object>(
    nats: NatsConnection,
    subject: string,
    body: Body<M>,
    user_jwt: string
) {
    const h = headers();
    h.append('jwt', user_jwt);
    h.append('uipe', UIPE);
    h.append('user-agent', window.navigator.userAgent);
    nats.publish(subject, jc.encode(body), {headers: h});
}
