import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    message: {
        display: 'flex',
        margin: '0 50px 10px 0',
        fontSize: '1rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        '@media(max-width: 640px)': {
            flexDirection: 'column',
        },
    },
    messageUser: {
        margin: '0 0 10px 50px',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        '@media(max-width: 640px)': {
            alignItems: 'flex-end',
            flexDirection: 'column',
        },
    },
    messageBodyUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    messageImgBlock: {
        display: 'flex',
        ':nth-child(1n) span': {
            display: 'none',
        },
        '@media(max-width: 640px)': {
            alignItems: 'center',
            ':nth-child(1n) span': {
                display: 'block',
            },
        },
    },
    messageImg: {
        width: 46,
        height: 46,
        marginRight: 15,
        flexShrink: 0,
        border: `1px solid ${GetColor('StrokeColor')}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':nth-child(1n) img': {
            display: 'block',
            width: '100%',
            borderRadius: '50%',
        },
        '@media(max-width: 640px)': {
            margin: '0 0 0 10px',
        },
    },
    messageImgUser: {
        margin: '0 0 0 15px',
        color: GetColor('Gray'),
        '@media(max-width: 640px)': {
            margin: '0 0 0 10px',
        },
    },
    messageSystem: {
        padding: '0 61px',
        '@media(max-width: 640px)': {
            padding: 0,
        },
    },
    messageName: {
        '@media(max-width: 640px)': {
            display: 'none',
        },
    },
    messageBlobContainer: {
        display: 'flex',
    },
    messageBlob: {
        display: 'inline-block',
        margin: '7px 0 0 0',
        padding: '8px 14px',
        fontSize: '1rem',
        fontWeight: 400,
        color: GetColor('TextColor'),
        borderRadius: 16,
        background: GetColor('TopLayerObjectBg'),
        ':nth-child(1n) ol': {
            margin: 0,
            padding: '0 0 0 15px',
        },
    },
    messageBlobFirst: {
        borderRadius: '4px 16px 16px 16px',
    },
    messageBlobFirstUser: {
        borderRadius: '16px 4px 16px 16px',
    },
    messageBlobUser: {
        background: GetColor('MessageBg'),
        ':first-child': {
            borderRadius: '16px 4px 16px 16px',
        },
    },
    messageFile: {
        position: 'relative',
        width: 170,
        height: 70,
        margin: '7px 0 0 0',
        padding: '10px 10px 10px 60px',
        fontSize: '1rem',
        fontWeight: 400,
        color: GetColor('TextColor'),
        borderRadius: 10,
        border: `1px solid ${GetColor('StrokeColor')}`,
        background: GetColor('LightGrayBg'),
    },
    messageFileIcon: {
        position: 'absolute',
        top: 10,
        left: 10,
    },
    messageFileName: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: GetColor('TextColor'),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    messageFileSize: {
        fontSize: '0.8125rem',
        fontWeight: 400,
        color: GetColor('Secondary'),
    },
    messageLink: {
        display: 'inline-flex',
        borderRadius: 14,
        padding: '10px 14px 10px 14px',
        margin: '7px 0 0 0',
        color: GetColor('NegativeTextColor'),
        background: GetColor('Primary'),
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
    },
});
