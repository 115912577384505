import {post} from 'lib/fetch';
import {OnboardingChatRuntype} from '../model';

export type NewOnboardingMessage =
    | {
          content: string;
      }
    | {
          user_data_source_guid: string;
      }
    | {
          data_source_guid: string;
          facebook_ad_account_id: string;
          facebook_ad_account_name: string;
      };

export default async function ContinueProjectOnboardingChatAPI(project_uri: string, message: NewOnboardingMessage) {
    return await post(
        {
            url: `projects/${project_uri}/onboarding`,
            body: message,
        },
        OnboardingChatRuntype
    );
}
