import {post} from 'lib/fetch';
import {ProjectChatRuntype} from '../model';

export type NewProjectChatMessage = {
    content: string;
};

export default async function ContinueProjectChatAPI(project_uri: string, message: NewProjectChatMessage) {
    return await post(
        {
            url: `projects/${project_uri}/chat`,
            body: message,
        },
        ProjectChatRuntype
    );
}
