import * as r from 'runtypes';
import {ChartType} from '../chart_type';

/* AREA CHART */
export const AreaChartValueRuntype = r.Dictionary(r.String.Or(r.Number), r.String);

export type AreaChartValue = r.Static<typeof AreaChartValueRuntype>;

export const AreaChartRuntype = r.Record({
    chart_type: r.Literal(ChartType.Area),
    data: r.Array(AreaChartValueRuntype),
});
export type AreaChart = r.Static<typeof AreaChartRuntype>;
