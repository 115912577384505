import React from 'react';

// styles
import {css} from 'aphrodite';
import Styles from './ChatMessages.jss';

export default function Message({
    children,
    isUser,
    isFirst,
}: {
    children: React.ReactNode;
    isUser?: boolean;
    isFirst?: boolean;
}) {
    return (
        <div className={css(Styles.messageBlobContainer)}>
            <div
                className={css(
                    Styles.messageBlob,
                    isUser && Styles.messageBlobUser,
                    isFirst && Styles.messageBlobFirst,
                    isFirst && isUser && Styles.messageBlobFirstUser
                )}
            >
                {children}
            </div>
        </div>
    );
}
