import {ThirdPartyLoginResponse} from '..';
import {BuildState, ThirdPartyLoginState} from './state';

export default function ConnectFacebook<R = unknown>(
    state: ThirdPartyLoginState,
    scope: 'email' | 'email,ads_read',
    onComplete: (res: ThirdPartyLoginResponse<R>) => void
) {
    let OAuthWindow: Window | null = null;
    const windowName = 'Facebook Login';
    const windowOptions = 'location=0,status=0,width=600,height=800,left=100,top=100';

    const href = new URL(FACEBOOK_OAUTH_URL);
    const currentState = href.searchParams.get('state');
    const newState = BuildState(state);
    href.searchParams.set('scope', scope);
    href.searchParams.set('state', currentState && currentState.length > 0 ? `${currentState};${newState}` : newState);

    OAuthWindow = window.open(href.toString(), windowName, windowOptions);

    if (OAuthWindow) {
        let closedLegit = false;
        // @ts-ignore
        window.AuthCallack = function (payload: string) {
            if (OAuthWindow && !OAuthWindow.closed) {
                closedLegit = true;
                OAuthWindow.close();
            }

            const res: ThirdPartyLoginResponse<R> = JSON.parse(payload);
            onComplete(res);
        };

        let timer = setInterval(function () {
            if (OAuthWindow && OAuthWindow.closed) {
                clearInterval(timer);
                if (!closedLegit) {
                    onComplete({status: false});
                }
            }
        }, 1000);
    }
}
