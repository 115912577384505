import {css} from 'aphrodite';
import {Route, Routes} from 'react-router-dom';
import Header from 'src/jsx/header/header';
import {HeaderUtilityStyles} from '../header/sizes';
import LoginSecuritySettings from './pages/LoginSecuritySettings/LoginSecuritySettings';
import AccountIndex from './pages/AccountIndex';

const AccountPageRouter: React.FC = () => {
    return (
        <article className={css(HeaderUtilityStyles().relatedTopPadding)}>
            <Header />
            <Routes>
                <Route
                    index
                    element={<AccountIndex />}
                />
                <Route
                    path="security"
                    element={<LoginSecuritySettings />}
                />
            </Routes>
        </article>
    );
};

export default AccountPageRouter;
