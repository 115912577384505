import {post} from 'lib/fetch';
import {SessionRuntype} from 'src/runtime/session/model';

export default function SubmitVerificationCodeAPI(token: string, code: string) {
    return post(
        {
            url: '/session/emails/verifications',
            body: {token, code},
        },
        SessionRuntype
    );
}
