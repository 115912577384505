import {Chart} from 'packages/charts';
import {ChartType} from 'packages/charts/chart_type';
import ChartDataToEvents from 'packages/charts/EventsUsersTable/ChartDataToEvents';
import {EventsUsersTable} from 'packages/charts/EventsUsersTable';
import {AreaChartValue} from 'packages/charts/AreaChart';
import {BarChartValue} from 'packages/charts/BarChart';

export default function ChartDataToCSV(chartData: Chart): string {
    switch (chartData.chart_type) {
        case ChartType.Donut:
            return `Name,Value\n${chartData.data.map(item => [item.name || 'n/a', item.value].join(',')).join('\n')}`;
        case ChartType.Bar:
            return chartData.data.length > 0 ? simpleDataToCSV(chartData.data) : '';
        case ChartType.Area:
            return chartData.data.length > 0 ? simpleDataToCSV(chartData.data) : '';
        case ChartType.EventsUsersTable:
            return chartData.data.length > 0 ? eventsUserTableToCSV(chartData) : '';
        case ChartType.Unknown:
            return ``;
    }
}

export function ReportSuffixByChartType(type: ChartType): string {
    switch (type) {
        case ChartType.Donut:
            return `_donut`;
        case ChartType.Bar:
            return `_bar`;
        case ChartType.Area:
            return `_area`;
        case ChartType.EventsUsersTable:
            return `_events_users_table`;
        case ChartType.Unknown:
            return `_unknown`;
    }
}

function simpleDataToCSV(data: AreaChartValue[] | BarChartValue[]): string {
    let csv =
        Object.keys(data[0])
            .map(item => item.replaceAll('_', ' '))
            .join(',') + '\n';
    data.forEach(item => {
        csv += Object.values(item).join(',') + '\n';
    });
    return csv;
}

function eventsUserTableToCSV(data: EventsUsersTable): string {
    let maxEventValuesCount = 0;
    let events = ChartDataToEvents({chart: data});
    let rows = Object.keys(events).map(key => {
        let row = [key.replaceAll('_', ' ')];
        let item = events[key];
        maxEventValuesCount = Math.max(item.values.length, maxEventValuesCount);
        for (let i = 0; i < item.values.length; i++) {
            const value = item.values[i];
            const prevAmount = item.values[i - 1]?.users_count || value.users_count + 0;
            const prst = ((prevAmount - value.users_count) / prevAmount) * 100;
            if (value.event_count > 1) {
                row.push(prst.toFixed(2) + '%');
            }
            row.push(value.users_count.toString());
        }
        return row;
    });
    let headerData = [...Array(maxEventValuesCount * 2 - 1)].map((value, i) => {
        return i % 2 === 0 ? i / 2 + 1 : '>';
    });
    let csv = `Event name, ${headerData.join(', ')}\n`;
    rows.forEach(item => (csv += item.join(', ') + '\n'));
    return csv;
}
