import React, {forwardRef, JSXElementConstructor, ReactElement, useImperativeHandle, useRef} from 'react';
import {ContentProps, TooltipSettings, TriggerProps} from './model';
import TooltipProvider from './TooltipProvider';
import TooltipTrigger from './TooltipTrigger';
import TooltipContent from './TooltipContent';
import CreateGUID from 'lib/helpers/CreateGUID';

type TooltipOptions = TooltipSettings & TriggerProps & Omit<ContentProps, 'selector'>;

export type TooltipProps = TooltipOptions & {
    content: ReactElement<any, string | JSXElementConstructor<any>> | string; // tooltip content
};

export type TooltipRef = {
    readonly getRootElement: () => HTMLElement | null;
};

const Tooltip = forwardRef((props: React.PropsWithChildren<TooltipProps>, ref: React.Ref<TooltipRef>) => {
    const selector = useRef(CreateGUID());

    useImperativeHandle(ref, () => ({
        getRootElement: () => {
            return document.getElementById(`outside-${selector.current}`);
        },
    }));

    return (
        <TooltipProvider
            open={props.open}
            event={props.event}
            place={props.place}
            distance={props.distance}
            disabled={props.disabled}
            interactive={props.interactive}
        >
            <TooltipTrigger tabIndex={props.tabIndex}>{props.children}</TooltipTrigger>
            <TooltipContent
                selector={selector.current}
                color={props.color}
                styles={props.styles}
                showArrow={props.showArrow}
                tooltipClassName={props.tooltipClassName}
                onShown={props.onShown}
                onHide={props.onHide}
            >
                {props.content}
            </TooltipContent>
        </TooltipProvider>
    );
});

export default Tooltip;
