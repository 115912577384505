import {Fragment} from 'react';
import {css} from 'aphrodite';
import {EventsUsersTable} from '.';
import ChartDataToEvents, {renderEvent} from './ChartDataToEvents';
import Styles from './EventsUsersTable.jss';

export default function EventsUsersTableComponent({chart}: {chart: EventsUsersTable}) {
    let events = ChartDataToEvents({chart});
    return (
        <table className={css(Styles.table)}>
            <thead className={css(Styles.thead)}>
                <tr>
                    <th className={css(Styles.th, Styles.thEmpty)}>Event name</th>
                    {[...Array(100)].map((e, i) => (
                        <th
                            key={i}
                            className={css(Styles.th, Styles.thSticky)}
                        >
                            {i % 2 === 0 ? i / 2 + 1 : '>'}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.values(events).map((item, i) => (
                    <MultiFunnelTableRowComponent
                        key={i}
                        item={item}
                    />
                ))}
            </tbody>
        </table>
    );
}

function MultiFunnelTableRowComponent({item}: {item: renderEvent}) {
    return (
        <tr>
            <td className={css(Styles.tdFirst)}>{item.event_name.replaceAll('_', ' ')}</td>
            {item.values.map((value, i) => {
                const prevAmount = item.values[i - 1]?.users_count || value.users_count + 0;
                const prst = ((prevAmount - value.users_count) / prevAmount) * 100;
                return (
                    <Fragment key={i}>
                        {value.event_count > 1 && (
                            <td className={css(Styles.td, prst >= 0 ? Styles.positive : Styles.negative)}>
                                {prst.toFixed(2)}%
                            </td>
                        )}
                        <td className={css(Styles.td, Styles.tdAmount)}>
                            <div className={css(Styles.amount)}>
                                <div>
                                    <div>{value.users_count.toLocaleString()}</div>
                                </div>
                            </div>
                        </td>
                    </Fragment>
                );
            })}
        </tr>
    );
}
