import {CreateSheet, css} from 'lib/aphrodite/aphrodite';
import {MakeButton} from 'packages/elements/button';
import {routing} from 'src/runtime/router';
import {session} from 'src/runtime/session';

type AuthButtonsProps = {
    fullWidth?: boolean;
};

export const AuthButtons: React.FC<AuthButtonsProps> = ({fullWidth}) => {
    if (session.user.guid !== '') {
        return null;
    }

    return (
        <div className={css(Styles.container)}>
            <a
                href="/login"
                className={MakeButton('Transparent-Default', fullWidth && Styles.fullWidth)}
                onClick={routing.OpenPage('login')}
            >
                Sign In
            </a>
            <a
                href={'/sign-up'}
                className={MakeButton('FilledAccent1-Default', fullWidth && Styles.fullWidth)}
                onClick={routing.OpenPage('/sign-up')}
            >
                Sign Up
            </a>
        </div>
    );
};

const Styles = CreateSheet({
    container: {
        display: 'flex',
        gap: 15,
    },
    fullWidth: {
        width: '100%',
    },
});
