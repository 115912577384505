import {get} from 'lib/fetch';
import {BlogPostRuntype} from '../model';

export default async function LoadSinglePost(guid: string) {
    return await get(
        {
            url: `blog/${guid}`,
        },
        BlogPostRuntype
    );
}
