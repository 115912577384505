import * as r from 'runtypes';
import {ChartType} from '../chart_type';

export {default as DonutChartComponent} from './DonutChart';
export {default as DonutTableComponent} from './DonutTable';

/* DONUT CHART */
export const DonutChartValueRuntype = r.Record({
    name: r.String,
    value: r.Number,
});
export type DonutChartValue = r.Static<typeof DonutChartValueRuntype>;

export const DonutChartRuntype = r.Record({
    chart_type: r.Literal(ChartType.Donut),
    data: r.Array(DonutChartValueRuntype),
});
export type DonutChart = r.Static<typeof DonutChartRuntype>;
