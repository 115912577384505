import {patch} from 'lib/fetch';

export const UpdatePasswordAPI = (current_password: string, new_password: string) =>
    patch({
        url: 'session/passwords',
        body: {
            current_password,
            new_password,
        },
    });
