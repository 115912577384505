import {CreateSheet, css, CSSProperties} from 'aphrodite';
import {toast, ToastContent} from 'react-toastify';

type callback = () => void;

const OnOpen = (withSound: boolean, callback?: callback) => () => {
    if (typeof callback === 'function') {
        callback();
    }

    if (withSound) {
        const audio = new Audio('/sounds/toast.mp3');
        audio.play();
    }
};

const OnClose = (callback?: callback) => () => {
    if (typeof callback === 'function') {
        callback();
    }
};

export type options = {
    sound?: boolean;
    timeout?: number | false;
    onOpen?: () => void;
    onClose?: () => void;
};

export type ToastTheme = {
    backgroundColor: CSSProperties['backgroundColor'];
    prgoressColor: CSSProperties['backgroundColor'];
    crossColor: CSSProperties['backgroundColor'];
    color: CSSProperties['color'];
};

export type ToastLayout = {
    borderRadius: CSSProperties['borderRadius'];
    boxShadow: CSSProperties['boxShadow'];
    padding: CSSProperties['padding'];
    fontSize: CSSProperties['fontSize'];
    fontWeight: CSSProperties['fontWeight'];
};

export type {ToastContent};

export function ShowToast(Content: ToastContent, options?: options): string | number {
    options = options || {};
    options.onClose = OnClose(options.onClose);
    options.onOpen = OnOpen(options.sound || false, options.onClose);

    return toast(Content, {
        ...options,
        autoClose: typeof options.timeout !== 'undefined' ? options.timeout : 3000,
        position: 'top-right',
        className: css(Styles.toast),
        bodyClassName: css(Styles.body),
        progressClassName: css(Styles.progress),
    });
}

const Styles = CreateSheet({
    toast: {
        borderRadius: 0,
        padding: 20,
        backgroundColor: '#eee',
        color: '#000',
        minHeight: 'auto',
        alignItems: 'center',
        ':nth-child(1n) .Toastify__close-button': {
            display: 'flex',
            color: '#000',
            opacity: 1,
        },
    },
    body: {
        padding: 0,
        margin: 0,
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    progress: {
        background: '#aaa',
    },
});
