import {css} from 'aphrodite';
import {ReactComponent as ArrowRightLongIcon} from 'src/icons/arrow-right-long.svg';
import {ReactComponent as ArrowDownIcon} from 'src/icons/arrow-down.svg';
import Styles from './DashboardModelOutputPage.jss';
import {MenuItem} from 'packages/elements/menu';
import FloatingDropDown from 'packages/elements/dropdowns';
import {useState} from 'react';
import WithSpinner from 'packages/spinners/SmartSpinner.react';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import {ProjectChart} from 'packages/projects/project_charts/charts';
import {ChartComponent, HasChartComponent} from 'packages/charts/ChartComponent';
import {routing} from 'src/runtime/router';
import {useDashboardStore} from '../../DashboardStore';
import useOnce from 'lib/hooks/useOnce';
import {showErrorLightbox} from 'lib/lightbox/warning.react';
import DashboardStyles from '../../DashboardPage.jss';

export default function DashboardModelOutputPage() {
    const [charts, isLoading, loadCharts] = useDashboardStore(s => [s.charts, s.isLoadingCharts, s.loadCharts]);

    useOnce(() => {
        async function load() {
            const err = await loadCharts();
            if (err !== null) {
                showErrorLightbox(err.message);
            }
        }

        load();
    });

    return (
        <>
            <h1 className={css(DashboardStyles.title)}>Overview</h1>
            <WithSpinner
                theme={PrimaryTheme}
                suspense={isLoading}
                spinnerHeight={40}
            >
                <div className={css(Styles.cards)}>
                    {charts.map((chart, i) => (
                        <ChartCard
                            chart={chart}
                            key={i}
                        />
                    ))}
                </div>
            </WithSpinner>
        </>
    );
}

const lastDaysOptions = [7, 14, 30];

type ChartCardProps = {
    chart: ProjectChart;
};

const ChartCard: React.FC<ChartCardProps> = ({chart}) => {
    const [company_uri, project_uri] = useDashboardStore(s => [s.company_uri, s.project_uri]);

    const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
    const [lastDays, setLastDays] = useState(30);

    const hasChart = HasChartComponent(chart.chart!);

    return (
        <div className={css(Styles.card)}>
            <div className={css(Styles.cardTop)}>
                <div className={css(Styles.cardTitle)}>{chart.name}</div>
                <a
                    href={`/ai/${company_uri}/${project_uri}/model-output/charts/${chart.marker}`}
                    onClick={routing.OpenPage(`/ai/${company_uri}/${project_uri}/model-output/charts/${chart.marker}`)}
                    className={css(Styles.cardLink)}
                >
                    <span>Full view</span>
                    <ArrowRightLongIcon
                        width="16"
                        height="16"
                        role="img"
                        aria-label="Full view"
                    />
                </a>
            </div>
            <div className={css(Styles.cardNumbers)}>
                <span>$12,345</span>
                <div className={css(Styles.cardPercent, Styles.cardPercentPlus)}>+10%</div>
            </div>
            <div className={css(Styles.cardChart)}>
                {!hasChart ? (
                    <div className={css(Styles.noPreview)}>No preview available</div>
                ) : (
                    <ChartComponent
                        chart={chart.chart!}
                        isCompact={true}
                    />
                )}
            </div>
            <div className={css(Styles.cardRangePickerContainer)}>
                <FloatingDropDown
                    onStatusChange={setIsRangePickerOpen}
                    trigger={
                        <div className={css(Styles.cardRangePicker)}>
                            Last {lastDays} days
                            <ArrowDownIcon
                                className={css(
                                    Styles.cardRangePickerIcon,
                                    isRangePickerOpen && Styles.cardRangePickerIconOpen
                                )}
                            />
                        </div>
                    }
                    settings={{
                        closeOn: {
                            clickFloatingBlock: true,
                        },
                    }}
                >
                    {lastDaysOptions.map((lastDays, i) => (
                        <MenuItem
                            onClick={() => setLastDays(lastDays)}
                            key={i}
                        >
                            Last {lastDays} days
                        </MenuItem>
                    ))}
                </FloatingDropDown>
            </div>
        </div>
    );
};
