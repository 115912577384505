import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    tooltip_container: {
        maxWidth: 300,
        zIndex: 100000000,
        padding: '4px 8px',
        fontSize: '0.75rem',
        fontWeight: 700,
        borderRadius: 4,
    },
    tooltip_string: {
        maxWidth: 500,
    },
    stub: {
        display: 'inline-block',
    },
});

export const ColorVariants = CreateSheet({
    dark: {
        color: GetColor('WhiteTextColor'),
        backgroundColor: GetColor('TooltipBg'),
        ':nth-child(1n) .tooltip-arrow': {
            fill: GetColor('TooltipBg'),
        },
    },
    light: {
        color: '#000000',
        backgroundColor: '#ffffff',
        border: `1px solid #DADADA`,
        boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.15)',
        ':nth-child(1n) .tooltip-arrow': {
            fill: '#ffffff',
        },
    },
});
