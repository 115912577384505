import {CreateSheet, GetColor, css} from 'aphrodite';
import {useHistory} from 'src/runtime/router/hooks';
import {Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import TruncateText from 'lib/helpers/TruncateText';
import {ReactComponent as ArrowRightIcon} from 'src/icons/arrow-right.svg';
export type BreadCrumbsPart = {
    title: string;
    href?: string;
};

export default function BreadCrumbs({parts}: {parts: BreadCrumbsPart[]}) {
    const location = useLocation();
    const history = useHistory();

    function changePage(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        const path = e.currentTarget.attributes.getNamedItem('href')?.value;
        if (path && path !== location.pathname) {
            history.push(path);
        }
    }

    return (
        <section className={css(Styles.main)}>
            {parts.map((part, index) => {
                return (
                    <Fragment key={index}>
                        {part.href ? (
                            <a
                                href={part.href}
                                className={css(Styles.title, part.href === location.pathname && Styles.titleActive)}
                                onClick={changePage}
                            >
                                {TruncateText(part.title, 30)}
                            </a>
                        ) : (
                            <span className={css(Styles.title)}>{TruncateText(part.title, 30)}</span>
                        )}
                        {index < parts.length - 1 && (
                            <ArrowRightIcon
                                width={12}
                                height={12}
                                className={css(Styles.divider)}
                            />
                        )}
                    </Fragment>
                );
            })}
        </section>
    );
}

const Styles = CreateSheet({
    main: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1,
        textAlign: 'left',
        color: GetColor('Secondary'),
        textDecoration: 'none',
        transition: 'color 0.15s ease-in',
        ':hover': {
            color: GetColor('Primary'),
        },
    },
    titleActive: {
        color: GetColor('Primary'),
        pointerEvents: 'none',
    },
    divider: {
        display: 'block',
        margin: '0 10px',
        color: GetColor('Gray'),
    },
});
