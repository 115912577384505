import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    filter: {
        display: 'flex',
        marginTop: 30,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    dropdownBlock: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 25,
        flexShrink: 0,
        ':last-child': {
            marginRight: 0,
        },
    },
    dropdownBlockLabel: {
        marginRight: 12,
        fontSize: '0.875rem',
        fontWeight: 500,
        color: GetColor('Secondary'),
        whiteSpace: 'nowrap',
    },
    dropdownTriggerIcon: {
        transform: 'rotate(0)',
        transition: 'transform 0.15s',
    },
    dropdownTriggerIconOpened: {
        transform: 'rotate(-180deg)',
    },
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '30px 20px',
        marginTop: 30,
    },
    card: {
        minHeight: 400,
        flex: '1 1 400px',
        padding: '25px 25px 20px 25px',
        borderRadius: 20,
        color: GetColor('TextColor'),
        border: `1px solid ${GetColor('StrokeColor')}`,
        background: GetColor('TopLayerObjectBg'),
        boxShadow: '0px 5px 25px 0px rgba(64, 64, 68, 0.05)',
        '@media(max-width: 640px)': {
            padding: '20px 15px',
        },
    },
    cardTitle: {
        margin: 0,
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1,
    },
    cardTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardLink: {
        display: 'inline-flex',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.1,
        alignItems: 'center',
        color: GetColor('Secondary'),
        textDecoration: 'none',
        ':nth-child(1n) svg': {
            display: 'block',
            marginLeft: 10,
        },
    },
    cardNumbers: {
        display: 'flex',
        marginTop: 25,
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardPercent: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    cardPercentPlus: {
        color: GetColor('Success'),
    },
    cardPercentMinus: {
        color: GetColor('Danger'),
    },
    cardChart: {
        marginTop: 15,
        height: 300,
    },
    cardRangePickerContainer: {
        marginTop: 15,
        display: 'flex',
    },
    cardRangePicker: {
        display: 'flex',
        gap: 7,
        alignItems: 'center',

        color: GetColor('Placeholder'),
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 'normal',

        cursor: 'pointer',
        userSelect: 'none',
    },
    cardRangePickerIcon: {
        width: 12,
        height: 12,
        display: 'block',
        transition: 'transform 0.2s ease-in-out',
    },
    cardRangePickerIconOpen: {
        transform: 'rotate(-180deg)',
        width: 12,
        height: 12,
        display: 'block',
    },
    noPreview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
});
