import {CreateSheet, GetColor, css} from 'aphrodite';
import {useDashboardStore} from '../../DashboardStore';
import useOnce from 'lib/hooks/useOnce';
import {showErrorLightbox} from 'lib/lightbox/warning.react';
import {useOnboardingStore} from './OnboardingStore';
import {ReactComponent as OverviewIcon} from 'src/icons/overview.svg';
import {Fragment, useEffect} from 'react';
import FacebookConnect, {FacebookAdAccountPicker} from './parts/FacebookConnect';
import {
    ChatComponent,
    ChatTextInput,
    ChatAIMessages,
    ChatUserMessages,
    ChatMessage,
    ChatSystemMessages,
} from 'packages/chat';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';
import {OnboardingMessage} from 'packages/projects/onboarding/model';
import {FacebookAdAccount} from 'packages/usersdatasources/model';
import {routing} from 'src/runtime/router';
import CreateNATSConnection from 'src/runtime/nats';
import {ServerToUserMessages} from 'src/runtime/nats/ServerToUserMessages';
import {hasRequestedFieldInMessage} from '../../components/GeneralChat/ProjectChatStore';

export default function Onboarding() {
    const [company_uri, project_uri, reloadProject] = useDashboardStore(s => [s.company_uri, s.project_uri, s.reload]);
    const [initOnboarding, reloadOnboarding, isLoading] = useOnboardingStore(s => [s.init, s.reload, s.isLoading]);
    const [canSubmit, messages, sendNewMessage] = useOnboardingStore(s => [
        s.canSubmitNewMessage,
        s.messages,
        s.sendNewMessage,
    ]);

    async function onSubmitNewMessage(message: string) {
        const err = await sendNewMessage(message);
        if (err !== null) {
            showErrorLightbox(err.message);
        }
    }

    function OnPersonalMessage(message: ServerToUserMessages['server.user.personal.user_token']) {
        if (message.marker === 'onboarding-changed') {
            const messages = useOnboardingStore.getState().messages;
            // no need to update anything
            if (messages.length > 0 && messages[messages.length - 1].guid === message.payload.last_message_guid) {
                return;
            }
            reloadProject();
            reloadOnboarding();
        }
    }

    useOnce(() => {
        async function init() {
            const err = await initOnboarding(company_uri, project_uri);
            if (err !== null) {
                showErrorLightbox(err.message);
            }
        }

        init();
    });

    useOnce(() => {
        const nats = CreateNATSConnection();
        nats.ListenPersonal(OnPersonalMessage);

        return () => {
            nats.StopListenPersonal(OnPersonalMessage);
        };
    });

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [messages.length]);

    return (
        <div className={css(Styles.page, HeaderUtilityStyles().relatedFullHeightPage)}>
            <ChatComponent
                messages={
                    <>
                        {messages.map((msg, i) => {
                            let Component = msg.role === 'user' ? ChatUserMessages : ChatAIMessages;

                            return (
                                <Fragment key={i}>
                                    <Component>
                                        <ChatMessage
                                            isFirst
                                            isUser={msg.role === 'user'}
                                        >
                                            {msg.content}
                                        </ChatMessage>
                                    </Component>
                                    <MessageAdditionalContent
                                        message={msg}
                                        is_last={i === messages.length - 1}
                                    />
                                </Fragment>
                            );
                        })}
                    </>
                }
                input={
                    <ChatTextInput
                        onSubmit={onSubmitNewMessage}
                        props={{
                            textarea: {
                                disabled: !canSubmit || isLoading,
                                placeholder: 'Message whitetail.ai',
                            },
                            button: {
                                loading: isLoading,
                                disabled: !canSubmit,
                            },
                        }}
                    />
                }
            />
        </div>
    );
}

function MessageAdditionalContent({message, is_last}: {message: OnboardingMessage; is_last: boolean}) {
    const [company_uri, project, reloadProject] = useDashboardStore(s => [s.company_uri, s.project, s.reload]);
    const [sendNewSystemMessage] = useOnboardingStore(s => [s.sendNewSystemMessage]);

    async function onConnectFacebook(user_data_source_guid: string) {
        const err = await sendNewSystemMessage({user_data_source_guid});
        if (err != null) {
            showErrorLightbox(err.message);
        }
    }

    async function onChooseFacebookAdAccount(data_source_guid: string, ad_account: FacebookAdAccount) {
        const err = await sendNewSystemMessage({
            data_source_guid: data_source_guid,
            facebook_ad_account_id: ad_account.id,
            facebook_ad_account_name: ad_account.name,
        });
        if (err != null) {
            showErrorLightbox(err.message);
        } else {
            reloadProject();
        }
    }

    if (hasRequestedFieldInMessage(message, 'data_source_type')) {
        return (
            <ChatSystemMessages>
                <ConnectDataSourceBlock onConnectFacebook={onConnectFacebook} />
            </ChatSystemMessages>
        );
    } else if (
        message.parsed_payload?.data_source_guid &&
        message.parsed_payload?.facebook_ad_accounts !== undefined &&
        is_last
    ) {
        let selectedAdAccount: FacebookAdAccount | undefined = undefined;
        const dataSource =
            project && project.data_sources.find(ds => ds.guid === message.parsed_payload!.data_source_guid);
        if (dataSource && dataSource.user_data_source_params) {
            try {
                const parsed = JSON.parse(dataSource.user_data_source_params);
                if (parsed.facebook_ad_account_id) {
                    selectedAdAccount = message.parsed_payload?.facebook_ad_accounts.find(
                        item => item.id === parsed.facebook_ad_account_id
                    );
                }
            } catch (error) {}
        }

        return (
            <ChatUserMessages>
                <ChatMessage isFirst>
                    <FacebookAdAccountPicker
                        ad_accounts={
                            selectedAdAccount ? [selectedAdAccount] : message.parsed_payload.facebook_ad_accounts
                        }
                        selected={selectedAdAccount}
                        onSelectAdAccount={acc => {
                            onChooseFacebookAdAccount(message.parsed_payload!.data_source_guid!, acc);
                        }}
                    />
                </ChatMessage>
            </ChatUserMessages>
        );
    } else if (project && message.parsed_payload?.is_project_ready) {
        return (
            <ChatSystemMessages>
                <div
                    className={css(Styles.link)}
                    onClick={routing.OpenPage(`/ai/${company_uri}/${project.uri}`)}
                >
                    <OverviewIcon
                        width={16}
                        height={16}
                        className={css(Styles.linkIcon)}
                    />{' '}
                    View Dashboard
                </div>
            </ChatSystemMessages>
        );
    }

    return null;
}

function ConnectDataSourceBlock({onConnectFacebook}: {onConnectFacebook: (user_data_source_guid: string) => void}) {
    const [isLoading] = useOnboardingStore(s => [s.isLoading]);

    return (
        <FacebookConnect
            onConnect={onConnectFacebook}
            isLoading={isLoading}
        />
    );
}

const Styles = CreateSheet({
    page: {
        display: 'flex',
        padding: 20,
    },
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 870,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 40px 120px 40px',
        '@media(max-width: 800px)': {
            padding: '0 20px',
        },
    },
    link: {
        display: 'inline-flex',
        borderRadius: 14,
        padding: '10px 14px 10px 14px',
        margin: '7px 0 0 0',
        color: GetColor('NegativeTextColor'),
        background: GetColor('Primary'),
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
    },
    linkIcon: {
        color: GetColor('NegativeTextColor'),
        marginRight: 8,
    },
});
