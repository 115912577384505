import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    footer: {
        padding: '50px 40px',
        background: GetColor('PageBg'),
        overflow: 'hidden',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    container: {
        width: 1200,
        position: 'relative',
        maxWidth: '100%',
        margin: '0 auto',
    },
    footerLogo: {
        display: 'flex',
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('TextColor'),
        alignItems: 'center',
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 10,
        },
    },
    footerContact: {
        display: 'flex',
        marginTop: 30,
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        alignItems: 'center',
    },
    footerContactLink: {
        marginLeft: 30,
        fontSize: '1.5rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        textDecoration: 'none',
    },
    footerBlock: {
        position: 'relative',
        display: 'flex',
        zIndex: 2,
        '@media(max-width: 800px)': {
            display: 'block',
        },
    },
    footerLeft: {
        minWidth: 320,
        flex: '1 0 0',
        '@media(max-width: 800px)': {
            width: '100%',
        },
    },
    footerRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: '1 0 0',
        '@media(max-width: 800px)': {
            marginTop: 30,
            justifyContent: 'flex-start',
        },
    },
    footerMenuTitle: {
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
    },
    footerMenu: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    footerMenuItem: {
        display: 'block',
        marginTop: 15,
        fontSize: '1rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        textDecoration: 'none',
    },
    footerBottom: {
        display: 'flex',
        position: 'relative',
        marginTop: 80,
        zIndex: 2,
        '@media(max-width: 800px)': {
            marginTop: 30,
        },
    },
    footerBottomRight: {
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        flexDirection: 'row-reverse',
        '@media(max-width: 800px)': {
            display: 'block',
        },
    },
    footerRightItem: {
        marginLeft: 100,
        '@media(max-width: 800px)': {
            margin: '0 100px 0 0',
        },
    },
    footerBottomList: {
        display: 'flex',
        margin: '0 0 0 35px',
        padding: 0,
        listStyle: 'none',
        '@media(max-width: 800px)': {
            margin: '0 0 15px 0',
        },
    },
    footerBottomListItem: {
        display: 'block',
        marginLeft: 15,
        color: GetColor('Secondary'),
        textDecoration: 'none',
        '@media(max-width: 800px)': {
            margin: '0 15px 0 0',
        },
    },
    footerBack: {
        position: 'absolute',
        right: 50,
        bottom: -250,
        color: 'rgba(137, 141, 143, 0.05)',
        zIndex: 1,
        ':nth-child(1n) svg': {
            display: 'block',
            width: 420,
            height: 'auto',
        },
    },
});
