import {CSSProperties, GetColor} from 'aphrodite';

export type SpinnerTheme = {
    color: CSSProperties['color'];
    pathColor: CSSProperties['color'];
};

export interface InterTheme extends SpinnerTheme {
    height?: number | '100%';
}

export const PrimaryTheme: SpinnerTheme = {
    color: GetColor('Primary'),
    pathColor: undefined,
};

export const LoadButtonTheme: SpinnerTheme = {
    color: GetColor('Black'),
    pathColor: undefined,
};
