import {routing} from 'src/runtime/router';
// icons
import {ReactComponent as Logo} from 'src/icons/logo/full.svg';
import {ReactComponent as LogoRound} from 'src/icons/logo/logo-round.svg';
// styles
import {GeneratedStyles, css} from 'aphrodite';
import Styles from './footer.jss';

export default function Footer({styles}: {styles?: GeneratedStyles}) {
    return (
        <div className={css(Styles.footer, styles && styles)}>
            <div className={css(Styles.container)}>
                <div className={css(Styles.footerBlock)}>
                    <div className={css(Styles.footerLeft)}>
                        <div className={css(Styles.footerLogo)}>
                            <LogoRound />
                            <span>Whitetail AI</span>
                        </div>
                        <div className={css(Styles.footerContact)}>
                            <span>Contact us</span>
                            <a
                                href="mailto:info@whitetail.ai"
                                target="_blank"
                                rel="noreferrer"
                                className={css(Styles.footerContactLink)}
                            >
                                info@whitetail.ai
                            </a>
                        </div>
                    </div>
                    <div className={css(Styles.footerRight)}>
                        <div className={css(Styles.footerRightItem)}>
                            <div className={css(Styles.footerMenuTitle)}>Company</div>
                            <ul className={css(Styles.footerMenu)}>
                                <li>
                                    <a
                                        href="/"
                                        onClick={routing.OpenPage('/')}
                                        className={css(Styles.footerMenuItem)}
                                    >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/blog"
                                        onClick={routing.OpenPage('/blog')}
                                        className={css(Styles.footerMenuItem)}
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/contact-us"
                                        onClick={routing.OpenPage('/contact-us')}
                                        className={css(Styles.footerMenuItem)}
                                    >
                                        Contact us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className={css(Styles.footerRightItem)}>
                            <div className={css(Styles.footerMenuTitle)}>Pricing</div>
                            <ul className={css(Styles.footerMenu)}>
                                <li>
                                    <a
                                        href="/pricing"
                                        onClick={routing.OpenPage('/pricing')}
                                        className={css(Styles.footerMenuItem)}
                                    >
                                        Subscriptions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={css(Styles.footerBottom)}>
                    <div className={css(Styles.footerBottomRight)}>
                        <ul className={css(Styles.footerBottomList)}>
                            <li>
                                <a
                                    href="/privacy"
                                    onClick={routing.OpenPage('/privacy')}
                                    className={css(Styles.footerBottomListItem)}
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms"
                                    onClick={routing.OpenPage('/terms')}
                                    className={css(Styles.footerBottomListItem)}
                                >
                                    Terms and Conditions
                                </a>
                            </li>
                        </ul>
                        <span>© {new Date().getFullYear()} Whitetail AI</span>
                    </div>
                </div>
                <div className={css(Styles.footerBack)}>
                    <Logo />
                </div>
            </div>
        </div>
    );

    //     return (
    //         <footer className={css(Styles.footer)}>
    //             <a
    //                 className={css(Styles.footerIcon)}
    //                 href="/"
    //                 onClick={routing.OpenPage('')}
    //             >
    //                 <Logo
    //                     width="125"
    //                     height="38"
    //                     role="img"
    //                     title="Logo"
    //                 />
    //             </a>
    //             <div className={css(Styles.footerBottom)}>
    //                 <div>© 2024 Whitetail AI</div>
    //                 <ul className={css(Styles.footerNav)}>
    //                     <li className={css(Styles.footerNavItem)}>
    //                         <a
    //                             href="/terms"
    //                             onClick={routing.OpenPage('/terms')}
    //                             className={css(Styles.footerNavItemLink)}
    //                         >
    //                             Terms of use
    //                         </a>
    //                     </li>
    //                     <li className={css(Styles.footerNavItem)}>
    //                         <a
    //                             href="/privacy"
    //                             onClick={routing.OpenPage('/privacy')}
    //                             className={css(Styles.footerNavItemLink)}
    //                         >
    //                             Privacy policy
    //                         </a>
    //                     </li>
    //                 </ul>
    //             </div>
    //         </footer>
    //     );
    // }
}
