import {CreateSheet, GetColor} from 'aphrodite';

export const animatedTime = 100;

export default CreateSheet({
    block: {
        padding: 6,
        background: GetColor('TopLayerObjectBg'),
        border: `1px solid ${GetColor('Border')}`,
        borderRadius: 8,
        boxShadow: '0px 5px 15px 0px rgba(48, 48, 48, 0.10)',
        outline: 0,
        zIndex: 10000,
    },
    floatingOverflow: {
        overflowY: 'auto',
    },
});
