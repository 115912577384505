import * as r from 'runtypes';
import {ChartType} from '../chart_type';

export {default as EventsUsersTableComponent} from './EventsUsersTable';

/* EVENTS USERS TABLE */
export const EventsUsersTableValueRuntype = r.Record({
    event_name: r.String,
    event_count: r.Number,
    users_count: r.Number,
});
export type EventsUsersTableValue = r.Static<typeof EventsUsersTableValueRuntype>;

export const EventsUsersTableRuntype = r.Record({
    chart_type: r.Literal(ChartType.EventsUsersTable),
    data: r.Array(EventsUsersTableValueRuntype),
});
export type EventsUsersTable = r.Static<typeof EventsUsersTableRuntype>;
