import {post} from 'lib/fetch';
import {OnboardingChatRuntype} from '../model';

export default async function CreateProjectOnboardingChatAPI(company_uri: string, content: string) {
    return await post(
        {
            url: `companies/${company_uri}/projects/onboarding`,
            body: {content},
        },
        OnboardingChatRuntype
    );
}
