import * as r from 'runtypes';
import {ChartType} from '../chart_type';

/* BAR CHART */
export const BarChartValueRuntype = r.Dictionary(r.String.Or(r.Number), r.String);

export type BarChartValue = r.Static<typeof BarChartValueRuntype>;

export const BarChartRuntype = r.Record({
    chart_type: r.Literal(ChartType.Bar),
    data: r.Array(BarChartValueRuntype),
});
export type BarChart = r.Static<typeof BarChartRuntype>;
