import * as runtypes from 'runtypes';
import {ExternalFile, ExternalFileRuntype} from 'packages/files/models';

export type FileACL = 'public-read' | 'private';

export type UploaderInput = {
    bucket: string;
    prefix?: string;
    file_name: string;
    file: Blob;
    part_size?: number;
    acl?: FileACL;
    on_progress?: (progress: number) => void;
    on_complete: (uploader: Uploader) => void;
};

export type Uploader = {
    GUID: string;
    Bucket: string;
    Prefix: string;
    File: Blob;
    FileName: string;
    FileGUID: string;
    OFN: string;
    S3Key: string;
    UploadID: string;
    Progress: number;
    PartSize: number;
    Parts: Record<number, UploaderPart>;
    Result: ExternalFile | null;
    Error: Error | null;
    OnProgress: ((progress: number) => void) | undefined;
    IsAborted: boolean;
    ACL: FileACL;
};

export const EmptyUploader: Uploader = {
    GUID: '',
    Bucket: '',
    Prefix: '',
    File: new Blob(),
    FileName: '',
    FileGUID: '',
    OFN: '',
    S3Key: '',
    UploadID: '',
    Progress: 0,
    PartSize: 6 * 1024 * 1024,
    Parts: {},
    Result: null,
    Error: null,
    OnProgress: undefined,
    IsAborted: false,
    ACL: 'public-read',
};

export type UploaderPart = {
    Blob: Blob;
    PartNumber: number;
    ETag: string;
    Progress: number;
    IsProcessing: boolean;
    IsFinishing: boolean;
    IsAborted: boolean;
    Error: Error | null;
};

export const EmptyUploadPart = {
    Blob: new Blob(),
    PartNumber: 0,
    ETag: '',
    Progress: 0,
    IsProcessing: false,
    IsFinishing: false,
    IsAborted: false,
    Error: null,
};

export type Error = {
    code: number;
    text: string;
};

export const SignatureResponseRuntypes = runtypes.Record({
    request: runtypes.String,
    s3_key: runtypes.String,
});

export type SignatureResponse = runtypes.Static<typeof SignatureResponseRuntypes>;

export const SignedInitResponseRuntypes = runtypes.Record({
    upload_id: runtypes.String,
    s3_key: runtypes.String,
    file_guid: runtypes.String,
});

export type SignedInitResponse = runtypes.Static<typeof SignedInitResponseRuntypes>;

export const CompleteResponseRuntypes = ExternalFileRuntype;
export type CompleteResponse = runtypes.Static<typeof CompleteResponseRuntypes>;
