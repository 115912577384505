import {convertToHTML as convertToHTMLExternal} from 'draft-convert';
import {ContentState} from 'draft-js';
import {renderToStaticMarkup} from 'react-dom/server';

import {ImageRenderer, ImageData} from 'packages/draftjs/ModComponents/Image.react';
import {createElement} from 'react';
import {LinkData, LinkRenderer} from '../ModComponents/Link.react';

export function convertToHTML(content: ContentState): string {
    return convertToHTMLExternal({
        blockToHTML: block => {
            if (block.type === 'section') {
                return <p />;
            }
        },
        entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
                const data = entity.data as LinkData;
                return renderToStaticMarkup(createElement(LinkRenderer, {data, children: originalText}));
            }

            if (entity.type === 'IMAGE') {
                const data = entity.data as ImageData;
                return renderToStaticMarkup(createElement(ImageRenderer, {data}));
            }

            return originalText;
        },
    })(content);
}
