import {defer, createBrowserRouter, RouteObject} from 'react-router-dom';
import ErrorPage from 'src/runtime/router/components/ErrorPage';
import {PageIDs, pagelist} from './pagelist';
import PageLoader from './components/PageLoader';
import {Page} from './model';
import RootComponent from './components/RootComponent';

function pagesToRoute(pages: Record<string, Page<any, any>>): RouteObject[] {
    let routes: RouteObject[] = [];

    Object.entries(pages).forEach(([key, page]) => {
        let loader: (() => ReturnType<typeof defer>) | undefined = undefined;
        if (page.preFetcher) {
            loader = () => defer({request: page.preFetcher!()});
        }

        let element = (
            <PageLoader
                page={page}
                page_id={key as PageIDs}
            />
        );

        if (Array.isArray(page.path)) {
            page.path.forEach((path, i) => {
                routes.push({
                    id: `${key}:::${i}`,
                    path,
                    element,
                    loader,
                    children: page.children ? pagesToRoute(page.children) : undefined,
                });
            });
        } else {
            let route: RouteObject = {};
            if (page.path === 'INDEX') {
                route = {
                    index: true,
                };
            } else if (page.path === 'WRAPPER') {
                route = {
                    children: page.children ? pagesToRoute(page.children) : undefined,
                };
            } else {
                route = {
                    path: page.path,
                    children: page.children ? pagesToRoute(page.children) : undefined,
                };
            }

            routes.push({
                id: key,
                element,
                loader,
                ...route,
            });
        }
    });

    return routes;
}

const BrowserRouter = createBrowserRouter([
    {
        path: '/',
        element: <RootComponent />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '*',
                lazy: () => import('./components/Page404'),
            },
            ...pagesToRoute(pagelist),
        ],
    },
]);
export default BrowserRouter;
