import {useEffect, useState, Fragment} from 'react';
import {Config} from './model';
import Portal from 'lib/portal/portal.react';
import {ActiveBoxes, Events} from './data';
import {closeLightbox} from './lightbox';
import Overlay from './Overlay.react';

export default function Renderer() {
    return <RendererMemo />;
}

function RendererMemo() {
    const [boxes, setBoxes] = useState(new Map<string, Config<any>>());

    function onRerender() {
        setBoxes(ActiveBoxes);
    }

    useEffect(() => {
        onRerender();
    }, []);

    useEffect(() => {
        Events.on('rerender-boxes', onRerender);

        return () => {
            Events.removeListener('rerender-boxes', onRerender);
        };
    });

    const triggerClose = (config: Config<any>) => (is_manual?: boolean) => {
        closeLightbox(config.selector, () => {
            config.onClose && config.onClose(config.selector, is_manual);
        });
    };

    return (
        <Fragment>
            {Array.from(boxes).map(([_, box], index) => (
                <Portal
                    key={box.selector}
                    styles={box.styles?.dom}
                >
                    {box.customTemplate ? (
                        <box.Component
                            {...box}
                            {...{
                                close: triggerClose(box),
                                order: index,
                            }}
                        />
                    ) : (
                        <Overlay
                            {...box}
                            {...{
                                close: triggerClose(box),
                                order: index,
                            }}
                        />
                    )}
                </Portal>
            ))}
        </Fragment>
    );
}
