import {BarChart, BarChartValue} from '.';
import PrettyTable from 'lib/tables/PrettyTable.react';
import {useMemo} from 'react';
import {cleanName, getDictNames} from '../general/utils';

export default function BarTable({chart}: {chart: BarChart}) {
    const keys = useMemo(() => {
        return getDictNames(chart.data);
    }, [chart]);

    if (chart.data.length === 0) {
        return null;
    }

    return (
        <PrettyTable<BarChartValue>
            {...{
                thead: keys.map(key => {
                    return cleanName(key);
                }),
                tbody: chart.data.map(item => {
                    return keys.map(key => {
                        if (key === 'name' && typeof item[key] === 'string') {
                            return cleanName(item[key] as string);
                        }
                        return item[key];
                    });
                }),
                items: chart.data,
            }}
        />
    );
}
