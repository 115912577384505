import React, {forwardRef} from 'react';
import {FloatingMenu, MenuRef} from 'lib/floating/FloatingMenu';
import {MenuElementProps} from './model';
import {MenuProps} from './Menu';
import {ReactComponent as ArrowRightSimpleIcon} from 'src/icons/arrow-right.svg';
import {StyleDeclaration, css} from 'aphrodite';
import Styles from './menu.jss';

export interface SubMenuProps extends Omit<MenuProps, 'event'> {
    styles?: {
        trigger?: StyleDeclaration;
        floatingBlock?: StyleDeclaration;
        arrow?: StyleDeclaration;
    };
}

export const SubMenu = forwardRef<MenuRef, MenuElementProps & SubMenuProps>(
    ({styles, trigger, ...rest}, forwaredRef) => {
        return (
            <FloatingMenu
                {...rest}
                ref={forwaredRef}
                trigger={
                    <>
                        <div className={css(Styles.trigger_sub_menu)}>{trigger}</div>
                        <span
                            aria-hidden
                            className={css(Styles.menu_item_arrow, styles?.arrow || null)}
                        >
                            <ArrowRightSimpleIcon
                                role="img"
                                title="Arrow"
                                width="13"
                                height="13"
                            />
                        </span>
                    </>
                }
                styles={{
                    trigger: [Styles.menu_item, Styles.menu_item_sub, styles?.trigger || null],
                    floatingBlock: [
                        Styles.menu_block,
                        rest.settings?.placement?.useSize ? Styles.floatingOverflow : null,
                        styles?.floatingBlock || null,
                    ],
                }}
            />
        );
    }
);
