import {Placement} from '@floating-ui/react';
import {StyleDeclaration} from 'aphrodite';
import {FloatingMenu, MenuRef, useIsNestedMenu} from 'lib/floating/FloatingMenu';
import React, {forwardRef} from 'react';
import Styles from './menu.jss';
import {MenuElementProps} from './model';

export type {MenuRef};

export interface MenuProps {
    event?: 'click' | 'hover' | 'contextmenu';
    place?: Placement;
    trigger?: React.ReactNode;
    styles?: {
        trigger?: StyleDeclaration;
        floatingBlock?: StyleDeclaration;
    };
    settings?: {
        durtation?: number;
        distance?: number;
        placement?: {
            useSize?: boolean; // if true - floating block will always be limitted on height to fit window size
            useWidth?: boolean; // if true - floating block matches the width of the reference regardless of its contents
            useSizeOffset?: number; // distance between floating block and window border
            noShift?: boolean; // do not adjust the align of the floating block (start, end) DEFAULT: false
            noFlip?: boolean; // do not adjust the side of the floating block (top, bottom, left, rigth) DEFAULT: false
        };
        closeOn?: {
            escapeKey?: boolean; // close menu on escape, DEFAULT: true
            resize?: boolean; // close menu on resize, DEFAULT: false
            scroll?: boolean; // close menu on scroll, DEFAULT: false
            // if you need to trigger menu from outside element - you use this function to avoid menu triggering close from click-outside
            clickOutside?: boolean | ((event: MouseEvent) => boolean); // close menu on click outside, DEFAULT: true
            clickFloatingBlock?: boolean; // close menu on click anywhere on the floating block, DEFAULT: false
        };
    };
    onStatusChange?: (opened: boolean) => void;
}

export const Menu = forwardRef<MenuRef, MenuProps & MenuElementProps>(
    ({styles, trigger, settings, ...rest}, forwaredRef) => {
        const isNested = useIsNestedMenu();

        return (
            <FloatingMenu
                {...rest}
                ref={forwaredRef}
                settings={{
                    ...settings,
                    distance: settings?.distance || 4,
                }}
                trigger={trigger}
                styles={{
                    trigger: [isNested ? Styles.menu_item : null, styles?.trigger || null],
                    floatingBlock: [
                        Styles.menu_block,
                        settings?.placement?.useSize ? Styles.floatingOverflow : null,
                        styles?.floatingBlock || null,
                    ],
                }}
            />
        );
    }
);
