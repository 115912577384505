export function cleanName(name: string) {
    name = name.replaceAll('_', ' ');
    if (name.length >= 1) {
        name = name[0].toUpperCase() + name.slice(1);
    }

    return name;
}

export function getDictNames(
    data: Array<{
        [x: string]: string | number;
    }>
) {
    return data.reduce<string[]>((acc, item) => {
        Object.keys(item).forEach(key => {
            if (!acc.includes(key)) {
                acc.push(key);
            }
        });
        return acc;
    }, []);
}
