import {post} from 'lib/fetch';
// styles
import {CreateSheet} from 'aphrodite';
// components
import {ComponentProps, Lightbox} from 'lib/lightbox/lightbox';
import useOnce from 'lib/hooks/useOnce';

export interface LightboxProps {
    key: string;
    skip_token_verification?: boolean;
    on_success: (token: string) => void;
}

export default function Captcha(data: ComponentProps<LightboxProps>) {
    const content = data.content;

    useOnce(() => {
        //@ts-ignore
        if (is_grecaptcha_loaded) {
            //@ts-ignore
            grecaptcha.render('grecaptcha', {
                sitekey: RECAPTCHA_SITE_KEY,
                callback: async (token: string) => {
                    try {
                        let success = true;
                        if (!data.content?.skip_token_verification) {
                            let res = await post({
                                url: 'landing/signups/captcha',
                                body: {
                                    token,
                                    key: content!.key,
                                },
                            });
                            success = !!res;
                        }

                        if (success) {
                            content!.on_success(token);
                            data.close();
                        }
                    } catch (e) {
                        //@ts-ignore
                        grecaptcha.reset();
                    }
                },
            });
        }
    });

    return (
        <Lightbox styles={Styles.lightbox}>
            <div id="grecaptcha"></div>
        </Lightbox>
    );
}
const Styles = CreateSheet({
    lightbox: {
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        boxShadow: 'none',
    },
});
