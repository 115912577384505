// component
import CodeInput from 'packages/elements/textfields/CodeInput';
// icons
import {ReactComponent as Warning} from 'src/icons/warning.svg';
// styles
import {css} from 'aphrodite';
import useSimpleError from 'lib/hooks/useSimpleError';
import useWaitFor from 'lib/hooks/useWaitFor';
import Countdown from 'lib/time/Countdown.react';
import {LoadButton, MakeButton} from 'packages/elements/button';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {useEffect, useState} from 'react';
import {routing} from 'src/runtime/router';
import Styles from './common.jss';
import {useAuthStore} from './store';

export default function SignUpVerificationPage() {
    return <SignUpVerificationForm />;
}

type SignUpVerificationFormProps = {
    isEmbedded?: boolean;
    email?: string;
    token?: string;
    onCancel?: (e: React.MouseEvent) => void;
    onSuccess?: () => void;
};

export const SignUpVerificationForm: React.FC<SignUpVerificationFormProps> = ({
    isEmbedded,
    onCancel,
    onSuccess,
    ...props
}) => {
    const [email, token] = useAuthStore(state => [state.email, state.signUpVerificationToken]);
    const [setEmail, setToken] = useAuthStore(state => [state.setEmail, state.setToken]);
    const [resendVerificationCode, submitVerificationCode] = useAuthStore(state => [
        state.resendVerificationCode,
        state.submitVerificationCode,
    ]);
    const [code, setCode] = useState('');
    const [isResent, setIsResent] = useState<boolean>();
    const [hasErr, setErr] = useSimpleError<'common', string>();
    const [isLoading, withLoading] = useWaitFor();

    async function onClickResendCode(e: React.MouseEvent) {
        setErr('common', false);
        setIsResent(true);
        e.preventDefault();
        const res = await withLoading(resendVerificationCode(email));
        if (res[1]) {
            setErr('common', res[1].message);
            setIsResent(false);
        }
    }

    async function onSubmitCode(e: React.FormEvent) {
        e.preventDefault();
        triggerSubmitCode(code);
    }

    async function triggerSubmitCode(code: string) {
        if (code.length !== 6) {
            setErr('common', 'Please enter a valid code');
        } else {
            const res = await withLoading(submitVerificationCode(token, code));
            if (res) {
                setErr('common', res.message);
                return;
            }

            if (isEmbedded) {
                onSuccess && onSuccess();
                return;
            }

            routing.push(`/sign-up/profile`);
        }
    }

    function onChangeCode(code: string) {
        setErr('common', false);
        setCode(code);
        if (code.length === 6) {
            triggerSubmitCode(code);
        }
    }

    function onCountdownEnded() {
        setIsResent(false);
    }

    useEffect(() => {
        if (props.email || props.token) {
            if (!email && props.email) {
                setEmail(props.email);
            }

            if (!token && props.token) {
                setToken(props.token);
            }

            return;
        }

        if (email.length === 0 || token.length === 0) {
            routing.push('/login');
        }
    }, [email, token, setEmail, setToken, props]);

    return (
        <div className={css(Styles.containerMiddle, isEmbedded && Styles.embeddedContainer)}>
            <form
                className={css(Styles.form)}
                onSubmit={onSubmitCode}
            >
                {!isEmbedded && <h1 className={css(Styles.formTitle)}>Confirm your email address</h1>}
                <p className={css(Styles.codeText, isEmbedded && Styles.embeddedCodeText)}>
                    Enter the verification code we send to:{' '}
                    <strong className={css(Styles.codeTextStrong)}>{email}</strong>
                </p>
                <div className={css(Styles.codeInput)}>
                    <CodeInput
                        {...{
                            inputMode: 'numeric',
                            fields: 6,
                            value: code,
                            onChange: onChangeCode,
                        }}
                    />
                    <SmoothLine>
                        {hasErr('common') ? (
                            <div className={css(Styles.errorText, Styles.errorContainer, Styles.verifyError)}>
                                <Warning
                                    width="14"
                                    height="14"
                                    role="img"
                                    title="Error"
                                    className={css(Styles.errorContainerIcon)}
                                />
                                <span>{hasErr('common')}</span>
                            </div>
                        ) : undefined}
                    </SmoothLine>
                </div>
                <div className={css(Styles.resend)}>
                    <span>
                        {isResent === undefined || isResent === true ? (
                            <>
                                You can resend the code in{' '}
                                <Countdown
                                    seconds={60}
                                    onFired={onCountdownEnded}
                                />
                            </>
                        ) : (
                            <>You can resend the code.</>
                        )}
                    </span>
                    <button
                        type="button"
                        onClick={onClickResendCode}
                        className={css(Styles.resendCode)}
                        disabled={isResent === undefined || isResent === true}
                    >
                        Resend code
                    </button>
                </div>
                {!isEmbedded ? (
                    <LoadButton
                        type="submit"
                        variant="FilledAccent1-Big"
                        styles={[Styles.button, Styles.verifyBtn, Styles.extraMarginTop]}
                        loading={isLoading}
                        disabled={code.length < 6}
                    >
                        Continue
                    </LoadButton>
                ) : (
                    <div className={css(Styles.embeddedVerificationFormFooter)}>
                        {onCancel && (
                            <button
                                className={MakeButton('FilledPageBg-Default')}
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        )}
                        <LoadButton
                            type="submit"
                            variant="FilledAccent1-Default"
                            loading={isLoading}
                            disabled={code.length < 6}
                        >
                            Save changes
                        </LoadButton>
                    </div>
                )}
            </form>
        </div>
    );
};
