import {get, makeAPIHook} from 'lib/fetch';
import {OnboardingChatRuntype} from '../model';

export default async function LoadOnboardingChatAPI(project_uri: string) {
    return await get(
        {
            url: `projects/${project_uri}/onboarding`,
        },
        OnboardingChatRuntype
    );
}

export const useLoadOnboardingChatAPI = makeAPIHook(LoadOnboardingChatAPI);
