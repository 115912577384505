import React from 'react';
import {css, StyleDeclaration} from 'aphrodite';
import Styles from './menu.jss';

type MenuItemDividerProps = {
    styles?: StyleDeclaration;
    alwaysVisible?: boolean;
} & Omit<React.HTMLAttributes<HTMLHRElement>, 'className'>;

export function MenuItemDivider({styles, alwaysVisible, ...rest}: MenuItemDividerProps) {
    return (
        <hr
            {...rest}
            className={`${css(Styles.divider, styles || null)}${alwaysVisible ? '' : ' menu-item-divider'}`}
        />
    );
}
