// components
import Header from '../header/header';
import Footer from '../footer/footer';
// styles
import {css} from 'aphrodite';
import Styles from './terms.jss';

export default function Landing() {
    return (
        <>
            <Header />

            <div className={css(Styles.container)}>
                <div className={css(Styles.contentHeader)}>
                    <h1 className={css(Styles.contentHeaderTitle)}>Terms of Service</h1>
                    <h2 className={css(Styles.contentHeaderSubTitle)}>Effective Date: January 1st, 2024.</h2>
                </div>

                <h3 className={css(Styles.mainTitle)}>Overview</h3>
                <p className={css(Styles.textBlock)}>
                    It’s important that you read this entire Terms of Service (“
                    <strong className={css(Styles.strong)}>Terms</strong>”); but, here are some of the more significant
                    terms that we want to bring to your attention:
                </p>
                <ul className={css(Styles.list)}>
                    <li className={css(Styles.listItem)}>
                        Each time you use the Sites/Apps, these Terms of Service and any Additional Terms (defined
                        below) apply. Any updates to them will apply to you; so you should check back each time you
                        return for any updates.
                    </li>
                    <li className={css(Styles.listItem)}>
                        You may only use the Content (defined below) on the Sites/Apps in connection with your permitted
                        activities on the Sites/Apps and not in an offline environment or in connection with another
                        site. (Sections{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos1"
                        >
                            1
                        </a>{' '}
                        &{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos3"
                        >
                            3
                        </a>
                        )
                    </li>
                    <li className={css(Styles.listItem)}>
                        Except as set forth in the{' '}
                        <a
                            className={css(Styles.link)}
                            href="/privacy"
                        >
                            Privacy Policy
                        </a>{' '}
                        that applies to the Sites/Apps, you and Company do not have a confidential, fiduciary, or any
                        other special relationship by virtue of your use of the Sites/Apps or your communications to
                        Company through or related to the Sites/Apps. (Section{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos2"
                        >
                            2
                        </a>
                        )
                    </li>
                    <li className={css(Styles.listItem)}>
                        Many types of disputes that may arise in connection with your access to and use of the
                        Sites/Apps are subject to mandatory arbitration – which includes your waiver of a right to a
                        jury trial. (Section{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos9"
                        >
                            9
                        </a>
                        )
                    </li>
                    <li className={css(Styles.listItem)}>
                        We are providing the Sites/Apps to you on an “as-is” basis, without any warranty of any kind,
                        and our liability to you in connection with your use of the Sites/Apps is very limited. Many
                        other limitations and disclaimers relate to your use of the Sites/Apps. (Sections{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos10"
                        >
                            10
                        </a>{' '}
                        &{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos11"
                        >
                            11
                        </a>
                        )
                    </li>
                </ul>
                <h3 className={css(Styles.mainTitle)}>Introduction</h3>
                <p className={css(Styles.textBlock)}>
                    Welcome to a site or application (the “<strong className={css(Styles.strong)}>Sites/Apps</strong>”)
                    that is owned and/or operated by ZappyLab, Inc. (collectively, “
                    <strong className={css(Styles.strong)}>Company,</strong>” “
                    <strong className={css(Styles.strong)}>we,</strong>” “
                    <strong className={css(Styles.strong)}>our</strong>”, or “
                    <strong className={css(Styles.strong)}>us</strong>”)! These Terms govern your use of the Sites/Apps,
                    regardless of how you access or use it. By “Sites/Apps”, we mean any Internet domain address within
                    which these Terms are posted and all features, widgets, applications, content, and downloads that
                    are operated by us and that are available through or interact with it, and/or post links to these
                    Terms. By using the Sites/Apps, you acknowledge and accept the Sites/Apps’{' '}
                    <a
                        className={css(Styles.link)}
                        href="/privacy"
                    >
                        <strong className={css(Styles.strong)}>Privacy Policy</strong>
                    </a>{' '}
                    and consent to the collection and use of your data in accordance with the{' '}
                    <a
                        className={css(Styles.link)}
                        href="/privacy"
                    >
                        <strong className={css(Styles.strong)}>Privacy Policy</strong>
                    </a>
                    . By using the Sites/Apps, you further agree that Company may change, alter, or modify the settings
                    or configurations on your Internet Device (defined below) in order to allow for or optimize your use
                    of the Sites/Apps.
                </p>
                <h3 className={css(Styles.mainTitle)}>If You Want to Use This Sites/Apps,</h3>
                <p className={css(Styles.textBlock)}>
                    then carefully read these Terms, as they constitute a written agreement between you and Company and
                    they affect your legal rights and obligations.
                </p>
                <p className={css(Styles.textBlock)}>
                    Each time you access and/or use the Sites/Apps (other than to simply read these Terms), you agree to
                    be bound by and comply with all of the terms of these Terms and any Additional Terms (defined
                    below). Therefore,{' '}
                    <i>
                        <strong className={css(Styles.strong)}>
                            do not use the Sites/Apps if you do not agree to all of the terms of these Terms and any
                            Additional Terms.
                        </strong>
                    </i>
                </p>
                <p className={css(Styles.textBlock)}>
                    The business realities associated with operating the Sites/Apps are such that, without the
                    limitations that are set forth in these Terms -- such as your grants and waivers of rights, the
                    limitations on our liability, your indemnity of us, and our arbitration of certain disputes –
                    Company would not make the Sites/Apps available to you.
                </p>
                <p className={css(Styles.textBlock)}>
                    In some instances, both these Terms and separate guidelines, rules, or terms of service or sale
                    setting forth additional or different terms and/or conditions will apply to your use of the
                    Sites/Apps or to a service or product offered via the Sites/Apps (in each such instance, and
                    collectively, “<strong className={css(Styles.strong)}>Additional Terms</strong>”). To the extent
                    there is a conflict between these Terms and any Additional Terms, the Additional Terms will control
                    unless the Additional Terms expressly state otherwise. Please also review the terms of the
                    Sites/Apps’{' '}
                    <a
                        className={css(Styles.link)}
                        href="/privacy"
                    >
                        <strong className={css(Styles.strong)}>Privacy Policy</strong>
                    </a>
                    , which you accept by using the Sites/Apps.
                </p>
                <h3 className={css(Styles.mainTitle)}>Full Details of Terms of Service</h3>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos1"
                    >
                        1. Sites/Apps Content, Ownership, Limited License, and Rights of Others
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>A. Content.</strong> The Sites/Apps contains a variety
                        of: (i) materials and other items relating to Company and its products and services, and similar
                        items from our licensors and other third parties, including all layout, information, text, data,
                        files, images, scripts, designs, graphics, button icons, instructions, illustrations,
                        photographs, audio clips, music, sounds, pictures, videos, advertising copy, URLs, technology,
                        software, interactive features, the “look and feel” of the Sites/Apps, and the compilation,
                        assembly, and arrangement of the materials of the Sites/Apps and any and all copyrightable
                        material (including source and object code); (ii) trademarks, logos, trade names, service marks,
                        and trade identities of various parties, including those of Company (collectively, “
                        <strong className={css(Styles.strong)}>Trademarks</strong>”); and (iii) other forms of
                        intellectual property (all of the foregoing, collectively “Content”).
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>B. Ownership.</strong> We claim no intellectual property
                        rights over the material you provide to the Sites/Apps. Your profile and materials uploaded
                        remain yours.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Content generated by our users belongs to the users and is licensed under the Creative Commons
                        Attribution License (CC-BY).
                    </p>
                    <p className={css(Styles.textBlock)}>
                        The Sites/Apps (including past, present, and future versions) and the Content generated by the
                        Company are owned or controlled by Company and our licensors and certain other third parties.
                        All right, title, and interest in and to the Content available via the Sites/Apps is the
                        property of Company or our licensors or certain other third parties, and is protected by U.S.
                        and international copyright, trademark, patent, or other intellectual property rights and laws
                        to the fullest extent possible. Company owns the copyright in the selection, compilation,
                        assembly, arrangement, and enhancement of the Content on the Sites/Apps.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        The look and feel of the Sites/Apps is copyright ©2024 Whitetail AI LLC All rights reserved. You
                        may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual design
                        elements or concepts without express written permission from Whitetail AI LLC.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>C. Screening.</strong> Staff at Whitetail AI reserve the
                        right to remove content which is not relevant.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos2"
                    >
                        2. Feedback You Submit
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        In your communications with Company, please keep in mind that unless we expressly request them,
                        Company does not seek any unsolicited ideas or materials for products or services, or even
                        improvements to products or services, such as ideas, concepts, inventions, or designs for
                        scientific tools or otherwise (collectively, “
                        <strong className={css(Styles.strong)}>Unsolicited Ideas and Materials</strong>”). Any
                        information and materials you post on or send to us via the Sites/Apps (regardless of whether we
                        have requested them or not) are deemed Unsolicited Ideas and Materials and licensed to us as set
                        forth below. In addition, Company retains all of the rights held by members of the general
                        public with regard to your Unsolicited Ideas and Materials. Company’s receipt of your
                        Unsolicited Ideas and Materials is not an admission by Company of their novelty, priority, or
                        originality, and it does not impair Company’s right to contest existing or future intellectual
                        property rights relating to your Unsolicited Ideas and Materials.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Except as otherwise described in the Sites/Apps’ posted{' '}
                        <a
                            className={css(Styles.link)}
                            href="/privacy"
                        >
                            <strong className={css(Styles.strong)}>Privacy Policy</strong>
                        </a>{' '}
                        or any Additional Terms, you agree that (a) your Unsolicited Ideas and Materials will be treated
                        as non-confidential – regardless of whether you mark them “confidential,” “proprietary,” or the
                        like – and will not be returned, and (b) Company does not assume any obligation of any kind to
                        you or any third party with respect to your Unsolicited Ideas and Materials. Upon Company’s
                        request, you will furnish us with any documentation necessary to substantiate the rights to such
                        content and to verify your compliance with these Terms or any Additional Terms. You acknowledge
                        that the Internet and mobile communications may be subject to breaches of security and that you
                        are aware that submissions of Unsolicited Ideas and Materials may not be secure, and you will
                        consider this before submitting any such materials and do so at your own risk. You acknowledge
                        that other users may have created ideas and concepts contained in their Unsolicited Ideas and
                        Materials that may have familiarities or similarities to your own Unsolicited Ideas and
                        Materials, and that you will not be entitled to any compensation or right to negotiate with
                        Company because of these familiarities or similarities. Notwithstanding any custom and practice
                        to pay an individual for an idea (if any), nothing herein shall create an implied or express
                        contract to compensate users for their Unsolicited Ideas and Materials and there is no
                        obligation for Company to pay or otherwise compensate you for any of ideas or materials in any
                        communications with Company, whatsoever.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        By providing Unsolicited Ideas and Materials to us or others via the Sites/Apps you hereby grant
                        to Company the non-exclusive, unrestricted, unconditional, unlimited, worldwide, irrevocable,
                        perpetual, and cost-free right and license to use, copy, record, distribute, reproduce,
                        disclose, sell, re-sell, sublicense (through multiple levels), display, publicly perform,
                        transmit, publish, broadcast, translate, make derivative works of, and otherwise use and exploit
                        in any manner whatsoever, all or any portion of your Unsolicited Ideas and Materials (and
                        derivative works thereof), for any purpose whatsoever in all formats, on or through any media,
                        software, formula, or medium now known or hereafter developed, and with any technology or
                        devices now known or hereafter developed, and to advertise, market, and promote the same.
                        Without limitation, the granted rights include the right to: (a) configure, host, index, cache,
                        archive, store, digitize, compress, optimize, modify, reformat, edit, adapt, publish in
                        searchable format, and remove such Unsolicited Ideas and Materials and combine same with other
                        materials, and (b) use any ideas, concepts, know-how, or techniques contained in any Unsolicited
                        Ideas and Materials for any purposes whatsoever, including developing, producing, and marketing
                        products and/or services. In order to further effect the rights and license that you grant to
                        Company to your Unsolicited Ideas and Materials, you also hereby grant to Company, and agree to
                        grant to Company, the unconditional, perpetual, irrevocable right to use and exploit your name,
                        persona, and likeness in connection with any Unsolicited Ideas and Materials, without any
                        obligation or remuneration to you. Except as prohibited by law, you hereby waive, and you agree
                        to waive, any moral rights (including attribution and integrity) that you may have in any
                        Unsolicited Ideas and Materials, even if it is altered or changed in a manner not agreeable to
                        you. To the extent not waivable, you irrevocably agree not to exercise such rights (if any) in a
                        manner that interferes with any exercise of the granted rights. You understand that you will not
                        receive any fees, sums, consideration, or remuneration for any of the rights granted in this
                        Section 2.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        From time to time, areas on the Sites/Apps may expressly request submissions of concepts,
                        improvements, feedback, or other potential content from you (“
                        <strong className={css(Styles.strong)}>Invited Submissions</strong>”). Where this is the case,
                        please carefully read any Additional Terms which appear elsewhere on the Sites/Apps to govern
                        those submissions, as they will affect your legal rights. If no Additional Terms govern those
                        submissions, then this Section 2 of these Terms will apply in full to any Invited Submissions
                        and you grant us a license to them just as if they were Unsolicited Ideas and Materials.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos3"
                    >
                        3. Sites/Apps and Content Use Restrictions
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>A. Sites/Apps Use Restrictions.</strong> You agree that
                        you will not: (i) aside from the purchase of goods or services offered for sale by Company or
                        its affiliates, use the Sites/Apps for any political or commercial purpose (including, without
                        limitation, for purposes of advertising, soliciting funds, collecting product prices, and
                        selling products); (ii) use any meta tags or any other “hidden text” utilizing any Company
                        trademarks or trade names; (iii) engage in any activities through or in connection with the
                        Sites/Apps that seek to attempt to or do harm any individuals or entities or are unlawful,
                        offensive, obscene, lewd, lascivious, filthy, violent, threatening, harassing, or abusive, or
                        that violate any right of any third party, or are otherwise objectionable to Company; (iv)
                        reverse engineer, decompile, disassemble, reverse assemble, or modify any Sites/Apps source or
                        object code or any software or other products, services, or processes accessible through any
                        portion of the Sites/Apps; (v) engage in any activity that interferes with a user’s access to
                        the Sites/Apps or the proper operation of the Sites/Apps, or otherwise causes harm to the
                        Sites/Apps, Company, or other users of the Sites/Apps; (vi) interfere with or circumvent any
                        security feature of the Sites/Apps or any feature that restricts or enforces limitations on use
                        of or access to the Sites/Apps or the Content; or (vii) otherwise violate these Terms or any
                        Additional Terms.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>B. Content Use Restrictions.</strong> You also agree
                        that, in using the Content: (i) you will not monitor, gather, copy, or distribute such Content
                        (except as may be a result of standard search engine activity or use of a standard browser) on
                        the Sites/Apps by using any robot, rover, “bot”, spider, scraper, crawler, spyware, engine,
                        device, software, extraction tool, or any other automatic device, utility, or manual process of
                        any kind; (ii) you will not frame or utilize framing techniques to enclose any such Content
                        (including any images, text, or page layout); (iii) you will keep intact all trademark,
                        copyright, and other intellectual property notices contained in such Content; (iv) you will not
                        use such Content in a manner that suggests an unauthorized association with any of our or our
                        licensors’ products, services, or brands; (v) you will not make any modifications to such
                        Content; (vi) you will not modify, reproduce, archive, sell, lease, rent, exchange, create
                        derivative works from, publish by hard copy or electronic means, publicly perform, display,
                        disseminate, distribute, broadcast, retransmit, circulate to any third party or on any
                        third-party application or webSites/Apps, or otherwise use or exploit such Content in any way
                        for any purpose except as specifically permitted by these Terms or any Additional Terms or with
                        the prior written consent of an officer of Company or, in the case of Content from a licensor,
                        the owner of the Content; and (vii) you will not insert any code or product to manipulate such
                        Content in any way that adversely affects any user experience.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>C. Availability of Sites/Apps and Content.</strong>{' '}
                        Company may immediately suspend or terminate the availability of the Sites/Apps and Content (and
                        any elements and features of them) for any reason, in Company’s sole discretion, and without
                        advance notice or liability.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>
                            D. Reservation of All Rights Not Granted as to Content and Sites/Apps.
                        </strong>{' '}
                        These Terms and any Additional Terms include only narrow, limited grants of rights to Content
                        and to use and access the Sites/Apps. No right or license may be construed, under any legal
                        theory, by implication, estoppel, industry custom, or otherwise. All rights not expressly
                        granted to you are reserved by Company and its licensors and other third parties. Any
                        unauthorized use of any Content or the Sites/Apps for any purpose is prohibited.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos4"
                    >
                        4. Opening and Terminating Accounts
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        In order to access or use some (or potentially all) of the features on the Sites/Apps, you must
                        first register through our online registration process. The Sites/Apps’ practices governing any
                        resulting collection and use of your personal information are disclosed in its{' '}
                        <a
                            className={css(Styles.link)}
                            href="/privacy"
                        >
                            <strong className={css(Styles.strong)}>Privacy Policy</strong>
                        </a>
                        .
                    </p>
                    <p className={css(Styles.textBlock)}>
                        If you register for any feature that requires a password and/or username, then you will select
                        your own password at the time of registration (or we may send you an e-mail notification with a
                        randomly generated initial password) and you agree that: (i) You will not use a username (or
                        e-mail address) that is already being used by someone else, may impersonate another person,
                        belongs to another person, violates the intellectual property or other right of any person or
                        entity, or is offensive. We may reject the use of any password, username, or e-mail address for
                        any other reason in our sole discretion; (ii) You will provide accurate, current, and complete
                        registration information about yourself in connection with the registration process and, as
                        permitted, to maintain and update it continuously and promptly to keep it accurate, current, and
                        complete; (iii) You are solely responsible for all activities that occur under your account,
                        password, and username – whether or not you authorized the activity; (iv) You are solely
                        responsible for maintaining the confidentiality of your password and for restricting access to
                        your Internet Device so that others may not access any password protected portion of the
                        Sites/Apps using your name, username, or password; (v) You will immediately notify us of any
                        unauthorized use of your account, password, or username, or any other breach of security; and
                        (vi) You will not sell, transfer, or assign your account or any account rights.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        We will not be liable for any loss or damage (of any kind and under any legal theory) to you or
                        any third party arising from your inability or failure for any reason to comply with any of the
                        foregoing obligations.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        If any information that you provide, or if we have reasonable grounds to suspect that any
                        information that you provide, is false, inaccurate, outdated, incomplete, or violates these
                        Terms, any Additional Terms, or any applicable law, then we may suspend or terminate your
                        account. We also reserve the more general and broad right to terminate your account or suspend
                        or otherwise deny you access to it or its benefits – all in our sole discretion, for any reason,
                        and without advance notice or liability.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos5"
                    >
                        5. Questions
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        If you have a question regarding using the Sites/Apps, you may contact Company by sending an
                        e-mail to{' '}
                        <a
                            className={css(Styles.link)}
                            href="mailto:admin@8thbyte.com"
                        >
                            admin@8thbyte.com
                        </a>
                        . You acknowledge that the provision of customer support is at Company’s sole discretion and
                        that we have no obligation to provide you with customer support of any kind. We may provide you
                        with customer support from time to time, at our sole discretion, provided that you have created
                        an account and that you submit your customer support inquiries using such account.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos6"
                    >
                        6. Links by You to the Sites/Apps
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        We grant you a limited, non-exclusive, revocable, non-assignable, personal, and non-transferable
                        license to create hyperlinks to the Sites/Apps, so long as: (a) the links only incorporate text,
                        and do not use any trademarked logos or graphics that are owned by or licensed to Company, (b)
                        the links and the content on your websites do not suggest any affiliation with Company or cause
                        any other confusion, and (c) the links and the content on your websites do not portray Company
                        or its products or services in a false, misleading, derogatory, or otherwise offensive matter,
                        and do not contain content that is unlawful, offensive, obscene, lewd, lascivious, filthy,
                        violent, threatening, harassing, or abusive, or that violate any right of any third party or are
                        otherwise objectionable to Company. Company reserves the right to suspend or prohibit linking to
                        the Sites/Apps for any reason, in its sole discretion, without advance notice or any liability
                        of any kind to you or any third party.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos7"
                    >
                        7. Linked-To Websites; Advertisements; Dealings with Third Parties
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>A. Linked Sites; Advertisements.</strong> The Sites/Apps
                        may contain links, as part of third-party ads on the Sites/Apps or otherwise, to or from
                        third-party websites (“
                        <strong className={css(Styles.strong)}>Linked Sites</strong>”), including websites operated by
                        advertisers, licensors, licensees, and certain other third parties who may have business
                        relationships with Company. Company may have no control over the content, operations, policies,
                        terms, or other elements of Linked Sites, and Company does not assume any obligation to review
                        any Linked Sites. Company does not endorse, approve, or sponsor any Linked Sites, or any
                        third-party content, advertising, information, materials, products, services, or other items.
                        Furthermore, Company is not responsible for the quality or delivery of the products or services
                        offered, accessed, obtained by or advertised at such sites. Finally, Company will under no
                        circumstances be liable for any direct, indirect, incidental or special loss or other damage,
                        whether arising from negligence, breach of contract, defamation, infringement of copyright or
                        other intellectual property rights, caused by the exhibition, distribution or exploitation of
                        any information or content contained within these third-party Linked Sites. Any activities you
                        engage in connection with any of the same are subject to the privacy and other policies, terms
                        and conditions of use, and rules issued by the operator of the Linked Sites. Company disclaims
                        all liability in connection therewith.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>B. Dealings with Third Parties.</strong> Any
                        interactions, correspondence, transactions, and other dealings that you have with any third
                        parties found on or through the Sites/Apps (including on or via Linked Sites or advertisements)
                        are solely between you and the third party (including issues related to the content of
                        third-party advertisements, payments, delivery of goods, warranties (including product
                        warranties), privacy and data security, and the like). Company disclaims all liability in
                        connection therewith.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos8"
                    >
                        8. Wireless
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>A. Wireless Features.</strong> The Sites/Apps may offer
                        certain features and services that are available to you via your wireless Internet Device. These
                        features and services may include the ability to access the Sites/Apps’ features and upload
                        content to the Sites/Apps, receive messages from the Sites/Apps, and download applications to
                        your wireless Internet Device (collectively, “Wireless Features”). Standard messaging, data, and
                        other fees may be charged by your carrier to participate in Wireless Features. Fees and charges
                        may appear on your wireless bill or be deducted from your pre-paid balance. Your carrier may
                        prohibit or restrict certain Wireless Features and certain Wireless Features may be incompatible
                        with your carrier or wireless Internet Device. You should check with your carrier to find out
                        what plans are available and how much they cost. Contact your carrier with questions regarding
                        these issues.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>B. Terms of Wireless Features.</strong> You agree that as
                        to the Wireless Features for which you are registered for, we may send communications to your
                        wireless Device regarding us or other parties. Further, we may collect information related to
                        your use of the Wireless Features. If you have registered via the Sites/Apps for Wireless
                        Features, then you agree to notify Company of any changes to your wireless contact information
                        (including phone number) and update your accounts on the Sites/Apps to reflect the changes.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos9"
                    >
                        9. Dispute Resolution
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        Certain portions of this Section 143 are deemed to be a “written agreement to arbitrate”
                        pursuant to the Federal Arbitration Act. You and Company agree that we intend that this Section
                        9 satisfies the “writing” requirement of the Federal Arbitration Act.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>
                            A. First – Try to Resolve Disputes and Excluded Disputes.
                        </strong>{' '}
                        If any controversy, allegation, or claim arises out of or relates to the Sites/Apps, the
                        Content, these Terms, or any Additional Terms, whether heretofore or hereafter arising
                        (collectively, “<strong className={css(Styles.strong)}>Dispute</strong>”), or to any of
                        Company’s actual or alleged intellectual property rights (an “
                        <strong className={css(Styles.strong)}>Excluded Dispute</strong>”, which includes those actions
                        set forth in Section 9(D)), then you and we agree to send a written notice to the other
                        providing a reasonable description of the Dispute or Excluded Dispute, along with a proposed
                        resolution of it. Our notice to you will be sent to you based on the most recent contact
                        information that you provide us. But if no such information exists or if such information is not
                        current, then we have no obligation under this Section 9(A). Your notice to us must be sent to:{' '}
                        <a
                            className={css(Styles.link)}
                            href="mailto:admin@8thbyte.com"
                        >
                            admin@8thbyte.com
                        </a>
                        . For a period of sixty (60) days from the date of receipt of notice from the other party,
                        Company and you will engage in a dialogue in order to attempt to resolve the Dispute or Excluded
                        Dispute, though nothing will require either you or Company to resolve the Dispute or Excluded
                        Dispute on terms with respect to which you and Company, in each of our sole discretion, are not
                        comfortable.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>B. Forums for Alternative Dispute Resolution.</strong>
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong>(i) Arbitration.</strong> If we cannot resolve a Dispute as set forth in Section 9(A)
                        within sixty (60) days of receipt of the notice, then either you or we may submit the Dispute to
                        formal arbitration in accordance with this Section 9(B). If we cannot resolve an Excluded
                        Dispute as set forth in Section 9(A) within sixty (60) days of receipt of the notice, then
                        either you or we may submit the Excluded Dispute to formal arbitration only if you and Company
                        consent, in a writing signed by you and an Officer or legal representative of Company, to have
                        that Excluded Dispute subject to arbitration. In such a case (and only in such a case), that
                        Excluded Dispute will be deemed a “Dispute” for the remainder of this Section 9(B).
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Upon expiration of the applicable sixty-day period and to the fullest extent permitted by
                        applicable law, a Dispute will be resolved solely by binding arbitration in accordance with the
                        then-current Commercial Arbitration Rules of the American Arbitration Association (“
                        <strong className={css(Styles.strong)}>AAA</strong>”). If the Dispute has a claimed value of not
                        more than $250,000, then the arbitration will be heard and determined by a single neutral
                        arbitrator who is a retired judge or a lawyer with not less than fifteen (15) years experience
                        as a practicing member of the bar in the substantive practice area related to the Dispute, who
                        will administer the proceedings in accordance with the AAAs Supplementary Procedures for
                        Consumer Related Disputes. If the Dispute has a claimed value of more than $250,000, or if
                        Company elects in its sole discretion to bear the costs of arbitration in excess of those that
                        would occur for a proceeding before a single neutral arbitrator, then the arbitration will be
                        heard and determined by a three-member panel, with one member to be selected by each party and
                        the third (who will be chair of the panel) selected by the two party-appointed members or by the
                        AAA in accordance with the Commercial Arbitration Rules. The arbitrator or arbitration panel, as
                        the case may be, will apply applicable law and the provisions of these Terms and any Additional
                        Terms, will determine any Dispute according to the applicable law and facts based upon the
                        record and no other basis, and will issue a reasoned award. If you and Company do not both
                        consent to the arbitration of an Excluded Dispute as set forth in the immediately preceding
                        paragraph of this Section 9(B)(i), then this paragraph and the remainder of this Section 9(B)
                        will not apply to the Excluded Dispute.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        If a party properly submits the Dispute to the AAA for formal arbitration and the AAA is
                        unwilling or unable to set a hearing date within sixty (60) days of the filing of a “demand for
                        arbitration,” then either party can elect to have the arbitration administered by the Judicial
                        Arbitration and Mediation Services Inc. (“
                        <strong className={css(Styles.strong)}>JAMS</strong>”) using JAMS’ streamlined Arbitration Rules
                        and Procedures, or by any other arbitration administration service that you and an Officer or
                        legal representative of Company consent to in writing. The substantive practice area
                        requirements for the arbitrator and the $250,000 threshold for a the number of arbitrators
                        assigned to the Dispute set forth in the paragraph above for the AAA arbitration will also apply
                        to any such arbitration under JAMS or another arbitration service.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        You can obtain AAA and JAMS procedures, rules, and fee information as follows:
                    </p>
                    <div>
                        <div>
                            AAA: 800.778.7879 <br />
                            <a
                                className={css(Styles.link)}
                                href="http://www.adr.org/"
                            >
                                http://www.adr.org/
                            </a>
                        </div>
                        <div>
                            JAMS: 949.224.1810 <br />
                            <a
                                className={css(Styles.link)}
                                href="http://www.jamsadr.com/"
                            >
                                http://www.jamsadr.com/
                            </a>
                        </div>
                    </div>
                </section>
                <section>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>
                            (ii) Nature, Limitations, and Location of Alternative Dispute Resolution.
                        </strong>{' '}
                        In arbitration, as with a court, the arbitrator must honor the terms of these Terms (and any
                        Additional Terms) and can award the prevailing party damages and other relief (including
                        attorneys’ fees). However, WITH ARBITRATION (A) THERE IS NO JUDGE OR JURY, (B) THE ARBITRATION
                        PROCEEDINGS AND ARBITRATION OUTCOME ARE SUBJECT TO CERTAIN CONFIDENTIALITY RULES, AND (C)
                        JUDICIAL REVIEW OF THE ARBITRATION OUTCOME IS LIMITED. All parties to the arbitration will have
                        the right, at their own expense, to be represented by an attorney or other advocate of their
                        choosing. If an in-person arbitration hearing is required, then it will be conducted in the
                        “metropolitan statistical area” (as defined by the U.S. Census Bureau) where you are a resident
                        at the time the Dispute is submitted to arbitration. You and we will pay the administrative and
                        arbitrator’s fees and other costs in accordance with the applicable arbitration rules; but if
                        applicable arbitration rules or laws require Company to pay a greater portion or all of such
                        fees and costs in order for this Section 9 to be enforceable, then Company will have the right
                        to elect to pay the fees and costs and proceed to arbitration. Discovery will be permitted
                        pursuant to the applicable arbitration rules. The arbitrator’s decision must consist of a
                        written statement stating the disposition of each claim of the Dispute, and must provide a
                        statement of the essential findings and conclusions on which the decision and any award (if any)
                        is based. Judgment on the arbitration decision and award (if any) may be entered in or by any
                        court that has jurisdiction over the parties pursuant to Section 9 of the Federal Arbitration
                        Act.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>C. Limited Time to File Claims.</strong> TO THE FULLEST
                        EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR WE WANT TO ASSERT A DISPUTE (BUT NOT A EXCLUDED
                        DISPUTE) AGAINST THE OTHER, THEN YOU OR WE MUST COMMENCE IT (BY DELIVERY OF WRITTEN NOTICE AS
                        SET FORTH IN SECTION 9(A)) WITHIN ONE (1) YEAR AFTER THE DISPUTE ARISES -- OR IT WILL BE FOREVER
                        BARRED.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>D. Injunctive Relief.</strong> The foregoing provisions
                        of this Section 9 will not apply to any legal action taken by Company to seek an injunction or
                        other equitable relief in connection with, any loss, cost, or damage (or any potential loss,
                        cost, or damage) relating to the Sites/Apps, any Content, and/or Company’s intellectual property
                        rights (including Company may claim that may be in dispute), Company’s operations, and/or
                        Company’s products or services.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>E. Small Claims Matters.</strong> Are Excluded from
                        Arbitration Requirement. Notwithstanding the foregoing, either of us may bring qualifying claim
                        of Disputes (but not Excluded Disputes) in small claims court, subject to Section 9(G).
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>F. No Class Action Matters.</strong> Disputes will be
                        arbitrated only on an individual basis and will not be consolidated with any other arbitrations
                        or other proceedings that involve any claim or controversy of any other party. But if, for any
                        reason, any court with competent jurisdiction or any arbitrator selected pursuant to Section
                        9(B)(i) holds that this restriction is unconscionable or unenforceable, then our agreement in
                        Section 9(B) to arbitrate will not apply and the Dispute must be brought exclusively in court
                        pursuant to Section 9(G).
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>G. Federal and State Courts in Los Angeles.</strong>{' '}
                        Except to the extent that arbitration is required in Section 9(B), and except as to the
                        enforcement of any arbitration decision or award, any action or proceeding relating to any
                        Dispute or Excluded Dispute may only be instituted in state or federal court in Wilmington,
                        Delaware. Accordingly, you and Company consent to the exclusive personal jurisdiction and venue
                        of such courts for such matters.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos10"
                    >
                        10. DISCLAIMER OF REPRESENTATIONS AND WARRANTIES
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        YOUR ACCESS TO AND USE OF THE SITES/APPS IS AT YOUR SOLE RISK.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        THE SITES/APPS IS PROVIDED ON AN “AS IS”, “AS AVAILABLE”, AND “WITH ALL FAULTS” BASIS.
                        Therefore, to the fullest extent permissible by law, Company and its subsidiaries and each of
                        their respective employees, directors, members, managers, shareholders, agents, vendors,
                        licensors, licensees, contractors, customers, successors, and assigns (collectively, “Company
                        Parties”) hereby disclaim and make no representations, warranties, endorsements, or promises,
                        express or implied, as to:
                    </p>
                    <ul className={css(Styles.list)}>
                        <li className={css(Styles.listItem)}>(a) the Sites/Apps (including the Content);</li>
                        <li>
                            (b) the functions, features, or any other elements on, or made accessible through, the
                            Sites/Apps;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (c) any products, services, or instructions offered or referenced at or linked through the
                            Sites/Apps;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (d) security associated with the transmission of your Unsolicited Ideas and Materials
                            transmitted to Company or via the Sites/Apps;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (e) whether the Sites/Apps or the servers that make the Sites/Apps available are free from
                            any harmful components (including viruses, Trojan horses, and other technologies that could
                            adversely impact your Internet Device);
                        </li>
                        <li className={css(Styles.listItem)}>
                            (f) whether the information (including any instructions) on the Sites/Apps is accurate,
                            complete, correct, adequate, useful, timely, or reliable;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (g) whether any defects to the Sites/Apps will be repaired; and
                        </li>
                        <li className={css(Styles.listItem)}>
                            (h) whether your use of the Sites/Apps is lawful in any particular jurisdiction.
                        </li>
                    </ul>
                    <p className={css(Styles.textBlock)}>
                        EXCEPT FOR ANY SPECIFIC WARRANTIES PROVIDED HEREIN OR IN ADDITIONAL TERMS PROVIDED BY A COMPANY
                        PARTY, COMPANY PARTIES HEREBY FURTHER DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR
                        MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, TITLE, CUSTOM, TRADE, QUIET
                        ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER VIRUS.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Some jurisdictions limit or do not allow the disclaimer of implied or other warranties so the
                        above disclaimers may not apply to the extent such jurisdictions’ laws are applicable.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos11"
                    >
                        11. LIMITATIONS OF LIABILITY OF COMPANY PARTIES
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        UNDER NO CIRCUMSTANCES WILL ANY COMPANY PARTIES BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGES
                        OF ANY KIND, including personal injury or death or for any direct, indirect, economic,
                        exemplary, special, punitive, incidental, or consequential losses or damages that are directly
                        or indirectly related to:
                    </p>
                    <ul className={css(Styles.list)}>
                        <li className={css(Styles.listItem)}>(a) the Sites/Apps (including the Content);</li>
                        <li className={css(Styles.listItem)}>
                            (b) your use of or inability to use the Sites/Apps, or the performance of the Sites/Apps;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (c) any action taken in connection with an investigation by Company Parties or law
                            enforcement authorities regarding your access to or use of the Sites/Apps;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (d) any action taken in connection with copyright or other intellectual property owners or
                            other rights owners;
                        </li>
                        <li className={css(Styles.listItem)}>
                            (e) any errors or omissions in the Sites/Apps’ technical operation; or
                        </li>
                        <li className={css(Styles.listItem)}>
                            (f) any damage to any user’s computer, hardware, software, modem, or other equipment or
                            technology, including damage from any security breach or from any virus, bugs, tampering,
                            fraud, error, omission, interruption, defect, delay in operation or transmission, computer
                            line, or network failure or any other technical or other malfunction, including losses or
                            damages in the form of lost profits, loss of goodwill, loss of data, work stoppage, accuracy
                            of results, or equipment failure or malfunction.
                        </li>
                    </ul>
                    <p className={css(Styles.textBlock)}>
                        The foregoing limitations of liability will apply even if any of the foregoing events or
                        circumstances were foreseeable and even if Company Parties were advised of or should have known
                        of the possibility of such losses or damages, regardless of whether you bring an action based in
                        contract, negligence, strict liability, or tort (including whether caused, in whole or in part,
                        by negligence, acts of god, telecommunications failure, or destruction of the Sites/Apps).
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
                        damages of the sort that are described above, so the above limitation or exclusion may not apply
                        to you.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>
                            EXCEPT AS MAY BE PROVIDED IN ANY ADDITIONAL TERMS, TO THE FULLEST EXTENT PERMITTED BY
                            APPLICABLE LAW, IN NO EVENT WILL COMPANY PARTIES’ TOTAL LIABILITY TO YOU, FOR ALL POSSIBLE
                            DAMAGES, LOSSES, AND CAUSES OF ACTION IN CONNECTION WITH YOUR ACCESS TO AND USE OF THE
                            SITES/APPS AND YOUR RIGHTS UNDER THESE TERMS, EXCEED AN AMOUNT EQUAL TO THE AMOUNT YOU HAVE
                            PAID COMPANY IN CONNECTION WITH THE TRANSACTION(S) THAT UNDERLIE THE CLAIM(S); PROVIDED,
                            HOWEVER, THIS PROVISION WILL NOT APPLY IF A TRIBUNAL WITH APPLICABLE JURISDICTION FINDS SUCH
                            TO BE UNCONSCIONABLE. FOR PURPOSES OF CLARITY, THE PRIOR SENTENCE DOES NOT EXPAND OR LIMIT
                            ANY EXPRESS, WRITTEN PRODUCT WARRANTY THAT IS PROVIDED BY COMPANY OR A MANUFACTURER OF A
                            PHYSICAL PRODUCT.
                        </strong>
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos12"
                    >
                        12. Waiver of Injunctive or Other Equitable Relief
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        IF YOU CLAIM THAT YOU HAVE INCURRED ANY LOSS, DAMAGES, OR INJURIES IN CONNECTION WITH YOUR USE
                        OF THE SITES/APPS, THEN THE LOSSES, DAMAGES, AND INJURIES WILL NOT BE IRREPARABLE OR SUFFICIENT
                        TO ENTITLE YOU TO AN INJUNCTION OR TO OTHER EQUITABLE RELIEF OF ANY KIND. THIS MEANS THAT, IN
                        CONNECTION WITH YOUR CLAIM, YOU AGREE THAT YOU WILL NOT SEEK, AND THAT YOU WILL NOT BE PERMITTED
                        TO OBTAIN, ANY COURT OR OTHER ACTION THAT MAY INTERFERE WITH OR PREVENT THE DEVELOPMENT OR
                        EXPLOITATION OF ANY WEBSITES/APPS, CONTENT, PRODUCT, SERVICE, OR OTHER INTELLECTUAL PROPERTY
                        OWNED, LICENSED, USED OR CONTROLLED BY COMPANY OR A LICENSOR OF COMPANY.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos13"
                    >
                        13. General Provisions
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>A. Updates to Terms.</strong> Company reserves the right
                        to modify these Terms and any Additional Terms, at any time without prior notice (“
                        <strong className={css(Styles.strong)}>Updated Terms</strong>”). You agree that we may notify
                        you of the Updated Terms by posting them on the Sites/Apps so that they are accessible via a
                        link on the Sites/Apps, and that your use of the Sites/Apps after we post the Updated Terms (or
                        engaging in such other conduct as we may reasonably specify) constitutes your agreement to the
                        Updated Terms. Therefore, you should review these Terms and any Additional Terms on a regular
                        and frequent basis. The Updated Terms will be effective as of the time that Company posts them
                        on the home page of the Sites/Apps, or such later date as may be specified in them.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>B. Company’s Consent or Approval.</strong> As to any
                        provision in these Terms or any Additional Terms that grants Company a right of consent or
                        approval, or permits Company to exercise a right in its “sole discretion,” Company may exercise
                        that right in its sole and absolute discretion. No Company consent or approval may be deemed to
                        have been granted by Company without being in writing and signed by an Officer of Company.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>C. Applicable Law.</strong> These Terms and any
                        Additional Terms will be governed by and construed in accordance with, and any Dispute and
                        Excluded Dispute will be resolved in accordance with, the laws of the State of California,
                        without regard to its conflicts of law provisions.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>D. Indemnity.</strong> You agree to, and you hereby,
                        defend, indemnify, and hold Company Parties harmless from and against any and all claims,
                        damages, losses, costs, investigations, liabilities, judgments, fines, penalties, settlements,
                        interest, and expenses (including attorneys’ fees) that directly or indirectly arise from or are
                        related to any claim, suit, action, demand, or proceeding made or brought against any Company
                        Party, or on account of the investigation, defense, or settlement thereof, arising out of or in
                        connection with, whether occurring heretofore or hereafter: (i) your use of the Sites/Apps and
                        your activities in connection with the Sites/Apps; (ii) your breach or anticipatory breach of
                        these Terms or any Additional Terms; (iii) your violation or anticipatory violation of any laws,
                        rules, regulations, codes, statutes, ordinances, or orders of any governmental or
                        quasi-governmental authorities in connection with your use of the Sites/Apps or your activities
                        in connection with the Sites/Apps; (iv) information or material transmitted through your
                        Internet Device, even if not submitted by you, that infringes, violates, or misappropriates any
                        copyright, trademark, trade secret, trade dress, patent, publicity, privacy, or other right of
                        any person or entity; (vi any misrepresentation made by you; and (vi) Company Parties’ use of
                        the information that you submit to us (all of the foregoing, “
                        <strong className={css(Styles.strong)}>Claims and Losses</strong>”). You will cooperate as fully
                        required by Company Parties in the defense of any Claim and Losses. Notwithstanding the
                        foregoing, Company Parties retain the exclusive right to settle, compromise, and pay any and all
                        Claims and Losses. Company Parties reserve the right to assume the exclusive defense and control
                        of any Claims and Losses. You will not settle any Claims and Losses without, in each instance,
                        the prior written consent of an officer of a Company Party.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        E. Operation of Sites/Apps; Availability of Products and Services; International Issues. Company
                        controls and operates the Sites/Apps from its U.S.-based offices in the U.S.A., and Company
                        makes no representation that the Sites/Apps is appropriate or available for use beyond the
                        U.S.A. If you use the Sites/Apps from other locations, you are doing so on your own initiative
                        and are responsible for compliance with applicable local laws regarding your online conduct and
                        acceptable content, if and to the extent local laws apply. The Sites/Apps may describe products
                        and services that are available only in the U.S.A. (or only parts of it) and are not available
                        worldwide. We reserve the right to limit the availability of the Sites/Apps and/or the provision
                        of any content, program, product, service, or other feature described or available on the
                        Sites/Apps to any person, entity, geographic area, or jurisdiction, at any time and in our sole
                        discretion, and to limit the quantities of any content, program, product, service, or other
                        feature that we provide. You and we disclaim any application to these Terms of the Convention on
                        Contracts for the International Sale of Goods.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>F. Export Controls.</strong> Software related to or made
                        available by the Sites/Apps may be subject to export controls of the U.S.A. No software from the
                        Sites/Apps may be downloaded, exported, or re-exported (i) into (or to a national or resident
                        of) any country or other jurisdiction to which the U.S.A. has embargoed goods, software,
                        technology or services (which, as of the effective date of these Terms, includes Cuba, North
                        Korea, Iran, Sudan, and Syria), or (ii) to anyone on the U.S. Treasury Department’s list of
                        Specially Designated Nationals or the U.S. Commerce Department’s Table of Deny Orders, or (iii)
                        to anyone on the U.S. Department of Commerce’s Bureau of Industry and Security Entities List as
                        published in the Export Administration Regulations (including entities engaged in weapons of
                        mass destruction proliferation in various countries and persons and entities that are suspected
                        of diverting U.S. origin items to embargoed countries or terrorist end-uses). You are
                        responsible for complying with all trade regulations and laws both foreign and domestic. Except
                        as authorized by law, you agree and warrant not to export or re-export the software to any
                        county, or to any person, entity, or end-user subject to U.S. export controls, including as set
                        forth in subsections (i) – (iii) above.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>G. Severability; Interpretation.</strong> If any
                        provision of these Terms, or any Additional Terms, is for any reason deemed invalid, unlawful,
                        void, or unenforceable by a court or arbitrator of competent jurisdiction, then that provision
                        will be deemed severable from these Terms or the Additional Terms, and the invalidity of the
                        provision will not affect the validity or enforceability of the remainder of these Terms or the
                        Additional Terms (which will remain in full force and effect). To the extent permitted by
                        applicable law, you agree to waive, and you hereby waive, any applicable statutory and common
                        law that may permit a contract to be construed against its drafter. Wherever the word
                        “including” is used in these Terms or any Additional Terms, the word will be deemed to mean
                        “including, without limitation,”.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>H. Communications.</strong> When you communicate with us
                        electronically, such as via e-mail and text message, you consent to receive communications from
                        us electronically. Please note that, except as set forth in{' '}
                        <a
                            className={css(Styles.link)}
                            href="#tos5"
                        >
                            Section 5
                        </a>
                        , we are not obligated to respond to inquiries that we receive. You agree that all agreements,
                        notices, disclosures, and other communications that we provide to you electronically satisfy any
                        legal requirement that such communications be in writing.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>
                            I. Investigations; Cooperation with Law Enforcement; Termination; Survival.
                        </strong>{' '}
                        Company reserves the right, without any limitation, to: (i) investigate any suspected breaches
                        of its Sites/Apps security or its information technology or other systems or networks, (ii)
                        investigate any suspected breaches of these Terms and any Additional Terms, (iii) investigate
                        any information obtained by Company in connection with reviewing law enforcement databases or
                        complying with criminal laws, (iv) involve and cooperate with law enforcement authorities in
                        investigating any of the foregoing matters, (v) prosecute violators of these Terms and any
                        Additional Terms, and (vi) discontinue the Sites/Apps, in whole or in part, or, except as may be
                        expressly set forth in any Additional Terms, suspend or terminate your access to it, in whole or
                        in part, including any user accounts or registrations, at any time, without notice, for any
                        reason and without any obligation to you or any third party. Any suspension or termination will
                        not affect your obligations to Company under these Terms or any Additional Terms. Upon
                        suspension or termination of your access to the Sites/Apps, or upon notice from Company, all
                        rights granted to you under these Terms or any Additional Terms will cease immediately, and you
                        agree that you will immediately discontinue use of the Sites/Apps. The provisions of these Terms
                        and any Additional Terms, which by their nature should survive your suspension or termination
                        will survive, including the rights and licenses you grant to Company in these Terms, as well as
                        the indemnities, releases, disclaimers, and limitations on liability and the provisions
                        regarding jurisdiction, choice of law, no class action, and mandatory arbitration.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>J. Assignment.</strong> Company may assign its rights and
                        obligations under these Terms and any Additional Terms, in whole or in part, to any party at any
                        time without any notice. These Terms and any Additional Terms may not be assigned by you, and
                        you may not delegate your duties under them, without the prior written consent of an officer of
                        Company.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>K. No Waiver.</strong> Except as expressly set forth in
                        these Terms or any Additional Terms, (i) no failure or delay by you or Company in exercising any
                        of rights, powers, or remedies under will operate as a waiver of that or any other right, power,
                        or remedy, and (ii) no waiver or modification of any term of these Terms or any Additional Terms
                        will be effective unless in writing and signed by the party against whom the waiver or
                        modification is sought to be enforced.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>M. U.S. Government Restricted Rights.</strong> If you are
                        a government end user, then this provision applies to you. The Sites/Apps provided in connection
                        with these Terms has been developed entirely at private expense, as defined in FAR section
                        2.101, DFARS section 252.227-7014(a)(1) and DFARS section 252.227-7015 (or any equivalent or
                        subsequent agency regulation thereof), and is provided as “commercial items,” “commercial
                        computer Sites/Apps” and/or “commercial computer Sites/Apps documentation.” Consistent with
                        DFARS section 227.7202 and FAR section 12.212 and to the extent required under U.S. federal law,
                        the minimum restricted rights as set forth in FAR section 52.227-19 (or any equivalent or
                        subsequent agency regulation thereof), any use, modification, reproduction, release,
                        performance, display, disclosure or distribution thereof by or for the U.S. Government shall be
                        governed solely by these Terms and shall be prohibited except to the extent expressly permitted
                        by these Terms.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        <strong className={css(Styles.strong)}>N. Connectivity.</strong> You are responsible for
                        obtaining and maintaining all Devices and other equipment and software, and all internet service
                        provider, mobile service, and other services needed for your access to and use of the App and
                        you will be responsible for all charges related to them.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos14"
                    >
                        14. Terms Applicable if Accessing the Sites/Apps Through an Apple Device
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        If you are accessing or using the Sites/Apps through an Apple device, the following additional
                        terms and conditions are applicable to you and are incorporated into the Terms by this
                        reference:
                    </p>
                    <ol className={css(Styles.list, Styles.listNumbers)}>
                        <li className={css(Styles.listItem)}>
                            To the extent that you are accessing the Sites/Apps through an Apple device, you acknowledge
                            that these Terms are entered into between you and Company and, that Apple, Inc. (“
                            <strong className={css(Styles.strong)}>Apple</strong>”) is not a party to these Terms other
                            than as third party beneficiary as contemplated below.
                        </li>
                        <li className={css(Styles.listItem)}>
                            The license granted to you in{' '}
                            <a
                                className={css(Styles.link)}
                                href="#tos1"
                            >
                                Section 1
                            </a>{' '}
                            of these Terms is subject to the permitted Usage Rules set forth in the App Store Terms of
                            Service (see:{' '}
                            <a
                                className={css(Styles.link)}
                                href="http://www.apple.com/legal/itunes/us/terms.html"
                            >
                                http://www.apple.com/legal/itunes/us/terms.html
                            </a>
                            ) and any third party terms of agreement applicable to the Sites/Apps.
                        </li>
                        <li className={css(Styles.listItem)}>
                            You acknowledge that Company, and not Apple, is responsible for providing the Sites/Apps and
                            Content thereof.
                        </li>
                        <li className={css(Styles.listItem)}>
                            You acknowledge that Apple has no obligation whatsoever to furnish any maintenance any
                            support services to you with respect to the Sites/Apps.
                        </li>
                        <li className={css(Styles.listItem)}>
                            To the maximum extent permitted by applicable law, Apple will have no other warranty
                            obligation whatsoever with respect to the Sites/Apps.
                        </li>
                        <li className={css(Styles.listItem)}>
                            Notwithstanding anything to the contrary herein, and subject to the terms in these Terms,
                            you acknowledge that, solely as between Apple and Company, Company, and not Apple is
                            responsible for addressing any claims you may have relating to the Sites/Apps, or your
                            possession and/or use thereof, including, but not limited, to: (i) product liability claims,
                            (ii) any claim that the Sites/Apps fails to confirm to any applicable legal or regulatory
                            requirement; and (iii) claims arising under consumer protection or similar legislation.
                        </li>
                        <li className={css(Styles.listItem)}>
                            Further, you agree that if the Sites/Apps, or your possession and use of the Sites/Apps,
                            infringes on a third party’s intellectual property rights, you will not hold Apple
                            responsible for the investigation, defense, settlement and discharge of any such
                            intellectual property infringement claims.
                        </li>
                        <li className={css(Styles.listItem)}>
                            You acknowledge and agree that Apple, and Apple’s subsidiaries, are third party
                            beneficiaries of these Terms, and that, upon your acceptance of the terms and conditions of
                            these Terms, Apple will have the right (and will be deemed to have accepted the right) to
                            enforce these Terms against you as a third party beneficiary thereof.
                        </li>
                        <li className={css(Styles.listItem)}>
                            When using the Sites/Apps, you agree to comply with any and all third party terms that are
                            applicable to any platform, website, technology or service that interacts with the
                            Sites/Apps.
                        </li>
                    </ol>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="tos15"
                    >
                        15. Digital Millennium Copyright Act
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        If you are a copyright owner or an agent thereof and believe that any User Submission or other
                        content at this Site infringes upon your copyright, you may submit a written notification
                        pursuant to the Digital Millennium Copyright Act (“DMCA”) (see 17 U.S.C. 512(c)(3) for further
                        information) by providing our Copyright Agent (listed below) with the following information: (i)
                        an electronic or physical signature of a person authorized to act on behalf of the owner of an
                        exclusive right that is allegedly infringed; (ii) a description of the copyrighted work claimed
                        to have been infringed or if multiple copyrighted works are covered by a notification, a
                        representative list of such works at our Site; (iii) a description of the location on the Site
                        of the allegedly infringing material(s); (iv) your address, telephone number, and email address;
                        (v) a written statement that you have a good faith belief that use of the material(s) in the
                        manner complained of is not authorized by the copyright owner, its agent, or the law; and (vi) a
                        written statement that the information in the notification is accurate, and under penalty of
                        perjury, that you are authorized to act on behalf of the owner of an exclusive right that is
                        allegedly infringed.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        If a User Submission that you submitted has been removed as a result of a notification as
                        described above and you believe that such a User Submission or portion of a User Submission was
                        posted lawfully, please contact us about our counter-notification procedure through which you
                        can dispute the allegation and request re-posting of the User Submission content at issue.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Whitetail AI’s designated Copyright Agent for notice of claims of infringement is:
                    </p>
                    <p className={css(Styles.textBlock)}>TBD</p>
                    <p className={css(Styles.textBlock)}>
                        Only notices of alleged copyright infringement and requests about the counter-notification
                        procedures should go to the Copyright Agent; any other feedback, comments, requests for
                        technical support, and other communications should be directed to Whitetail AI LLC at
                        admin@8thbyte.com. You acknowledge that if you fail to comply with all of the notice
                        requirements of the DMCA, your notice may not be valid.
                    </p>
                </section>
            </div>

            <Footer />
        </>
    );
}
