import {CreateSheet, GetColor, css} from 'aphrodite';
import ProjectChat from 'src/jsx/dashboard/components/GeneralChat/ProjectChat';
import {WithCustomScrollbar} from 'packages/elements/scrollbar/scrollbar';
import {ReactComponent as Logo} from 'src/icons/logo/logo.svg';
import {ReactComponent as ArrowDownIcon} from 'src/icons/arrow-down.svg';
import {useState} from 'react';
import useOnce from 'lib/hooks/useOnce';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';

export default function ChatOverlay({
    company_uri,
    project_uri,
    opened,
    onClose,
}: {
    company_uri: string;
    project_uri: string;
    opened: boolean;
    onClose: () => void;
}) {
    const [isRendered, setIsRendered] = useState(false);

    useOnce(() => {
        setIsRendered(true);
    });

    return (
        <div
            className={css(
                Styles.container,
                !isRendered && Styles.containerNotRendered,
                HeaderUtilityStyles({delta: 40}).relatedMaxHeight,
                opened && Styles.containerOpened
            )}
        >
            <div className={css(Styles.header)}>
                <div className={css(Styles.title)}>
                    <Logo
                        height="24"
                        width="auto"
                    />
                    <span>Chat</span>
                </div>
                <button
                    className={css(Styles.closeBtn)}
                    onClick={onClose}
                >
                    <ArrowDownIcon
                        role="img"
                        title="Clear"
                        width="16"
                        height="16"
                    />
                </button>
            </div>
            <ProjectChat
                company_uri={company_uri}
                project_uri={project_uri}
                styles={{
                    messages: [Styles.messages, WithCustomScrollbar],
                    input: [Styles.input],
                    systemMessage: [Styles.systemMessage],
                    component: [Styles.component],
                }}
                hideAvatars
                isOverlay
            />
        </div>
    );
}
const Styles = CreateSheet({
    container: {
        position: 'fixed',
        display: 'flex',
        height: 600,
        right: 20,
        bottom: 20,
        width: 400,
        flexDirection: 'column',
        borderRadius: 18,
        border: `3px solid ${GetColor('StrokeColor')}`,
        background: GetColor('PageBg'),
        boxShadow: '0px 15px 50px 0px rgba(0, 0, 0, 0.40)',
        overflow: 'hidden',

        transition: 'opacity .15s ease-out',
        opacity: 0,
    },
    containerNotRendered: {
        display: 'none',
    },
    containerOpened: {
        opacity: 1,
    },
    closeBtn: {
        background: 'transparent',
        color: GetColor('TextColor'),
        border: 0,
        display: 'flex',
        width: 34,
        height: 34,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        transition: 'background 0.15s',
        zIndex: 2,
        ':hover': {
            background: GetColor('ButtonBgHover'),
        },
        '@media(max-width: 800px)': {
            background: GetColor('ButtonBgHover'),
        },
    },
    messages: {
        padding: '0px 15px 25px 15px',
        overflow: 'auto',
    },
    component: {
        flex: 1,
        overflow: 'hidden',
    },
    systemMessage: {
        padding: 0,
    },
    input: {
        marginTop: 0,
        padding: 10,
        borderRadius: '0 0 18px 18px',
    },
    header: {
        display: 'flex',
        padding: 15,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '18px 18px 0 0',
        flexGrow: 0,
    },
    title: {
        display: 'flex',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('Primary'),
        alignItems: 'center',
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 10,
        },
    },
});
