import {EventsUsersTable} from '.';

type renderEventValue = {
    event_count: number;
    users_count: number;
};

export type renderEvent = {
    event_name: string;
    values: Array<renderEventValue>;
};

export default function ChartDataToEvents({chart}: {chart: EventsUsersTable}): Record<string, renderEvent> {
    let events: Record<string, renderEvent> = {};
    chart.data.forEach(item => {
        if (!events[item.event_name]) {
            events[item.event_name] = {
                event_name: item.event_name,
                values: [],
            };
        }

        if (item.event_count <= 50) {
            events[item.event_name].values.push({
                event_count: item.event_count,
                users_count: item.users_count,
            });
        }
    });

    for (const key in events) {
        if (Object.prototype.hasOwnProperty.call(events, key)) {
            const element = events[key];
            element.values = element.values.sort((a, b) => a.event_count - b.event_count);
        }
    }
    return events;
}
