// components
import Header from '../header/header';
import Footer from '../footer/footer';
// styles
import {css} from 'aphrodite';
import Styles from './privacy.jss';

export default function Landing() {
    return (
        <>
            <Header />

            <div className={css(Styles.container)}>
                <div className={css(Styles.contentHeader)}>
                    <h1 className={css(Styles.contentHeaderTitle)}>Privacy Policy</h1>
                    <h2 className={css(Styles.contentHeaderSubTitle)}>Effective Date: January 1st, 2024.</h2>
                </div>

                <h3 className={css(Styles.mainTitle)}>Overview</h3>
                <p className={css(Styles.textBlock)}>
                    You should read this entire Privacy Policy, but here are some high points detailed below:
                </p>
                <ul className={css(Styles.list)}>
                    <li className={css(Styles.listItem)}>
                        We ask you for, and use technology to detect, information about you. Some of this information is
                        shared with others.
                    </li>
                    <li className={css(Styles.listItem)}>
                        You consent to our practices detailed below. If you want to change your consent, terminate your
                        account or stop using and/or uninstall the Site/App (defined below).
                    </li>
                    <li className={css(Styles.listItem)}>
                        Click{' '}
                        <a
                            className={css(Styles.link)}
                            href="#toc"
                        >
                            here
                        </a>{' '}
                        for an index of topics covered below. You may click on any topic to go to the details.
                    </li>
                </ul>
                <h3 className={css(Styles.mainTitle)}>Introduction</h3>
                <p className={css(Styles.textBlock)}>
                    Welcome! You have arrived at a site or application that is owned and/or operated by Whitetail AI
                    LLC. (“
                    <strong className={css(Styles.strong)}>Company</strong>” or “
                    <strong className={css(Styles.strong)}>we,</strong>” “
                    <strong className={css(Styles.strong)}>our</strong>” or “
                    <strong className={css(Styles.strong)}>us</strong>”). This “
                    <strong className={css(Styles.strong)}>Privacy Policy</strong>” governs your use of our sites and
                    apps that post a link to this Privacy Policy (including, without limitation, both mobile and online
                    versions of our sites), and also applies to your use of interactive features, widgets, applications,
                    content or downloads that: (i) we own or control; (ii) we make available through such sites; or
                    (iii) interact with such sites and post this Privacy Policy (collectively, the “
                    <strong className={css(Styles.strong)}>Sites/Apps</strong>”) regardless of how you access or use
                    them, whether via personal computers, mobile devices or otherwise. Our statements under this Privacy
                    Policy only cover information collected at the Sites/Apps directly by us, and not by third parties
                    that may interact with our Sites/Apps.
                </p>
                <div className={css(Styles.important)}>
                    This Privacy Policy does not apply to our data collection activities offline or otherwise outside of
                    our Sites/Apps (unless otherwise stated below). To the extent we provide you notice on our
                    Sites/Apps of different or additional privacy policies or practices (e.g., at the point of our
                    collection), those additional terms shall govern such data collection and use.
                </div>
                <p className={css(Styles.textBlock)}>
                    In addition, please review the Sites/Apps’{' '}
                    <strong className={css(Styles.strong)}>
                        <a
                            className={css(Styles.link)}
                            href="/terms"
                        >
                            Terms of Service
                        </a>
                    </strong>
                    , which governs your use of the Sites/Apps.{' '}
                </p>
                <div className={css(Styles.important)}>
                    By using our Sites/Apps, you consent to our Privacy Policy and Terms of Service and our collection,
                    use and sharing of your information and data, and other activities, as described below.
                </div>
                <p className={css(Styles.textBlock)}>
                    If you have any questions about this Privacy Policy or our treatment of the information you provide
                    us, please write to us by email at{' '}
                    <a
                        className={css(Styles.link)}
                        href="mailto:admin@8thbyte.com"
                    >
                        admin@8thbyte.com
                    </a>{' '}
                    or by mail to TBD.
                </p>
                <h3 className={css(Styles.mainTitle)}>The Full Detailed Privacy Policy</h3>
                <section id="q1">
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q1"
                    >
                        1. What Information Do The Sites /Apps Collect?
                    </h3>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q1a"
                        >
                            a. Information You Provide To Us
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            <strong className={css(Styles.strong)}>
                                Personal Information and Demographic Information.
                            </strong>{' '}
                            On the Sites/Apps, we may ask you to provide us with certain categories of information such
                            as: (1) personally identifiable information, which is information that identifies you
                            personally, such as your first and last name and e-mail address (“
                            <strong className={css(Styles.strong)}>Personal Information</strong>”); and (2) demographic
                            information, such as information about your area of scientific interest, education level,
                            and occupation (“
                            <strong className={css(Styles.strong)}>Demographic Information</strong>”). We may collect
                            this information through various forms and in various places on the Sites/Apps, including
                            account registration forms, contact us forms, or when you otherwise interact with the
                            Sites/Apps. If we combine Demographic Information with the Personal Information we collect
                            directly from you on the Sites/Apps, we will treat the combined data as Personal Information
                            under this Privacy Policy.
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q1b"
                        >
                            b. Information We Collect or Store As You Access And Use The Sites/Apps
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            In addition to any Personal Information or other information that you choose to submit to us
                            via our Sites/Apps, we and our third-party service providers may use a variety of
                            technologies that automatically (or passively) store or collect certain information whenever
                            you visit or interact with the Sites/Apps (“
                            <strong className={css(Styles.strong)}>Usage Information</strong>”). This Usage Information
                            may be stored or accessed using a variety of technologies that may be downloaded to your
                            personal computer, laptop, tablet or mobile phone (a “
                            <strong className={css(Styles.strong)}>Device</strong>”) whenever you visit or interact with
                            our Sites/Apps. Usage Information may be non identifying or may be associated with you.
                            Whenever we associate Usage Information with your Personal Information, we will treat it as
                            Personal Information.
                        </p>
                        <p className={css(Styles.textBlock)}>This Usage Information may include:</p>
                        <ul className={css(Styles.list)}>
                            <li className={css(Styles.listItem)}>
                                our IP address or other unique identifier (“
                                <strong className={css(Styles.strong)}>Device Identifier</strong>
                                ”). A Device Identifier is a number that is automatically assigned to your Device used
                                to access the Sites/Apps, and our computers identify your Device by its Device
                                Identifier;
                            </li>
                            <li className={css(Styles.listItem)}>
                                your Device functionality (including browser, operating system, hardware, mobile network
                                information);
                            </li>
                            <li className={css(Styles.listItem)}>the URL that referred you to our Sites/Apps;</li>
                            <li className={css(Styles.listItem)}>
                                the areas within our Sites/Apps that you visit and your activities there, including
                                remembering you and your preferences;
                            </li>
                            <li className={css(Styles.listItem)}>your Device location;</li>
                            <li className={css(Styles.listItem)}>your Device characteristics; and</li>
                            <li className={css(Styles.listItem)}>
                                certain other Device data, including the time of day, among other information.
                            </li>
                        </ul>
                        <p className={css(Styles.textBlock)}>
                            We may use various developed methods and technologies to store or collect Usage Information
                            (“<strong className={css(Styles.strong)}>Tracking Technologies</strong>
                            ”). Tracking Technologies may set, change, alter or modify settings or configurations on
                            your Device. A few of the Tracking Technologies include, without limitation, the following
                            (and subsequent technology and methods later developed):
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Cookies.</strong>
                            </i>{' '}
                            A cookie is a data file placed on a Device when it is used to visit the Sites/Apps. A Flash
                            cookie (or locally shared object) is a data file placed on a Device via the Adobe Flash
                            plug-in that may be built-in to or downloaded by you to your Device. HTML5 cookies can be
                            programmed through HTML5 local storage. Unlike Flash cookies, HTML5 cookies do not require a
                            plug-in. Regular cookies may generally be disabled or removed by tools that are available as
                            part of most commercial browsers, and in some but not all instances can be blocked in the
                            future by selecting certain settings. Each browser you use will need to be set separately
                            and different browsers offer different functionality and options in this regard. Also, these
                            tools may not be effective with regard to Flash cookies or HTML5 cookies. For information on
                            disabling Flash cookies go to Adobe’s website{' '}
                            <a
                                className={css(Styles.link)}
                                href="http://www.adobe.com"
                            >
                                www.adobe.com
                            </a>
                            . Please be aware that if you disable or remove cookies, Flash cookies, or HTML5 cookies on
                            your Device, some parts of our Sites/Apps may not function properly, and that when you
                            revisit our Sites/Apps your ability to limit cookies is subject to your browser settings and
                            limitations.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Web Beacons.</strong>
                            </i>{' '}
                            Small graphic images or other web programming code called web beacons (also known as “1x1
                            GIFs” or “clear GIFs”) may be included in our Sites/Apps’ pages and messages. Web beacons
                            may be invisible to you, but any electronic image or other web programming code inserted
                            into a page or e-mail can act as a web beacon. Web beacons or similar technologies may be
                            used for a number of purposes, including, without limitation, to count visitors to the
                            Sites/Apps, to monitor how users navigate the Sites/Apps, to count how many e-mails that
                            were sent were actually opened or to count how many particular articles or links were
                            actually viewed.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Embedded Scripts.</strong>
                            </i>{' '}
                            An embedded script is programming code that is designed to collect information about your
                            interactions with the Sites/Apps, such as the links you click on. The code is temporarily
                            downloaded onto your Device from our web server or a third party service provider, is active
                            only while you are connected to the Sites/Apps, and is deactivated or deleted thereafter.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>ETag, or entity tag.</strong>
                            </i>{' '}
                            A feature of the cache in browsers. It is an opaque identifier assigned by a web server to a
                            specific version of a resource found at a URL. If the resource content at that URL ever
                            changes, a new and different ETag is assigned. Used in this manner ETags are a form of
                            Device Identifier. ETag tracking may generate unique tracking values even where the consumer
                            blocks HTTP, Flash, and HTML5 cookies.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            We may use Tracking Technologies for a variety of purposes, including:
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Strictly Necessary.</strong>
                            </i>{' '}
                            We may use cookies or other Tracking Technologies that we consider are strictly necessary to
                            allow you to use and access our Sites/Apps, including cookies required to prevent fraudulent
                            activity, improve security or allow you to make use of shopping cart functionality.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Performance Related.</strong>
                            </i>{' '}
                            We may use cookies or other Tracking Technologies that are useful in order to assess the
                            performance of the Sites/Apps, including as part of our analytic practices or otherwise to
                            improve the content, products or services offered through the Sites/Apps.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Functionality Related.</strong>
                            </i>{' '}
                            We may use cookies or other Tracking Technologies that are required to offer you enhanced
                            functionality when accessing the Sites/Apps, including identifying you when you log in to
                            our Sites/Apps or keeping track of our specified preferences, including in terms of the
                            presentation of content on our Sites/Apps.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            <i>
                                <strong className={css(Styles.strong)}>Advertising Related.</strong>
                            </i>{' '}
                            We may use Tracking Technologies to deliver content, including ads, relevant to your
                            interests on our Sites/Apps and third party sites based on how you interact with our
                            advertisements or content. This includes using Tracking Technologies to understand the
                            usefulness to you of the advertisements that have been delivered to you. Further information
                            on this is found in Section 5 below.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            There may be other Tracking Technologies now and later devised and used by us in connection
                            with the Sites/Apps. Further, third parties may use Tracking Technologies with our
                            Sites/Apps. We may not control those Tracking Technologies and in such event, we are not
                            responsible for them. However, you consent to potentially encountering third party Tracking
                            Technologies in connection with use of our Sites/Apps and accept that our statements under
                            this Privacy Policy do not apply to the Tracking Technologies or practices of such third
                            parties. For more information on advertising and advertisers, and related Tracking
                            Technologies, click{' '}
                            <a
                                className={css(Styles.link)}
                                href="#q5"
                            >
                                here
                            </a>
                            .
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q1c"
                        >
                            c. Information Third Parties Provide About You
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We may, from time to time, supplement the information we collect directly from you on our
                            Sites/Apps with outside records from third parties for various purposes, including to
                            enhance our ability to serve you, to tailor our content to you and to offer you
                            opportunities that may be of interest to you. To the extent we combine information we
                            receive from those sources with Personal Information we collect on the Sites/Apps, it will
                            be treated as Personal Information and we will apply this Privacy Policy to such combined
                            information, unless we have disclosed otherwise. In no other circumstances do our promises
                            under this Privacy Policy apply to information we receive about you from third parties.{' '}
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q1d"
                        >
                            d. Information You Provide About a Third Party
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            You may send someone else a communication from the Sites/Apps, such as sending an invitation
                            to a friend. If so, the information you provide (names, e-mail addresses, mobile number,
                            etc.) is used to facilitate the communication and is not used by us for any other marketing
                            purpose unless we obtain consent from that person or we explicitly say otherwise. Please be
                            aware that when you use any send-to-a-friend functionality on our Sites/Apps, your e-mail
                            address, mobile number, name or user name and message may be included in the communication
                            sent to your addressee(s). Some of these tools may be third party tools subject to 3rd party
                            privacy policies.
                        </p>
                        <h4 className={css(Styles.mainSmallTitle)}>
                            There are separate third party privacy policies for some of the tools and systems we use:
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            Google Drive (for files collaboration) –{' '}
                            <a
                                href="https://www.google.com/policies/privacy/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="https://www.google.com/policies/privacy/"
                                className={css(Styles.link)}
                            >
                                https://www.google.com/policies/privacy/
                            </a>
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q1e"
                        >
                            e. Legal Bases for Processing Information
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We rely on the following legal grounds to process your personal information:{' '}
                        </p>
                        <ul className={css(Styles.list)}>
                            <li className={css(Styles.listItem)}>
                                Performance of a contract – We need to collect and use your personal information to
                                perform our agreement with you to deliver the Service as described in this Privacy
                                Policy.
                            </li>
                            <li className={css(Styles.listItem)}>
                                Consent – We may use or disclose some of your personal information such as precise
                                location information or collect cookies as described in this Privacy Policy subject to
                                your consent.
                            </li>
                            <li className={css(Styles.listItem)}>
                                Legitimate interests – We may use your personal information for our legitimate interests
                                to improve our products and services and the content on our Services. Consistent with
                                our legitimate interests and any choices that we offer or consents that may be required
                                under applicable laws, we may use technical information as described in this Privacy
                                Policy and use personal information for our marketing purposes.
                            </li>
                        </ul>
                    </section>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q2"
                    >
                        2. How Do We Use The Information Collected?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        We may use your Personal Information, Demographic Information or Usage Information that is
                        subject to this Privacy Policy: (1) to provide you with information or services or process
                        transactions that you have requested or agreed to receive including to send you electronic
                        newsletters, or to provide you with special offers or promotional materials on behalf of us or
                        third parties; (2) to enable you to participate in a variety of the Sites/Apps’ features such
                        providing you with recommendations for materials we think you might be interested in reading;
                        (3) to process your registration with the Sites/Apps, including verifying your information is
                        active and valid; (4) to improve the Sites/Apps or our services, to customize your experience on
                        the Sites/Apps, or to serve you specific content that is relevant to you; (5) to contact you
                        with regard to your use of the Sites/Apps and, in our discretion, changes to the Sites/Apps
                        and/or Sites/Apps’ policies; (6) for internal business purposes; and (7) for purposes disclosed
                        at the time you provide your information or as otherwise set forth in this Privacy Policy.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Please note that information submitted to the Sites/Apps via a “contact us” or other similar
                        function may not receive a response and we will not use that information so provided to contact
                        you for marketing purposes unrelated to your inquiry unless you agree otherwise.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q3"
                    >
                        3. How and When Do We Disclose Information to Third Parties?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        We may share Personal Information, such as aggregated user statistics, with third parties. We
                        may share your Device Identifiers with third parties along with data related to you and your
                        activities. We do not share your Personal Information with third parties for those third
                        parties’ marketing purposes unless you consent to such sharing at the time you provide your
                        Personal Information. In addition, we may share the information we have collected about you,
                        including Personal Information, as disclosed at the time you provide your information and as
                        described below or otherwise in this Privacy Policy.
                    </p>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q3a"
                        >
                            a. When You Agree To Receive Information From Third Parties
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            You may be presented with an option on our Sites/Apps to receive certain information and/or
                            marketing offers directly from third parties. If you choose to do so, your Personal
                            Information will be disclosed to such third parties and all information you disclose will be
                            subject to the third party privacy policies and practices of such third parties. We are not
                            responsible for such third party privacy policies and practices of such third parties and,
                            therefore, you should review such third party privacy policies and practices of such third
                            parties prior to agreeing to receive such information from them. If you later decide that
                            you no longer want to receive communications from one of these third parties, you will need
                            to contact that third party directly.
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q3b"
                        >
                            b. Third Parties Providing Services on Our Behalf
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We may use third party vendors to perform certain services on behalf of us or the
                            Sites/Apps, such as hosting the Sites/Apps, designing and/or operating the Sites/Apps’
                            features, tracking the Sites/Apps’ activities and analytics, and enabling us to send you
                            special offers or perform other administrative services. We may provide these vendors with
                            access to user information, including Device Identifiers and Personal Information, to carry
                            out the services they are performing for you or for us. Third party analytics service
                            providers may set and access their own Tracking Technologies on your Device and they may
                            otherwise collect or have access to information about you, potentially including Personal
                            Information, about you. We are not responsible for those third party technologies or
                            activities arising out of them.
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q3c"
                        >
                            c. Administrative and Legal Reasons
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We may access, use, preserve, transfer and disclose your information (including Device
                            Identifiers and Personal Information), including disclosure to third parties: (i) to satisfy
                            any applicable law, regulation, subpoenas, governmental requests or legal process if in our
                            good faith opinion such is required or permitted by law; (ii) to protect and/or defend the
                            Sites/Apps’{' '}
                            <a
                                className={css(Styles.link)}
                                href="/terms"
                            >
                                Terms of Service
                            </a>{' '}
                            or other policies applicable to the Sites/Apps, including investigation of potential
                            violations thereof; (iii) to protect the safety, rights, property or security of the
                            Sites/Apps or any third party; and/or (iv) to detect, prevent or otherwise address fraud,
                            security or technical issues. Further, we may use IP address or other Device Identifiers, to
                            identify users, and may do so in cooperation with third parties such as copyright owners,
                            internet service providers, wireless service providers and/or law enforcement agencies,
                            including disclosing such information to third parties, all in our discretion, subject to
                            applicable law. Such disclosures may be carried out without notice to you.
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q3d"
                        >
                            d. Business Transfer
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We may share your information, including your Device Identifiers and Personal Information,
                            Demographic Information and Usage Information with our parent, subsidiaries and affiliates.
                            We also reserve the right to disclose and transfer all such information: (i) to a subsequent
                            owner, co-owner or operator of the Sites/Apps or applicable database; or (ii) in connection
                            with a merger, consolidation, restructuring, the sale of substantially all of our interests
                            and/or assets or other corporate change, including, during the course of any due diligence
                            process.
                        </p>
                    </section>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q4"
                    >
                        4. Do Third Party Content And Links To Third Party Sites Appear on The Sites/Apps?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        The Sites/Apps may contain content that is supplied by a third party, and those third parties
                        may collect Usage Information and your Device Identifier when pages from the Sites/Apps are
                        served to you. In addition, when you are on the Sites/Apps you may be directed to other sites
                        that are operated and controlled by third parties that we do not control. We are not responsible
                        for the data collection and privacy practices employed by any of these third parties or their
                        sites. For example, if you “click” on a link, the “click” may take you off the Sites/Apps onto a
                        different site. These other sites may associate their Tracking Technologies with you,
                        independently collect data about you, including Personal Information, and may or may not have
                        their own published privacy policies. We encourage you to note when you leave our Sites/Apps and
                        to review the third party privacy policies of all third party locations and exercise caution in
                        connection with them.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q5"
                    >
                        5. Are There Third Party Ads Served On The Sites/Apps?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        The Sites/Apps may use third parties such as network advertisers to serve advertisements on the
                        Sites/Apps and may use third party analytics service providers to evaluate and provide us and/or
                        third parties with information about the use of the Sites/Apps and viewing of ads and of our
                        content. Network advertisers are third parties that display advertisements, which may be based
                        on your visits to the Sites/Apps and other apps and sites you have visited. Third party ad
                        serving enables us to target advertisements to you for products and services in which you might
                        be interested.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        The Sites/Apps’ third party ad network providers, the advertisers, the sponsors and/or traffic
                        measurement services may themselves set and access their own cookies (including Flash cookies),
                        web beacons and other Tracking Technologies on your Device and track certain behavioral Usage
                        Information regarding users of your Device via a Device Identifier. These third party Tracking
                        Technologies may be set to, among other things: (a) help deliver advertisements to you that you
                        might be interested in; (b) prevent you from seeing the same advertisements too many times; and
                        (c) understand the usefulness to you of the advertisements that have been delivered to you. You
                        acknowledge and agree that associated technology may access and use your Device and may set or
                        change settings on your Device in connection with the associated operations. Note that any
                        images (or any other parts of content) served by third parties in association with third party
                        ads or other content may serve as web beacons, which enable third parties to carry out the
                        previously described activities.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        Third party Tracking Technologies are not controlled by us, even if they use our technology to
                        help store or collect data. This Privacy Policy does not apply to the methods for collecting
                        information used by these third party advertisers and others or the use of the information that
                        such third parties collect. We do however work with third parties to make efforts to have you
                        provided with the information on their practices and any available opportunity to exercise
                        choice. The relevant third party’s Terms of Service, privacy policy, permissions, notices and
                        choices should be reviewed regarding their collection, storage and sharing practices. We make no
                        representations regarding the policies or practices of third party advertisers or advertising
                        networks.
                    </p>
                    <p className={css(Styles.textBlock)}>
                        While we may use a variety of companies to serve advertisements on the Sites/Apps, you may wish
                        to visit{' '}
                        <a
                            className={css(Styles.link)}
                            href="http://www.networkadvertising.org/optout_nonppii.asp"
                        >
                            http://www.networkadvertising.org/optout_nonppii.asp
                        </a>
                        , which provides information regarding this practice by Network Advertising Initiative (“
                        <strong className={css(Styles.strong)}>NAI</strong>”) members, and your choices regarding having
                        this information used by these companies, including the “opt-out” procedures of NAI members.
                        Opting out of one or more NAI members only means that those NAI members no longer will be able
                        to deliver targeted content and/or ads to you, which will affect this and other sites, but does
                        not mean you will no longer receive any targeted content and/or ads. Also, if your browsers are
                        configured to reject cookies when you visit this opt-out page, or you subsequently erase your
                        cookies, use a different Device or change web browsers, your NAI opt-out may not, or may no
                        longer, be effective. Additional information is available on the NAI’s web site accessible by
                        the above link. You may also opt-out of receiving third party behavioral ads on this site by
                        visiting the Digital Advertising Alliance (“
                        <strong className={css(Styles.strong)}>DAA</strong>”) website at{' '}
                        <a
                            className={css(Styles.link)}
                            href="http://www.aboutads.info/choices/#completed"
                        >
                            http://www.aboutads.info/choices/#completed
                        </a>
                        . Similar limitations may apply to the DAA opt-out.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q6"
                    >
                        6. How Do I Change My Information And Communications Preferences?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        You are responsible for maintaining the accuracy of the information you submit to us, such as
                        your contact information provided as part of registration. The Sites/Apps may allow you to
                        review, correct or update Personal Information you have provided through the Sites/Apps’
                        registration forms or otherwise, and you may provide registration updates and changes by
                        contacting us at:{' '}
                        <a
                            className={css(Styles.link)}
                            href="mailto:admin@8thbyte.com"
                        >
                            admin@8thbyte.com
                        </a>
                        . If so, we will make good faith efforts to make requested changes in our then active databases
                        as soon as reasonably practicable. With respect to our mobile applications (i.e., when we are
                        the application publisher), you can prospectively stop all collection of information by the
                        application by uninstalling the application. You may use the standard uninstall process as may
                        be available as part of your applicable Device or potentially via the appropriate application or
                        app marketplace. Please note that it is not always possible to completely remove or delete all
                        of your information from our databases and that residual data may remain on backup media or for
                        other reasons. When you edit your Personal Information or change your preferences on the
                        Sites/Apps, information that you remove may persist internally for Company’s administrative
                        purposes. You may cancel or modify our e-mail marketing communications you receive from us by
                        following the instructions contained within our promotional e-mails or in some cases by logging
                        into your account and changing your communication preferences. This will not affect subsequent
                        subscriptions and if your opt-out is limited to certain types of e-mails the opt-out will be so
                        limited. Please note that we reserve the right to send you certain communications relating to
                        your account or use of our Sites/Apps, such as administrative and service announcements and
                        these transactional account messages may be unaffected if you choose to opt-out from receiving
                        our marketing communications. If you have any questions about: your Privacy Policy or practices
                        described in it, you should contact us by e-mail:{' '}
                        <a
                            className={css(Styles.link)}
                            href="mailto:admin@8thbyte.com"
                        >
                            admin@8thbyte.com
                        </a>
                        .
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q7"
                    >
                        7. Data Transfer, Retention, and Deletion
                    </h3>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q7a"
                        >
                            a. Transfer and Processing of Data
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            For users within the United States, we process data in data centers located in the United
                            States. We have adopted reasonable physical, technical, and organizational safeguards
                            against accidental, unauthorized, or unlawful destruction, loss, alteration, disclosure,
                            access, use, or processing of user data in our possession. We comply with state and federal
                            laws governing the protection of personal information.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            For users within the European Union and Switzerland, we transfer data from the European
                            Union to data centers located in the United States for processing. Such processing is
                            performed in accordance with Regulation 2016/679 of the European Parliament and of the
                            Council of April 27, 2016 on the protection of natural persons with regard to the processing
                            of personal data and free movement of such data, known as the General Data Protection
                            Regulation (“GDPR”). This includes the imposition of required safeguards with respect to
                            accidental, unauthorized or unlawful destruction, loss, alteration, disclosure, access, use
                            or processing of data. Transfers of European Union user data to processors in the United
                            States are made in accordance with the EU-US and Swiss-US Privacy Shield Framework, as
                            explained below.
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q7b"
                        >
                            b. Retention and Deletion
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We will only retain your personal information for as long as necessary to fulfill the
                            purposes for which it was collected and processed, including for the purposes of satisfying
                            any legal, regulatory, accounting or reporting requirements.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            In some circumstances, we may anonymize your personal information so that it can no longer
                            be associated with you, in which case it is no longer personal information.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            It is our policy to retain personal information for three (3) months once such personal
                            information is no longer necessary to deliver the Service and to delete such personal
                            information thereafter. This means that, if you close your account with us, we will delete
                            personal information associated with your account after three (3) months. Regarding other
                            types of information we collect as described in this policy (Cookies, Log Files,
                            Geo-Location Information, Device Identifiers), it is our policy to retain such personal
                            information for three (3) months and to delete such personal information thereafter.
                        </p>
                    </section>
                    <section>
                        <h4
                            className={css(Styles.mainSmallTitle)}
                            id="q7c"
                        >
                            c. Users Located in the European Union and Switzerland
                        </h4>
                        <p className={css(Styles.textBlock)}>
                            We comply with the EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework
                            as set forth by the US Department of Commerce regarding the collection, use, and retention
                            of personal information from European Union member countries and Switzerland transferred to
                            the United States pursuant to Privacy Shield. We have certified that we adhere to the
                            Privacy Shield Principles with respect to such data. If there is any conflict between the
                            policies in this privacy policy and data subject rights under the Privacy Shield Principles,
                            the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program,
                            and to view our certification page, please visit{' '}
                            <a
                                href="https://www.privacyshield.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="https://www.privacyshield.gov"
                                className={css(Styles.link)}
                            >
                                https://www.privacyshield.gov
                            </a>
                        </p>
                        <p className={css(Styles.textBlock)}>
                            If you are a resident of the EU or Switzerland, have the right to:
                        </p>
                        <ul className={css(Styles.list)}>
                            <li className={css(Styles.listItem)}>
                                request an accounting of all personal information that we possess that pertains to you
                                in an electronically portable format (e.g., electronic copies of information attached to
                                an email).
                            </li>
                            <li className={css(Styles.listItem)}>
                                request that we change any personal information that pertains to you.
                            </li>
                            <li className={css(Styles.listItem)}>
                                request that we delete any personal information that pertains to you.
                            </li>
                            <li className={css(Styles.listItem)}>
                                fully or partially withdraw your consent to the collection, processing, and/or transfer
                                of your personal information.
                            </li>
                        </ul>
                        <p className={css(Styles.textBlock)}>
                            To request an accounting of your personal information, a change to your personal
                            information, deletion of your personal information, or to withdraw your consent to the
                            collection, processing, and/or transfer of your personal information, contact{' '}
                            <a
                                className={css(Styles.link)}
                                href="mailto:admin@8thbyte.com"
                            >
                                admin@8thbyte.com
                            </a>
                            . Once we have received notification that you withdraw your consent, we will no longer
                            process your information for the purpose(s) to which you originally consented unless there
                            are compelling legitimate grounds for further processing which override your interests,
                            rights and freedoms or for the establishment, exercise or defense of legal claims.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            We commit to resolve complaints about your privacy and our collection or use of your
                            personal information transferred to the United States pursuant to the EU-US and Swiss-US
                            Privacy Shield Framework. .
                        </p>
                        <p className={css(Styles.textBlock)}>
                            We are committed to refer unresolved privacy complaints under the Privacy Shield Framework
                            to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD, operated by the
                            Council of Better Business Bureaus. If you do not receive timely acknowledgment of your
                            complaint, or if your complaint is not satisfactorily addressed, please visit{' '}
                            <a
                                href="www.bbb.org/EU-privacy-shield/for-eu-consumers"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="www.bbb.org/EU-privacy-shield/for-eu-consumers"
                                className={css(Styles.link)}
                            >
                                www.bbb.org/EU-privacy-shield/for-eu-consumers
                            </a>{' '}
                            for more information and to file a complaint. This service is provided free of charge to
                            you.
                        </p>
                        <p className={css(Styles.textBlock)}>
                            If your Privacy Shield complaint cannot be resolved through the above channels, under
                            certain conditions, you may invoke binding arbitration for some residual claims not resolved
                            by other redress mechanisms. See Privacy Shield Annex 1 at
                            <a
                                href="https://www.privacyshield.gov/article?id=ANNEX-I-introductio"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
                                className={css(Styles.link)}
                            >
                                https://www.privacyshield.gov/article?id=ANNEX-I-introduction
                            </a>
                            .
                        </p>
                        <p className={css(Styles.textBlock)}>
                            You also have a right to lodge a complaint with a national Data Protection Authority. Each
                            European Union member nation has established its own Data Protection Authority; you can find
                            out about the Data Protection Authority in your country here:
                            <a
                                href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                                className={css(Styles.link)}
                            >
                                http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
                            </a>
                            .
                        </p>
                    </section>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q8"
                    >
                        8. What Should Parents Know About Children?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        The Sites/Apps are general audience web sites and we do not knowingly collect any personal
                        information from children younger than the age of thirteen (13) as required by U.S. law. We will
                        delete any personal information collected that we later determine to be from a user younger than
                        the age of thirteen (13). If you are a parent or guardian of a child under the age of thirteen
                        (13) and believe he or she has disclosed personal information to us, please contact us at{' '}
                        <a
                            className={css(Styles.link)}
                            href="mailto:admin@8thbyte.com"
                        >
                            admin@8thbyte.com
                        </a>
                        .
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q9"
                    >
                        9. What About Security?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        We incorporate commercially reasonable safeguards to help protect and secure your Personal
                        Information. However, no data transmission over the Internet, mobile networks, wireless
                        transmission or electronic storage of information can be guaranteed to be 100% secure. Please
                        note that we cannot ensure the security of any information you transmit to us, and you use our
                        Sites/Apps and provide us with your information at your own risk.
                    </p>
                </section>
                <section>
                    <h3
                        className={css(Styles.mainSubTitle)}
                        id="q10"
                    >
                        10. What About Changes To The Privacy Policy?
                    </h3>
                    <p className={css(Styles.textBlock)}>
                        We reserve the right to change this Privacy Policy at any time without notice to you. Any
                        changes will be effective immediately upon the posting of the revised Privacy Policy. However,
                        we will not use your Personal Information in a manner materially different than promised at the
                        time it was collected without your consent. To the extent any provision of this Privacy Policy
                        is found by a competent tribunal to be invalid or unenforceable, such provision shall be severed
                        to the extent necessary for the remainder to be valid and enforceable.
                    </p>
                </section>
            </div>

            <Footer />
        </>
    );
}
