import * as r from 'runtypes';
import {ChartRuntype} from 'packages/charts';

export const ProductRevenueMarker = 'product-revenue-chart';

export const ProjectChartRuntype = r.Record({
    guid: r.String,
    name: r.String,
    error_text: r.String.nullable(),
    marker: r.String,
    chart: ChartRuntype.nullable(),
});
export type ProjectChart = r.Static<typeof ProjectChartRuntype>;
