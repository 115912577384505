import {css} from 'aphrodite';
import {Styles} from '../general/RuntypeGuidlines';

export default function BarChartGuidelines() {
    return (
        <div className={css(Styles.container)}>
            <div>This chart is expecting to have the following columns in the SQL statement: </div>
            <div className={css(Styles.feilds)}>
                <div>
                    <strong>name</strong> - string
                </div>
                <div>
                    <strong>[string]</strong> - number
                </div>
                <div>
                    <strong>...</strong>
                </div>
            </div>
        </div>
    );
}
