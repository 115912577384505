import React, {useEffect, useRef} from 'react';
// styles
import {css, GeneratedStyles} from 'aphrodite';
import useMountTransition from 'lib/hooks/useMountTransition';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import Portal from 'lib/portal/portal.react';
import Styles from './drawer.jss';

interface StylesProps {
    root?: GeneratedStyles;
    box?: GeneratedStyles;
    bg?: GeneratedStyles;
}

interface Props {
    position?: 'left' | 'right' | 'top' | 'bottom';
    isOpened: boolean;
    children: React.ReactNode;
    close: () => void;
    styles?: StylesProps;
    preventCloseOnClickOutside?: boolean;
    keepScrolling?: boolean;
}

export default function Drawer(props: Props) {
    const {position = 'left', isOpened, children, styles, close, preventCloseOnClickOutside, keepScrolling} = props;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isTransitioning = useMountTransition(isOpened, isOpened ? 0 : 300);

    useEffect(() => {
        const updatePageScroll = () => {
            if (!keepScrolling) {
                if (isOpened) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = '';
                }
            }
        };

        updatePageScroll();
    }, [isOpened, keepScrolling]);

    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                close();
            }
        };

        if (isOpened) {
            window.addEventListener('keyup', onKeyPress);
        }

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        };
    }, [isOpened, close]);

    useOutsideClick(wrapperRef, () => {
        if (isOpened && isTransitioning && !preventCloseOnClickOutside) {
            close();
        }
    });

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    if (!isTransitioning && !isOpened) {
        return null;
    }

    return (
        <Portal>
            <div className={css(Styles.block, keepScrolling && Styles.blockKeepScrolling, styles?.root)}>
                <div
                    className={css(Styles.bg, isOpened && isTransitioning && Styles.bgOpened, styles?.bg)}
                    onClick={() => {
                        if (!preventCloseOnClickOutside) {
                            close();
                        }
                    }}
                />
                <div
                    ref={wrapperRef}
                    className={css(
                        Styles.content,
                        position === 'left' && Styles.contentLeft,
                        position === 'right' && Styles.contentRight,
                        position === 'top' && Styles.contentTop,
                        position === 'bottom' && Styles.contentBottom,
                        isOpened && isTransitioning && Styles.contentOpened,
                        styles?.box
                    )}
                >
                    {children}
                </div>
            </div>
        </Portal>
    );
}
