import moment from 'moment';
import 'moment-duration-format';
import {useEffect, useRef, useState} from 'react';

export default function Countdown({seconds, onFired}: {seconds: number; onFired?: () => void}) {
    const Interval = useRef<NodeJS.Timeout>();
    const [Seconds, SetSeconds] = useState(seconds);

    useEffect(() => {
        Interval.current = setInterval(() => {
            SetSeconds(s => Math.max(0, s - 1));
        }, 1000);

        return () => {
            if (Interval.current) {
                clearInterval(Interval.current);
            }
        };
    }, []);

    useEffect(() => {
        if (Seconds === 0) {
            if (Interval.current) {
                clearInterval(Interval.current);
            }

            if (typeof onFired === 'function') {
                onFired();
            }
        }
    }, [Seconds, onFired]);

    return <span>{moment.duration(Seconds, 'seconds').format('mm:ss', {trim: false})}</span>;
}
