import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export const SectionsStyles = CreateSheet({
    sectionTitle: {
        color: GetColor('Primary'),
        fontSize: '1.375rem',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    setting: {
        margin: '20px 0',
        display: 'flex',
        gap: 20,
        alignItems: 'center',
    },
    settingInfo: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 7,

        color: 'Primary',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    settingInfoDesc: {
        color: GetColor('Secondary'),
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 'normal',
        wordBreak: 'break-all',
    },
    divider: {
        height: 1,
        background: GetColor('StrokeColor'),
    },
    dangerBtn: {
        color: GetColor('Danger'),
        ':hover': {
            color: `${GetColor('Danger')} !important`,
            boxShadow: `inset 0 0 0 3px ${GetColor('StrokeColor')} !important`,
        },
        ':focus': {
            color: `${GetColor('Danger')} !important`,
            boxShadow: `inset 0 0 0 3px ${GetColor('StrokeColor')} !important`,
        },
    },
    outsideFocus: {
        opacity: 0.3,
        pointerEvents: 'none',
    },
});
