import {routing} from 'src/runtime/router';
// components
import Header from '../header/header';
import Footer from '../footer/footer';
import Tooltip from 'packages/tooltip';
// icons
import {ReactComponent as Info} from 'src/icons/info-outlined.svg';
import {ReactComponent as Done} from 'src/icons/done.svg';
import {ReactComponent as None} from 'src/icons/none-circle.svg';
import {ReactComponent as Star} from 'src/icons/star-filled.svg';
// styles
import {css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import Styles from './pricing.jss';

export default function Pricing() {
    return (
        <div className={css(Styles.page)}>
            <Header />

            <div className={css(Styles.content)}>
                <div className={css(Styles.hero)}>
                    <div className={css(Styles.container)}>
                        <div className={css(Styles.eyeBrow)}>Pricing</div>
                        <h1 className={css(Styles.title)}>Affordable Subscription Plans</h1>
                        <p className={css(Styles.heroText)}>
                            Explore our range of subscription plans designed to meet your needs.
                        </p>
                    </div>
                </div>
                <div className={css(Styles.container)}>
                    <div className={css(Styles.row)}>
                        <div className={css(Styles.col, Styles.planTitles)}>
                            <div className={css(Styles.plan)}>
                                <div className={css(Styles.planHeaderTitles)}>
                                    <span>Compare plans & features</span>
                                </div>
                                <ul className={css(Styles.list)}>
                                    <li className={css(Styles.listItemTopic, Styles.listItemFirst)}>
                                        Visualization and Analytics
                                    </li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>
                                        Time Until You Have In-Depth Visualization
                                    </li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>
                                        <span>20+ Autogenerated Key Business Graphs</span>
                                        <div className={css(Styles.listItemInfo)}>
                                            <Tooltip
                                                interactive={true}
                                                content={
                                                    <div>
                                                        Get key data visualizations for revenue, retention, engagement
                                                        full list{' '}
                                                        <a
                                                            href="/blog"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={css(Styles.tooltipLink)}
                                                        >
                                                            here
                                                        </a>
                                                        .
                                                    </div>
                                                }
                                            >
                                                <Info
                                                    width="16"
                                                    height="16"
                                                    role="img"
                                                    title="Info"
                                                />
                                            </Tooltip>
                                        </div>
                                    </li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>Predictive Analyics</li>
                                </ul>
                                <ul className={css(Styles.list)}>
                                    <li className={css(Styles.listItemTopic, Styles.listItemFirst)}>User Engagement</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>Users</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>Chats</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>
                                        <span>Feature Recommendation Engine</span>
                                        <div className={css(Styles.listItemInfo)}>
                                            <Tooltip content="Our AI crunches your data and recommends features that provide the most lift based on what we see.">
                                                <Info
                                                    width="16"
                                                    height="16"
                                                    role="img"
                                                    title="Info"
                                                />
                                            </Tooltip>
                                        </div>
                                    </li>
                                </ul>
                                <ul className={css(Styles.list)}>
                                    <li className={css(Styles.listItemTopic, Styles.listItemFirst)}>Data Management</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>Data Stored</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>
                                        <span>Data Sources</span>
                                        <div className={css(Styles.listItemInfo)}>
                                            <Tooltip content="We sit on top of your existing data stack and automatically join your data to give you a unified view of your business.">
                                                <Info
                                                    width="16"
                                                    height="16"
                                                    role="img"
                                                    title="Info"
                                                />
                                            </Tooltip>
                                        </div>
                                    </li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>Dedicated Data Rep</li>
                                </ul>
                                <ul className={css(Styles.list)}>
                                    <li className={css(Styles.listItemTopic, Styles.listItemFirst)}>
                                        Support and Communicationt
                                    </li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>Email Support</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>In-app Chat Support</li>
                                    <li className={css(Styles.listItem, Styles.listItemFirst)}>
                                        Dedicated Slack Channel
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={css(Styles.col)}>
                            <div className={css(Styles.plan)}>
                                <div className={css(Styles.planHeader)}>
                                    <div className={css(Styles.planHeaderBlock)}>
                                        <div>
                                            <div className={css(Styles.planHeaderTitle)}>Free</div>
                                            Starter Package
                                        </div>
                                        <a
                                            href="/contact-us"
                                            onClick={routing.OpenPage('contact-us')}
                                            className={MakeButton('Outlined-Default', Styles.startBtn)}
                                        >
                                            Get started for Free
                                        </a>
                                    </div>
                                </div>
                                <div className={css(Styles.lists)}>
                                    <div className={css(Styles.listsItem)}>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Visualization and Analytics
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>1 Day</div>
                                                <div className={css(Styles.listItemTitle)}>
                                                    1 day for having In-Depth Visualization
                                                </div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>
                                                    20+ Autogenerated Key Business Graphs
                                                </div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Predictive Analyics</div>
                                            </li>
                                        </ul>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                User Engagement
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>Unlimited</div>
                                                <div className={css(Styles.listItemTitle)}>Unlimited users</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>100/mo</div>
                                                <div className={css(Styles.listItemTitle)}>10,000 Charts / mo</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>
                                                    Feature Recommendation Engine
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={css(Styles.listsItem)}>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Data Management
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>30 Days</div>
                                                <div className={css(Styles.listItemTitle)}>30 Days Data Stored</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>1</div>
                                                <div className={css(Styles.listItemTitle)}>1 Data Sources</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Dedicated Data Rep</div>
                                            </li>
                                        </ul>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Support and Communication
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Email support</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>In-app Chat Support</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Dedicated Slack Channel</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css(Styles.col)}>
                            <div className={css(Styles.plan, Styles.recommended)}>
                                <div className={css(Styles.planHeader)}>
                                    <div className={css(Styles.recommendedLabel)}>
                                        <Star
                                            width="13"
                                            height="13"
                                            role="img"
                                            title="Star"
                                        />
                                        <span>Recommended</span>
                                    </div>
                                    <div className={css(Styles.planHeaderBlock, Styles.recommenedHeader)}>
                                        <div>
                                            <div className={css(Styles.planHeaderTitle)}>Growth</div>
                                            Business Boost
                                        </div>
                                        <a
                                            href="/contact-us"
                                            onClick={routing.OpenPage('contact-us')}
                                            className={MakeButton(
                                                'FilledAccent1-Default',
                                                Styles.startBtn,
                                                Styles.growthBtn
                                            )}
                                        >
                                            Contact sales
                                        </a>
                                    </div>
                                </div>
                                <div className={css(Styles.lists)}>
                                    <div className={css(Styles.listsItem)}>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Visualization and Analytics
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>1 Day</div>
                                                <div className={css(Styles.listItemTitle)}>
                                                    1 day for having In-Depth Visualization
                                                </div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>
                                                    20+ Autogenerated Key Business Graphs
                                                </div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Predictive Analyics</div>
                                            </li>
                                        </ul>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                User Engagement
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>Unlimited</div>
                                                <div className={css(Styles.listItemTitle)}>Unlimited users</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>100/mo</div>
                                                <div className={css(Styles.listItemTitle)}>10,000 Charts / mo</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>
                                                    Feature Recommendation Engine
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={css(Styles.listsItem)}>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Data Management
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>30 Days</div>
                                                <div className={css(Styles.listItemTitle)}>30 Days Data Stored</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>1</div>
                                                <div className={css(Styles.listItemTitle)}>1 Data Sources</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Dedicated Data Rep</div>
                                            </li>
                                        </ul>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Support and Communication
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Email support</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>In-app Chat Support</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Dedicated Slack Channel</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css(Styles.col)}>
                            <div className={css(Styles.plan)}>
                                <div className={css(Styles.planHeader)}>
                                    <div className={css(Styles.planHeaderBlock)}>
                                        <div>
                                            <div className={css(Styles.planHeaderTitle)}>Scale</div>
                                            Enterprise Excellence
                                        </div>
                                        <a
                                            href="/contact-us"
                                            onClick={routing.OpenPage('contact-us')}
                                            className={MakeButton('FilledAccent1-Default', Styles.startBtn)}
                                        >
                                            Contact sales
                                        </a>
                                    </div>
                                </div>
                                <div className={css(Styles.lists)}>
                                    <div className={css(Styles.listsItem)}>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Visualization and Analytics
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>1 Day</div>
                                                <div className={css(Styles.listItemTitle)}>
                                                    1 day for having In-Depth Visualization
                                                </div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>
                                                    20+ Autogenerated Key Business Graphs
                                                </div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Predictive Analyics</div>
                                            </li>
                                        </ul>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                User Engagement
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>Unlimited</div>
                                                <div className={css(Styles.listItemTitle)}>Unlimited users</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>100/mo</div>
                                                <div className={css(Styles.listItemTitle)}>10,000 Charts / mo</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>
                                                    Feature Recommendation Engine
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={css(Styles.listsItem)}>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Data Management
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>30 Days</div>
                                                <div className={css(Styles.listItemTitle)}>30 Days Data Stored</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(
                                                        Styles.listIcon,
                                                        Styles.listIconActive,
                                                        Styles.listIconExtra
                                                    )}
                                                />
                                                <div className={css(Styles.listItemResult)}>1</div>
                                                <div className={css(Styles.listItemTitle)}>1 Data Sources</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <None
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="-"
                                                    className={css(Styles.listIcon)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Dedicated Data Rep</div>
                                            </li>
                                        </ul>
                                        <ul className={css(Styles.list)}>
                                            <li className={css(Styles.listItemTopic, Styles.listItemTopicInside)}>
                                                Support and Communication
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Email support</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>In-app Chat Support</div>
                                            </li>
                                            <li className={css(Styles.listItem)}>
                                                <Done
                                                    width="20"
                                                    height="20"
                                                    role="img"
                                                    title="+"
                                                    className={css(Styles.listIcon, Styles.listIconActive)}
                                                />
                                                <div className={css(Styles.listItemTitle)}>Dedicated Slack Channel</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
