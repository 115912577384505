import {FetchResponseError} from 'lib/fetch';
import {MessagePayload, MessagePayloadRuntype, ProjectChatMessage} from 'packages/projects/generalchat/model';
import {create} from 'zustand';
import LoadProjectChatAPI from 'packages/projects/generalchat/api/LoadProjectChatAPI';
import ContinueProjectChatAPI from 'packages/projects/generalchat/api/ContinueProjectChatAPI';
import {Project} from 'packages/projects';

export const useProjectChatStore = create<{
    company_uri: string;
    project_uri: string;
    // payloads
    project: Project | null;
    messages: ProjectChatMessage[];
    // system
    isLoading: boolean;
    canSubmitNewMessage: boolean;
    // methods
    init: (company_uri: string, project_uri: string) => Promise<FetchResponseError | null>;
    reload: (project_uri?: string) => Promise<FetchResponseError | null>;
    sendNewMessage: (message: string) => Promise<FetchResponseError | null>;
    // internal
    setNewMessages: (messages: ProjectChatMessage[]) => void;
}>((set, get) => ({
    company_uri: '',
    project_uri: 'new',
    // payloads
    project: null,
    messages: [],
    // system
    isLoading: false,
    canSubmitNewMessage: true,
    // methods
    init: async (company_uri: string, project_uri: string) => {
        set({isLoading: true, project_uri, company_uri});

        const res = await LoadProjectChatAPI(project_uri);
        if (res[1]) {
            set({isLoading: false});
            return res[1];
        }

        set({project: res[0].project});
        get().setNewMessages(res[0].messages);

        set({isLoading: false});

        return null;
    },
    reload: async (project_uri?: string) => {
        if (project_uri) {
            set({project_uri: project_uri});
        }

        const res = await LoadProjectChatAPI(get().project_uri);
        if (res[1]) {
            return res[1];
        }

        set({project: res[0].project});
        get().setNewMessages(res[0].messages);

        return null;
    },
    sendNewMessage: async (message: string) => {
        set({isLoading: true});
        set({messages: [...get().messages, {content: message, role: 'user', guid: '', payload: null}]});

        const res = await ContinueProjectChatAPI(get().project_uri, {
            content: message,
        });
        if (res[1]) {
            set({isLoading: false});
            return res[1];
        }

        get().setNewMessages(res[0].messages);

        set({isLoading: false});

        return null;
    },
    // internal
    setNewMessages: (messages: ProjectChatMessage[]) => {
        messages = messages.map(message => {
            let parsedMessage = {...message};
            if (message.payload) {
                try {
                    const parsedPayload = JSON.parse(message.payload);
                    const res = MessagePayloadRuntype.validate(parsedPayload);
                    if (res.success) {
                        parsedMessage.parsed_payload = res.value;
                    }
                } catch (error) {}
            }

            return {...parsedMessage};
        });

        set({messages: messages});
    },
}));

export function hasRequestedFieldInMessage(message: {parsed_payload?: MessagePayload}, field: string): boolean {
    return (
        (message.parsed_payload &&
            message.parsed_payload.requested_fields &&
            message.parsed_payload.requested_fields.findIndex(f => f === field) >= 0) ||
        false
    );
}
