import useSimpleError from 'lib/hooks/useSimpleError';
import {useState} from 'react';
// api
import SendFeedback from './api/sendFeedback';
// components
import TextField from 'packages/elements/textfields/TextField';
import Header from '../header/header';
// icons
import {ReactComponent as FavoriteFilledIcon} from 'src/icons/favorite-filled.svg';
import {ReactComponent as Logo} from './img/back.svg';
import {ReactComponent as MailIcon} from 'src/icons/mail.svg';
// styles
import {css} from 'aphrodite';
import {IsValidEmail} from 'lib/helpers/IsValidEmail';
import useWaitFor from 'lib/hooks/useWaitFor';
import {LoadButton} from 'packages/elements/button';
import {routing} from 'src/runtime/router';
import Styles from './contact.jss';

export default function Landing() {
    return (
        <div className={css(Styles.page)}>
            <Header />

            <div className={css(Styles.main)}>
                <div className={css(Styles.container)}>
                    <div className={css(Styles.row)}>
                        <div className={css(Styles.content)}>
                            <div className={css(Styles.eyeBrow)}>
                                <FavoriteFilledIcon
                                    width="20"
                                    height="20"
                                    role="img"
                                    aria-label="Contact Us"
                                />
                                <span>Contact Us</span>
                            </div>
                            <h1 className={css(Styles.title)}>Let’s work together!</h1>
                            <p className={css(Styles.text)}>
                                Feel free to contact us and we will get back to you as soon as possible. Fill the form
                                or contact us directly.
                            </p>
                            <a
                                href="mailto:info@whitetail.ai"
                                target="_blank"
                                rel="noreferrer"
                                className={css(Styles.mailBtn)}
                            >
                                <MailIcon
                                    width="26"
                                    height="26"
                                    role="img"
                                    aria-label="Mail"
                                />
                                <span>info@whitetail.ai</span>
                            </a>
                        </div>
                        <Form />
                        <a
                            href="mailto:info@whitetail.ai"
                            target="_blank"
                            rel="noreferrer"
                            className={css(Styles.mailBtn, Styles.mailBtnMobile)}
                        >
                            <MailIcon
                                width="26"
                                height="26"
                                role="img"
                                aria-label="Mail"
                            />
                            <span>info@whitetail.ai</span>
                        </a>
                    </div>
                    <div className={css(Styles.footer)}>
                        <ul className={css(Styles.footerList)}>
                            <li>
                                <a
                                    href="/privacy"
                                    onClick={routing.OpenPage('/privacy')}
                                    className={css(Styles.footerListItem)}
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms"
                                    onClick={routing.OpenPage('/terms')}
                                    className={css(Styles.footerListItem)}
                                >
                                    Terms and Conditions
                                </a>
                            </li>
                        </ul>
                        <span>© {new Date().getFullYear()} Whitetail AI</span>
                    </div>
                </div>
            </div>
            <div className={css(Styles.back)}>
                <Logo />
            </div>
        </div>
    );
}

interface FormValues {
    name: string;
    job: string;
    company: string;
    email: string;
    message: string;
}

type FormFields = keyof FormValues;

const Form = () => {
    const [form, setForm] = useState<FormValues>({
        name: '',
        job: '',
        company: '',
        email: '',
        message: '',
    });
    const [formErrors, setFormErrors] = useSimpleError<FormFields>();
    const [sent, setSent] = useState(false);
    const [isLoading, withLoading] = useWaitFor(false);

    const onFormChanged = (type: FormFields) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm(f => ({
            ...f,
            [type]: e.target.value,
        }));
        setFormErrors(type, false);
    };

    const send = async () => {
        let hasError = false;
        (Object.keys(form) as FormFields[]).forEach(key => {
            const isError = key !== 'message' && (!form[key] || (key === 'email' && !IsValidEmail(form[key])));
            hasError = hasError || isError;
            setFormErrors(key, isError);
        });

        if (hasError) {
            return;
        }

        const res = await withLoading(
            SendFeedback({
                name: form.name,
                job_title: form.job,
                company_name: form.company,
                message: form.message,
                email: form.email,
            })
        );

        if (res[0]) {
            setSent(true);
        }
    };

    return (
        <div className={css(Styles.form, sent && Styles.thanks)}>
            {sent ? (
                <>
                    <h2 className={css(Styles.thanksTitle)}>Thank You, We'll reach out shortly!</h2>
                    <p className={css(Styles.thanksText)}>
                        We've got your details and will keep you in the loop with the latest updates. Have a fantastic
                        day!
                    </p>
                </>
            ) : (
                <>
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            type="text"
                            label="Name"
                            placeholder="Enter your name"
                            value={form.name}
                            onChange={onFormChanged('name')}
                            styles={{
                                element: Styles.input,
                            }}
                        />
                    </div>
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            type="text"
                            label="Job title"
                            placeholder="Enter your job title"
                            value={form.job}
                            onChange={onFormChanged('job')}
                            styles={{
                                element: Styles.input,
                            }}
                        />
                    </div>
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            type="text"
                            label="Company name"
                            placeholder="Enter your company name"
                            value={form.company}
                            onChange={onFormChanged('company')}
                            styles={{
                                element: Styles.input,
                            }}
                        />
                    </div>
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            type="text"
                            label="Email"
                            placeholder="Enter your email address"
                            value={form.email}
                            onChange={onFormChanged('email')}
                            error={formErrors('email')}
                            styles={{
                                element: Styles.input,
                            }}
                        />
                    </div>
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            kind="textarea"
                            label="Message"
                            placeholder="How can we help you?"
                            rows={3}
                            value={form.message}
                            onChange={onFormChanged('message')}
                            styles={{
                                element: Styles.textarea,
                            }}
                        />
                    </div>
                    <LoadButton
                        variant="FilledAccent1-Big"
                        styles={[Styles.btn]}
                        type="button"
                        onClick={send}
                        loading={isLoading}
                        disabled={Object.keys(form).some(key => key !== 'message' && !form[key as FormFields])}
                    >
                        Contact us
                    </LoadButton>
                </>
            )}
        </div>
    );
};
