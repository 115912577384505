import {css} from 'aphrodite';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Styles from './range.jss';

export default function Range() {
    return (
        <Slider
            range
            min={0}
            max={100}
            defaultValue={[20, 40]}
            marks={{20: 20, 40: 40, 100: 100}}
            className={css(Styles.slider)}
        />
    );
}
