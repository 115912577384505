import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export default CreateSheet({
    main: {
        padding: '25px 0',
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
        background: GetColor('PageBg'),
        position: 'relative',
        transition: 'box-shadow 0.2s ease-out',
        ":hover [data-selector='title']": {
            color: GetColor('Accent1Hover'),
        },
        '@media(max-width: 800px)': {
            padding: '20px 0',
        },
    },
    visited: {
        background: GetColor('PageBg'),
        ":nth-child(1n) [data-selector='title']": {
            color: GetColor('Secondary'),
        },
        ":hover [data-selector='title']": {
            color: GetColor('Accent1Hover'),
        },
    },
    content: {
        flexGrow: 1,
    },
    image: {
        display: 'block',
        borderRadius: 8,
        width: 170,
        marginLeft: 25,
        flexShrink: 0,
        '@media(max-width: 800px)': {
            width: 80,
            height: 80,
            marginLeft: 15,
        },
    },
    authorTimeLine: {
        display: 'flex',
        color: GetColor('Secondary'),
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1,
        alignItems: 'center',
        paddingBottom: 3,
        marginBottom: 10,
        '@media(max-width: 800px)': {
            marginBottom: 5,
        },
        '@media(max-width: 490px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 5,
        },
    },
    dotSymbol: {
        display: 'block',
        margin: '0 2px',
        '@media(max-width: 490px)': {
            display: 'none',
        },
    },
    titleLine: {
        margin: '0 0 10px 0',
    },
    titleLineLink: {
        color: GetColor('TextColor'),
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        textDecoration: 'none',
        transition: 'box-shadow 0.2s ease-out, color 0.2s ease-out',
        '@media(max-width: 640px)': {
            fontSize: '1.125rem',
        },
    },
    descriptionLine: {
        marginBottom: 10,
        color: GetColor('Primary'),
        ':nth-child(1n) .DraftEditor-root': {
            color: GetColor('Primary'),
            fontFamily: 'Figtree',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        '@media(max-width: 800px)': {
            display: 'none',
        },
    },
    readDuration: {
        color: GetColor('Secondary'),
        fontFamily: 'Figtree',
        fontSize: '0.8125rem',
        fontWeight: 500,
        lineHeight: 1,
    },
    hiddenLine: {
        marginBottom: 10,
    },
    hiddenTag: {
        display: 'inline-block',
        borderRadius: 50,
        color: GetColor('WhiteTextColor'),
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1,
        padding: '7px 14px',
        background: GetColor('Primary'),
    },
    actionsWrapper: {
        display: 'block',
        position: 'absolute',
        right: 10,
        top: 10,
        borderRadius: 8,
        boxShadow: '0px 3px 7px 0px rgba(68, 68, 68, 0.10)',
    },
    actionsDanger: {
        color: GetColor('Danger'),
    },
});
