import {
    Placement,
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useMergeRefs,
    useRole,
    useTransitionStyles,
} from '@floating-ui/react';
import React, {forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import {css, StyleDeclaration} from 'aphrodite';
import useResize from '../hooks/useResize';

export interface DropDownProps {
    event?: 'click' | 'hover' | 'contextmenu' | 'manual';
    place?: Placement;
    trigger?: React.ReactNode;
    styles?: {
        trigger?: Array<StyleDeclaration | null>;
        floatingBlock?: Array<StyleDeclaration | null>;
    };
    settings?: {
        durtation?: number;
        distance?: number;
        placement?: {
            useSize?: boolean; // if true - floating block will always be limitted on height to fit window size
            useWidth?: boolean; // if true - floating block matches the width of the reference regardless of its contents
            useSizeOffset?: number; // distance between floating block and window border
            noShift?: boolean; // do not adjust the align of the floating block (start, end) DEFAULT: false
            noFlip?: boolean; // do not adjust the side of the floating block (top, bottom, left, rigth) DEFAULT: false
        };
        closeOn?: {
            escapeKey?: boolean; // close dropdown on escape, DEFAULT: true
            resize?: boolean; // close dropdown on resize, DEFAULT: false
            scroll?: boolean; // close dropdown on scroll, DEFAULT: false
            // if you need to trigger dropdown from outside element - you use this function to avoid dropdown triggering close from click-outside
            clickOutside?: boolean | ((event: MouseEvent) => boolean); // close dropdown on click outside, DEFAULT: true
            clickFloatingBlock?: boolean; // close dropdown on click anywhere on the floating block, DEFAULT: false
        };
    };
    onStatusChange?: (opened: boolean) => void;
}

export type DropDownElProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>;

export type ContextOpenEventData = {preventDefault: () => void; clientX: number; clientY: number};

export interface DropDownRef {
    trigger: React.MutableRefObject<HTMLDivElement | undefined>; // element which triggers the dropdown
    floatingBlock: React.MutableRefObject<HTMLDivElement | undefined>; // dropdown floating block
    isOpen: boolean; // current dropdown status
    setIsOpen: (status: boolean, event?: ContextOpenEventData) => void; // set dropdown status, 'close-all' closes parent dropdown as well
}

export const FloatingDropDownCore = forwardRef<DropDownRef, DropDownElProps & DropDownProps>(
    ({children, trigger, styles, settings, event, onStatusChange, place, ...props}, forwardedRef) => {
        const triggerRef = useRef<HTMLDivElement>();
        const floatingBlockRef = useRef<HTMLDivElement>();

        const [isOpen, setIsOpen] = useState(false);

        const {floatingStyles, refs, context} = useFloating<HTMLElement>({
            open: isOpen,
            onOpenChange: setIsOpen,
            strategy: 'fixed',
            placement: place ? place : 'bottom-start',
            middleware: [
                offset({mainAxis: settings?.distance || 0}),
                settings && settings.placement && settings.placement.noFlip ? null : flip(),
                settings && settings.placement && settings.placement.noShift ? null : shift(),
                settings && settings.placement && settings.placement.useSize
                    ? size({
                          apply({availableHeight, elements}) {
                              if (settings.placement?.useSizeOffset) {
                                  availableHeight = availableHeight - settings.placement.useSizeOffset;
                              }

                              elements.floating.style.maxHeight = `${availableHeight}px`;
                          },
                      })
                    : null,
                settings && settings.placement && settings.placement.useWidth
                    ? size({
                          apply({rects, elements}) {
                              Object.assign(elements.floating.style, {
                                  width: `${rects.reference.width}px`,
                              });
                          },
                      })
                    : null,
            ],
            whileElementsMounted: autoUpdate,
        });

        const hover = useHover(context, {
            enabled: event === 'hover',
            handleClose: safePolygon({blockPointerEvents: true}),
        });
        const click = useClick(context, {
            enabled: event === undefined || event === 'click',
        });
        const role = useRole(context, {role: 'dialog'});
        const dismiss = useDismiss(context, {
            bubbles: true,
            escapeKey: settings?.closeOn?.escapeKey || true,
            ancestorScroll: settings?.closeOn?.scroll || false,
            outsidePress: settings?.closeOn?.clickOutside || true,
        });

        const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
            duration: settings?.durtation || 200,
        });

        const {getReferenceProps, getFloatingProps} = useInteractions([hover, click, role, dismiss]);

        function onContextMenuClick(e: ContextOpenEventData) {
            if (event === 'contextmenu') {
                e.preventDefault();

                refs.setPositionReference({
                    getBoundingClientRect() {
                        return {
                            width: 0,
                            height: 0,
                            x: e.clientX,
                            y: e.clientY,
                            top: e.clientY,
                            right: e.clientX,
                            bottom: e.clientY,
                            left: e.clientX,
                        };
                    },
                });

                setIsOpen(true);
            }
        }

        function onResize() {
            setIsOpen(false);
        }

        useResize(settings?.closeOn?.resize ? onResize : undefined);

        useImperativeHandle(forwardedRef, () => {
            return {
                trigger: triggerRef,
                floatingBlock: floatingBlockRef,
                get isOpen() {
                    return isOpen;
                },
                setIsOpen: (status: boolean, e?: ContextOpenEventData) => {
                    if (event === 'contextmenu') {
                        if (!e) {
                            console.warn('For dropdown with event "contextmenu" you must pass event data');
                            return;
                        }
                        onContextMenuClick(e);
                    } else {
                        setIsOpen(status);
                    }
                },
            };
        });

        useLayoutEffect(() => {
            if (onStatusChange) {
                onStatusChange(isOpen);
            }
        }, [onStatusChange, isOpen]);

        const floatingRef = useMergeRefs([refs.setFloating, floatingBlockRef]);

        return (
            <>
                <div
                    ref={useMergeRefs([event === 'contextmenu' ? null : refs.setReference, triggerRef])}
                    tabIndex={props.tabIndex || 0}
                    role={props.role || 'button'}
                    data-open={isOpen ? '' : undefined}
                    className={styles?.trigger ? css(...styles.trigger) : undefined}
                    onContextMenu={onContextMenuClick}
                    {...getReferenceProps({
                        ...props,
                    })}
                >
                    {trigger}
                </div>
                {isMounted && children && (
                    <div
                        ref={floatingRef}
                        className={styles?.floatingBlock ? css(...styles.floatingBlock) : undefined}
                        style={{...floatingStyles, ...transitionStyles}}
                        onClick={event => {
                            if (settings?.closeOn?.clickFloatingBlock) {
                                setIsOpen(false);
                            }
                        }}
                        {...getFloatingProps()}
                    >
                        {children}
                    </div>
                )}
            </>
        );
    }
);
