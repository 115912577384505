import {post} from 'lib/fetch';

export type ResetPasswordRequestAPIResponse = {
    token: string;
};

export default function ResetPasswordRequestAPI(email: string) {
    return post<ResetPasswordRequestAPIResponse>({
        url: '/session/passwords',
        body: {email},
    });
}
