import {ReactComponent as CSV} from 'src/icons/files/csv.svg';
import {ReactComponent as Doc} from 'src/icons/files/doc.svg';
import {ReactComponent as Docx} from 'src/icons/files/docx.svg';
import {ReactComponent as File} from 'src/icons/files/file.svg';
import {ReactComponent as Gif} from 'src/icons/files/gif.svg';
import {ReactComponent as Jpeg} from 'src/icons/files/jpeg.svg';
import {ReactComponent as Jpg} from 'src/icons/files/jpg.svg';
import {ReactComponent as Mp4} from 'src/icons/files/mp4.svg';
import {ReactComponent as PDF} from 'src/icons/files/pdf.svg';
import {ReactComponent as Png} from 'src/icons/files/png.svg';
import {ReactComponent as Svg} from 'src/icons/files/svg.svg';
import {ReactComponent as Xls} from 'src/icons/files/xls.svg';
import {ReactComponent as Xlsx} from 'src/icons/files/xlsx.svg';
import {ReactComponent as Zip} from 'src/icons/files/zip.svg';

export default function GetFileIcon(ext: string) {
    let Icon = File;

    switch (ext) {
        case 'cvs':
            Icon = CSV;
            break;
        case 'doc':
            Icon = Doc;
            break;
        case 'docx':
            Icon = Docx;
            break;
        case 'gif':
            Icon = Gif;
            break;
        case 'jpeg':
            Icon = Jpeg;
            break;
        case 'jpg':
            Icon = Jpg;
            break;
        case 'mp4':
            Icon = Mp4;
            break;
        case 'pdf':
            Icon = PDF;
            break;
        case 'png':
            Icon = Png;
            break;
        case 'svg':
            Icon = Svg;
            break;
        case 'xls':
            Icon = Xls;
            break;
        case 'xlsx':
            Icon = Xlsx;
            break;
        case 'zip':
            Icon = Zip;
            break;
        default:
            Icon = File;
    }

    return Icon;
}
