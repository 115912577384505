import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export default CreateSheet({
    slider: {
        padding: '5px 0 30px 0',
        ':nth-child(1n) .rc-slider-rail': {
            background: GetColor('StrokeColor'),
        },
        ':nth-child(1n) .rc-slider-track': {
            background: GetColor('Accent1'),
        },
        ':nth-child(1n) .rc-slider-tracks': {
            background: GetColor('Accent1'),
        },
        ':nth-child(1n) .rc-slider-dot': {
            borderColor: GetColor('StrokeColor'),
            background: GetColor('StrokeColor'),
            opacity: 1,
        },
        ':nth-child(1n) .rc-slider-dot-active': {
            borderColor: GetColor('Accent1'),
            background: GetColor('Accent1'),
        },
        ':nth-child(1n) .rc-slider-handle': {
            borderColor: GetColor('Accent1'),
            background: GetColor('Accent1'),
            opacity: 1,
        },
        ':nth-child(1n) .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging': {
            boxShadow: `0 0 0 5px ${GetColor('Accent1')}`,
        },
        ':nth-child(1n) .rc-slider-mark-text': {
            fontFamily: 'Figtree',
            color: GetColor('TextColor'),
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1,
            letterSpacing: '-0.28px',
        },
    },
});
