import {CreateSheet} from 'aphrodite';

export default CreateSheet({
    codeInput: {
        width: 55,
        fontSize: '1.375rem',
        MozAppearance: 'textfield',
        boxSizing: 'border-box',
        appearance: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        textAlign: 'center',
        '@media(max-width: 450px)': {
            width: 45,
            fontSize: '1rem',
        },
    },
    codeBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
