import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    upload: {
        width: '100%',
        padding: '20px 30px',
        fontFamily: "'Figtree', sans-serif",
        fontSize: '0.8125rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        borderRadius: 16,
        border: `2px dashed ${GetColor('Gray')}`,
        boxShadow: 'none',
        background: 'transparent',
        textAlign: 'center',
        cursor: 'pointer',
        ':focus': {
            border: `2px solid ${GetColor('Gray')}`,
        },
    },
    uploadDrop: {
        border: `2px solid ${GetColor('Gray')}`,
    },
    uploadIcon: {
        display: 'flex',
        width: 40,
        height: 40,
        margin: '0 auto 15px auto',
        alignItems: 'center',
        justifyContent: 'center',
        color: GetColor('Gray'),
        background: GetColor('LightGrayBg'),
        borderRadius: '50%',
        ':nth-child(1n) svg': {
            display: 'block',
        },
    },
    uploadTitle: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    uploadTitleStrong: {
        fontWeight: 700,
        color: GetColor('Primary'),
        textDecoration: 'underline',
    },
    uploadText: {
        margin: '7px 0 0 0',
    },
    ////
    ////
    ///
    ///
    //
    block: {
        backgroundColor: '#C4C4C4',
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer',
        display: 'block',
        border: 'none',
        outline: 'none',
        color: 'white',
        padding: 0,
        ':nth-child(1n) .upload-img-icon': {
            display: 'none',
        },
        ':hover': {
            ':nth-child(1n) .upload-img-icon': {
                display: 'flex',
            },
            ':nth-child(1n) .icon-overlay': {
                display: 'block',
            },
        },
    },
    activeBlock: {
        ':nth-child(1n) .icon-overlay': {
            display: 'block',
        },
    },
    iconOverlay: {
        opacity: 0.8,
        display: 'none',
        background: '#000',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 10,
    },
    icon: {
        width: '60px',
        height: '60px',
        borderRadius: '30px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 'calc(50% - 30px)',
        left: 'calc(50% - 30px)',
        fontWeight: 700,
        fontSize: '1rem',
    },
    uploadIconBlock: {
        color: 'white',
        fontWeight: 700,
        fontSize: '1rem',
        lineHeight: '1.2193rem',
        ':nth-child(1n) svg': {
            stroke: 'white',
            marginBottom: 5,
        },
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    },
    circleProgress: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    err: {
        position: 'absolute',
        bottom: '0px',
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'rgb(255 200 200)',
        left: '0px',
        right: '0px',
        background: '#707070',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        height: '25px',
        lineHeight: '25px',
    },
    cropper: {
        width: 'auto',
    },
});
