import {ChatMessageRuntype} from 'packages/chat/model';
import * as r from 'runtypes';
import {ProjectRuntype} from '..';
import {FacebookAdAccountRuntype} from 'packages/usersdatasources/model';

export const MessagePayloadRuntype = r.Record({
    requested_fields: r.Array(r.String).optional(),
    facebook_ad_accounts: r.Array(FacebookAdAccountRuntype).optional(),
    data_source_guid: r.String.optional(),
    prevent_text_messages: r.Boolean.optional(),
    is_project_ready: r.Boolean.optional(),
});
export type MessagePayload = r.Static<typeof MessagePayloadRuntype>;

export const ProjectChatMessageRuntype = ChatMessageRuntype.extend({
    guid: r.String,
    payload: r.String.optional().nullable(),
});
export type ProjectChatMessage = r.Static<typeof ProjectChatMessageRuntype> & {
    parsed_payload?: MessagePayload;
};

export const ProjectChatRuntype = r.Record({
    messages: r.Array(ProjectChatMessageRuntype),
    project: ProjectRuntype,
});
export type ProjectChat = r.Static<typeof ProjectChatRuntype>;
