import {css} from 'aphrodite';
import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {CreateTransitionAnimation} from './CreateTransitionAnimation';
import SmoothAppear from './SmoothAppear.react';
import {Motion} from './keyframes';

export type SmoothLineProps = PropsWithChildren<{
    initialState?: boolean;
    timeout?: number;
    motion?: Motion;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
}>;

// same as SmoothErrorText but height grows smoothly
export default function SmoothLine({children, motion, timeout, initialState, ...rest}: SmoothLineProps) {
    const ref = useRef<HTMLDivElement>(null);
    const child = useRef(children);
    const [status, setStatus] = useState(!!initialState);
    const [, setReload] = useState(false);

    useEffect(() => {
        if (children !== undefined && children !== null) {
            if (child.current !== undefined && children !== null && child.current !== children) {
                child.current = children;
                setReload(re => !re);
            } else {
                child.current = children;
            }
        }
        setStatus(children !== undefined && children !== null);
    }, [children]);

    return (
        <SmoothAppear
            {...{
                initialState: initialState,
                height: status ? 'auto' : 0,
                duration: timeout || 300,
            }}
        >
            <CSSTransition
                {...{
                    timeout: timeout || 300,
                    in: status,
                    unmountOnExit: true,
                    nodeRef: ref,
                    classNames: `motion-animation`,
                    onExited: () => {
                        child.current = undefined;
                    },
                }}
            >
                <div
                    {...rest}
                    {...{
                        ref,
                        className: `${css(CreateTransitionAnimation(motion, {animationDuration: timeout || 300}))} ${
                            rest.className || ''
                        }`,
                    }}
                >
                    {child.current}
                </div>
            </CSSTransition>
        </SmoothAppear>
    );
}
