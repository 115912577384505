import React, {useState} from 'react';
import {MakeButton} from './button';
import {ReactComponent as ChatIcon} from 'src/icons/chat.svg';
import {CreateSheet} from 'aphrodite';
import {session} from 'src/runtime/session';
import ChatOverlay from 'src/jsx/dashboard/modal/chatOverlay';
import useMaxWidth from 'lib/hooks/useMaxWidth';

const GPTButton: React.FC = () => {
    const [opened, setOpened] = useState(false);
    const isLess640px = useMaxWidth(640);

    const company = session.extra.companies?.[0];
    const project = company?.projects?.[0];

    if (isLess640px) {
        return null;
    }

    if (!company || !project) {
        return null;
    }

    return (
        <>
            <button
                className={MakeButton('FilledAccent1-Big', Styles.gptButton, opened && Styles.gptButtonHidden)}
                onClick={() => setOpened(true)}
            >
                <ChatIcon />
                GPT
            </button>
            <ChatOverlay
                opened={opened}
                onClose={() => setOpened(false)}
                company_uri={company?.uri ?? ''}
                project_uri={project?.uri ?? ''}
            />
        </>
    );
};
export default GPTButton;

const Styles = CreateSheet({
    gptButton: {
        zIndex: 10,
        position: 'fixed',
        right: 20,
        bottom: 25,

        transition: 'opacity 0.15s ease-out',
    },
    gptButtonHidden: {
        opacity: 0,
        pointerEvents: 'none',
    },
});
