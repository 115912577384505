import {CreateSheet, css, GeneratedStyles, GetColor} from 'aphrodite';
import {ReactComponent as CheckIcon} from 'src/icons/check.svg';

type props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    label: string | React.ReactNode;
    styles?: {
        root?: GeneratedStyles;
        label?: GeneratedStyles;
        input?: GeneratedStyles;
    };
};

export default function Checkbox({label, styles, ...rest}: props) {
    const Styles = CreateStyles(!!rest?.disabled);

    return (
        <label className={css(Styles.checkbox, styles?.root)}>
            <span className={css(Styles.checkboxInput)}>
                <input
                    {...{
                        type: 'checkbox',
                        name: 'checkbox',
                        className: css(Styles.input, styles?.input),
                        ...rest,
                    }}
                />
                <span className={`checkbox-control ${css(Styles.control)}`}>
                    <CheckIcon className={css(Styles.svg)} />
                </span>
            </span>
            <span className={css(Styles.label, styles?.label)}>{label}</span>
        </label>
    );
}

function CreateStyles(disabled: boolean) {
    return CreateSheet({
        checkbox: {
            display: 'grid',
            gridTemplateColumns: 'min-content auto',
            gridGap: '0.75rem',
            userSelect: 'none',
            cursor: disabled ? 'not-allowed' : 'pointer',
            color: GetColor('TextColor'),
            fontFamily: 'Figtree',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.125rem',
            alignItems: 'flex-start',
            ':hover .checkbox-control': {
                background: GetColor('ButtonBgHover'),
            },
        },
        input: {
            opacity: 0,
            width: 0,
            height: 0,
            ':checked + .checkbox-control': {
                background: GetColor('Primary'),
                borderColor: GetColor('Primary'),
                ':hover': {
                    background: GetColor('Primary'),
                },
            },
            ':checked + .checkbox-control svg': {
                transform: 'scale(0.7)',
            },
            ':disabled + .checkbox-control': {
                color: GetColor('WhiteTextColor'),
                opacity: 0.5,
            },
            cursor: disabled ? 'not-allowed' : 'pointer',
        },
        control: {
            display: 'flex',
            width: '1.125rem',
            height: '1.125rem',
            alignItems: 'center',
            justifyContent: 'center',
            color: GetColor('NegativeTextColor'),
            borderRadius: 2,
            border: `1.15px solid ${GetColor('Gray')}`,
            cursor: disabled ? 'not-allowed' : 'pointer',
            transition: 'background 0.1s ease-in 25ms',
        },
        checkboxInput: {
            display: 'grid',
            gridTemplateAreas: 'checkbox',
            ':nth-child(1n) > *': {
                gridArea: 'checkbox',
            },
        },
        svg: {
            transition: 'transform 0.1s ease-in 25ms',
            transform: 'scale(0)',
            transformOrigin: '50%, 50%',
        },
        label: {},
    });
}
