import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

const PutResourceLightboxStyles = CreateSheet({
    container: {
        width: '100%',
        maxWidth: 700,
        margin: '0 auto',
        height: '100%',
        padding: '20px 0px 50px 0px',
        '@media(max-width: 740px)': {
            padding: '20px 20px 50px 20px',
        },
    },
    block: {
        marginBottom: 20,
        ':last-child': {
            marginBottom: 0,
        },
    },
    blockLabel: {
        display: 'block',
        marginBottom: 10,
        color: GetColor('Secondary'),
        fontSize: '1.125rem',
        fontWeight: 600,
    },
    blockInput: {
        width: '100%',
        marginBottom: 15,
        ':last-child': {
            marginBottom: 0,
        },
        '& input': {
            width: '100%',
        },
    },
    draft: {
        ':nth-child(1n) .DraftEditor-root': {
            color: GetColor('Primary'),
            fontFamily: 'Figtree',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
    },
    flexLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    flexTitle: {
        marginRight: 20,
        flexShrink: 0,
    },
    flexError: {
        flexGrow: 1,
        marginRight: 20,
        display: 'flex',
        alignItems: 'center',
        color: GetColor('Danger'),
        fontSize: '0.8125rem',
        fontWeight: 600,
        lineHeight: '120%',
    },
    errorIcon: {
        marginRight: 10,
        width: 14,
        height: 14,
        display: 'block',
        flexShrink: 0,
    },
    nav: {
        display: 'flex',
        marginTop: 35,
        alignItems: 'center',
        justifyContent: 'flex-end',
        ':nth-child(1n) button': {
            marginLeft: 10,
        },
    },
});

export default PutResourceLightboxStyles;
