import {get, makeAPIHook} from 'lib/fetch';
import {ProjectChartRuntype} from '../project_charts/charts';

export default async function LoadProjectChartAPI(uri: string, marker: string) {
    return await get(
        {
            url: `projects/${uri}/charts/${marker}`,
        },
        ProjectChartRuntype
    );
}
export const useLoadProjectChartAPI = makeAPIHook(LoadProjectChartAPI);
