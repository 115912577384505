import {InitUploader} from './Uploader';
import {Error, FileACL} from './model';
import {ExternalFile} from 'packages/files/models';

export type {FileACL};

export async function UploadFile(
    File: File,
    OnCompelete: (res: [ExternalFile | null, Error | null]) => void,
    OnProgress?: (progress: number) => void,
    ACL: FileACL = 'public-read'
): Promise<string> {
    // returns upload id
    return await InitUploader({
        bucket: FILES_S3_BUCKET,
        prefix: FILES_S3_PREFIX,
        file_name: File.name,
        file: File,
        acl: ACL,
        on_progress: OnProgress,
        on_complete: uploader => {
            OnCompelete([uploader.Result, uploader.Error]);
        },
    });
}

export function UploadFiles(
    Files: FileList,
    OnComplete: (res: [ExternalFile | null, Error | null][]) => void,
    OnProgress?: (progress: number) => void,
    ACL: FileACL = 'public-read'
) {
    let totalProgress = 0;
    let completedCount = 0;
    let result: [ExternalFile | null, Error | null][] = [];

    const onComplete = (index: number) => (res: [ExternalFile | null, Error | null]) => {
        result[index] = res;
        completedCount += 1;
        if (completedCount === Files.length) {
            OnComplete(result);
        }
    };

    function onProgress(progress: number) {
        totalProgress = totalProgress + progress / Files.length;
        if (typeof OnProgress === 'function') {
            OnProgress(totalProgress);
        }
    }

    let Promises: Promise<string>[] = [];
    for (let i = 0; i < Files.length; i++) {
        Promises.push(UploadFile(Files[i], onComplete(i), onProgress, ACL));
    }
}
