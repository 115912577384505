import {css} from 'aphrodite';
import {useParams} from 'react-router-dom';
import DraftEditorReact from 'packages/draftjs/DraftEditor.react';
import Header from '../header/header';
import {LoadButton, MakeButton} from 'packages/elements/button';
import TextField from 'packages/elements/textfields/TextField';
import {UploadImageBlock, PreviewFile} from 'packages/files/components';
import {useCreatePostStore} from 'packages/blog/store/create/useCreatePostStore';
import WithSpinner from 'packages/spinners/SmartSpinner.react';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import {useEffect} from 'react';
import {ReactComponent as WarningIcon} from 'src/icons/warning.svg';
import Styles from './blogCreate.jss';
import {PrimaryDraft} from 'packages/draftjs/themes/draftjs';
import {routing} from 'src/runtime/router';

export default function CreatePost() {
    const {uri} = useParams();
    const [details, setDetails] = useCreatePostStore(state => [state.details, state.setDetails]);
    const [metadata] = useCreatePostStore(state => [state.metadata]);
    const errors = useCreatePostStore(state => state.errors);
    const [Init, Save] = useCreatePostStore(state => [state.init, state.save]);

    useEffect(() => {
        Init(uri);
    }, [uri, Init]);

    return (
        <>
            <Header />{' '}
            <div className={css(Styles.container)}>
                <WithSpinner
                    suspense={details.is_loading}
                    theme={PrimaryTheme}
                    minDuration={0}
                >
                    {details.is_loaded && (
                        <>
                            <section className={css(Styles.block)}>
                                <label className={css(Styles.blockLabel)}>Main Information</label>
                                <div className={css(Styles.blockInput)}>
                                    <TextField
                                        label="Title"
                                        placeholder="Enter title"
                                        error={errors.title}
                                        defaultValue={details.title}
                                        onChange={e => setDetails({title: {$set: e.target.value}})}
                                        maxLength={255}
                                    />
                                </div>
                                <div className={css(Styles.blockInput)}>
                                    <TextField
                                        label="Author name"
                                        placeholder="Enter author name"
                                        error={errors.author_name}
                                        defaultValue={details.author_name}
                                        onChange={e => setDetails({author_name: {$set: e.target.value}})}
                                        maxLength={255}
                                    />
                                </div>
                            </section>
                            <section className={css(Styles.block)}>
                                <label className={css(Styles.blockLabel, Styles.flexLabel)}>
                                    <span className={css(Styles.flexTitle)}>Image</span>
                                    <span className={css(Styles.flexError)}>
                                        {errors.image ? (
                                            <>
                                                <WarningIcon
                                                    className={css(Styles.errorIcon)}
                                                    role="img"
                                                    title="Error"
                                                />
                                                <span>{errors.image}</span>
                                            </>
                                        ) : null}
                                    </span>
                                </label>
                                {details.image !== null ? (
                                    <PreviewFile
                                        file={details.image}
                                        onRemove={() => setDetails({image: {$set: null}})}
                                    />
                                ) : (
                                    <UploadImageBlock
                                        OnUpload={file => setDetails({image: {$set: file}})}
                                        aspect={16 / 9}
                                    />
                                )}
                            </section>
                            <section className={css(Styles.block)}>
                                <label className={css(Styles.blockLabel)}>Body</label>
                                <div className={css(Styles.blockInput, Styles.draft)}>
                                    <DraftEditorReact
                                        {...{
                                            theme: PrimaryDraft,
                                            defaultValue: details.body,
                                            onContentChange: (value: string) => {
                                                setDetails({body: {$set: value}});
                                            },
                                            label: 'Description',
                                            placeholder: 'Enter blog post',
                                            additionalModsList: ['insert_image'],
                                        }}
                                    />
                                </div>
                            </section>
                            <section className={css(Styles.nav)}>
                                <button
                                    className={MakeButton('Flat-Big')}
                                    onClick={routing.OpenPage('/blog')}
                                >
                                    Cancel
                                </button>
                                <LoadButton
                                    loading={metadata.is_saving}
                                    variant="FilledAccent2-Big"
                                    onClick={Save}
                                >
                                    Save
                                </LoadButton>
                            </section>
                        </>
                    )}
                </WithSpinner>
            </div>
        </>
    );
}
