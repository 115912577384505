import {CreateSheet, GeneratedStyles, GetColor, css} from 'aphrodite';
import {useState} from 'react';
import {ReactComponent as PasswordInvisibleIcon} from 'src/icons/password-invisible.svg';
import {ReactComponent as PasswordVisibleIcon} from 'src/icons/password-visible.svg';
import TextField from './TextField';

export type PasswordInputProps = {
    label: React.ReactNode | React.ComponentType; // label on top of the input
    labelHidden?: boolean;
    error?: boolean | React.ReactNode | React.ComponentType; // error, if string then will be printed under the input
    hint?: React.ReactNode | React.ComponentType; // hint on top of the input
    styles?: {
        // styles overwrite
        element?: GeneratedStyles; // input/textarea element
        label?: GeneratedStyles; // label
        hint?: GeneratedStyles; // hint
        error?: GeneratedStyles; // error
    };
    autoComplete?: boolean | string; // boolean option for autoComplete
    inputRef?: React.RefObject<HTMLInputElement>;
};

export type input = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'autoComplete'
> & {rows?: undefined; kind?: 'input'};

export default function PasswordInput(props: PasswordInputProps & input) {
    const [isShow, setIsShow] = useState(false);

    return (
        <TextField
            {...{
                ...props,
                type: isShow ? 'text' : 'password',
                aside: {
                    right: (
                        <div
                            onClick={() => setIsShow(s => !s)}
                            className={css(Styles.iconContainer)}
                        >
                            {isShow ? (
                                <PasswordVisibleIcon
                                    width={15}
                                    height={15}
                                />
                            ) : (
                                <PasswordInvisibleIcon
                                    width={15}
                                    height={15}
                                />
                            )}
                        </div>
                    ),
                },
                styles: {
                    aside_right: Styles.icon,
                    ...props.styles,
                },
            }}
        />
    );
}

const Styles = CreateSheet({
    iconContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        cursor: 'pointer',
        color: GetColor('Secondary'),
    },
});
