import React from 'react';
// styles
import {css, GeneratedStyles} from 'aphrodite';
import Styles from './ChatMessages.jss';

type ChatSystemMessagesProps = {
    styles?: {
        message?: GeneratedStyles[];
    };
};

export default function ChatSystemMessages({styles, children}: React.PropsWithChildren<ChatSystemMessagesProps>) {
    return <div className={css(Styles.message, Styles.messageSystem, styles?.message)}>{children}</div>;
}
