import {
    FloatingFocusManager,
    FloatingList,
    FloatingNode,
    FloatingPortal,
    FloatingTree,
    Placement,
    autoUpdate,
    flip,
    offset,
    safePolygon,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingParentNodeId,
    useFloatingTree,
    useHover,
    useInteractions,
    useListItem,
    useListNavigation,
    useMergeRefs,
    useRole,
    useTransitionStyles,
} from '@floating-ui/react';
import React, {
    createContext,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import {css, StyleDeclaration} from 'aphrodite';
import useResize from '../hooks/useResize';

const MenuContext = createContext<{
    getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
    activeIndex: number | null;
    setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
    setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
}>({
    getItemProps: () => ({}),
    activeIndex: null,
    setActiveIndex: () => {},
    setHasFocusInside: () => {},
    isOpen: false,
});

interface MenuProps {
    event?: 'click' | 'hover' | 'contextmenu';
    place?: Placement;
    trigger?: React.ReactNode;
    styles?: {
        trigger?: Array<StyleDeclaration | null>;
        floatingBlock?: Array<StyleDeclaration | null>;
    };
    settings?: {
        durtation?: number;
        distance?: number;
        placement?: {
            useSize?: boolean; // if true - floating block will always be limitted on height to fit window size
            useWidth?: boolean; // if true - floating block matches the width of the reference regardless of its contents
            useSizeOffset?: number; // distance between floating block and window border
            noShift?: boolean; // do not adjust the align of the floating block (start, end) DEFAULT: false
            noFlip?: boolean; // do not adjust the side of the floating block (top, bottom, left, rigth) DEFAULT: false
        };
        closeOn?: {
            escapeKey?: boolean; // close menu on escape, DEFAULT: true
            resize?: boolean; // close menu on resize, DEFAULT: false
            scroll?: boolean; // close menu on scroll, DEFAULT: false
            // if you need to trigger menu from outside element - you use this function to avoid menu triggering close from click-outside
            clickOutside?: boolean | ((event: MouseEvent) => boolean); // close menu on click outside, DEFAULT: true
            clickFloatingBlock?: boolean; // close menu on click anywhere on the floating block, DEFAULT: false
        };
    };
    onStatusChange?: (opened: boolean) => void;
}

export type MenuElProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>;

export type ContextOpenEventData = {preventDefault: () => void; clientX: number; clientY: number};

export interface MenuRef {
    trigger: React.MutableRefObject<HTMLDivElement | undefined>; // element which triggers the menu
    floatingBlock: React.MutableRefObject<HTMLDivElement | undefined>; // menu floating block
    isOpen: boolean; // current menu status
    setIsOpen: (status: boolean, event?: ContextOpenEventData) => void; // set menu status, 'close-all' closes parent menus as well
}

const FloatingMenuComponent = forwardRef<MenuRef, MenuElProps & MenuProps>(
    ({children, trigger, styles, settings, event, onStatusChange, place, ...props}, forwardedRef) => {
        const triggerRef = useRef<HTMLDivElement>();
        const floatingBlockRef = useRef<HTMLDivElement>();

        const [isOpen, setIsOpen] = useState(false);
        const [hasFocusInside, setHasFocusInside] = useState(false);
        const [activeIndex, setActiveIndex] = useState<number | null>(null);

        const elementsRef = useRef<Array<HTMLDivElement | null>>([]);
        const labelsRef = useRef<Array<string | null>>([]);
        const parent = useContext(MenuContext);

        const tree = useFloatingTree();
        const nodeId = useFloatingNodeId();
        const parentId = useFloatingParentNodeId();
        const item = useListItem();

        const isNested = parentId != null;

        const {floatingStyles, refs, context} = useFloating<HTMLElement>({
            nodeId,
            open: isOpen,
            onOpenChange: setIsOpen,
            strategy: 'fixed',
            placement: place ? place : isNested ? 'right-start' : 'bottom-start',
            middleware: [
                offset({mainAxis: settings?.distance || 0, alignmentAxis: isNested ? -4 : 0}),
                settings && settings.placement && settings.placement.noFlip ? null : flip(),
                settings && settings.placement && settings.placement.noShift ? null : shift(),
                settings && settings.placement && settings.placement.useSize
                    ? size({
                          apply({availableHeight, elements}) {
                              if (settings.placement?.useSizeOffset) {
                                  availableHeight = availableHeight - settings.placement.useSizeOffset;
                              }

                              elements.floating.style.maxHeight = `${availableHeight}px`;
                          },
                      })
                    : null,
                settings && settings.placement && settings.placement.useWidth
                    ? size({
                          apply({rects, elements}) {
                              Object.assign(elements.floating.style, {
                                  width: `${rects.reference.width}px`,
                              });
                          },
                      })
                    : null,
            ],
            whileElementsMounted: autoUpdate,
        });

        const hover = useHover(context, {
            enabled: isNested || event === 'hover',
            delay: {open: isNested ? 75 : 0},
            handleClose: safePolygon({blockPointerEvents: true}),
        });
        const click = useClick(context, {
            enabled: event === undefined || event === 'click',
            event: 'mousedown',
            toggle: !isNested,
            ignoreMouse: isNested,
        });
        const role = useRole(context, {role: 'menu'});
        const dismiss = useDismiss(context, {
            bubbles: true,
            escapeKey: settings?.closeOn?.escapeKey || true,
            ancestorScroll: settings?.closeOn?.scroll || false,
            outsidePress: settings?.closeOn?.clickOutside || true,
        });
        const listNavigation = useListNavigation(context, {
            listRef: elementsRef,
            activeIndex: activeIndex,
            nested: isNested,
            onNavigate: setActiveIndex,
        });

        const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
            duration: settings?.durtation || 200,
        });

        const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions([
            hover,
            click,
            role,
            dismiss,
            listNavigation,
        ]);

        function onContextMenuClick(e: ContextOpenEventData) {
            if (event === 'contextmenu') {
                e.preventDefault();

                refs.setPositionReference({
                    getBoundingClientRect() {
                        return {
                            width: 0,
                            height: 0,
                            x: e.clientX,
                            y: e.clientY,
                            top: e.clientY,
                            right: e.clientX,
                            bottom: e.clientY,
                            left: e.clientX,
                        };
                    },
                });

                setIsOpen(true);
            }
        }

        function onResize() {
            setIsOpen(false);
            tree?.events.emit('close-menu');
        }

        useResize(settings?.closeOn?.resize ? onResize : undefined);

        useImperativeHandle(forwardedRef, () => {
            return {
                trigger: triggerRef,
                floatingBlock: floatingBlockRef,
                get isOpen() {
                    return isOpen;
                },
                setIsOpen: (status: boolean, e?: ContextOpenEventData) => {
                    if (event === 'contextmenu') {
                        if (!e) {
                            console.warn('For menu with event "contextmenu" you must pass event data');
                            return;
                        }
                        onContextMenuClick(e);
                    } else {
                        if (status) {
                            setIsOpen(true);
                        } else {
                            setIsOpen(false);
                            tree?.events.emit('close-menu');
                        }
                    }
                },
            };
        });

        useEffect(() => {
            if (!tree) return;

            function handleCloseClick() {
                setIsOpen(false);
            }

            function onSubMenuOpen(event: {nodeId: string; parentId: string}) {
                if (event.nodeId !== nodeId && event.parentId === parentId) {
                    setIsOpen(false);
                }
            }

            tree.events.on('close-menu', handleCloseClick);
            tree.events.on('menuopen', onSubMenuOpen);

            return () => {
                tree.events.off('close-menu', handleCloseClick);
                tree.events.off('menuopen', onSubMenuOpen);
            };
        }, [tree, nodeId, parentId]);

        useEffect(() => {
            if (isOpen && tree) {
                tree.events.emit('menuopen', {parentId, nodeId});
            }
        }, [tree, isOpen, nodeId, parentId]);

        useLayoutEffect(() => {
            if (onStatusChange) {
                onStatusChange(isOpen);
            }
        }, [onStatusChange, isOpen]);

        const floatingRef = useMergeRefs([refs.setFloating, floatingBlockRef]);

        return (
            <FloatingNode id={nodeId}>
                <div
                    ref={useMergeRefs([event === 'contextmenu' ? null : refs.setReference, item.ref, triggerRef])}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={isNested ? 0 : undefined}
                    role={isNested ? 'menuitem' : undefined}
                    data-open={isOpen ? '' : undefined}
                    data-nested={isNested ? '' : undefined}
                    data-focus-inside={hasFocusInside ? '' : undefined}
                    className={styles?.trigger ? css(...styles.trigger) : undefined}
                    onContextMenu={onContextMenuClick}
                    {...getReferenceProps({
                        ...props,
                        ...parent.getItemProps({
                            onFocus(event: React.FocusEvent<HTMLDivElement>) {
                                props.onFocus?.(event);
                                setHasFocusInside(false);
                                parent.setHasFocusInside(true);
                            },
                        }),
                    })}
                >
                    {trigger}
                </div>
                <MenuContext.Provider
                    value={{
                        activeIndex,
                        setActiveIndex,
                        getItemProps,
                        setHasFocusInside,
                        isOpen: isMounted,
                    }}
                >
                    <FloatingList
                        elementsRef={elementsRef}
                        labelsRef={labelsRef}
                    >
                        {isMounted && (
                            <FloatingPortal>
                                <FloatingFocusManager
                                    context={context}
                                    modal={false}
                                    guards={false}
                                    initialFocus={isNested ? -1 : 0}
                                    returnFocus={!isNested}
                                >
                                    <div
                                        ref={floatingRef}
                                        className={styles?.floatingBlock ? css(...styles.floatingBlock) : undefined}
                                        style={{...floatingStyles, ...transitionStyles}}
                                        onClick={
                                            settings?.closeOn?.clickFloatingBlock
                                                ? event => {
                                                      if (settings?.closeOn?.clickFloatingBlock) {
                                                          tree?.events.emit('close-menu');
                                                      }
                                                  }
                                                : undefined
                                        }
                                        {...getFloatingProps()}
                                    >
                                        {children}
                                    </div>
                                </FloatingFocusManager>
                            </FloatingPortal>
                        )}
                    </FloatingList>
                </MenuContext.Provider>
            </FloatingNode>
        );
    }
);

interface MenuItemProps {
    disabled?: boolean;
    styles?: Array<StyleDeclaration | null>;
    settings?: {
        keepOpenOnClick?: boolean;
    };
}

// raw menu item, no styles, use packages/elements/menu instead
export const FloatingMenuItem = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & MenuItemProps>(
    ({children, styles, disabled, settings, ...props}, forwardedRef) => {
        const menu = useContext(MenuContext);
        const item = useListItem();
        const tree = useFloatingTree();

        return (
            <div
                {...props}
                ref={useMergeRefs([item.ref, forwardedRef])}
                role="menuitem"
                className={styles ? css(...styles) : undefined}
                tabIndex={!disabled ? 0 : -1}
                aria-disabled={disabled || undefined}
                {...menu.getItemProps({
                    onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
                        props.onKeyDown?.(event);
                        if (event.key === 'Enter') {
                            if (!settings?.keepOpenOnClick) {
                                tree?.events.emit('close-menu');
                            }
                        }
                    },
                    onClick: (event: React.MouseEvent<HTMLDivElement>) => {
                        props.onClick?.(event);
                        if (!settings?.keepOpenOnClick) {
                            tree?.events.emit('close-menu');
                        }
                    },
                    onFocus: (event: React.FocusEvent<HTMLDivElement>) => {
                        props.onFocus?.(event);
                        menu.setHasFocusInside(true);
                    },
                })}
            >
                {children}
            </div>
        );
    }
);

// raw menu, no styles, use packages/elements/menu instead
export const FloatingMenu = forwardRef<MenuRef, MenuElProps & MenuProps>((props, ref) => {
    const isNested = useIsNestedMenu();
    if (!isNested) {
        return (
            <FloatingTree>
                <FloatingMenuComponent
                    {...props}
                    ref={ref}
                />
            </FloatingTree>
        );
    }

    return (
        <FloatingMenuComponent
            {...props}
            ref={ref}
        />
    );
});

export function useIsNestedMenu() {
    return useFloatingParentNodeId() !== null;
}
