export const SplitFullName = (fullName: string): [string, string] => {
    const fullNameParts = fullName.split(' ');

    return [
        fullNameParts[0],
        fullNameParts
            .slice(1)
            .filter(x => x.trim() !== '')
            .join(' '),
    ];
};
