import {CreateSheet, css} from 'aphrodite';
import LineProgress from 'packages/progress/Line.react';
import {forwardRef, Fragment, useImperativeHandle, useState} from 'react';

export interface UploadProgressToastFunctions {
    onProgress: (progress: number) => void;
    onError: () => void;
}

const UploadProgressToast: React.ForwardRefRenderFunction<UploadProgressToastFunctions, {}> = (props, ref) => {
    const [Progress, SetProgress] = useState(0);
    const [Err, SetErr] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            onProgress: SetProgress,
            onError: () => {
                SetErr(true);
            },
        };
    });

    if (Err) {
        return <div>Error! Please try again later.</div>;
    }

    return (
        <Fragment>
            <div className={css(Styles.label)}>Uploading files...</div>
            <LineProgress
                {...{
                    progress: Progress,
                }}
            />
        </Fragment>
    );
};

export default forwardRef(UploadProgressToast);

const Styles = CreateSheet({
    label: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 10,
    },
});
