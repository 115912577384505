import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    page: {
        minHeight: '100vh',
        background: GetColor('PageBg'),
    },
    container: {
        flexGrow: 1,
        marginLeft: 260,
        padding: '20px 30px',
        '@media(max-width: 800px)': {
            marginLeft: 0,
            padding: '20px 20px',
        },
    },
    title: {
        fontSize: '1.875rem',
        fontWeight: 600,
        color: GetColor('Primary'),
        lineHeight: 'normal',
        '@media(max-width: 800px)': {
            fontSize: '1.5rem',
        },
    },
});
