import {RuntypeFromEnum} from 'lib/types/RuntypeFromEnum';

export enum ChartType {
    Donut = 'donut_chart',
    Bar = 'bar_chart',
    Area = 'area_chart',
    EventsUsersTable = 'events_users_table',
    Unknown = 'unknown_chart',
}
export const ChartTypeRuntype = RuntypeFromEnum(ChartType);
