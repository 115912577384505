import {FetchResponseError, ResultWithError} from 'lib/fetch';
import {routing} from 'src/runtime/router';
import {ReloadSession, SubmitProfileData, UpdateSession, session} from 'src/runtime/session';
import {SubmitProfileDataInput} from 'src/runtime/session/API/SubmitProfileDataAPI';
import {create} from 'zustand';
import ConfirmPasswordResetAPI from './api/ConfirmPasswordResetAPI';
import ResendVerificationCodeAPI, {ResendVerificationCodeAPIResponse} from './api/ResendVerificationCodeAPI';
import ResetPasswordRequestAPI, {ResetPasswordRequestAPIResponse} from './api/ResetPasswordRequestAPI';
import SignInAPI from './api/SignInAPI';
import SignUpAPI, {SignUpAPIResponse} from './api/SignUpAPI';
import SubmitVerificationCodeAPI from './api/SubmitVerificationCodeAPI';
import LoginWithFacebook from './socials/facebook';
import LoginWithGoogle from './socials/google';
import {ThirdPartyLoginState} from './socials/state';

type InitParams = {
    source_page: string;
};

export type SocialTypes = 'google' | 'apple' | 'facebook';

export type ThirdPartyLoginResponse<P = unknown> = {
    status: boolean;
    is_new_user?: boolean;
    error_text?: string;
    payload?: P;
};

interface AuthState {
    // init data
    source_page: string;
    // fields
    email: string;
    setEmail: (email: string) => void;
    setToken: (token: string) => void;
    signUpVerificationToken: string;
    // methods
    init: (params: InitParams) => void;
    signUpWithEmail: (email: string, passsword: string) => Promise<ResultWithError<SignUpAPIResponse>>;
    loginWithPassword: (email: string, password: string) => Promise<FetchResponseError | null>;
    loginWithThirdParty: (
        provider: SocialTypes,
        state: ThirdPartyLoginState,
        onComplete?: (res: ThirdPartyLoginResponse) => void
    ) => void;
    resendVerificationCode: (email: string) => Promise<ResultWithError<ResendVerificationCodeAPIResponse>>;
    submitVerificationCode: (token: string, code: string) => Promise<FetchResponseError | null>;
    resetPasswordRequest: (email: string) => Promise<ResultWithError<ResetPasswordRequestAPIResponse>>;
    confirmPasswordReset: (token: string, code: string, password: string) => Promise<FetchResponseError | null>;
    submitProfileData: (data: SubmitProfileDataInput) => Promise<FetchResponseError | null>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
    // init data
    source_page: '',
    // fields
    email: '',
    setEmail: (email: string) => set({email}),
    setToken: (token: string) => set({signUpVerificationToken: token}),
    signUpVerificationToken: '',
    // methods
    init: (params: InitParams) => {
        set({
            ...params,
            email: '',
            signUpVerificationToken: '',
        });
    },
    signUpWithEmail: async (email: string, passsword: string) => {
        const res = await SignUpAPI(email, passsword);
        if (res[0]) {
            set({signUpVerificationToken: res[0].token});
        }
        return res;
    },
    loginWithPassword: async (email: string, passsword: string) => {
        const res = await SignInAPI(email, passsword);
        if (res[0]) {
            UpdateSession(res[0]);
            routing.push(get().source_page || '/account');
        }
        return res[1];
    },
    loginWithThirdParty: (
        provider: SocialTypes,
        state: ThirdPartyLoginState,
        onComplete?: (res: ThirdPartyLoginResponse) => void
    ) => {
        async function OnComplete(res: ThirdPartyLoginResponse) {
            if (res.status) {
                await ReloadSession();
                if (res.is_new_user) {
                    routing.push(`/sign-up/profile`);
                } else {
                    routing.push(get().source_page || '/account');
                }
            }

            onComplete && onComplete(res);
        }

        if (provider === 'google') {
            LoginWithGoogle(state, OnComplete);
        } else if (provider === 'facebook') {
            LoginWithFacebook(state, 'email', OnComplete);
        } else if (provider === 'apple') {
        }
    },
    resendVerificationCode: async (email: string) => {
        const res = await ResendVerificationCodeAPI(email);
        if (res[0]) {
            set({signUpVerificationToken: res[0].token});
        }
        return res;
    },
    submitVerificationCode: async (token: string, code: string) => {
        const res = await SubmitVerificationCodeAPI(token, code);
        if (res[0]) {
            UpdateSession(res[0]);
        }
        return res[1];
    },
    resetPasswordRequest: async (email: string) => {
        return await ResetPasswordRequestAPI(email);
    },
    confirmPasswordReset: async (token: string, code: string, password: string) => {
        const res = await ConfirmPasswordResetAPI(token, code, password);
        return res[1];
    },
    submitProfileData: async (data: SubmitProfileDataInput) => {
        const res = await SubmitProfileData(data);
        if (!res) {
            if (session.extra.companies && session.extra.companies?.length > 0) {
                routing.push(`/ai/${session.extra.companies[0].uri}/new`);
            } else {
                routing.push(get().source_page);
            }
        }
        return res;
    },
}));
