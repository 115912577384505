interface PrintOptions {
    decimalPlaces?: number | undefined;
    fixedDecimals?: boolean | undefined;
    thousandsSeparator?: string | undefined;
    unitSeparator?: string | undefined;
}

const SizeMap = {
    B: 1,
    KB: 1 << 10,
    MB: 1 << 20,
    GB: 1 << 30,
    TB: Math.pow(1024, 4),
    PB: Math.pow(1024, 5),
};

const formatDecimalsRegEx = /(?:\.0*|(\.[^0]+)0+)$/;
const formatThousandsRegEx = /\B(?=(\d{3})+(?!\d))/g;

export default function PrintSize(value: number, options?: PrintOptions) {
    if (!Number.isFinite(value)) {
        return null;
    }

    const mag = Math.abs(value);
    const thousandsSeparator = (options && options.thousandsSeparator) || '';
    const unitSeparator = (options && options.unitSeparator) || '';
    const decimalPlaces = options && options.decimalPlaces !== undefined ? options.decimalPlaces : 2;
    const fixedDecimals = Boolean(options && options.fixedDecimals);
    let unit: keyof typeof SizeMap = 'B';

    if (mag >= SizeMap.PB) {
        unit = 'PB';
    } else if (mag >= SizeMap.TB) {
        unit = 'TB';
    } else if (mag >= SizeMap.GB) {
        unit = 'GB';
    } else if (mag >= SizeMap.MB) {
        unit = 'MB';
    } else if (mag >= SizeMap.KB) {
        unit = 'KB';
    }

    let val = value / SizeMap[unit];
    let str = val.toFixed(decimalPlaces);

    if (!fixedDecimals) {
        str = str.replace(formatDecimalsRegEx, '$1');
    }

    if (thousandsSeparator) {
        str = str
            .split('.')
            .map(function (s, i) {
                return i === 0 ? s.replace(formatThousandsRegEx, thousandsSeparator) : s;
            })
            .join('.');
    }

    return str + unitSeparator + unit;
}
