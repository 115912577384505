import {css} from 'aphrodite';
import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';
import {useMemo} from 'react';
import {
    AreaChart as ReAreaChart,
    Area as AreaInternal,
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
} from 'recharts';
import {NameType} from 'recharts/types/component/DefaultTooltipContent';
import {AreaChart} from '.';
import {cleanName, getDictNames} from '../general/utils';

const Colors = [
    '#E87CB0',
    '#F4B842',
    '#459EE4',
    '#BBEA6E',
    '#FF868D',
    '#7665DA',
    '#79F2BF',
    '#EA8AF2',
    '#79A2F2',
    '#F4E542',
    '#4EDF77',
    '#EA8EF0',
    '#E75961',
    '#5DE5D5',
];

export default function AreaChartComponent({chart, isCompact}: {chart: AreaChart; isCompact?: boolean}) {
    const keys = useMemo(() => {
        return getDictNames(chart.data).filter(key => key !== 'name');
    }, [chart]);

    return (
        <ResponsiveContainer
            minWidth={200}
            minHeight={200}
        >
            <ReAreaChart
                data={chart.data}
                margin={{top: 0, right: 20, bottom: isCompact ? 0 : 100, left: 20}}
            >
                <CartesianGrid
                    vertical={false}
                    stroke={GetColor('StrokeColor')}
                />
                <XAxis
                    hide={isCompact}
                    dataKey="name"
                    tickLine={false}
                    className={css(Styles.axis)}
                    angle={70}
                    textAnchor="start"
                    tickFormatter={name => {
                        name = cleanName(name);
                        if (name.length > 15) {
                            return name.slice(0, 12) + '...';
                        }

                        return name;
                    }}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    className={css(Styles.axis)}
                />
                <Tooltip
                    wrapperClassName={css(Styles.tooltipWrapper)}
                    cursor={{fill: GetColor('TopLayerObjectBg')}}
                    labelFormatter={cleanName}
                    formatter={(value, name): [React.ReactNode, NameType] => {
                        if (typeof name === 'string') {
                            name = cleanName(name);
                        }
                        return [value.toLocaleString(), name];
                    }}
                />
                {keys.map((key, index) => (
                    <AreaInternal
                        key={key}
                        fillOpacity={1}
                        strokeWidth={0}
                        dataKey={key}
                        stackId="stack"
                        fill={Colors[index % Colors.length]}
                    />
                ))}
            </ReAreaChart>
        </ResponsiveContainer>
    );
}

const Styles = CreateSheet({
    axis: {
        color: GetColor('Secondary'),
        fontSize: '0.875rem',
        lineHeight: '100%',
        fontWeight: 500,
    },
    tooltipWrapper: {
        padding: 6,
        background: `${GetColor('TopLayerObjectBg')} !important`,
        border: `1px solid ${GetColor('StrokeColor')} !important`,
        borderRadius: 8,
        boxShadow: 'rgba(48, 48, 48, 0.1) 0px 5px 15px 0px',
    },
});
