import {CreateSheet, css} from 'aphrodite';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';
import {useDashboardStore} from 'src/jsx/dashboard/DashboardStore';
import ProjectChat from 'src/jsx/dashboard/components/GeneralChat/ProjectChat';

export default function ProjectChatPage() {
    const [company_uri, project_uri] = useDashboardStore(s => [s.company_uri, s.project_uri]);

    return (
        <div className={css(Styles.page, HeaderUtilityStyles().relatedFullHeightPage)}>
            <ProjectChat
                company_uri={company_uri}
                project_uri={project_uri}
            />
        </div>
    );
}
const Styles = CreateSheet({
    page: {
        display: 'flex',
    },
});
