export default function PrintMoney(
    amount?: number | string,
    currency: string = 'USD',
    maximumFractionDigits: number = 2
) {
    if (typeof amount === 'string') {
        amount = parseFloat(amount);
    }

    return new Intl.NumberFormat('en-EU', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits,
    }).format(amount || 0);
}
