import {useEffect} from 'react';

export default function useOutsideClick<T extends HTMLElement>(ref: React.RefObject<T>, callback: () => void) {
    const handleClick = (e: MouseEvent) => {
        if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleClick);

        return () => {
            document.removeEventListener('mouseup', handleClick);
        };
    });
}
