import Editor from '@draft-js-plugins/editor';
import {
    ContentState,
    convertFromRaw,
    convertToRaw,
    DraftDecorator,
    EditorState,
    getDefaultKeyBinding,
    RichUtils,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {convertToHTML} from './utils/convertToHTML';

import {CreateSheetWithTheme, css, StyleDeclarationValue, useTheme} from 'aphrodite';
import React, {
    createContext,
    createRef,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import {DraftDefaultLayout} from './themes/draftjs';
import {DraftLayout, DraftTheme} from './Draft.theme';
import DraftNavigation from './DraftNavigation.react';
import {plugins} from './ModComponents/Image.react';
import {AdditionalModsList, GetMods} from './Mods.react';

type BaseDraftEditorProps = {
    theme: DraftTheme;
    layout?: Partial<DraftLayout>;
    hideNavigation?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    defaultValue?: string;
    additionalModsList?: AdditionalModsList;
    returnHTMLInOnContentChange?: boolean;
    classNames?: {
        root?: StyleDeclarationValue;
    };
};

export type DraftEditorProps =
    | (BaseDraftEditorProps & {
          returnHTMLInOnContentChange: true;
          onContentChange?: (content: string, html: string) => void;
      })
    | (BaseDraftEditorProps & {
          returnHTMLInOnContentChange?: false | undefined;
          onContentChange?: (content: string) => void;
      });

export interface DraftEditorFunctions {
    focus: () => void;
    blur: () => void;
    onChange: (editorState: EditorState) => void;
    getState: () => EditorState;
    setStateByValue: (value: string) => void;
}

type DraftEditorContextParams = {
    functions: DraftEditorFunctions;
    readOnly: boolean;
};

export const DraftEditorContext = createContext<DraftEditorContextParams>({} as DraftEditorContextParams);

const DraftEditor: React.ForwardRefRenderFunction<DraftEditorFunctions, DraftEditorProps> = (
    {
        theme,
        layout,
        hideNavigation,
        readOnly,
        placeholder,
        defaultValue,
        additionalModsList,
        returnHTMLInOnContentChange,
        onContentChange,
        classNames,
    },
    ref
) => {
    const EditorRef = createRef<Editor>();

    const mods = useMemo(() => GetMods(additionalModsList), [additionalModsList]);
    const decorators = useMemo(
        () =>
            Object.values(mods)
                .filter(m => m.decorator !== undefined)
                .map(m => m.decorator) as DraftDecorator[],
        [mods]
    );

    const createStateByValue = (value: string): EditorState => {
        try {
            return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
        } catch (error) {
            return EditorState.createWithContent(ContentState.createFromText(value));
        }
    };

    const [State, SetState] = useState<EditorState>(() => {
        if (!defaultValue) {
            return EditorState.createEmpty();
        } else {
            return createStateByValue(defaultValue);
        }
    });

    const Styles = useTheme(theme, StylesWithTheme, {...DraftDefaultLayout, ...(layout || {})});

    const onChange = useCallback(
        (editorState: EditorState) => {
            SetState(editorState);

            if (onContentChange) {
                const content = editorState.getCurrentContent();
                onContentChange(
                    content.hasText() ? JSON.stringify(convertToRaw(content)) : '',
                    returnHTMLInOnContentChange ? convertToHTML(content) : ''
                );
            }
        },
        [onContentChange, returnHTMLInOnContentChange]
    );

    function handleKeyCommand(command: string, editorState: EditorState, eventTimeStamp: number) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    function onTab(e: React.KeyboardEvent<{}>) {
        e.preventDefault();

        const newState = RichUtils.onTab(e, State, 4);

        if (newState) {
            SetState(newState);
            return 'handled';
        } else {
            return 'not-handled';
        }
    }

    function keyBindingFn(e: React.KeyboardEvent<{}>) {
        e.persist();

        if (e.key === 'Tab') {
            return onTab(e);
        }

        return getDefaultKeyBinding(e);
    }

    const focus = useCallback(() => {
        EditorRef.current?.focus();
    }, [EditorRef]);

    const blur = useCallback(() => {
        EditorRef.current?.focus();
    }, [EditorRef]);

    const InitRef = useCallback(() => {
        return {
            focus,
            blur,
            onChange,
            getState: () => State,
            setStateByValue: (value: string) => SetState(createStateByValue(value)),
        };
    }, [State, focus, blur, onChange]);

    useImperativeHandle(ref, InitRef);
    let NavigationRef = useRef<DraftEditorFunctions>(InitRef());
    NavigationRef.current = InitRef();

    return (
        <section className={css(Styles.main, classNames?.root)}>
            <DraftEditorContext.Provider
                {...{
                    value: {
                        functions: {
                            focus,
                            blur,
                            onChange,
                            getState: () => State,
                            setStateByValue: (value: string) => SetState(createStateByValue(value)),
                        },
                        readOnly: !!readOnly,
                    },
                }}
            >
                {!hideNavigation && !readOnly ? (
                    <DraftNavigation
                        {...{
                            Mods: mods,
                            theme,
                            layout,
                            editorRef: NavigationRef,
                        }}
                    />
                ) : null}
                <Editor
                    {...{
                        editorState: State,
                        onChange,
                        handleKeyCommand,
                        keyBindingFn,
                        ref: EditorRef,
                        readOnly: !!readOnly,
                        placeholder,
                        plugins,
                        decorators,
                    }}
                />
            </DraftEditorContext.Provider>
        </section>
    );
};

export default forwardRef(DraftEditor);

const StylesWithTheme = CreateSheetWithTheme((theme?: DraftTheme, layout?: DraftLayout) => {
    return {
        main: {
            ':nth-child(1n) .DraftEditor-root': {
                color: theme?.editor_color,
                padding: layout?.editor_padding,
                background: theme?.editor_background,
                border: theme?.editor_border,
                borderTopLeftRadius: layout?.editor_border_top_left_radius,
                borderTopRightRadius: layout?.editor_border_top_right_radius,
                borderBottomLeftRadius: layout?.editor_border_bottom_left_radius,
                borderBottomRightRadius: layout?.editor_border_bottom_right_radius,
                minHeight: layout?.editor_min_height,
                maxHeight: layout?.editor_max_height,
                overflow: layout?.editor_max_height && layout?.editor_max_height !== 'initial' ? 'auto' : 'initial',
                fontSize: layout?.editor_font_size,
                cursor: 'text',
            },
            ':nth-child(1n) .DraftEditor-editorContainer, :nth-child(1n) .public-DraftEditor-content': {
                minHeight: 'inherit',
            },
            ':nth-child(1n) figure': {
                margin: 0,
            },
        },
    };
});
