import {debounce} from 'lodash';
import {FetchResponseError, ResultWithError} from './model';
import {useDetached} from 'packages/useDetached';
import {useRef} from 'react';

export interface State<R> {
    executionCount: number;
    isLoading: boolean;
    error: FetchResponseError | null;
    payload: R | null;
}

type func<R, P extends any[]> = (...args: P) => Promise<ResultWithError<R>>;

function useAPI<R, P extends any[]>(
    func: func<R, P>,
    listenArgs: boolean,
    ...args: P
): [Readonly<State<R>>, (...args: P) => Promise<ResultWithError<R> | undefined>, (payload: R | null) => void] {
    const {state, execute, setPayload: setDataPayload} = useDetached(func, {listenArgs: listenArgs}, ...args);

    function setPayload(payload: R | null) {
        setDataPayload(payload ? [payload, null] : undefined);
    }

    return [
        {
            executionCount: state.executionCount,
            isLoading: state.isLoading,
            error: state.payload && state.payload[1] ? state.payload[1] : null,
            payload: state.payload && state.payload[0] ? state.payload[0] : null,
        },
        execute,
        setPayload,
    ];
}

// make Debounced API hook which also cancells fetch requsts when it's cancalled
export function makeDebouncedAPIHook<R, P extends any[]>(
    func: (ac: AbortController, ...args: P) => Promise<ResultWithError<R>>,
    timeout: number = 300
) {
    return function (initLoad: boolean, ...args: P) {
        const ac = useRef<AbortController>();
        const debouncedFetch = useRef(debounce(fetch, timeout)).current as (...args: P) => Promise<ResultWithError<R>>;

        async function fetch(...args: P) {
            if (ac.current) {
                ac.current.abort();
            }

            ac.current = new AbortController();
            return await func(ac.current, ...args);
        }

        return useAPI(debouncedFetch, initLoad, ...args);
    };
}

export function makeAPIHook<R, P extends any[]>(func: func<R, P>) {
    return function (initLoad: boolean, ...args: P) {
        return useAPI(func, initLoad, ...args);
    };
}
