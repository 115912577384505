import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    page: {
        display: 'flex',
        position: 'relative',
        minHeight: '100vh',
        paddingTop: 70,
        flexDirection: 'column',
        overflow: 'hidden',
        '@media(max-width: 800px)': {
            paddingTop: 60,
        },
    },
    main: {
        padding: '50px 0',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 1280,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 40px',
        '@media(max-width: 800px)': {
            padding: '0 20px',
        },
    },
    row: {
        display: 'flex',
        flexShrink: 0,
        '@media(max-width: 1040px)': {
            display: 'block',
        },
    },
    content: {
        flexGrow: 1,
    },
    form: {
        minWidth: 460,
        marginLeft: 100,
        padding: 30,
        flex: '1 0 0',
        borderRadius: 24,
        border: `1px solid ${GetColor('StrokeColor')}`,
        background: GetColor('TopLayerObjectBg'),
        boxShadow: '0px 10px 80px 0px rgba(0, 0, 0, 0.25)',
        '@media(max-width: 1040px)': {
            width: 600,
            minWidth: 'auto',
            maxWidth: '100%',
            margin: '40px auto 0 auto',
        },
        '@media(max-width: 640px)': {
            padding: 15,
            borderRadius: 14,
        },
    },
    thanks: {
        display: 'flex',
        minHeight: 310,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        margin: '20px 0 0 0',
        fontSize: '3.75rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
        '@media(max-width: 800px)': {
            fontSize: '2.75rem',
        },
        '@media(max-width: 640px)': {
            fontSize: '1.875rem',
        },
    },
    eyeBrow: {
        display: 'flex',
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: GetColor('Accent1'),
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 10,
        },
    },
    text: {
        margin: '15px 0 0 0',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: GetColor('Secondary'),
        '@media(max-width: 640px)': {
            fontSize: '1.125rem',
        },
    },
    mailBtn: {
        display: 'inline-flex',
        marginTop: 40,
        padding: 20,
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('TextColor'),
        alignItems: 'center',
        borderRadius: 14,
        border: `4px solid ${GetColor('LightGrayBg')}`,
        textDecoration: 'none',
        background: GetColor('PageBg'),
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 15,
        },
        '@media(max-width: 640px)': {
            width: '100%',
            display: 'none',
            fontSize: '1.25rem',
            ':nth-child(1n) svg': {
                display: 'block',
                width: 22,
                height: 22,
                marginRight: 15,
            },
        },
    },
    mailBtnMobile: {
        display: 'none',
        justifyContent: 'center',
        '@media(max-width: 640px)': {
            display: 'inline-flex',
        },
    },
    inputContainer: {
        marginBottom: 15,
    },
    input: {
        width: '100%',
        margin: '5px 0',
    },
    textarea: {
        width: '100%',
        margin: '5px 0',
        resize: 'vertical',
    },
    btn: {
        width: '100%',
        marginTop: 10,
    },
    footer: {
        flexGrow: 1,
        display: 'flex',
        marginTop: 70,
        fontSize: '0.875rem',
        fontWeight: 600,
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: GetColor('Secondary'),
        flexDirection: 'row-reverse',
        '@media(max-width: 640px)': {
            display: 'block',
        },
    },
    footerList: {
        display: 'flex',
        margin: '0 0 0 35px',
        padding: 0,
        listStyle: 'none',
        '@media(max-width: 640px)': {
            margin: '0 0 20px 0',
        },
    },
    footerListItem: {
        display: 'block',
        marginLeft: 15,
        color: GetColor('Secondary'),
        textDecoration: 'none',
        '@media(max-width: 640px)': {
            margin: '0 15px 0 0',
        },
    },
    back: {
        position: 'absolute',
        left: 120,
        bottom: 0,
        color: 'rgba(137, 141, 143, 0.05)',
        zIndex: -1,
        ':nth-child(1n) svg': {
            display: 'block',
            width: 420,
            height: 'auto',
        },
    },
    thanksTitle: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
    },
    thanksText: {
        margin: '20px 0 0 0',
        fontSize: '1rem',
        fontWeight: 400,
        color: GetColor('Secondary'),
        textAlign: 'center',
    },
});
