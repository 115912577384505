import {ChatMessageRuntype} from 'packages/chat/model';
import * as r from 'runtypes';
import {ProjectRuntype} from '..';
import {MessagePayload} from '../generalchat/model';

export const OnboardingMessageRuntype = ChatMessageRuntype.extend({
    guid: r.String,
    payload: r.String.optional().nullable(),
});
export type OnboardingMessage = r.Static<typeof OnboardingMessageRuntype> & {
    parsed_payload?: MessagePayload;
};

export const OnboardingChatRuntype = r.Record({
    messages: r.Array(OnboardingMessageRuntype),
    project: ProjectRuntype,
});
export type OnboardingChat = r.Static<typeof OnboardingChatRuntype>;
