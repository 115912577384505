import {css} from 'aphrodite';
import useSimpleError from 'lib/hooks/useSimpleError';
import useWaitFor from 'lib/hooks/useWaitFor';
import {LoadButton} from 'packages/elements/button';
import PasswordInput from 'packages/elements/textfields/PasswordInput';
import TextField from 'packages/elements/textfields/TextField';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {useState} from 'react';
import {routing} from 'src/runtime/router';
// import LoginWithSocial from './LoginWithSocial';
import Styles from './common.jss';
import {ReactComponent as Warning} from 'src/icons/warning.svg';
import {useAuthStore} from './store';
// import {ThirdPartyLoginResponse, useAuthStore} from './store';

export function SignUpForm() {
    const [email, setEmail] = useAuthStore(state => [state.email, state.setEmail]);
    const [signUpWithEmail] = useAuthStore(state => [state.signUpWithEmail]);
    const [password, setPassword] = useState('');
    const [isExisting, setIsExisting] = useState(false);
    const [hasErr, setErr] = useSimpleError<'email' | 'password' | 'common', string>();
    const [isLoading, withLoading] = useWaitFor();

    async function onSubmitSignUp(e: React.FormEvent) {
        e.preventDefault();

        if (email.trim() === '') {
            setErr('email', 'Email is required');
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
            setErr('email', 'Please enter a valid email address');
        } else if (password.trim() === '') {
            setErr('password', 'Password is required');
        } else {
            const res = await withLoading(signUpWithEmail(email, password));
            if (res[1]) {
                setErr('common', res[1].message);
                if (res[1].status_code === 9) {
                    setIsExisting(true);
                }
            } else {
                routing.push(`/sign-up/verification`);
            }
        }
    }

    // function OnCompleteSocialsSignUp(data: ThirdPartyLoginResponse) {
    //     if (data.error_text) {
    //         setErr('common', data.error_text);
    //     }
    // }

    return (
        <form
            className={css(Styles.form)}
            onSubmit={onSubmitSignUp}
        >
            <h1 className={css(Styles.formTitle)}>Create an account</h1>
            {/* <LoginWithSocial
                allowSignUp={true}
                onComplete={OnCompleteSocialsSignUp}
            /> */}
            <div className={css(Styles.inputs)}>
                {/* <div className={css(Styles.inputsTitle)}>or Sign Up with email</div> */}
                <div className={css(Styles.inputContainer)}>
                    <TextField
                        type="text"
                        label="Email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={e => {
                            setErr('email', false);
                            setErr('common', false);
                            setIsExisting(false);
                            setEmail(e.target.value);
                        }}
                        error={hasErr('email') || undefined}
                        styles={{
                            element: Styles.input,
                        }}
                    />
                </div>
                <div className={css(Styles.inputContainer)}>
                    <PasswordInput
                        label="Password"
                        placeholder="Password"
                        value={password}
                        onChange={e => {
                            setErr('password', false);
                            setErr('common', false);
                            setPassword(e.target.value);
                        }}
                        error={hasErr('password') || undefined}
                        styles={{
                            element: Styles.input,
                        }}
                    />
                </div>
                <SmoothLine>
                    {hasErr('common') ? (
                        <div className={css(Styles.errorText, Styles.errorContainer)}>
                            <Warning
                                width="14"
                                height="14"
                                role="img"
                                title="Error"
                                className={css(Styles.errorContainerIcon)}
                            />
                            <span>
                                {isExisting ? (
                                    <>
                                        Account using this email is already registered.
                                        <div>
                                            Try again or{' '}
                                            <a
                                                href="/reset-password"
                                                className={css(Styles.agreeLink)}
                                                onClick={routing.OpenPage('/reset-password')}
                                            >
                                                reset your password
                                            </a>{' '}
                                            to sign in
                                        </div>
                                    </>
                                ) : (
                                    hasErr('common')
                                )}
                            </span>
                        </div>
                    ) : undefined}
                </SmoothLine>
                <LoadButton
                    type="submit"
                    variant="FilledAccent1-Big"
                    styles={[Styles.button, email.trim() === '' && password.trim() ? Styles.disabledBtn : undefined]}
                    loading={isLoading}
                >
                    Sign up
                </LoadButton>
                <div className={css(Styles.agree)}>
                    By signing in you are agreeing to our{' '}
                    <a
                        href="/terms"
                        onClick={routing.OpenPage('/terms')}
                        className={css(Styles.agreeLink)}
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        href="/privacy"
                        onClick={routing.OpenPage('/privacy')}
                        className={css(Styles.agreeLink)}
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
            <div className={css(Styles.switch)}>
                Already have an account?
                <a
                    href="/login"
                    onClick={routing.OpenPage('/login')}
                    className={css(Styles.switchLink)}
                >
                    Sign In
                </a>
            </div>
        </form>
    );
}
