import * as r from 'runtypes';

export const DataSourceRuntype = r.Record({
    guid: r.String,
    project_guid: r.String,
    user_data_source_guid: r.String.nullable(),
    user_data_source_type_id: r.String.nullable(),
    user_data_source_params: r.String.nullable(),
});
export type DataSource = r.Static<typeof DataSourceRuntype>;
