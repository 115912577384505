import {css} from 'aphrodite';
import useOnce from 'lib/hooks/useOnce';
import {MakeButton} from 'packages/elements/button';
import {ReactComponent as Back} from 'src/icons/arrow-left-long.svg';
import {ReactComponent as Logo} from 'src/icons/logo/logo.svg';
import {routing} from 'src/runtime/router';
import Styles from './AuthPage.jss';
import {useAuthStore} from './store';
import {pagelist} from 'src/runtime/router/pagelist';
import {useOutlet} from 'react-router-dom';

export default function AuthPage() {
    const InitAuthStore = useAuthStore(store => store.init);

    const outlet = useOutlet();

    useOnce(() => {
        InitAuthStore({source_page: routing.__prev_path !== routing.__current_path ? routing.__prev_path : ''});
    });

    function isBackButtonHidden() {
        const currentPath = routing.__current_path.replace(/^\//, '');
        return currentPath.startsWith(pagelist.auth.children.signUpProfile.path);
    }

    return (
        <div className={css(Styles.page)}>
            <header className={css(Styles.header)}>
                {!isBackButtonHidden() && (
                    <button
                        type="button"
                        className={MakeButton('Flat-Flat', Styles.back)}
                        onClick={routing.OpenPage(-1)}
                    >
                        <Back
                            width="16"
                            height="16"
                            role="img"
                            title="Back"
                        />
                        <span>Back</span>
                    </button>
                )}
                <a
                    className={css(Styles.logo)}
                    href="/"
                    onClick={routing.OpenPage('/')}
                >
                    <Logo
                        role="img"
                        aria-label="Whitetail AI"
                    />
                    <span>Whitetail AI</span>
                </a>
            </header>
            <div className={css(Styles.content)}>{outlet}</div>
            <footer className={css(Styles.footer)}>© {new Date().getFullYear()} Whitetail AI</footer>
        </div>
    );
}
