import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    trigger: {
        position: 'sticky',
        padding: '10px 20px 15px 20px',
        boxSizing: 'content-box',
        height: 18,
        background: GetColor('PageBg'),
        zIndex: 10,
    },
    triggerButton: {
        padding: 0,
        minHeight: 'none',
    },
    overlay: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        background: GetColor('PageBg'),
        zIndex: 5,
        padding: '80px 20px 50px 20px',
        borderTop: `1px solid ${GetColor('StrokeColor')}`, // TODO: remove when logged-in header is done (it should contains this border as border-bottom)
    },
    withSlideAnimation: {
        opacity: 0,
        transform: 'translateX(-100%)',
        transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
    },
    withSlideAnimationShown: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    overlayHeader: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        left: 0,
        right: 0,
        padding: '20px 20px 20px 20px',
        background: GetColor('PageBg'),
        zIndex: 15,
    },
    overlayHeaderUser: {
        flexGrow: 1,
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    overlayHeaderUserName: {
        fontWeight: 700,
    },
    overlayHeaderClose: {
        padding: 10,
        borderRadius: '50%',
        background: GetColor('ButtonBgHover'),
        cursor: 'pointer',
    },
    overlayHeaderCloseIcon: {
        display: 'block',
        width: 14,
        height: 14,
    },
    overlayTalkButton: {
        width: 'none',
        '@media(max-width: 480px)': {
            width: '100%',
        },
    },
    menuSection: {
        padding: '30px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        borderBottom: `1px solid ${GetColor('TopLayerObjectBg')}`,
        ':first-child': {
            paddingTop: 0,
        },
    },
    menuSectionTitle: {
        fontSize: '1.375rem',
        fontWeight: 600,
        lineHeight: '120%',
    },
    menuItems: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
        '@media(max-width: 640px)': {
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
    },
    navCardContainer: {
        // flex: '0 1 calc(50% - 15px)',
        flex: '1 0 0',
    },
    navCardSettings: {
        marginTop: 30,
    },
    upgradeNow: {
        marginTop: 30,
    },
});
