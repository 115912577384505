import React, {ChangeEvent, useEffect, useRef} from 'react';
import {css, GeneratedStyles} from 'aphrodite';
import {AddExtraPadding} from 'lib/helpers/ExtraPadding';
import CreateGUID from 'lib/helpers/CreateGUID';
import {ReactComponent as Warning} from 'src/icons/warning.svg';
import Styles, {SetPadding, baseSizes} from './TextField.jss';

export type TextFieldProps = {
    label: React.ReactNode | React.ComponentType; // label on top of the input
    labelHidden?: boolean;
    error?: boolean | React.ReactNode | React.ComponentType; // error, if string then will be printed under the input
    hint?: React.ReactNode | React.ComponentType; // hint on top of the input
    kind?: 'input' | 'textarea'; // input kind, DEFAULT input
    styles?: {
        // styles overwrite
        element?: GeneratedStyles; // input/textarea element
        label?: GeneratedStyles; // label
        hint?: GeneratedStyles; // hint
        error?: GeneratedStyles; // error
        aside_left?: GeneratedStyles; // aside left elem
        aside_right?: GeneratedStyles; // aside right elem
        aside_wrapper?: GeneratedStyles; // aside right elem
    };
    state?: {
        active?: boolean;
    };
    autoComplete?: boolean | string; // boolean option for autoComplete
    inputRef?: React.RefObject<HTMLInputElement> | React.RefObject<HTMLTextAreaElement>;
    aside?: {
        ariaLabel?: string;
        left?: React.ReactNode | React.ComponentType;
        right?: React.ReactNode | React.ComponentType;
    };
    autoHeight?: boolean; // works for textarea only
};

export type input = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'autoComplete'
> & {rows?: undefined; kind?: 'input'};
export type textarea = Omit<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'autoComplete'
> &
    ({rows: number} | {kind: 'textarea'});

function IsInput(data: input | textarea): data is input {
    return (
        ((data as input).rows === undefined && (data as input).kind === undefined) || (data as input).kind === 'input'
    );
}

const TextElement = React.forwardRef(
    ({autoHeight, ...props}: (input | textarea) & {autoComplete?: string; autoHeight?: boolean}, ref) => {
        const updateHeight = () => {
            //@ts-ignore
            if (ref.current) {
                //@ts-ignore
                ref.current.style.height = '0px';
                //@ts-ignore
                ref.current.style.height = ref.current.scrollHeight + 'px';
            }
        };

        useEffect(() => {
            if (!IsInput(props)) {
                if (autoHeight) updateHeight();
            }
            // @ts-ignore
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref.current]);

        if (IsInput(props)) {
            const {kind, ...rest} = props;

            return (
                <input
                    {...rest}
                    ref={ref as React.Ref<HTMLInputElement>}
                    type={props.type || 'text'}
                />
            );
        } else {
            const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
                if (autoHeight) updateHeight();
                if (props.onChange) props.onChange(event);
            };
            return (
                <textarea
                    {...props}
                    onChange={onChange}
                    ref={ref as React.Ref<HTMLTextAreaElement>}
                />
            );
        }
    }
);

// forwardRef will kill type checking, don't use it
// use inputRef prop instead
export default function TextField(props: TextFieldProps & (input | textarea)) {
    const internalRef = useRef<{value?: string}>(null);

    const {aside, error, hint, styles, autoComplete, inputRef, labelHidden, ...rest} = props;

    let label = 'label' in props ? props.label : undefined;
    const uniqueID = useRef(rest.id || CreateGUID());

    const AsideLeft = aside?.left;
    const AsideRight = aside?.right;
    const hasAside = AsideLeft || AsideRight || error;
    const ref = inputRef || internalRef;

    const baseInputPadding = styles?.element?._definition.padding || Styles.input._definition.padding || 0;
    let inputPadding = baseInputPadding;
    if (aside?.left || aside?.right || error) {
        inputPadding = AddExtraPadding(
            baseInputPadding,
            [
                '0px',
                aside?.right || error ? `${baseSizes.asidePadding + baseSizes.asideWidth}px` : 0,
                '0px',
                aside?.left ? `${baseSizes.asidePadding + baseSizes.asideWidth}px` : 0,
            ].join(' ')
        );
    }

    const elemProps: (input | textarea) & {autoComplete: string; autoHeight?: boolean} = {
        ...rest,
        autoHeight: props.autoHeight,
        autoComplete: !autoComplete ? 'off' : 'on',
        'aria-labelledby': label ? `label-${uniqueID.current}` : undefined,
        className: css(
            Styles.input,
            props.state?.active && Styles.input_active,
            !IsInput(props) && Styles.textArea,
            Boolean(error) && Styles.input_error,
            styles?.element || null,
            SetPadding(inputPadding),
            hasAside ? Styles.input_with_aside : null
        ),
    };

    const LabelElement = label;
    const HintElement = hint;
    const ErrorElement = error;

    return (
        <>
            {LabelElement ? (
                <label
                    id={`label-${uniqueID.current}`}
                    htmlFor={uniqueID.current}
                    className={labelHidden ? css(Styles.label_hidden) : css(Styles.label, styles?.label || null)}
                >
                    {typeof LabelElement === 'function' ? <LabelElement /> : LabelElement}
                </label>
            ) : null}
            <div
                key="aside-textfield-component"
                {...(hasAside
                    ? {
                          className: css(Styles.aside_wrapper, styles?.aside_wrapper),
                      }
                    : {})}
            >
                {AsideLeft && (
                    <aside
                        className={css(Styles.aside, Styles.aside_left, styles?.aside_left)}
                        aria-label={aside.ariaLabel}
                    >
                        {typeof AsideLeft === 'function' ? <AsideLeft /> : AsideLeft}
                    </aside>
                )}
                <TextElement
                    {...elemProps}
                    id={uniqueID.current}
                    ref={ref}
                />
                {error && (
                    <aside
                        className={css(Styles.aside, Styles.aisde_right, Styles.aside_error)}
                        aria-label="error"
                    >
                        <Warning
                            width="16"
                            height="16"
                            role="img"
                            title="Error"
                        />
                    </aside>
                )}
                {AsideRight && !error && (
                    <aside
                        className={css(Styles.aside, Styles.aisde_right, styles?.aside_right)}
                        aria-label={aside.ariaLabel}
                    >
                        {typeof AsideRight === 'function' ? <AsideRight /> : AsideRight}
                    </aside>
                )}
                {ErrorElement && typeof ErrorElement !== 'boolean' ? (
                    <small className={css(Styles.error_element, styles?.error || null)}>
                        {typeof ErrorElement === 'function' ? <ErrorElement /> : ErrorElement}
                    </small>
                ) : null}
            </div>
            {HintElement && (
                <small className={css(Styles.hint, styles?.hint || null)}>
                    {typeof HintElement === 'function' ? <HintElement /> : HintElement}
                </small>
            )}
        </>
    );
}
