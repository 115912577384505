import {FetchResponseError} from 'lib/fetch';
import {ProjectStatus, ProjectWithDetails} from 'packages/projects';
import LoadProjectAPI from 'packages/projects/api/LoadProjectAPI';
import LoadProjectChartsAPI from 'packages/projects/api/LoadProjectChartsAPI';
import {ProjectCharts} from 'packages/projects/project_charts';
import {create} from 'zustand';

type InitParams = {
    company_uri: string;
    project_uri: string;
};

export const useDashboardStore = create<{
    // initial data
    company_uri: string;
    project_uri: string;
    // payloads
    project: ProjectWithDetails | null;
    charts: ProjectCharts;
    // system
    isOnboarding: boolean;
    isLoading: boolean;
    isLoadingCharts: boolean;
    // methods
    init: (params: InitParams) => Promise<FetchResponseError | null>;
    reload: (params?: Partial<InitParams>) => Promise<FetchResponseError | null>;
    // fetchers
    loadCharts: () => Promise<FetchResponseError | null>;
}>((set, get) => ({
    // initial data
    company_uri: '',
    project_uri: '',
    // payloads
    project: null,
    charts: [],
    // system
    isOnboarding: false,
    isLoading: false,
    isLoadingCharts: false,
    // methods
    init: async (params: InitParams) => {
        set({...params});

        if (!get().project) {
            set({isLoading: true});
        }

        if (params.project_uri !== 'new') {
            const res = await LoadProjectAPI(params.project_uri);
            if (res[1]) {
                set({isLoading: false});
                return res[1];
            }

            set({project: res[0]});

            if (res[0].status_id === ProjectStatus.Created || res[0].status_id === ProjectStatus.Analyzing) {
                set({isOnboarding: true});
            }
        } else {
            set({isOnboarding: true});
        }

        set({isLoading: false});

        return null;
    },
    reload: async (params?: Partial<InitParams>) => {
        if (params) {
            set({...params});
        }

        const res = await LoadProjectAPI(get().project_uri);
        if (res[1]) {
            return res[1];
        }

        set({project: res[0]});

        return null;
    },
    // fetchers
    loadCharts: async () => {
        set({isLoadingCharts: true});
        const res = await LoadProjectChartsAPI(get().project_uri, ['sandbox']);
        set({isLoadingCharts: false});

        if (res[1]) {
            return res[1];
        }

        set({charts: res[0]});

        return null;
    },
}));
