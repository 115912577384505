import {css} from 'aphrodite';
import useMaxWidth from 'lib/hooks/useMaxWidth';
import SafeUpdate from 'lib/helpers/SafeUpdate';
import IsLocalStorageAvailable from 'lib/helpers/IsLocalStorageAvailable';
import showWarning, {showErrorLightbox} from 'lib/lightbox/warning.react';
import moment from 'moment';
import {MakeButton} from 'packages/elements/button';
import {Menu, MenuItem} from 'packages/elements/menu';
import {PreviewPicture} from 'packages/files/components';
import DeleteBlogPostAPI from 'packages/blog/API/DeleteBlogPostAPI';
import {BlogPostMetadata} from 'packages/blog/model';
import Tooltip from 'packages/tooltip';
import {useState} from 'react';
import {ReactComponent as CirlceIcon} from 'src/icons/cirlce.svg';
import {ReactComponent as MoreIcon} from 'src/icons/more.svg';
import Styles from './Card.jss';
import {routing} from 'src/runtime/router';
import UpdateBlogPostAPI from 'packages/blog/API/UpdateBlogPostAPI';
import {session} from 'src/runtime/session';

type CardProps = {
    item: BlogPostMetadata;
    onChange: (item: BlogPostMetadata) => void;
    onRemove: (guid: string) => void;
};

export default function Card(props: CardProps) {
    const max800 = useMaxWidth(800);

    const {item} = props;
    const [IsVisited, setIsVisited] = useState(checkVisited());

    function checkVisited() {
        if (IsLocalStorageAvailable()) {
            const visited = localStorage.getItem(`resources:visited:${item.guid}`);
            if (visited === '1') {
                return true;
            }
        }

        return false;
    }

    function openItem() {
        if (IsLocalStorageAvailable()) {
            localStorage.setItem(`resources:visited:${item.guid}`, '1');
            setIsVisited(true);
        }
        routing.OpenPage(`blog/${item.uri}`)();
    }

    return (
        <div
            className={css(Styles.main, IsVisited && Styles.visited)}
            onClick={openItem}
        >
            <div className={css(Styles.content)}>
                {item.is_hidden && (
                    <div className={css(Styles.hiddenLine)}>
                        <div className={css(Styles.hiddenTag)}>Hidden</div>
                    </div>
                )}
                <div className={css(Styles.titleLine)}>
                    <a
                        href={item.uri}
                        className={css(Styles.titleLineLink)}
                        target="_blank"
                        rel="noreferrer"
                        onClick={e => e.preventDefault()}
                        data-selector="title"
                    >
                        {item.title}
                    </a>
                </div>
                <div className={css(Styles.authorTimeLine)}>
                    {item.published_on && <time>{moment.unix(item.published_on).format('MMMM D, YYYY')}</time>}
                    {item.published_on && item.author_name && (
                        <CirlceIcon
                            width="14"
                            height="14"
                            className={css(Styles.dotSymbol)}
                            role="img"
                            title="Circle"
                        />
                    )}
                    {item.author_name && <span>{item.author_name}</span>}
                    {max800 ? (
                        <>
                            {(item.published_on || item.author_name) && (
                                <CirlceIcon
                                    width="14"
                                    height="14"
                                    className={css(Styles.dotSymbol)}
                                    role="img"
                                    title="Circle"
                                />
                            )}
                            <span>{Math.max(1, Math.round(item.read_duration / 60))} min read</span>
                        </>
                    ) : null}
                </div>
                {item.description && <div className={css(Styles.descriptionLine)}>{item.description}</div>}
                {!max800 && (
                    <div className={css(Styles.readDuration)}>
                        {Math.max(1, Math.round(item.read_duration / 60))} min read
                    </div>
                )}
            </div>
            {item.image !== null && (
                <PreviewPicture
                    image={item.image}
                    thumbnail={{width: 200, height: 200}}
                    img_tag={{className: css(Styles.image)}}
                />
            )}
            {session.extra.su?.can_manage_blog && <CardActions {...props} />}
        </div>
    );
}

function CardActions(props: CardProps) {
    const {item, onChange, onRemove} = props;
    function edit(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        routing.push(`/blog/create/${item.uri}`);
    }

    async function hide(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        const [, err] = await UpdateBlogPostAPI({
            guid: item.guid,
            is_hidden: !item.is_hidden,
        });
        if (err !== null) {
            showErrorLightbox('Failed to hide resource');
        } else {
            onChange(SafeUpdate(item, {is_hidden: {$set: !item.is_hidden}}));
        }
    }

    async function remove(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        showWarning(
            <div>
                Are you sure you want to remove this resource? <div>This action can't be undone.</div>
            </div>,
            undefined,
            {
                buttons: [
                    {
                        text: 'Cancel',
                        className: MakeButton('Flat-Big'),
                    },
                    {
                        text: 'Remove',
                        className: MakeButton('FilledDanger-Big'),
                        onClick: async (e, close) => {
                            const [, err] = await DeleteBlogPostAPI(item.guid);
                            if (err !== null) {
                                showErrorLightbox('Failed to remove resource');
                            } else {
                                onRemove(item.guid);
                            }
                        },
                    },
                ],
            }
        );
    }

    return (
        <Tooltip content="More options">
            <div className={css(Styles.actionsWrapper)}>
                <Menu
                    place="left-start"
                    onClick={e => e.stopPropagation()}
                    trigger={
                        <button className={MakeButton('Outlined-Small')}>
                            <MoreIcon />
                        </button>
                    }
                    settings={{
                        closeOn: {
                            clickFloatingBlock: true,
                        },
                        placement: {
                            useSize: true,
                        },
                    }}
                    event="click"
                >
                    <MenuItem onClick={edit}>Edit</MenuItem>
                    <MenuItem onClick={hide}>{item.is_hidden ? 'Show' : 'Hide'}</MenuItem>
                    <MenuItem
                        onClick={remove}
                        styles={[Styles.actionsDanger]}
                    >
                        Remove
                    </MenuItem>
                </Menu>
            </div>
        </Tooltip>
    );
}
