import {Body, ComponentProps, Footer, Header, Lightbox} from 'lib/lightbox/lightbox';
import {useState} from 'react';
import ImageCropper, {ImageCropperProps} from './ImageCropper';
// styles
import {CreateSheet} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';

export default function ImageCropperLightbox({content, close}: ComponentProps<ImageCropperProps>) {
    const [CroppedImage, SetCroppedImage] = useState<File | null>(null);

    function OnClickSave() {
        if (CroppedImage) {
            content.onImageCropped(CroppedImage);
            close();
        }
    }

    return (
        <Lightbox styles={Styles.lightbox}>
            <Header>Crop the image</Header>
            <Body styles={Styles.cropper}>
                <ImageCropper
                    {...content}
                    onImageCropped={SetCroppedImage}
                />
            </Body>
            <Footer styles={Styles.footer}>
                <button
                    {...{
                        className: MakeButton('Flat-Big'),
                        onClick: () => {
                            close();
                        },
                    }}
                >
                    Close
                </button>
                <button
                    {...{
                        className: MakeButton('FilledAccent2-Big'),
                        onClick: OnClickSave,
                    }}
                >
                    Save
                </button>
            </Footer>
        </Lightbox>
    );
}

const Styles = CreateSheet({
    lightbox: {
        maxHeight: 'initial',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        ':nth-child(1n) button': {
            marginLeft: 15,
        },
    },
    cropper: {
        maxWidth: 1000,
    },
});
