import {put} from 'lib/fetch';
import {ProjectRuntype} from '../index';

type UpdateProjectInput = {
    name: string;
};

export default async function UpdateProjectAPI(uri: string, input: UpdateProjectInput) {
    return await put(
        {
            url: `projects/${uri}`,
            body: input,
        },
        ProjectRuntype
    );
}
