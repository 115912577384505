import React, {useLayoutEffect} from 'react';
import {createRoot, Root} from 'react-dom/client';
import useOnce from 'lib/hooks/useOnce';

const reactRoots: Record<string, Root | undefined> = {};

// Renders outside of main react tree (same as portal but no even propagation)
// PREFER USING PORTAL and only use this component when you need to stop propagation of events in the dom
export default function RenderOutside({selector, children}: React.PropsWithChildren<{selector: string}>) {
    function getTooltipElement(create: boolean) {
        let tooltipElement = document.getElementById(`outside-${selector}`);
        if (create && !tooltipElement) {
            tooltipElement = document.createElement('div');
            tooltipElement.setAttribute('id', `outside-${selector}`);
            tooltipElement.className = 'outside-block';
            document.body.appendChild(tooltipElement);
        }

        return tooltipElement;
    }

    useLayoutEffect(() => {
        let root = reactRoots[selector];
        if (!root) {
            let tooltipElement = getTooltipElement(true);
            if (tooltipElement) {
                root = createRoot(tooltipElement);
                reactRoots[selector] = root;
            }
        }

        if (root) {
            root.render(children);
        }
    });

    useOnce(() => {
        return () => {
            const root = reactRoots[selector];
            reactRoots[selector] = undefined;
            if (root) {
                setTimeout(() => {
                    root.unmount();
                    let tooltipElement = getTooltipElement(false);
                    if (tooltipElement) {
                        document.body.removeChild(tooltipElement);
                    }
                });
            }
        };
    });

    return null;
}
