import {Error} from './model';

export const ErrNotSupported: Error = {
    code: 1,
    text: 'Browser is not supported',
};

export const ErrCantSign: Error = {
    code: 2,
    text: 'Can not sign request',
};

export const ErrCantGetUploadID: Error = {
    code: 3,
    text: 'Can not get upload ID',
};

export const ErrCantUploadPart: Error = {
    code: 4,
    text: 'Can not upload part',
};

export const ErrCantComplete: Error = {
    code: 5,
    text: 'Can not complete upload',
};

export const ErrMissingUploader: Error = {
    code: 6,
    text: 'Can not find uploader',
};

export const ErrCantAbort: Error = {
    code: 7,
    text: 'Can not abort upload',
};

export const ErrAborted: Error = {
    code: 8,
    text: 'Upload aborted by user',
};
