import React, {forwardRef} from 'react';
import {FloatingMenuItem} from 'lib/floating/FloatingMenu';
import Styles from './menu.jss';
import {MenuElementProps} from './model';
import {StyleDeclaration} from 'aphrodite';

export interface MenuItemProps {
    styles?: Array<StyleDeclaration | null>;
    state?: {
        disabled?: boolean;
        active?: boolean;
    };
    settings?: {
        keepOpenOnClick?: boolean;
        big?: boolean;
    };
}

export const MenuItem = forwardRef<HTMLDivElement, MenuElementProps & MenuItemProps>(
    ({styles, ...rest}, forwaredRef) => {
        return (
            <FloatingMenuItem
                {...rest}
                ref={forwaredRef}
                styles={[
                    Styles.menu_item,
                    rest.settings?.big ? Styles.menu_item_big : null,
                    rest.state?.active ? Styles.menu_item_active : null,
                    rest.state?.disabled ? Styles.menu_item_disabled : null,
                    styles || null,
                ]}
            />
        );
    }
);
