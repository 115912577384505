import {CreateSheet, GetColor, css} from 'aphrodite';
import {Menu} from 'packages/elements/menu';
import MenuStyles from 'packages/elements/menu/menu.jss';
import {useState} from 'react';
import {routing} from 'src/runtime/router';
import {session} from 'src/runtime/session';
import {MenuButton} from './MenuButton';
import useMaxWidth from 'lib/hooks/useMaxWidth';

export const SessionDropdown: React.FC = () => {
    const [isStateOpen, setIsStateOpen] = useState<boolean>(false);
    const isLess640px = useMaxWidth(640);

    if (session.user.guid === '') {
        return null;
    }

    return (
        <Menu
            trigger={<MenuButton isActive={isStateOpen} />}
            onStatusChange={setIsStateOpen}
            styles={{
                floatingBlock: Styles.dropdown,
            }}
            event="click"
            place="bottom-end"
            settings={{
                closeOn: {
                    clickFloatingBlock: true,
                },
            }}
        >
            <div className={css(Styles.title)}>{session.user.email}</div>
            <a
                className={css(MenuStyles.menu_item)}
                href={'/account'}
                onClick={routing.OpenPage('/account')}
            >
                Account
            </a>
            {session.extra.su?.has_console_access && (
                <a
                    className={css(MenuStyles.menu_item)}
                    href={'/console'}
                    onClick={routing.OpenPage('/console')}
                >
                    Console
                </a>
            )}
            {isLess640px && (
                <>
                    <div className={css(Styles.divider)}></div>
                    <a
                        className={css(MenuStyles.menu_item)}
                        href="/blog"
                        onClick={routing.OpenPage('/blog')}
                    >
                        Blog
                    </a>
                    <a
                        className={css(MenuStyles.menu_item)}
                        href="/pricing"
                        onClick={routing.OpenPage('/pricing')}
                    >
                        Pricing
                    </a>
                </>
            )}

            <div className={css(Styles.divider)}></div>
            <a
                className={css(MenuStyles.menu_item)}
                href="/sign-out"
            >
                Log out
            </a>
        </Menu>
    );
};

const Styles = CreateSheet({
    title: {
        padding: 10,
        lineHeight: 'normal',
        fontSize: '0.875rem',
        fontWeight: 600,
        color: GetColor('Primary'),
    },
    dropdown: {
        minWidth: 250,
        padding: 10,
        borderRadius: 16,
        '@media(max-width: 380px)': {
            position: 'fixed',
            top: '0 !important',
            bottom: '0 !important',
            left: '0 !important',
            right: '0 !important',
            maxHeight: 'initial !important',
            transform: 'none !important',
            borderRadius: 0,
        },
    },
    divider: {
        margin: '15px 0 10px 0',
        height: 1,
        background: GetColor('StrokeColor'),
    },
});
