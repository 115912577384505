import {CreateSheet, GeneratedStyles, GetColor, css} from 'aphrodite';
import {routing} from 'src/runtime/router';

type NavCardProps = {
    title?: string;
    description?: string;
    href: string;
    icon: (className?: string) => React.ReactNode;
    onClick?: () => void;
    styles?: {
        container?: GeneratedStyles[];
    };
};

const NavCard: React.FC<NavCardProps> = ({title, description, href, icon, onClick, styles}) => {
    const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        routing.OpenPage(href)(e);
        onClick && onClick();
    };

    return (
        <a
            href={href}
            onClick={onLinkClick}
            className={css(
                Styles.navCard,
                window.location.pathname === href && Styles.navCardActive,
                styles?.container
            )}
        >
            {icon(css(Styles.navCardIcon))}
            <div className={css(Styles.navCardDescription)}>
                {title && <div className={css(Styles.navCardDescriptionHeading)}>{title}</div>}
                {description && <div className={css(Styles.navCardDescriptionContent)}>{description}</div>}
            </div>
        </a>
    );
};
export default NavCard;

const Styles = CreateSheet({
    navCard: {
        gap: 15,
        padding: 20,
        borderRadius: 20,
        background: GetColor('TopLayerObjectBg'),
        display: 'flex',
        flexDirection: 'column',
        transition: 'background .15s ease-in',
        border: `1px solid ${GetColor('StrokeColor')}`,
        '@media(max-width: 640px)': {
            flexDirection: 'row',
        },
        ':hover': {
            background: GetColor('ButtonBgHover'),
        },

        textDecoration: 'none',
        color: GetColor('Primary'),
    },
    navCardActive: {
        outline: `2px solid ${GetColor('Accent1')}`,
    },
    navCardIcon: {
        flexShrink: 0,
        width: 36,
        height: 36,
        color: GetColor('Accent1'),
        '@media(max-width: 640px)': {
            width: 24,
            height: 24,
        },
    },
    navCardDescription: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 5,
    },
    navCardDescriptionHeading: {
        fontSize: '1.125rem',
        fontWeight: 700,
        lineHeight: '120%',
    },
    navCardDescriptionContent: {
        color: GetColor('Secondary'),
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '140%',
    },
});
