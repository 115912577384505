import {css} from 'aphrodite';
import useSimpleError from 'lib/hooks/useSimpleError';
import useWaitFor from 'lib/hooks/useWaitFor';
import {LoadButton} from 'packages/elements/button';
import PasswordInput from 'packages/elements/textfields/PasswordInput';
import TextField from 'packages/elements/textfields/TextField';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {useState} from 'react';
import {routing} from 'src/runtime/router';
// import LoginWithSocial from './LoginWithSocial';
import Styles from './common.jss';
import {ReactComponent as Warning} from 'src/icons/warning.svg';
import {useAuthStore} from './store';
// import {ThirdPartyLoginResponse, useAuthStore} from './store';

export default function SignInPage() {
    const [email, setEmail] = useAuthStore(state => [state.email, state.setEmail]);
    const [loginWithPassword] = useAuthStore(state => [state.loginWithPassword]);
    const [password, setPassword] = useState('');
    const [hasErr, setErr] = useSimpleError<'email' | 'password' | 'common', string>();
    const [isLoading, withLoading] = useWaitFor();

    async function onSubmitSignIn(e: React.FormEvent) {
        e.preventDefault();

        if (email.trim() === '') {
            setErr('email', 'Email is required');
        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
            setErr('email', 'Please enter a valid email address');
        } else if (password.trim() === '') {
            setErr('password', 'Password is required');
        } else {
            const res = await withLoading(loginWithPassword(email, password));
            if (res) {
                setErr('common', res.message);
            }
        }
    }

    // function OnCompleteSocialsSignUp(data: ThirdPartyLoginResponse) {
    //     if (data.error_text) {
    //         setErr('common', data.error_text);
    //     }
    // }

    return (
        <div className={css(Styles.containerMiddle)}>
            <form
                className={css(Styles.form)}
                onSubmit={onSubmitSignIn}
            >
                <h1 className={css(Styles.formTitle)}>Sign In</h1>
                {/* <LoginWithSocial
                    allowSignUp={false}
                    onComplete={OnCompleteSocialsSignUp}
                /> */}
                <div className={css(Styles.inputs)}>
                    {/* <div className={css(Styles.inputsTitle)}>or Sign In with email</div> */}
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            type="text"
                            label="Email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={e => {
                                setErr('email', false);
                                setErr('common', false);
                                setEmail(e.target.value);
                            }}
                            error={hasErr('email') || undefined}
                            styles={{
                                element: Styles.input,
                            }}
                        />
                    </div>
                    <div className={css(Styles.inputContainer)}>
                        <PasswordInput
                            label="Password"
                            placeholder="Password"
                            value={password}
                            onChange={e => {
                                setErr('password', false);
                                setErr('common', false);
                                setPassword(e.target.value);
                            }}
                            error={hasErr('password') || undefined}
                            styles={{
                                element: Styles.input,
                            }}
                        />
                        <div className={css(Styles.resetPasswordLinkContainer)}>
                            <a
                                href="/reset-password"
                                onClick={routing.OpenPage('/reset-password')}
                                className={css(Styles.agreeLink)}
                            >
                                Reset password
                            </a>
                        </div>
                    </div>
                    <SmoothLine>
                        {hasErr('common') ? (
                            <div className={css(Styles.errorText, Styles.errorContainer)}>
                                <Warning
                                    width="14"
                                    height="14"
                                    role="img"
                                    title="Error"
                                    className={css(Styles.errorContainerIcon)}
                                />
                                <span>{hasErr('common')}</span>
                            </div>
                        ) : undefined}
                    </SmoothLine>
                    <LoadButton
                        type="submit"
                        variant="FilledAccent1-Big"
                        styles={[
                            Styles.button,
                            email.trim() === '' && password.trim() ? Styles.disabledBtn : undefined,
                        ]}
                        loading={isLoading}
                    >
                        Sign In
                    </LoadButton>
                </div>
                <div className={css(Styles.switch)}>
                    New to Whitetail AI?
                    <a
                        href="/sign-up"
                        onClick={routing.OpenPage('/sign-up')}
                        className={css(Styles.switchLink)}
                    >
                        Sign Up
                    </a>
                </div>
            </form>
        </div>
    );
}
