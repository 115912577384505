import {CreateSheet, GetColor} from 'aphrodite';

export const WithCustomScrollbar = CreateSheet({
    scrollbar: {
        overflow: 'auto',
        '::-webkit-scrollbar': {
            width: 5,
            height: 5,
        },
        '::-webkit-scrollbar-track': {
            background: 'transparent',
        },

        '::-webkit-scrollbar-thumb': {
            background: GetColor('StrokeColor'),
            borderRadius: 3,
        },
    },
}).scrollbar;
