import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    page: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
    },
    content: {
        paddingTop: 70,
        flexGrow: 1,
        '@media(max-width: 800px)': {
            paddingTop: 60,
        },
    },
    container: {
        width: 1280,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 40px',
        '@media(max-width: 800px)': {
            padding: '0 20px',
        },
        '@media(max-width: 640px)': {
            padding: '0 15px',
        },
    },
    hero: {
        padding: '40px 0',
    },
    eyeBrow: {
        margin: 0,
        fontSize: '1rem',
        fontWeight: 600,
        color: GetColor('Gray'),
    },
    title: {
        margin: '15px 0',
        fontSize: '2.75rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        lineHeight: 1.2,
        '@media(max-width: 640px)': {
            fontSize: '1.75rem',
        },
    },
    heroText: {
        margin: 0,
        fontSize: '1.25rem',
        fontWeight: 500,
        color: GetColor('Secondary'),
        '@media(max-width: 640px)': {
            fontSize: '1rem',
        },
    },
    row: {
        display: 'flex',
        marginBottom: 40,
        '@media(max-width: 950px)': {
            margin: '0 -10px 40px -10px',
        },
        '@media(max-width: 800px)': {
            display: 'block',
            margin: '0 0 40px 0',
        },
    },
    col: {
        flex: '1 0 0',
        '@media(max-width: 950px)': {
            margin: '0 10px',
        },
        '@media(max-width: 800px)': {
            maxWidth: 640,
            margin: '20px auto 0 auto',
        },
    },
    plan: {
        position: 'relative',
        borderRadius: 20,
        '@media(max-width: 950px)': {
            border: `1px solid ${GetColor('StrokeColor')}`,
        },
    },
    planTitles: {
        minWidth: 420,
        '@media(max-width: 1100px)': {
            minWidth: 250,
        },
        '@media(max-width: 950px)': {
            display: 'none',
        },
    },
    recommended: {
        background: GetColor('LightGrayBg'),
        '@media(max-width: 950px)': {
            border: 0,
        },
    },
    planHeader: {
        position: 'sticky',
        top: 70,
        height: 140,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.2,
        background: GetColor('PageBg'),
        '@media(max-width: 800px)': {
            top: 60,
        },
    },
    planHeaderBlock: {
        margin: '-1 0 0 0',
        padding: '20px 20px 15px 20px',
        color: GetColor('Secondary'),
        background: GetColor('PageBg'),
        borderRadius: '20px 20px 0px 0px',
        '@media(max-width: 950px)': {
            border: `1px solid ${GetColor('StrokeColor')}`,
            borderBottom: 0,
        },
    },
    planHeaderTitles: {
        position: 'sticky',
        top: 70,
        height: 140,
        padding: '15px 20px 15px 0',
        fontSize: '1.875rem',
        fontWeight: 600,
        color: GetColor('Primary'),
        background: GetColor('PageBg'),
        ':nth-child(1n) span': {
            display: 'block',
            maxWidth: 300,
        },
        '@media(max-width: 1100px)': {
            padding: '15px 20px 15px 0',
        },
        '@media(max-width: 800px)': {
            top: 60,
        },
    },
    recommendedLabel: {
        position: 'absolute',
        display: 'flex',
        top: 10,
        right: 10,
        padding: '5px 7px',
        fontSize: '0.8125rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('Primary'),
        alignItems: 'center',
        borderRadius: 10,
        background: GetColor('PageBg'),
        zIndex: 2,
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 5,
        },
        '@media(max-width: 1200px)': {
            ':nth-child(1n) span': {
                display: 'none',
            },
            ':nth-child(1n) svg': {
                display: 'block',
                marginRight: 0,
            },
        },
        '@media(max-width: 800px)': {
            ':nth-child(1n) span': {
                display: 'block',
            },
            ':nth-child(1n) svg': {
                display: 'block',
                marginRight: 5,
            },
        },
    },
    planHeaderTitle: {
        margin: '0 0 7px 0',
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: GetColor('Primary'),
    },
    recommenedHeader: {
        background: GetColor('LightGrayBg'),
        '@media(max-width: 950px)': {
            border: 0,
        },
    },
    startBtn: {
        marginTop: 15,
        '@media(max-width: 800px)': {
            width: '100%',
        },
    },
    growthBtn: {
        color: GetColor('NegativeTextColor'),
        background: GetColor('Primary'),
        ':hover': {
            color: GetColor('NegativeTextColor'),
            background: GetColor('Primary'),
        },
    },
    lists: {
        '@media(max-width: 800px)': {
            display: 'flex',
        },
        '@media(max-width: 640px)': {
            display: 'block',
        },
    },
    listsItem: {
        '@media(max-width: 800px)': {
            width: '50%',
            minWidth: '50%',
            flexShrink: 0,
        },
        '@media(max-width: 640px)': {
            width: '100%',
            minWidth: '100%',
        },
    },
    list: {
        margin: 0,
        padding: '0 0 20px 0',
        listStyle: 'none',
    },
    listItem: {
        display: 'flex',
        height: 50,
        padding: '10px 20px',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        alignItems: 'center',
        '@media(max-width: 1100px)': {
            height: 75,
        },
        '@media(max-width: 950px)': {
            padding: '7px 20px',
            fontSize: '1rem',
            fontWeight: 500,
            alignItems: 'flex-start',
        },
        '@media(max-width: 800px)': {
            height: 'auto',
        },
    },
    listItemFirst: {
        padding: '10px 20px 10px 0',
    },
    listItemInfo: {
        marginLeft: 10,
        ':nth-child(1n) svg': {
            display: 'block',
            marginBottom: -2,
            color: GetColor('Gray'),
        },
    },
    tooltipLink: {
        color: GetColor('WhiteTextColor'),
        textDecoration: 'underline',
    },
    listItemTitle: {
        display: 'none',
        alignItems: 'center',
        '@media(max-width: 950px)': {
            display: 'flex',
        },
        ':nth-child(1n) svg': {
            display: 'block',
            marginLeft: 10,
            flexShrink: 0,
            color: GetColor('Gray'),
        },
    },
    listIcon: {
        display: 'block',
        color: GetColor('Gray'),
        flexShrink: 0,
        '@media(max-width: 1100px)': {
            marginRight: 7,
        },
        '@media(max-width: 950px)': {
            width: 18,
            height: 18,
            marginTop: 3,
        },
    },
    listIconActive: {
        color: GetColor('Primary'),
    },
    listIconExtra: {
        display: 'none',
        '@media(max-width: 950px)': {
            display: 'block',
        },
    },
    listItemResult: {
        fontWeight: 500,
        '@media(max-width: 950px)': {
            display: 'none',
        },
    },
    listItemTopic: {
        height: 65,
        padding: '25px 20px 10px 20px',
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
        '@media(max-width: 1100px)': {
            height: 80,
        },
        '@media(max-width: 950px)': {
            height: 'auto',
            fontSize: '1rem',
        },
    },
    listItemTopicInside: {
        opacity: 0,
        '@media(max-width: 950px)': {
            opacity: 1,
        },
    },
});
