import {SmoothHeight} from 'packages/motion/SmoothHeight.react';
import {Fragment, PropsWithChildren, useEffect, useState} from 'react';
import Spinner, {SpinnerProps} from './spinner.react';
import {SpinnerTheme} from './spinner.theme';
import {css, CreateSheet, StyleDeclarationValue} from 'aphrodite';
import useTimeout from 'lib/hooks/useTimeout';

export type {SpinnerTheme};

const defaultDuration = 1000;

type WithSpinnerProps = PropsWithChildren<
    SpinnerProps & {
        suspense: boolean;
        minDuration?: number;
        grow?: boolean;
        showContentOnLoading?: boolean;
        spinnerHeight?: number | '100%';
        styles?: StyleDeclarationValue;
    }
>;

export default function WithSpinner(props: WithSpinnerProps) {
    if (props.minDuration === 0) {
        return <SpinnerWithDelayShow {...props} />;
    } else {
        return <SpinnerWithDelayHide {...props} />;
    }
}

function SpinnerWithDelayHide({minDuration, suspense, ...props}: WithSpinnerProps) {
    const [IsPassed, startSpinner] = useTimeout(minDuration || defaultDuration);
    const [ShowSpinner, SetShowSpinner] = useState(suspense);

    useEffect(() => {
        if (suspense && !ShowSpinner) {
            SetShowSpinner(true);
            startSpinner();
        } else if (!suspense && ShowSpinner && IsPassed) {
            SetShowSpinner(false);
        }
    }, [startSpinner, IsPassed, ShowSpinner, suspense]);

    return (
        <SpinnerRender
            ShowSpinner={ShowSpinner}
            {...props}
        />
    );
}

function SpinnerWithDelayShow({minDuration, suspense, ...props}: WithSpinnerProps) {
    const [IsPassed] = useTimeout(300);
    const [ShowSpinner, SetShowSpinner] = useState(false);

    useEffect(() => {
        if (IsPassed) {
            SetShowSpinner(suspense ? true : false);
        }
    }, [IsPassed, suspense]);

    return (
        <SpinnerRender
            ShowSpinner={ShowSpinner}
            {...props}
        />
    );
}

function SpinnerRender({
    grow,
    ShowSpinner,
    showContentOnLoading,
    spinnerHeight,
    children,
    styles,
    ...props
}: PropsWithChildren<
    SpinnerProps & {
        grow?: boolean;
        ShowSpinner: boolean;
        showContentOnLoading?: boolean;
        spinnerHeight?: number | '100%';
        styles?: StyleDeclarationValue;
    }
>) {
    return (
        <Fragment>
            {grow ? (
                <SmoothHeight
                    {...{
                        ignore: false,
                        status: true,
                        height: ShowSpinner ? 0 : 'auto',
                    }}
                >
                    {(!ShowSpinner || showContentOnLoading) && children ? children : <div />}
                </SmoothHeight>
            ) : (
                <Fragment>{(!ShowSpinner || showContentOnLoading) && children ? children : <div />}</Fragment>
            )}
            {ShowSpinner && (
                <div className={css(Styles.spinner, styles)}>
                    <Spinner
                        {...{height: spinnerHeight || 100}}
                        {...props}
                    />
                </div>
            )}
        </Fragment>
    );
}

const Styles = CreateSheet({
    spinner: {
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
