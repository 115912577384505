import {routing} from 'src/runtime/router';
// icons
import {ReactComponent as LogoIcon} from 'src/icons/logo/logo.svg';
import {ReactComponent as MenuIcon} from 'src/icons/menu.svg';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import {ReactComponent as ArrowRightIcon} from 'src/icons/arrow-right.svg';
// styles
import {GeneratedStyles, css} from 'aphrodite';
import Styles from './header.jss';
import {routerIsActivePage, useRouterGetActivePagesIDs} from 'src/runtime/router/hooks';
import {useEffect, useState} from 'react';
// components
import {MessageButton} from './components/MessageButton';
import {SessionDropdown} from './components/SessionDropdown';
import {AuthButtons} from './components/AuthButtons';
import useMaxWidth from 'lib/hooks/useMaxWidth';
import {session} from 'src/runtime/session';
import {HeaderUtilityStyles} from './sizes';
import {useScrollLocker} from 'lib/hooks/useScrollLocker';
import {MakeButton} from 'packages/elements/button';
import {GetProjectPageURL} from 'packages/projects/helpers/GetProjectPageURL';
import Tooltip from 'packages/tooltip';
import {ReactComponent as LockIcon} from 'src/icons/lock.svg';

type HeaderProps = {
    styles?: {
        container?: GeneratedStyles;
    };
};

export default function Header({styles}: HeaderProps) {
    const [menuOpened, setMenuOpened] = useState(false);
    const isLess800px = useMaxWidth(800);

    const isLoggedIn = session.user.guid !== '';

    return (
        <header
            className={css(
                Styles.header,
                routerIsActivePage('landing') && Styles.headerNoBorder,
                HeaderUtilityStyles().relatedHeight,
                styles?.container
            )}
        >
            <Logo />
            <Menu />
            <div className={css(Styles.headerGrow)} />
            {!isLoggedIn && (
                <div className={css(Styles.headerPart)}>
                    <button
                        type="button"
                        className={css(Styles.menuBtn)}
                        onClick={() => setMenuOpened(!menuOpened)}
                    >
                        <MenuIcon
                            width="20"
                            height="20"
                            className={css(Styles.menuBtnIcon, !menuOpened && Styles.menuBtnIconActive)}
                        />
                        <CrossIcon
                            width="20"
                            height="20"
                            className={css(Styles.menuBtnIcon, menuOpened && Styles.menuBtnIconActive)}
                        />
                    </button>
                </div>
            )}
            <div className={css(Styles.user)}>
                {!isLess800px && <AuthButtons />}
                <MessageButton />
                <SessionDropdown />
            </div>
            <MenuMobile
                menuOpened={menuOpened}
                closeMenu={() => setMenuOpened(false)}
            />
        </header>
    );
}

export const Logo = () => {
    const isLess800px = useMaxWidth(800);
    return (
        <a
            className={css(Styles.logo)}
            href="/"
            onClick={routing.OpenPage('')}
        >
            <LogoIcon />
            {isLess800px ? '' : 'Whitetail AI'}
        </a>
    );
};

export const Menu = () => {
    const isLess640px = useMaxWidth(640);
    const activePages = useRouterGetActivePagesIDs();

    const company = session.extra.companies?.[0];
    const project = company?.projects?.[0];

    return (
        <ul className={css(Styles.menu)}>
            {session.user.guid !== '' && company && (
                <li>
                    <Tooltip
                        interactive={true}
                        distance={10}
                        styles={Styles.customTip}
                        disabled={project !== undefined}
                        content={
                            <div className={css(Styles.customTipContent)}>
                                <LockIcon className={css(Styles.customTipContentIcon)} />
                                <div className={css(Styles.customTipContentText)}>
                                    <div className={css(Styles.customTipContentTitle)}>Your project unavailable</div>
                                    <div className={css(Styles.customTipContentHint)}>
                                        To create a project, start a conversation with Whitetail AI.
                                    </div>
                                </div>
                                <button
                                    className={MakeButton('FilledAccent1-Small')}
                                    onClick={routing.OpenPage(GetProjectPageURL(company.uri, project?.uri))}
                                >
                                    Go to Whitetail GPT
                                </button>
                            </div>
                        }
                    >
                        <button
                            className={MakeButton(
                                'Primary-Default',
                                Styles.myProjectBtn,
                                !project && Styles.btnDisabled
                            )}
                            onClick={e => {
                                if (project) routing.OpenPage(GetProjectPageURL(company.uri, project?.uri))(e);
                            }}
                        >
                            My project
                        </button>
                    </Tooltip>
                </li>
            )}
            {!isLess640px && (
                <>
                    {' '}
                    <li>
                        <a
                            href="/blog"
                            className={css(
                                Styles.menuItemLink,
                                activePages.find(p => p === 'blog') && Styles.menuItemLinkActive
                            )}
                            onClick={routing.OpenPage('blog')}
                        >
                            Blog
                        </a>
                    </li>
                    <li>
                        <a
                            href="/pricing"
                            className={css(
                                Styles.menuItemLink,
                                activePages.find(p => p === 'pricing') && Styles.menuItemLinkActive
                            )}
                            onClick={routing.OpenPage('pricing')}
                        >
                            Pricing
                        </a>
                    </li>
                </>
            )}
        </ul>
    );
};

export const MenuMobile = ({menuOpened, closeMenu}: {menuOpened: boolean; closeMenu: () => void}) => {
    const [lock, unlock] = useScrollLocker();

    const open = (link: string) => (e: React.MouseEvent) => {
        routing.OpenPage(link)(e);
        closeMenu();
    };

    useEffect(() => {
        if (menuOpened) {
            lock();
            return;
        }

        unlock();
    }, [lock, menuOpened, unlock]);

    return (
        <div className={css(Styles.menuFull, menuOpened && Styles.menuFullActive, HeaderUtilityStyles().relatedTop)}>
            <ul className={css(Styles.menuFullList)}>
                <li>
                    <a
                        href="/blog"
                        className={css(Styles.menuFullLink)}
                        onClick={open('blog')}
                    >
                        <span>Blog</span>
                        <ArrowRightIcon
                            width="16"
                            height="16"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="/pricing"
                        className={css(Styles.menuFullLink)}
                        onClick={open('pricing')}
                    >
                        <span>Pricing</span>
                        <ArrowRightIcon
                            width="16"
                            height="16"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="/contact-us"
                        className={css(Styles.menuFullLink)}
                        onClick={open('contact-us')}
                    >
                        <span>Contact us</span>
                        <ArrowRightIcon
                            width="16"
                            height="16"
                        />
                    </a>
                </li>
            </ul>
            <div className={css(Styles.footerBlock)}>
                <AuthButtons fullWidth={true} />
                <div className={css(Styles.footerContact)}>
                    <span>Contact us</span>
                    <a
                        href="mailto:info@whitetail.ai"
                        target="_blank"
                        rel="noreferrer"
                        className={css(Styles.contactLink)}
                    >
                        info@whitetail.ai
                    </a>
                </div>
            </div>
        </div>
    );
};
