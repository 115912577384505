import * as r from 'runtypes';
import {ChartType} from '../chart_type';

export {default as UnknownTable} from './UnknownTable';

/* UNKNOWN CHART */
export const UnknownChartValueRuntype = r.Record({
    name: r.String,
    value: r.Number,
});
export type UnknownChartValue = r.Static<typeof UnknownChartValueRuntype>;

export const UnknownChartRuntype = r.Record({
    chart_type: r.Literal(ChartType.Unknown),
    data: r.Array(r.Dictionary(r.Unknown, r.String)),
});
export type UnknownChart = r.Static<typeof UnknownChartRuntype>;
