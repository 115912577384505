import {forwardRef} from 'react';
import {FloatingDropDownCore, DropDownRef, DropDownElProps, DropDownProps} from 'lib/floating/FloatingDropdown';
import Styles from './FloatingDropDown.jss';

export type {DropDownRef, DropDownProps};

const FloatingDropDown = forwardRef<DropDownRef, DropDownElProps & DropDownProps>(
    ({styles, trigger, settings, ...rest}, forwaredRef) => {
        return (
            <FloatingDropDownCore
                {...rest}
                ref={forwaredRef}
                settings={{
                    ...settings,
                    distance: settings?.distance || 4,
                }}
                trigger={trigger}
                styles={{
                    trigger: [styles?.trigger || null],
                    floatingBlock: [
                        Styles.block,
                        settings?.placement?.useSize ? Styles.floatingOverflow : null,
                        styles?.floatingBlock || null,
                    ],
                }}
            />
        );
    }
);

export default FloatingDropDown;
