import React, {useContext} from 'react';
import {css} from 'aphrodite';
import TooltipContext from './TooltipContext';
import Styles from './Tooltip.jss';
import {TriggerProps} from './model';

export default function TooltipTrigger({children, tabIndex}: React.PropsWithChildren<TriggerProps>) {
    const context = useContext(TooltipContext);

    if (React.isValidElement(children) && isDOMTypeElement(children)) {
        return React.cloneElement(
            children,
            context!.getReferenceProps({
                ref: context!.refs.setReference,
                tabIndex: tabIndex,
                ...children.props,
                'data-state': context!.open ? 'open' : 'closed',
            })
        );
    } else {
        return (
            <span
                ref={context!.refs.setReference}
                data-state={context!.open ? 'open' : 'closed'}
                className={css(Styles.stub)}
                tabIndex={tabIndex}
                {...context!.getReferenceProps()}
            >
                {children}
            </span>
        );
    }
}

function isDOMTypeElement(element: React.ReactNode) {
    return React.isValidElement(element) && typeof element.type === 'string';
}
