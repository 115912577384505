import {useEffect, useRef, useState} from 'react';
import {routing} from 'src/runtime/router';
import useInViewport from 'lib/hooks/useInViewport';
import useOnce from 'lib/hooks/useOnce';
import useScrollDirection from 'lib/hooks/useScrollDirection';
import {useHistory} from 'src/runtime/router/hooks';
import {useQueryParser} from 'typed-query-parser';
// store
import {BlogPostMetadata, SortByTitles} from 'packages/blog/model';
import {Data} from 'packages/blog/store/view_page/createGeneral';
import {useBlogStore} from 'packages/blog/store/view_page/useBlogStore';
// components
import WindowVirtualList from 'lib/virtual/WindowVirtualList';
import Checkbox from 'packages/elements/checkbox/Checkbox';
import Header from '../header/header';
import {Menu, MenuItem} from 'packages/elements/menu';
import TextField from 'packages/elements/textfields/TextField';
import RadioButton from 'packages/elements/radio/RadioButton';
import WithSpinner from 'packages/spinners/SmartSpinner.react';
import Spinner from 'packages/spinners/spinner.react';
import Card from './components/Card';
import Footer from '../footer/footer';
import Drawer from 'packages/elements/drawer/drawer';
// icons
import {ReactComponent as SortIcon} from 'src/icons/sort.svg';
import {ReactComponent as AddIcon} from 'src/icons/add.svg';
import {ReactComponent as ArrowDownIcon} from 'src/icons/arrow-down.svg';
// import {ReactComponent as ArrowRightLongIcon} from 'src/icons/arrow-right-long.svg';
import {ReactComponent as SearchIcon} from 'src/icons/search-icon.svg';
import {ReactComponent as SearchResultsIcon} from 'src/icons/search-results.svg';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
// styles
import {css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import Styles from './blog.jss';
import {session} from 'src/runtime/session';

export default function ResourcesPage() {
    const Data = useBlogStore(state => state.data);
    const [Init, LoadData] = useBlogStore(state => [state.init, state.loadData]);
    const [storeQuery] = useBlogStore(state => [state.storeQuery]);

    const history = useHistory();

    const [queryData, setQuery] = useQueryParser(
        history,
        types => ({
            order_by: types.literal(...(Object.keys(SortByTitles) as (keyof typeof SortByTitles)[]))('published_on'),
            order_dir: types.literal('ASC', 'DESC')('DESC'),
            show_hidden: types.number(0),
            q: types.string(''),
        }),
        {onUpdateAction: 'replace'}
    );

    useOnce(() => {
        Init(queryData, setQuery);
    });

    useEffect(() => {
        if (Data.is_initialized) {
            window.scrollTo(0, 0);
            LoadData(storeQuery);
        }
    }, [storeQuery, LoadData, Data.is_initialized]);

    return (
        <>
            <Header />
            <section className={css(Styles.container)}>
                <Hero />
            </section>

            <ControlsBlock />

            <section className={css(Styles.container)}>
                <WithSpinner
                    theme={PrimaryTheme}
                    suspense={Data.is_loading}
                    minDuration={0}
                >
                    {Data.is_loaded ? <CardsBlock data={Data} /> : null}
                </WithSpinner>
            </section>

            <Footer />
        </>
    );
}

function CardsBlock({data}: {data: Data}) {
    const [queryData, setData] = useBlogStore(state => [state.storeQuery, state.setData]);

    function onCardChanged(item: BlogPostMetadata) {
        const newItems = queryData.show_hidden
            ? data.items.map(x => (x.guid === item.guid ? item : x))
            : data.items.filter(x => x.guid !== item.guid);
        setData({
            items: {$set: newItems},
        });
    }

    function onCardRemoved(guid: string) {
        setData({
            $merge: {
                items: data.items.filter(x => x.guid !== guid),
                pagination: {
                    ...data.pagination,
                    total_results: data.pagination.total_results - 1,
                },
            },
        });
    }

    if (data.pagination.total_results === 0) {
        return (
            <div className={css(Styles.emptyResults)}>
                <SearchResultsIcon />
                <p>No results found</p>
            </div>
        );
    }

    return (
        <div className={css(Styles.cards)}>
            {session.extra.su?.can_manage_blog && <FilterBlock />}

            <div className={css(Styles.count)}>
                {data.pagination.total_results} article{data.pagination.total_results !== 1 ? 's' : ''}
                {queryData.q !== '' ? (
                    <>
                        {' '}
                        found for <strong className={css(Styles.countKey)}>{queryData.q}</strong>
                    </>
                ) : (
                    ''
                )}
            </div>

            <WindowVirtualList
                itemsCount={data.pagination.next_page ? data.items.length + 1 : data.items.length}
                estimatedRowHeight={300}
                render={index => {
                    const isLoaderRow = index > data.items.length - 1;
                    if (isLoaderRow) {
                        return <CardsLoader />;
                    }
                    return (
                        <>
                            <Card
                                key={data.items[index].guid}
                                item={data.items[index]}
                                onChange={onCardChanged}
                                onRemove={onCardRemoved}
                            />
                            {index !== data.items.length - 1 && <hr className={css(Styles.cardDivider)} />}
                        </>
                    );
                }}
                overscan={5}
            />
        </div>
    );
}

function CardsLoader() {
    const [load, query] = useBlogStore(state => [state.loadData, state.storeQuery]);
    const data = useBlogStore(state => state.data);

    useOnce(() => {
        load({
            ...query,
            page_id: data.is_loaded ? data.pagination.current_page + 1 : 1,
        });
    });

    return (
        <div>
            <Spinner
                theme={PrimaryTheme}
                height={20}
            />
        </div>
    );
}

function Hero() {
    return (
        <section className={css(Styles.header)}>
            <h1 className={css(Styles.headerTitle)}>Blog</h1>
            <h2 className={css(Styles.headerSubTitle)}>
                Keep up with the latest industry trends, techniques, and business management to stay ahead of the curve!
            </h2>
        </section>
    );
}

function ControlsBlock() {
    const hrRef = useRef<HTMLHRElement>(null);
    const [Layout, SetLayout] = useBlogStore(state => [state.layout, state.setLayout]);

    const scrollDirection = useScrollDirection(50);

    const isDividerInView = useInViewport(hrRef, {rootMargin: '-70px'});

    function toggleBottomDrawer() {
        SetLayout({showBottomDrawer: {$set: !Layout.showBottomDrawer}});
    }

    return (
        <>
            <section
                className={css(
                    Styles.stickyContainer,
                    scrollDirection === 'up' && !isDividerInView ? Styles.showControls : false
                )}
            >
                <div className={css(Styles.container)}>
                    <nav className={css(Styles.controlsBlock)}>
                        <Search />
                        <div className={css(Styles.rightControls)}>
                            <SortByBlock />
                            <OrderByBlock />
                        </div>
                        <div
                            className={css(Styles.sortBtn)}
                            onClick={toggleBottomDrawer}
                        >
                            <SortIcon
                                width="18"
                                height="18"
                            />
                        </div>
                    </nav>
                </div>
            </section>
            <hr
                ref={hrRef}
                className={css(Styles.pageDivider)}
            />

            <BottomDrawer />
        </>
    );
}

const Search = () => {
    const [queryData, setQuery] = useBlogStore(state => [state.storeQuery, state.setStoreQuery]);

    const [value, setValue] = useState<string>(queryData.q);

    function search() {
        if (value !== queryData.q) {
            setQuery({
                q: value,
            });
        }
    }

    function clearSearch() {
        if (queryData.q !== '') {
            setQuery({
                q: '',
            });
        }
    }

    const clear = () => {
        setValue('');
        clearSearch();
    };

    return (
        <TextField
            type="text"
            label="Search articles"
            labelHidden
            placeholder="Search articles"
            value={value}
            onChange={e => setValue(e.target.value)}
            styles={{
                element: Styles.searchInput,
                aside_wrapper: Styles.searchInputAsideWrapper,
            }}
            aside={{
                left: (
                    <SearchIcon
                        className={css(Styles.searchInputIcon)}
                        role="img"
                        title="Search"
                    />
                ),
                right:
                    value.trim() !== '' ? (
                        <button
                            type="button"
                            className={MakeButton('Transparent-Default', Styles.searchInputClearIcon)}
                            onClick={clear}
                        >
                            <CrossIcon
                                role="img"
                                title="Clear"
                                width="14"
                                height="14"
                            />
                        </button>
                    ) : undefined,
            }}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    search();
                }
            }}
        />
    );
};

function SortByBlock() {
    const [isStateOpen, setIsStateOpen] = useState(false);
    const [query, setQuery] = useBlogStore(state => [state.storeQuery, state.setStoreQuery]);

    return (
        <div className={css(Styles.dropdownBlock)}>
            <label className={css(Styles.dropdownBlockLabel)}>Sort by</label>
            <Menu
                onStatusChange={setIsStateOpen}
                trigger={
                    <TextField
                        label="Sort by"
                        labelHidden
                        value={SortByTitles[query.order_by]}
                        state={{active: isStateOpen}}
                        styles={{
                            element: Styles.dropdownBlockInput,
                        }}
                        readOnly
                        aside={{
                            right: (
                                <ArrowDownIcon
                                    className={css(
                                        Styles.dropdownTriggerIcon,
                                        isStateOpen && Styles.dropdownTriggerIconOpened
                                    )}
                                />
                            ),
                        }}
                    />
                }
                settings={{
                    closeOn: {
                        clickFloatingBlock: true,
                    },
                    placement: {
                        useSize: true,
                        useWidth: true,
                    },
                }}
            >
                {Object.entries(SortByTitles).map(([key, title]) => {
                    return (
                        <MenuItem
                            key={key}
                            onClick={() => setQuery({order_by: key as keyof typeof SortByTitles})}
                            state={{
                                active: query.order_by === key,
                            }}
                            settings={{
                                big: true,
                            }}
                        >
                            {title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

function BottomDrawer() {
    const [Layout, SetLayout] = useBlogStore(state => [state.layout, state.setLayout]);
    const [query, setQuery] = useBlogStore(state => [state.storeQuery, state.setStoreQuery]);

    const SortBy = useRef(query.order_by);
    const SortDir = useRef(query.order_dir);

    useEffect(() => {
        SortBy.current = query.order_by;
        SortDir.current = query.order_dir;
    }, [query.order_by, query.order_dir]);

    function closeDrawer() {
        SetLayout({showBottomDrawer: {$set: false}});
    }

    function saveFilter() {
        if (SortBy.current !== query.order_by || SortDir.current !== query.order_dir) {
            setQuery({
                order_by: SortBy.current,
                order_dir: SortDir.current,
            });
        }

        closeDrawer();
    }

    return (
        <Drawer
            isOpened={Layout.showBottomDrawer}
            close={closeDrawer}
            styles={{
                bg: Styles.bottomDrawerBg,
                box: Styles.bottomDrawerBox,
            }}
            position="bottom"
        >
            <div className={css(Styles.bottomDrawerContent)}>
                <div className={css(Styles.drawerTopLine, Styles.drawerInnerContainer)}>
                    <div className={css(Styles.drawerFilterBlock)}>
                        <label className={css(Styles.drawerFilterBlockLabel)}>Sort by</label>
                        {Object.entries(SortByTitles).map(([key, title]) => {
                            return (
                                <RadioButton
                                    key={key}
                                    label={title}
                                    id={key}
                                    name="order_by"
                                    defaultChecked={query.order_by === key}
                                    onChange={() => {
                                        SortBy.current = key as keyof typeof SortByTitles;
                                    }}
                                    styles={{
                                        outer: Styles.radioBtn,
                                    }}
                                />
                            );
                        })}
                    </div>
                    <hr className={css(Styles.drawerDivider)} />
                    <div className={css(Styles.drawerFilterBlock)}>
                        <label className={css(Styles.drawerFilterBlockLabel)}>Order</label>
                        <RadioButton
                            label="Descending"
                            id="order_desc"
                            name="order_dir"
                            defaultChecked={query.order_dir === 'DESC'}
                            styles={{
                                outer: Styles.radioBtn,
                            }}
                            onChange={() => (SortDir.current = 'DESC')}
                        />
                        <RadioButton
                            label="Ascending"
                            id="order_asc"
                            name="order_dir"
                            defaultChecked={query.order_dir === 'ASC'}
                            styles={{
                                outer: Styles.radioBtn,
                            }}
                            onChange={() => (SortDir.current = 'ASC')}
                        />
                    </div>
                </div>
                <div className={css(Styles.drawerBottomLine, Styles.drawerInnerContainer)}>
                    <button
                        className={MakeButton('FilledAccent1-Big', Styles.drawerSaveBtn)}
                        onClick={saveFilter}
                    >
                        Save
                    </button>
                </div>
            </div>
        </Drawer>
    );
}

function OrderByBlock() {
    const [isStateOpen, setIsStateOpen] = useState(false);
    const [query, setQuery] = useBlogStore(state => [state.storeQuery, state.setStoreQuery]);

    return (
        <div className={css(Styles.dropdownBlock)}>
            <label className={css(Styles.dropdownBlockLabel)}>Order</label>
            <Menu
                onStatusChange={setIsStateOpen}
                trigger={
                    <TextField
                        label="Order"
                        labelHidden
                        value={query.order_dir === 'ASC' ? 'Ascending' : 'Descending'}
                        styles={{
                            element: Styles.dropdownBlockInput,
                        }}
                        readOnly
                        aside={{
                            right: (
                                <ArrowDownIcon
                                    className={css(
                                        Styles.dropdownTriggerIcon,
                                        isStateOpen && Styles.dropdownTriggerIconOpened
                                    )}
                                />
                            ),
                        }}
                    />
                }
                settings={{
                    closeOn: {
                        clickFloatingBlock: true,
                    },
                    placement: {
                        useSize: true,
                        useWidth: true,
                    },
                }}
                event="click"
            >
                <MenuItem
                    onClick={() => setQuery({order_dir: 'ASC'})}
                    state={{
                        active: query.order_dir === 'ASC',
                    }}
                    settings={{
                        big: true,
                    }}
                >
                    Ascending
                </MenuItem>
                <MenuItem
                    onClick={() => setQuery({order_dir: 'DESC'})}
                    state={{
                        active: query.order_dir === 'DESC',
                    }}
                    settings={{
                        big: true,
                    }}
                >
                    Descending
                </MenuItem>
            </Menu>
        </div>
    );
}

function FilterBlock() {
    const [query, setQuery] = useBlogStore(state => [state.storeQuery, state.setStoreQuery]);

    return (
        <div className={css(Styles.filterWrapper)}>
            <Checkbox
                label="Show hidden events"
                styles={{root: Styles.filterCheckbox}}
                defaultChecked={query.show_hidden === 1}
                onChange={e => {
                    setQuery({
                        show_hidden: e.target.checked ? 1 : 0,
                    });
                }}
            />

            <button
                className={MakeButton('FilledAccent1-Default', Styles.addBtn)}
                onClick={routing.OpenPage('blog/create')}
            >
                <AddIcon />
                Add new
            </button>
        </div>
    );
}
