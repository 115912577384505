import {css} from 'aphrodite';
import {ReactComponent as ChatIcon} from 'src/icons/chat.svg';
import {routing} from 'src/runtime/router';
import Tooltip from 'packages/tooltip';
import {session} from 'src/runtime/session';
import Styles from '../header.jss';
import {GetProjectPageURL} from 'packages/projects/helpers/GetProjectPageURL';

export const MessageButton: React.FC = () => {
    const company = session.extra.companies?.[0];
    const project = company?.projects?.[0];

    const disabled = !company && !project;

    if (session.user.guid === '') {
        return null;
    }

    return (
        <Tooltip content="Chat">
            <button
                type="button"
                className={css(Styles.menuIconBtn, disabled && Styles.menuIconBtnDisabled)}
                aria-label="Chat"
                disabled={disabled}
                onClick={routing.OpenPage(`${GetProjectPageURL(company!.uri, project?.uri)}${!project ? '' : '/chat'}`)}
            >
                <ChatIcon />
            </button>
        </Tooltip>
    );
};
