import {useLayoutEffect} from 'react';
import {debounce} from 'lodash';

export default function useResize(func?: (e: UIEvent) => void) {
    const onResize = debounce(
        e => {
            func && func(e);
        },
        300,
        {leading: true}
    );

    useLayoutEffect(() => {
        let enabled = typeof func === 'function';
        if (enabled) {
            window.addEventListener('resize', onResize);
        }

        return () => {
            if (enabled) {
                window.removeEventListener('resize', onResize);
            }
        };
    });
}
