import {ChartType} from './chart_type';
import {DonutChartComponent, DonutTableComponent, DonutChartValueRuntype} from './DonutChart';
import {EventsUsersTableComponent, EventsUsersTableValueRuntype} from './EventsUsersTable';
import UnknownTableComponent from './UnknownChart/UnknownTable';
import {Chart} from '.';
import RuntypeGuidlines from './general/RuntypeGuidlines';
import BarChartComponent from './BarChart/BarChart';
import BarChartGuidelines from './BarChart/BarChartGuidelines';
import BarTableComponent from './BarChart/BarTable';
import AreaChartComponent from './AreaChart/AreaChart';
import AreaChartGuidelines from './AreaChart/AreaChartGuidelines';
import AreaTableComponent from './AreaChart/AreaTable';

let chartComponents = {
    [ChartType.Donut]: DonutChartComponent,
    [ChartType.Bar]: BarChartComponent,
    [ChartType.Area]: AreaChartComponent,
    [ChartType.EventsUsersTable]: null,
    [ChartType.Unknown]: null,
};

export function ChartComponent({chart, isCompact}: {chart: Chart; isCompact?: boolean}) {
    const Chart = chartComponents[chart.chart_type];
    if (Chart === null) {
        return null;
    }

    return (
        <Chart
            chart={chart as never}
            isCompact={isCompact}
        />
    );
}

export function HasChartComponent(chart: Chart) {
    return chart ? chartComponents[chart.chart_type] !== null : false;
}

let chartTables = {
    [ChartType.Donut]: DonutTableComponent,
    [ChartType.Bar]: BarTableComponent,
    [ChartType.Area]: AreaTableComponent,
    [ChartType.EventsUsersTable]: EventsUsersTableComponent,
    [ChartType.Unknown]: UnknownTableComponent,
};

export function TableComponent({chart}: {chart: Chart}) {
    const Table = chartTables[chart.chart_type];
    if (Table === null) {
        return <UnknownTableComponent chart={chart as never} />;
    }

    return <Table chart={chart as never} />;
}

export function GuidlinesComponent({chart_type}: {chart_type: ChartType}) {
    if (chart_type === ChartType.Donut) {
        return <RuntypeGuidlines runtype={DonutChartValueRuntype} />;
    } else if (chart_type === ChartType.Bar) {
        return <BarChartGuidelines />;
    } else if (chart_type === ChartType.Area) {
        return <AreaChartGuidelines />;
    } else if (chart_type === ChartType.EventsUsersTable) {
        return <RuntypeGuidlines runtype={EventsUsersTableValueRuntype} />;
    }

    return null;
}
