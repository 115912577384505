import {CreateSheet} from 'aphrodite';
import {ContentBlock, ContentState} from 'draft-js';
import {ModProps} from '../Mods.react';
// components
import IsValidURL from 'lib/helpers/IsValidURL';
import {Body, ComponentProps, Footer, Header, Lightbox} from 'lib/lightbox/lightbox';
import {MakeButton} from 'packages/elements/button';
import TextField from 'packages/elements/textfields/TextField';
import React, {PropsWithChildren, useState} from 'react';

export type LinkData = {
    url: string;
};

export function LinkStrategy(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
}

export function LinkComponent({children, contentState, entityKey}: React.PropsWithChildren<ModProps>) {
    const data = contentState.getEntity(entityKey).getData() as LinkData;
    return <LinkRenderer {...{data}}>{children}</LinkRenderer>;
}

type LinkRendererProps = {
    data: LinkData;
};

export const LinkRenderer: React.FC<PropsWithChildren<LinkRendererProps>> = ({data, children}) => (
    <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
    >
        {children}
    </a>
);

export interface InsertLinkLightboxProps {
    onInsert: (url: string) => void;
}

export default function InsertLink(data: ComponentProps<InsertLinkLightboxProps>) {
    const [link, setLink] = useState('');
    const [InputError, SetInputError] = useState(false);

    function insert() {
        if (link) {
            SetInputError(false);
            if (!IsValidURL(link.trim())) {
                SetInputError(true);
            } else {
                data.content?.onInsert(link.trim());
                data.close();
            }
        }
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            insert();
        }
    }

    return (
        <Lightbox {...{close: data.close}}>
            <Header>Insert link</Header>
            <Body>
                <TextField
                    {...{
                        label: 'Link',
                        type: 'text',
                        placeholder: 'http(s)://',
                        onKeyDown,
                        error: InputError,
                        value: link,
                        onChange: e => {
                            setLink(e.target.value);
                        },
                    }}
                />
            </Body>
            <Footer {...{styles: Styles.footer}}>
                <button
                    className={MakeButton('FilledAccent2-Default')}
                    onClick={insert}
                >
                    Insert
                </button>
            </Footer>
        </Lightbox>
    );
}
const Styles = CreateSheet({
    footer: {
        padding: '10px 20px',
        textAlign: 'right',
        ':nth-child(1n) button': {
            marginLeft: 10,
        },
    },
});
