import * as r from 'runtypes';

export const FacebookAdAccountRuntype = r.Record({
    id: r.String,
    name: r.String,
});

export type FacebookAdAccount = r.Static<typeof FacebookAdAccountRuntype>;

export const UserDataSourceRuntype = r.Record({
    guid: r.String,
    user_guid: r.String,
    type_id: r.String,
    name: r.String,
    created_at: r.Number,
});

export type UserDataSource = r.Static<typeof UserDataSourceRuntype>;
