type ImageMetadata = {
    width?: number;
    height?: number;
    avg_color?: string;
};

export default async function ExtractImageMetadata(file: Blob): Promise<ImageMetadata> {
    if (file.type.indexOf('image') > -1 && file.type !== 'image/tiff') {
        return new Promise<ImageMetadata>(resolve => {
            let img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                resolve({
                    avg_color: GetImageAvgColor(img),
                    width: img.width,
                    height: img.height,
                });
            };
            img.onerror = () => {
                resolve({});
            };
        });
    }

    return {};
}

export function GetImageAvgColor(img: HTMLImageElement): string {
    let blockSize = 5, // only visit every 5 pixels
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data: ImageData,
        width: number,
        height: number,
        i = -4,
        length: number,
        rgb = {r: 0, g: 0, b: 0},
        count = 0;

    if (!context) {
        return '#AAAAAA';
    }

    height = canvas.height = img.naturalHeight || img.offsetHeight || img.height;
    width = canvas.width = img.naturalWidth || img.offsetWidth || img.width;

    context.drawImage(img, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        return '#AAAAAA';
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    let hex_rgb = rgb.b | (rgb.g << 8) | (rgb.r << 16);
    return '#' + (0x1000000 + hex_rgb).toString(16).slice(1);
}
