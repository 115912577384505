import {css} from 'aphrodite';
import Styles from './blog.jss';
import {Outlet} from 'react-router-dom';

export default function BlogPage() {
    return (
        <article className={css(Styles.main)}>
            <Outlet />
        </article>
    );
}
