import {put} from 'lib/fetch';
import * as runtypes from 'runtypes';
export const RequestNewEmailAPI = (email: string) =>
    put(
        {
            url: '/session/emails',
            body: {email},
        },
        runtypes.String
    );
