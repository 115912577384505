import {UserSessionAccessRuntype} from 'generated/AccessPolicies';
import {UserMetadataRuntype} from 'packages/users/model';
import * as runtypes from 'runtypes';

export const SessionUserRuntype = runtypes.Record({
    guid: runtypes.String,
    is_verified: runtypes.Boolean,
    email: runtypes.String.Or(runtypes.Null),
    first_name: runtypes.String,
    last_name: runtypes.String,
    full_name: runtypes.String,
    nats_user_token: runtypes.String.nullable(),
});

export const SessionCompanyProjectRuntype = runtypes.Record({
    guid: runtypes.String,
    name: runtypes.String,
    uri: runtypes.String,
});
export type SessionCompanyProject = runtypes.Static<typeof SessionCompanyProjectRuntype>;

export const SessionCompanyRuntype = runtypes.Record({
    guid: runtypes.String,
    created_at: runtypes.Number,
    name: runtypes.String,
    uri: runtypes.String,
    industry: runtypes.String,
    size: runtypes.String,
    projects: runtypes.Array(SessionCompanyProjectRuntype),
});
export type SessionCompany = runtypes.Static<typeof SessionCompanyRuntype>;

export const SessionExtraRuntype = runtypes.Record({
    su_login: runtypes.Optional(UserMetadataRuntype),
    su: runtypes.Optional(UserSessionAccessRuntype.nullable()),
    companies: runtypes.Optional(runtypes.Array(SessionCompanyRuntype).nullable()),
});

export const SessionRuntype = runtypes.Record({
    user: SessionUserRuntype,
    extra: SessionExtraRuntype,
});

export type SessionUser = Readonly<runtypes.Static<typeof SessionUserRuntype>>;
export type SessionExtra = Readonly<runtypes.Static<typeof SessionExtraRuntype>>;
export type Session = Readonly<runtypes.Static<typeof SessionRuntype>>;
