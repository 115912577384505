import {Virtualizer, useWindowVirtualizer} from '@tanstack/react-virtual';
import {css} from 'aphrodite';
import {GeneratedStyles} from 'lib/aphrodite/aphrodite';
import {forwardRef, useImperativeHandle} from 'react';

export interface WindowVirtualListProps {
    itemsCount: number;
    overscan?: number; // how much rows show before and after visible area
    estimatedRowHeight: number; // estimated!! row height (should be as much close to real as possible)
    render: (index: number) => React.ReactNode; // render function
    styles?: {
        rowElementRoot?: GeneratedStyles;
    };
}

export interface WindowVirtualListRef {
    virtualizer: Virtualizer<Window, Element>;
}

const WindowVirtualList = forwardRef<WindowVirtualListRef, WindowVirtualListProps>(
    ({itemsCount, overscan, estimatedRowHeight, render, styles}, forwardedRef) => {
        const rowVirtualizer = useWindowVirtualizer({
            count: itemsCount,
            estimateSize: () => estimatedRowHeight,
            overscan: overscan ?? 3,
        });

        useImperativeHandle(forwardedRef, () => {
            return {
                virtualizer: rowVirtualizer,
            };
        });

        const vItems = rowVirtualizer.getVirtualItems();
        return (
            <div
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    position: 'relative',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${vItems[0]?.start ?? 0}px)`,
                    }}
                >
                    {vItems.map(virtualItem => (
                        <div
                            key={virtualItem.key}
                            data-index={virtualItem.index}
                            className={css(styles?.rowElementRoot)}
                            ref={rowVirtualizer.measureElement}
                        >
                            {render(virtualItem.index)}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export default WindowVirtualList;
