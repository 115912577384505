import {StateCreator} from 'zustand';
import {BlogPost} from '../../model';
import {BlogStore} from './usePostStore';
import LoadBlogPostByGUIDAPI from 'packages/blog/API/LoadBlogPostByGUIDAPI';

export type Data = {
    is_loaded: boolean;
    is_loading: boolean;
    item: BlogPost | null;
};

export type General = {
    data: Data;
    init: (uri: string) => Promise<void>;
};

const emptyGeneral = {
    data: {
        is_loaded: false,
        is_loading: true,
        item: null,
    },
} satisfies Partial<General>;

export const createGeneral: StateCreator<BlogStore, [], [], General> = (set, get) => ({
    ...emptyGeneral,
    init: async (uri: string) => {
        set({
            ...emptyGeneral,
            data: {
                is_loading: true,
                is_loaded: false,
                item: null,
            },
        });

        const [res, err] = await LoadBlogPostByGUIDAPI(uri);
        if (err !== null) {
            alert(err.message);
        } else {
            set({
                ...emptyGeneral,
                data: {
                    is_loading: false,
                    is_loaded: true,
                    item: res,
                },
            });
        }
    },
});
