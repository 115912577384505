import {put} from 'lib/fetch';
import {SessionRuntype} from 'src/runtime/session/model';

export default function SignInAPI(email: string, password: string) {
    return put(
        {
            url: '/session',
            body: {email, password},
        },
        SessionRuntype
    );
}
