import {useState, RefObject} from 'react';
import useOnce from './useOnce';

export default function useInViewport(ref: RefObject<HTMLDivElement>, options?: IntersectionObserverInit) {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);
    useOnce(() => {
        const observer = new IntersectionObserver(([entry]) => {
            // Update our state when observer callback fires
            setIntersecting(entry.isIntersecting);
        }, options);
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    });
    return isIntersecting;
}
