import {FetchResponseError} from 'lib/fetch';
import {OnboardingMessage} from 'packages/projects/onboarding/model';
import {create} from 'zustand';
import LoadOnboardingChatAPI from 'packages/projects/onboarding/api/LoadOnboardingChatAPI';
import LoadOnboardingInitialMessagesAPI from 'packages/projects/onboarding/api/LoadOnboardingInitialMessagesAPI';
import CreateProjectOnboardingChatAPI from 'packages/projects/onboarding/api/CreateProjectOnboardingChatAPI';
import ContinueProjectOnboardingChatAPI, {
    NewOnboardingMessage,
} from 'packages/projects/onboarding/api/ContinueProjectOnboardingChatAPI';
import {routing} from 'src/runtime/router';
import {MessagePayloadRuntype} from 'packages/projects/generalchat/model';
import {hasRequestedFieldInMessage} from '../../components/GeneralChat/ProjectChatStore';

export const useOnboardingStore = create<{
    company_uri: string;
    project_uri: string;
    // payloads
    messages: OnboardingMessage[];
    // system
    isLoading: boolean;
    canSubmitNewMessage: boolean;
    // methods
    init: (company_uri: string, project_uri: string) => Promise<FetchResponseError | null>;
    reload: (project_uri?: string) => Promise<FetchResponseError | null>;
    sendNewMessage: (message: string) => Promise<FetchResponseError | null>;
    sendNewSystemMessage: (message: NewOnboardingMessage) => Promise<FetchResponseError | null>;
    // internal
    setNewMessages: (messages: OnboardingMessage[]) => void;
}>((set, get) => ({
    company_uri: '',
    project_uri: 'new',
    // payloads
    messages: [],
    // system
    isLoading: false,
    canSubmitNewMessage: true,
    // methods
    init: async (company_uri: string, project_uri: string) => {
        set({isLoading: true, project_uri, company_uri});

        if (project_uri === 'new') {
            const res = await LoadOnboardingInitialMessagesAPI(company_uri);
            if (res[1]) {
                set({isLoading: false});
                return res[1];
            }

            get().setNewMessages(res[0].messages);
        } else {
            const res = await LoadOnboardingChatAPI(project_uri);
            if (res[1]) {
                set({isLoading: false});
                return res[1];
            }

            get().setNewMessages(res[0].messages);
        }

        set({isLoading: false});

        return null;
    },
    reload: async (project_uri?: string) => {
        if (project_uri) {
            set({project_uri: project_uri});
        }

        const res = await LoadOnboardingChatAPI(get().project_uri);
        if (res[1]) {
            return res[1];
        }

        get().setNewMessages(res[0].messages);

        return null;
    },
    sendNewMessage: async (message: string) => {
        set({isLoading: true});
        set({messages: [...get().messages, {content: message, role: 'user', guid: '', payload: null}]});
        if (get().project_uri === 'new') {
            const res = await CreateProjectOnboardingChatAPI(get().company_uri, message);
            if (res[1]) {
                set({isLoading: false});
                return res[1];
            }

            get().setNewMessages(res[0].messages);
            set({project_uri: res[0].project.uri});
            routing.push(`/ai/${get().company_uri}/${res[0].project.uri}`);
        } else {
            const res = await ContinueProjectOnboardingChatAPI(get().project_uri, {
                content: message,
            });
            if (res[1]) {
                set({isLoading: false});
                return res[1];
            }

            get().setNewMessages(res[0].messages);
        }
        set({isLoading: false});

        return null;
    },
    sendNewSystemMessage: async (message: NewOnboardingMessage) => {
        set({isLoading: true});
        const res = await ContinueProjectOnboardingChatAPI(get().project_uri, message);
        if (res[1]) {
            set({isLoading: false});
            return res[1];
        }

        get().setNewMessages(res[0].messages);

        set({isLoading: false});

        return null;
    },
    // internal
    setNewMessages: (messages: OnboardingMessage[]) => {
        messages = messages.map(message => {
            let parsedMessage = {...message};
            if (message.payload) {
                try {
                    const parsedPayload = JSON.parse(message.payload);
                    const res = MessagePayloadRuntype.validate(parsedPayload);
                    if (res.success) {
                        parsedMessage.parsed_payload = res.value;
                    }
                } catch (error) {}
            }

            return {...parsedMessage};
        });

        set({messages: messages, canSubmitNewMessage: !shouldDisableNewMessages(messages)});
    },
}));

export function shouldDisableNewMessages(messages: OnboardingMessage[]) {
    if (messages.length > 0) {
        const last = messages[messages.length - 1];
        if (hasRequestedFieldInMessage(last, 'data_source_type')) {
            return true;
        } else if (last.parsed_payload?.prevent_text_messages) {
            return true;
        }
    }

    return false;
}
