import useMaxWidth from 'lib/hooks/useMaxWidth';
import {ExternalFile} from '../models';
// components
import Tooltip from 'packages/tooltip';
// icons
import {ReactComponent as TrashIcon} from 'src/icons/trash.svg';
// styles
import {css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import Styles from './PreviewFile.jss';
import GetFileIcon from '../images/GetFileIcon';
import {CanMakeThumbnail} from '../images/GetFileThumbnailURL';
import {PreviewPicture} from '.';
import PrintSize from 'lib/helpers/PrintSize';

interface Props {
    file: ExternalFile;
    onRemove?: () => void;
}

export default function PreviewFile({file, onRemove}: Props) {
    const isSmallerScreen = useMaxWidth(800);

    const mimeParts = file.mime.split('/');
    const FileIcon = GetFileIcon(mimeParts.length > 1 ? mimeParts[1] : mimeParts[0]);
    const isImage = CanMakeThumbnail(file.mime);

    return (
        <div className={css(Styles.file)}>
            {!isSmallerScreen && onRemove ? (
                <Tooltip content={isImage ? 'Remove image' : 'Remove file'}>
                    <button
                        type="button"
                        className={css(isImage ? Styles.removeImg : Styles.removeFile)}
                        onClick={onRemove}
                    >
                        <TrashIcon
                            width="16"
                            height="16"
                        />
                    </button>
                </Tooltip>
            ) : null}
            {isImage ? (
                <PreviewPicture
                    image={file}
                    thumbnail={{width: 100, height: 100, BackgroundType: 'blur'}}
                    img_tag={{className: css(Styles.img)}}
                />
            ) : (
                <FileIcon
                    width="100"
                    height="100"
                    className={css(Styles.fileIcon)}
                    role="img"
                    title="PDF"
                />
            )}
            <Tooltip content={file.file_name}>
                <div className={css(Styles.name, Styles.fileName)}>{file.file_name}</div>
            </Tooltip>
            {file.size > 0 && (
                <div className={css(Styles.fileSize)}>
                    {PrintSize(file.size, {
                        thousandsSeparator: ',',
                        decimalPlaces: 1,
                    })}
                </div>
            )}
            {isSmallerScreen && onRemove ? (
                <button
                    type="button"
                    className={MakeButton('Outlined-Default', Styles.removeBtn)}
                    onClick={onRemove}
                >
                    <TrashIcon
                        width="16"
                        height="16"
                    />
                    <span>Remove</span>
                </button>
            ) : null}
        </div>
    );
}
