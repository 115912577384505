import {UnknownChart} from '.';

export default function UnknownTableComponent({chart}: {chart: UnknownChart}) {
    return (
        <div>
            <ul>
                {Object.entries(chart.data).map(([key, value]) => (
                    <li key={key}>
                        {key} : {JSON.stringify(value)}
                    </li>
                ))}
            </ul>
        </div>
    );
}
