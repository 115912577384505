import {css} from 'aphrodite';
import Header from 'src/jsx/header/header';
import useMaxWidth from 'lib/hooks/useMaxWidth';
import {Outlet, useParams} from 'react-router-dom';
import Styles from './DashboardPage.jss';
import {Sidebar} from './components/Sidebar/Sidebar';
import {MobileSidebar} from './components/Sidebar/MobileSidebar';
import {HeaderUtilityStyles} from '../header/sizes';
import {routing} from 'src/runtime/router';
import {useDashboardStore} from './DashboardStore';
import {showErrorLightbox} from 'lib/lightbox/warning.react';
import WithSpinner from 'packages/spinners/SmartSpinner.react';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import {useEffect} from 'react';
import {routerIsActivePage} from 'src/runtime/router/hooks';
import {GPTButton} from 'packages/elements/button';

export default function DashboardPage() {
    const [project, isLoading, isOnboarding, initStore] = useDashboardStore(s => [
        s.project,
        s.isLoading,
        s.isOnboarding,
        s.init,
    ]);

    const {company_uri, project_uri} = useParams<{company_uri: string; project_uri: string}>();
    const isSmallerScreen = useMaxWidth(800);

    useEffect(() => {
        async function init() {
            if (!company_uri || !project_uri) {
                routing.OpenPage('')();
                return;
            }

            const err = await initStore({company_uri, project_uri});
            if (err !== null) {
                showErrorLightbox(err.message);
            }
        }

        init();
    }, [initStore, company_uri, project_uri]);

    return (
        <div className={css(Styles.page, HeaderUtilityStyles().relatedTopPadding)}>
            <WithSpinner
                theme={PrimaryTheme}
                suspense={!project && isLoading}
                minDuration={0}
                showContentOnLoading={true}
            >
                <div>
                    <Header />
                    {isOnboarding ? (
                        <Outlet />
                    ) : project ? (
                        <>
                            {routerIsActivePage('dashboardOnboarding') ? (
                                <Outlet />
                            ) : (
                                <>
                                    {!isSmallerScreen ? <Sidebar /> : <MobileSidebar />}
                                    <div className={css(Styles.container)}>
                                        <Outlet />
                                    </div>
                                </>
                            )}
                        </>
                    ) : null}
                </div>
            </WithSpinner>
            {!routerIsActivePage('dashboardOnboarding') && !routerIsActivePage('dashboardChat') && <GPTButton />}
        </div>
    );
}
