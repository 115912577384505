import {JSONCodec, Subscription, NatsConnection} from 'nats.ws';
import {Body} from './model';

export type {Subscription};
export default function Subscribe<Response extends object>(
    nc: NatsConnection,
    subject: string,
    callback: (body: Body<Response>, reply?: string) => void
): Subscription {
    const res = nc.subscribe(subject, {
        callback: (err, msg) => {
            if (err !== null) {
                console.log(err);
            }
            const jcr = JSONCodec<Body<Response>>();
            try {
                const decoded = jcr.decode(msg.data);
                callback(decoded, msg.reply);
            } catch (error) {
                console.log(msg);
                console.error(error);
            }
        },
    });

    return res;
}
