import {css} from 'aphrodite';
import useWaitFor from 'lib/hooks/useWaitFor';
import {LoadButton} from 'packages/elements/button';
import {useState} from 'react';
import Styles from './common.jss';
import {useAuthStore} from './store';
import EditForm, {EditFormData, EmptyEditFormData, validateEditForm} from './components/EditForm';
import {SplitFullName} from 'packages/users/SplitFullName';

export default function SignUpProfilePage() {
    const [data, setData] = useState(EmptyEditFormData);
    const [commonErr, setCommonErr] = useState('');
    const submitProfileData = useAuthStore(state => state.submitProfileData);

    const [isLoading, withLoading] = useWaitFor();

    async function onSubmitProfile(e: React.FormEvent) {
        e.preventDefault();

        const validatedData = validateEditForm(data);
        const errors = Object.keys(validatedData).map(item => validatedData[item as keyof EditFormData].err);

        setData(validatedData);

        if (!Object.values(errors).some(e => e)) {
            const [first_name, last_name] = SplitFullName(validatedData.fullName.value);
            const res = await withLoading(
                submitProfileData({
                    first_name,
                    last_name,
                    company_name: validatedData.company.value,
                    company_industry: validatedData.industry.value,
                    company_size: validatedData.size.value,
                })
            );
            if (res) {
                setCommonErr(res.message);
            }
        }
    }

    return (
        <div className={css(Styles.containerMiddle)}>
            <form
                className={css(Styles.form)}
                onSubmit={onSubmitProfile}
            >
                <h1 className={css(Styles.formTitle)}>Finish signing up</h1>
                <div className={css(Styles.inputs)}>
                    <EditForm
                        data={data}
                        onChange={setData}
                        commonErr={commonErr}
                    />
                    <LoadButton
                        type="submit"
                        variant="FilledAccent1-Big"
                        styles={[Styles.button, Styles.verifyBtn, Styles.extraMarginTop]}
                        loading={isLoading}
                    >
                        Continue
                    </LoadButton>
                </div>
            </form>
        </div>
    );
}
