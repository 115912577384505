import {css} from 'aphrodite';
import Styles from './common.jss';
import {SignUpForm} from './SignUpForm';

export default function SignUpEmployerPage() {
    return (
        <div className={css(Styles.containerMiddle)}>
            <SignUpForm />
        </div>
    );
}
