import React from 'react';
// icons
import {ReactComponent as LogoRound} from 'src/icons/logo/logo-round.svg';
// styles
import {css} from 'aphrodite';
import Styles from './ChatMessages.jss';

export default function ChatAIMessages({children, hideAvatars}: {children: React.ReactNode; hideAvatars?: boolean}) {
    return (
        <div className={css(Styles.message)}>
            <div className={css(Styles.messageImgBlock)}>
                <span>You</span>
                {!hideAvatars && (
                    <div className={css(Styles.messageImg)}>
                        <LogoRound />
                    </div>
                )}
            </div>
            <div>
                <div className={css(Styles.messageName)}>Whitetail AI</div>
                {children}
            </div>
        </div>
    );
}
