import {ContentState, DraftDecorator, EditorState, RichUtils} from 'draft-js';
import {showLightbox} from 'lib/lightbox/lightbox';
import {ReactComponent as UploadMediaIcon} from 'src/icons/upload.svg';
import {DraftEditorFunctions} from './DraftEditor.react';
import {ReactComponent as BoldIcon} from './icons/bold.svg';
import {ReactComponent as ClearIcon} from './icons/clear.svg';
import {ReactComponent as ItalicIcon} from './icons/italic.svg';
import {ReactComponent as LinkIcon} from './icons/link.svg';
import {ReactComponent as OrderedListIcon} from './icons/ordered-list.svg';
import {ReactComponent as RedoIcon} from './icons/redo.svg';
import {ReactComponent as RemoveLinkIcon} from './icons/remove-link.svg';
import {ReactComponent as UnderlineIcon} from './icons/underline.svg';
import {ReactComponent as UndoIcon} from './icons/undo.svg';
import {ReactComponent as UnorderedListIcon} from './icons/unordered-list.svg';
import InsertImage, {ImagePlugin} from './ModComponents/Image.react';
import InsertLink, {LinkComponent, LinkStrategy} from './ModComponents/Link.react';
import ClearInlineStyles from './utils/ClearInlineStyles';

export type ModProps = {
    entityKey: string;
    contentState: ContentState;
};

export type Mod = {
    component: React.ReactNode;
    tooltip?: string;
    onClick: (draft: DraftEditorFunctions) => void;
    decorator?: DraftDecorator;
};

export type ModsObject = {
    [key: string]: Mod;
};

export const DefaultMods = {
    bold: {
        tooltip: 'Bold',
        component: (
            <BoldIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleInlineStyle(draft.getState(), 'BOLD'));
        },
    },
    italic: {
        tooltip: 'Italic',
        component: (
            <ItalicIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleInlineStyle(draft.getState(), 'ITALIC'));
        },
    },
    underline: {
        tooltip: 'Underline',
        component: (
            <UnderlineIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleInlineStyle(draft.getState(), 'UNDERLINE'));
        },
    },
    'ordered-list': {
        tooltip: 'Ordered list',
        component: (
            <OrderedListIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), 'ordered-list-item'));
        },
    },
    'unordered-list': {
        tooltip: 'Unordered list',
        component: (
            <UnorderedListIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), 'unordered-list-item'));
        },
    },
    undo: {
        tooltip: 'Undo',
        component: (
            <UndoIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(EditorState.undo(draft.getState()));
        },
    },
    redo: {
        tooltip: 'Redo',
        component: (
            <RedoIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(EditorState.redo(draft.getState()));
        },
    },
    insert_link: {
        tooltip: 'Insert link',
        component: (
            <LinkIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            showLightbox({
                selector: 'InsertLink',
                Component: InsertLink,
                content: {
                    onInsert: url => {
                        const contentState = draft.getState().getCurrentContent();
                        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {url});
                        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                        const newEditorState = EditorState.set(draft.getState(), {
                            currentContent: contentStateWithEntity,
                        });

                        draft.onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
                        setTimeout(() => draft.focus(), 0);
                    },
                },
            });
        },
        decorator: {
            strategy: LinkStrategy,
            component: LinkComponent,
        },
    },
    remove_link: {
        tooltip: 'Remove link',
        component: (
            <RemoveLinkIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            const selection = draft.getState().getSelection();
            if (!selection.isCollapsed()) {
                draft.onChange(RichUtils.toggleLink(draft.getState(), selection, null));
            }
        },
    },
    clear_formatting: {
        tooltip: 'Clear formatting',
        component: (
            <ClearIcon
                height="15"
                width="15"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            draft.onChange(ClearInlineStyles(draft.getState()));
        },
    },
} satisfies ModsObject;

export const AdditionalMods = {
    insert_image: {
        tooltip: 'Insert image',
        component: (
            <UploadMediaIcon
                height="17"
                width="17"
            />
        ),
        onClick: (draft: DraftEditorFunctions) => {
            showLightbox({
                selector: 'InsertImage',
                Component: InsertImage,
                content: {
                    onInsert: data => {
                        draft.onChange(ImagePlugin.addImage(draft.getState(), data));
                        setTimeout(() => draft.focus(), 0);
                    },
                },
            });
        },
    },
} satisfies ModsObject;

export type AdditionalModsList = Array<keyof typeof AdditionalMods>;

export const GetMods = (additionalModsList?: AdditionalModsList): ModsObject => {
    const ResultMods: ModsObject = DefaultMods;

    if (!additionalModsList) {
        return ResultMods;
    }

    additionalModsList.forEach(mod => {
        ResultMods[mod] = AdditionalMods[mod];
    });

    return ResultMods;
};
