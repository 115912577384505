import {CreateSheet, css, GetColor} from 'aphrodite';
import {ButtonVariant, MakeButton} from 'packages/elements/button/button';
import {Fragment} from 'react';
import {ReactComponent as SignOutIcon} from 'src/icons/sign-out.svg';

export default function ButtonsDemoPage() {
    const variants: ButtonVariant[] = [
        'Primary-Default',
        'FilledAccent1-Default',
        'FilledPageBg-Default',
        'FilledAccent2-Default',
        'Outlined-Default',
        'Flat-Default',
        'FilledAccent1-Big',
        'FilledAccent2-Big',
        'Outlined-Big',
        'FilledGray-Big',
        'FilledPageBg-Big',
        'Flat-Flat',
        'Transparent-Small',
        'FilledGray-Label',
        'FilledAccent1Light-Label',
        'FilledAccent2Light-Label',
        'FilledDanger-Big',
    ];

    return (
        <div className={css(Styles.page)}>
            {variants.map(variant => (
                <Fragment key={variant}>
                    <div className={css(Styles.label)}>Variant: {variant}</div>
                    <div className={css(Styles.buttonsRow)}>
                        <button className={MakeButton(variant)}>Button</button>
                        <button className={MakeButton(variant)}>
                            <SignOutIcon />
                            Button
                        </button>
                        <button
                            className={MakeButton(variant)}
                            disabled
                        >
                            Button
                        </button>
                    </div>
                </Fragment>
            ))}
        </div>
    );
}

const Styles = CreateSheet({
    page: {
        width: '100%',
        background: GetColor('PageBg'),
        padding: 200,
    },
    label: {
        fontWeight: 700,
        fontSize: 12,
        color: GetColor('TextColor'),
        marginBottom: 10,
    },
    buttonsRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
        ':nth-child(1n) button': {
            marginRight: 60,
        },
    },
});
