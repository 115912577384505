import {CreateSheet, GeneratedStyles, GetColor, css} from 'aphrodite';
import {forwardRef} from 'react';

type ChatComponentProps = {
    messages: React.ReactNode;
    input: React.ReactNode;
    styles?: {messages?: GeneratedStyles[]; input?: GeneratedStyles[]; component?: GeneratedStyles[]};
};
export const ChatComponent = forwardRef<HTMLDivElement, ChatComponentProps>(({messages, input, styles}, ref) => {
    return (
        <div className={css(Styles.component, styles?.component)}>
            <div
                ref={ref}
                className={css(Styles.messages, styles && styles.messages)}
            >
                {messages}
            </div>
            {input ? <div className={css(Styles.input, styles && styles.input)}>{input}</div> : null}
        </div>
    );
});

export default ChatComponent;

const Styles = CreateSheet({
    component: {
        maxWidth: 840,
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    messages: {
        width: '100%',
        flexGrow: 1,
    },
    input: {
        width: '100%',
        position: 'sticky',
        bottom: 0,
        marginTop: 50,
        paddingBottom: 25,

        background: GetColor('PageBg'),
        boxShadow: '0px -10px 25px 20px #131B20',
    },
});
