import {GeneratedStyles, css} from 'aphrodite';
import Styles from './RadioButton.jss';

export type RadioButtonProps = {
    label: string;
    styles?: {
        outer?: GeneratedStyles;
    };
};

export default function RadioButton(
    props: RadioButtonProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
) {
    return (
        <div className={css(props.styles?.outer)}>
            <label className={css(Styles.inputWrapper)}>
                <input
                    id={props.id}
                    type="radio"
                    className={css(Styles.input)}
                    {...props}
                />
                {props.label}
            </label>
        </div>
    );
}
