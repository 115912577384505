import * as r from 'runtypes';

export const CompanyAccessRuntype = r.Partial({
    can_view: r.Boolean,
    can_edit: r.Boolean,
    can_manage_users: r.Boolean,
    can_create_projects: r.Boolean,
    can_delete: r.Boolean,
});
export type CompanyAccess = r.Static<typeof CompanyAccessRuntype>;

export const ProjectAccessRuntype = r.Partial({
    can_view: r.Boolean,
    can_edit: r.Boolean,
    can_delete: r.Boolean,
});
export type ProjectAccess = r.Static<typeof ProjectAccessRuntype>;

export const UserSessionAccessRuntype = r.Partial({
    has_console_access: r.Boolean,
    has_users_access: r.Boolean,
    can_login_as_user: r.Boolean,
    has_admins_access: r.Boolean,
    can_manage_admins: r.Boolean,
    can_manage_blog: r.Boolean,
    can_manage_sitemaps: r.Boolean,
    has_companies_access: r.Boolean,
    can_manage_companies: r.Boolean,
});
export type UserSessionAccess = r.Static<typeof UserSessionAccessRuntype>;

