import {useState} from 'react';
import {CreateSheet, GetColor, css} from 'aphrodite';
import {Body, ComponentProps, Footer, Header, Lightbox} from 'lib/lightbox/lightbox';
import {LoadButton, MakeButton} from 'packages/elements/button';
import EditForm, {
    CompanySizeTypes,
    EditFormData,
    IndustriesTypes,
    MakeEmptyFieldWithError,
    validateEditForm,
} from 'src/jsx/auth/components/EditForm';
import {ReloadSession} from 'src/runtime/session';
import {SplitFullName} from 'packages/users/SplitFullName';
import UpdateProfileDetailsAPI from 'src/runtime/session/API/UpdateProfileDetailsAPI';

type EditAccountInfoLBData = {
    fullName: string;
    company: string;
    industry: string;
    size: string;
    company_guid: string;
    onSave?: () => void;
};

const EditAccountInfoLB = (data: ComponentProps<EditAccountInfoLBData>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [commonErr, setCommonErr] = useState('');
    const [formData, setFormData] = useState<EditFormData>({
        fullName: MakeEmptyFieldWithError(data.content.fullName),
        company: MakeEmptyFieldWithError(data.content.company),
        industry: MakeEmptyFieldWithError(data.content.industry as (typeof IndustriesTypes)[number]),
        size: MakeEmptyFieldWithError(data.content.size as (typeof CompanySizeTypes)[number]),
    });

    const onSubmit = async () => {
        const validatedData = validateEditForm(formData);
        const errors = Object.keys(validatedData).map(item => validatedData[item as keyof EditFormData].err);

        setFormData(validatedData);

        if (!Object.values(errors).some(e => e)) {
            const [first_name, last_name] = SplitFullName(validatedData.fullName.value);

            setIsLoading(true);
            const response = await UpdateProfileDetailsAPI({
                first_name,
                last_name,
                company_guid: data.content.company_guid,
                company_name: validatedData.company.value,
                company_industry: validatedData.industry.value,
                company_size: validatedData.size.value,
            });
            setIsLoading(false);

            if (response[1]) {
                setCommonErr(response[1].message);
                return;
            }

            ReloadSession();
            data.content.onSave && data.content.onSave();
            data.close();
        }
    };

    return (
        <Lightbox {...{close: data.close}}>
            <Header>Edit profile information</Header>
            <Body styles={Styles.body}>
                <EditForm
                    data={formData}
                    onChange={setFormData}
                    commonErr={commonErr}
                />
            </Body>
            <Footer styles={Styles.footer}>
                <div className={css(Styles.grow)}></div>
                <button
                    className={MakeButton('FilledPageBg-Big')}
                    onClick={() => data.close(true)}
                >
                    Cancel
                </button>
                <LoadButton
                    onClick={onSubmit}
                    loading={isLoading}
                    variant="FilledAccent1-Big"
                >
                    Save
                </LoadButton>
            </Footer>
        </Lightbox>
    );
};
export default EditAccountInfoLB;

const Styles = CreateSheet({
    body: {
        paddingBottom: -20, // negative margin to avoid modifying original form
    },
    input: {
        width: '100%',
    },
    inputLabel: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '120%',
        color: GetColor('TextColor'),
    },
    footer: {
        display: 'flex',
        gap: 10,
    },
    grow: {
        flexGrow: 1,
    },
});
