import {css, CSSProperties, CreateSheet, GetColor} from 'aphrodite';

export default function LineProgress({height, progress}: {progress: number; height?: CSSProperties['height']}) {
    const styles = Styles(height || 4);
    progress = progress > 100 ? 100 : progress;

    return (
        <aside className={css(styles.container)}>
            <div
                className={css(styles.line)}
                style={{
                    transform: `translateX(${progress - 100}%)`,
                }}
            />
        </aside>
    );
}

const Styles = (height: CSSProperties['height']) => {
    return CreateSheet({
        container: {
            width: '100%',
            overflow: 'hidden',
        },
        line: {
            background: GetColor('Accent2'),
            height: height,
            borderTopRightRadius: typeof height === 'number' ? height / 2 : 0,
            borderBottomRightRadius: typeof height === 'number' ? height / 2 : 0,
            transition: 'transform 0.05s',
            willChange: 'transform',
        },
    });
};
