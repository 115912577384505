import {useCallback} from 'react';

export const useScrollLocker = () => {
    const lock = useCallback(() => {
        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = 'hidden';
        }
    }, []);

    const unlock = useCallback(() => {
        const body = document.querySelector('body');
        if (body) {
            body.removeAttribute('style');
        }
    }, []);

    return [lock, unlock];
};
