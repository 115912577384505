import {get} from 'lib/fetch';
import {ProjectWithDetailsRuntype} from '../index';

export default async function LoadProjectAPI(uri: string) {
    return await get(
        {
            url: `projects/${uri}`,
        },
        ProjectWithDetailsRuntype
    );
}
