import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    table: {
        fontSize: 14,
        position: 'relative',
        borderCollapse: 'collapse',
    },
    thead: {},
    th: {
        height: 50,
        textAlign: 'center',
        backgroundColor: GetColor('PageBg'),
        color: GetColor('TextColor'),
    },
    thSticky: {
        position: 'sticky',
        top: 58,
        zIndex: 2,
    },
    thEmpty: {
        content: '""',
        position: 'sticky',
        top: 58,
        left: 0,
        backgroundColor: GetColor('PageBg'),
        zIndex: 3,
        fontWeight: 700,
        fontSize: 16,
    },
    td: {
        textAlign: 'center',
    },
    tdFirst: {
        whiteSpace: 'nowrap',
        padding: '0 14px 0 14px',
        height: 50,
        width: 260,
        minWidth: 260,
        color: GetColor('Accent1'),
        textTransform: 'capitalize',
        position: 'sticky',
        left: 240,
        backgroundColor: GetColor('PageBg'),
        zIndex: 1,
    },
    tdAmount: {},
    amount: {
        backgroundColor: GetColor('TopLayerObjectBg'),
        margin: '5px 3px',
        padding: '0 5px',
        height: 50,
        width: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    positive: {
        color: GetColor('Accent2'),
        textAlign: 'center',
        padding: '0 10px',
    },
    negative: {
        color: GetColor('Accent2'),
        textAlign: 'center',
        padding: '0 10px',
    },
});
