import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    sidebar: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        width: 260,
        padding: '20px 12px',
        flexDirection: 'column',
        borderRight: `1px solid ${GetColor('StrokeColor')}`,
        background: GetColor('NavBarBgColor'),
    },
    sidebarMenu: {
        // @ts-ignore
        overflowX: 'hidden !important',
        flexGrow: 1,
    },
    sidebarInfo: {
        flexGrow: 0,
        flexShrink: 0,

        padding: '20px 0 30px 0',
        borderTop: `1px solid ${GetColor('StrokeColor')}`,
    },
    menu: {
        marginTop: 20,
    },
    linkItemDisabled: {
        pointerEvents: 'none',
    },
    linkItem: {
        display: 'flex',
        width: '100%',
        margin: 0,
        padding: '9px 10px',
        fontFamily: "'Figtree', sans-serif",
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: GetColor('Primary'),
        textDecoration: 'none',
        alignItems: 'center',
        textAlign: 'left',
        borderRadius: 10,
        background: 'transparent',
        boxShadow: 'none',
        border: 0,
        cursor: 'pointer',
        transition: 'background 0.2s ease-out, color 0.2s ease-out',
        ':hover': {
            background: GetColor('LightGrayBg'),
        },
        ':nth-child(1n) span': {
            flexGrow: 1,
        },
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 12,
            flexGrow: 0,
            flexShrink: 0,
        },
        ':nth-child(1n) span + svg': {
            marginLeft: 12,
            marginRight: 0,
        },
    },
    linkItemSection: {
        color: GetColor('Secondary'),
    },
    linkItemActive: {
        color: GetColor('Accent1Text'),
        background: GetColor('LightGrayBg'),
        pointerEvents: 'none',
    },
    linkItemIcon: {
        display: 'block',
        flexShrink: 0,
        color: GetColor('Secondary'),
    },
    linkItemIconActive: {
        color: GetColor('Accent1Text'),
    },
    subLinkItem: {},
    arrowIcon: {
        transition: 'transform 0.3s cubic-bezier(.2,0,0,1)',
    },
    arrowIconClosed: {
        transform: 'rotate(180deg)',
    },
    projectContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginBottom: 20,

        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '140%',
        color: GetColor('Secondary'),
    },
    projectTitle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: '120%',
        color: GetColor('Primary'),
    },
    projectTitlePlaceholder: {
        color: GetColor('Placeholder'),
    },
});
