import {get} from 'lib/fetch';
import {ProjectChartsRuntype} from '../project_charts';

export default async function LoadProjectChartsAPI(uri: string, exclude_markers?: string[]) {
    return await get(
        {
            url: `projects/${uri}/charts`,
            query: {exclude_markers},
        },
        ProjectChartsRuntype
    );
}
