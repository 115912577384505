import React, {useState, useRef} from 'react';
import useMaxWidth from 'lib/hooks/useMaxWidth';
import {ShowToast} from 'packages/toasts/ShowToast';
// models
import {ExternalFile} from '../models';
// components
import ImageCropperLightbox from '../images/ImageCropperLightbox';
import UploadProgressToastReact, {UploadProgressToastFunctions} from './UploadProgressToast';
// icons
import {ReactComponent as UploadIcon} from 'src/icons/upload.svg';
// functions
import {UploadFile, FileACL} from 'packages/files/s3files';
import {showLightbox} from 'lib/lightbox/lightbox';
// styles
import {StyleDeclaration, css} from 'aphrodite';
import Styles from './UploadFilesBlock.jss';
import {MakeButton} from 'packages/elements/button';
import {toast} from 'react-toastify';

interface StylesProps {
    container?: StyleDeclaration;
    containerDrop?: StyleDeclaration;
    uploadTitle?: StyleDeclaration;
    uploadText?: StyleDeclaration;
    uploadIcon?: StyleDeclaration;
}

interface UploadImageBlockProps {
    children?: React.ReactNode;
    aspect?: number;
    file?: ExternalFile;
    OnUpload: (file: ExternalFile) => void;
    styles?: StylesProps;
    acl?: FileACL;
}

export default function UploadImageBlock({children, OnUpload, aspect, styles, acl}: UploadImageBlockProps) {
    const fileRef = useRef<HTMLInputElement>(null);
    const [activeDrag, setActiveDrag] = useState<boolean>(false);

    const progressToastRef = useRef<UploadProgressToastFunctions>(null);
    const progressToastID = useRef<string | number>('');

    const isSmallerScreen = useMaxWidth(800);

    const onDrop = (event: React.DragEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveDrag(false);

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files = event.dataTransfer.files;

            uploadFiles(files);
        }
    };

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            uploadFiles(e.target.files);
        }
    };

    const uploadFiles = (files: FileList | null) => {
        if (files && files.length) {
            const file = files[0];
            showLightbox({
                selector: 'image-crop',
                Component: ImageCropperLightbox,
                styles: {
                    wrapper: Styles.cropper,
                },
                content: {
                    image: file,
                    aspect: aspect,
                    onImageCropped: onSaveCroppedImage,
                },
            });
        }
    };

    function onSaveCroppedImage(file: File) {
        uploadFile(file);
    }

    function OnClick() {
        fileRef.current?.click();
    }

    function uploadFile(file: File) {
        progressToastID.current = ShowToast(<UploadProgressToastReact ref={progressToastRef} />, {
            timeout: false,
        });

        UploadFile(
            file,
            ([image, error]) => {
                if (error !== null) {
                    progressToastRef.current?.onProgress(0);
                    progressToastRef.current?.onError();
                } else if (image !== null) {
                    OnUpload(image);
                }
                toast.dismiss(progressToastID.current);
            },
            p => {
                progressToastRef.current?.onProgress(p);
            },
            acl
        );
    }

    const onDragEnter = () => {
        setActiveDrag(true);
    };

    const onDragLeave = () => {
        setActiveDrag(false);
    };

    const onDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <>
            <button
                {...{
                    className: isSmallerScreen
                        ? MakeButton('Outlined-Default')
                        : css(
                              Styles.upload,
                              activeDrag && Styles.uploadDrop,
                              styles && styles.container && styles.container,
                              styles && styles.containerDrop && styles.containerDrop
                          ),
                    onClick: OnClick,
                    onDrop: onDrop,
                    onDragOver: onDragOver,
                    onDragEnter: onDragEnter,
                    onDragLeave: onDragLeave,
                }}
            >
                {children ? (
                    children
                ) : (
                    <>
                        {isSmallerScreen ? (
                            <>
                                <span>Upload file</span>
                                <UploadIcon
                                    width="16"
                                    height="16"
                                    role="img"
                                    title="Upload"
                                />
                            </>
                        ) : (
                            <>
                                <div
                                    className={css(Styles.uploadIcon, styles && styles.uploadIcon && styles.uploadIcon)}
                                >
                                    <UploadIcon
                                        width="24"
                                        height="24"
                                        role="img"
                                        title="Upload"
                                    />
                                </div>
                                <div
                                    className={css(
                                        Styles.uploadTitle,
                                        styles && styles.uploadTitle && styles.uploadTitle
                                    )}
                                >
                                    <strong className={css(Styles.uploadTitleStrong)}>Click to Upload</strong> or
                                    Drag&Drop
                                </div>
                                <p className={css(Styles.uploadText, styles && styles.uploadText && styles.uploadText)}>
                                    Supported formats: JPEG, PNG
                                </p>
                            </>
                        )}
                    </>
                )}
            </button>
            <input
                {...{
                    style: {
                        display: 'none',
                    },
                    type: 'file',
                    accept: '.jpg, .jpeg, .png',
                    onChange: onSelectFile,
                    ref: fileRef,
                }}
            />
        </>
    );
}
