import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    page: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        background: GetColor('PageBg'),
    },
    header: {
        position: 'relative',
        display: 'flex',
        padding: '20px 30px',
        justifyContent: 'center',
        flexGrow: 0,
    },
    logo: {
        display: 'inline-flex',
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('TextColor'),
        textDecoration: 'none',
        alignItems: 'center',
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 15,
        },
    },
    back: {
        position: 'absolute',
        top: '50%',
        left: 30,
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('Secondary'),
        transform: 'translate(0, -50%)',
        zIndex: 2,
    },
    content: {
        flexGrow: 1,
    },
    footer: {
        padding: 30,
        fontSize: '0.875rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        flexGrow: 0,
        textAlign: 'center',
    },
});
