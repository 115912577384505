import {css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import {Menu, MenuItem} from 'packages/elements/menu';
import MenuStyles from 'packages/elements/menu/menu.jss';
import Range from 'packages/elements/range/range';
import {useLoadProjectChartAPI} from 'packages/projects/api/LoadProjectChartAPI';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {ReactComponent as ArrowDownIcon} from 'src/icons/arrow-down.svg';
import {ReactComponent as DownloadIcon} from 'src/icons/download.svg';
import {ReactComponent as SettingsIcon} from 'src/icons/settings.svg';
import Styles from './DashboardSingleChartPage.jss';
import {ProjectChart} from 'packages/projects/project_charts/charts';
import {ChartComponent, TableComponent, HasChartComponent} from 'packages/charts/ChartComponent';
import WithSpinner from 'packages/spinners/SmartSpinner.react';
import {PrimaryTheme} from 'packages/spinners/spinner.theme';
import ExportAsCSV from 'lib/helpers/ExportAsCSV';
import ChartDataToCSV, {ReportSuffixByChartType} from 'lib/helpers/ChartDataToCSV';
import {ChartType} from 'packages/charts/chart_type';
import moment from 'moment';
import Tooltip from 'packages/tooltip';
import {useDashboardStore} from '../../DashboardStore';

export default function DashboardSingleChartPage() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const {chart_marker} = useParams<{chart_marker: string}>();

    const project_uri = useDashboardStore(s => s.project_uri);

    const [state] = useLoadProjectChartAPI(true, project_uri, chart_marker!);

    function exportCSV() {
        if (state.payload?.chart && state.payload.chart.chart_type !== ChartType.Unknown) {
            ExportAsCSV(
                ChartDataToCSV(state.payload.chart),
                moment().unix() + '_' + project_uri + ReportSuffixByChartType(state.payload.chart.chart_type)
            );
        }
    }

    // useEffect(() => {
    //     const onClick = () => {
    //         if (activeIndex !== -1) setActiveIndex(-1);
    //     };

    //     window.addEventListener('click', onClick);

    //     return () => {
    //         window.removeEventListener('click', onClick);
    //     };
    // }, [activeIndex]);

    // useEffect(() => {
    //     const onKeyPress = (e: KeyboardEvent) => {
    //         if (e.key === 'Escape') {
    //             if (activeIndex !== -1) setActiveIndex(-1);
    //         }
    //     };

    //     window.addEventListener('keyup', onKeyPress);

    //     return () => {
    //         window.removeEventListener('keyup', onKeyPress);
    //     };
    // }, [activeIndex]);

    return (
        <>
            <h1 className={css(Styles.title)}>{state.payload?.name}</h1>
            <WithSpinner
                theme={PrimaryTheme}
                suspense={state.isLoading}
            >
                <div className={css(Styles.filter)}>
                    <FilterMenu />
                    <Tooltip
                        content="Unavailable for Unknown type"
                        disabled={state.payload?.chart?.chart_type !== ChartType.Unknown}
                    >
                        <button
                            type="button"
                            className={MakeButton('Primary-Default')}
                            disabled={state.payload?.chart?.chart_type === ChartType.Unknown}
                            onClick={exportCSV}
                        >
                            <span>Export Data</span>
                            <DownloadIcon
                                width="16"
                                height="16"
                                role="img"
                                aria-label="Export"
                            />
                        </button>
                    </Tooltip>
                </div>
                <div className={css(Styles.stats)}>
                    <div className={css(Styles.statsBlock)}>
                        <div className={css(Styles.statsBlockLabel)}>Delta Amount (Change)</div>
                        n/a
                        <strong className={css(Styles.statsBlockPercent, Styles.statsBlockPercentPlus)}>+N/A%</strong>
                    </div>
                    <div className={css(Styles.statsBlock)}>
                        <div className={css(Styles.statsBlockLabel)}>Average Selling Price</div>
                        n/a
                        <strong className={css(Styles.statsBlockPercent, Styles.statsBlockPercentMinus)}>-N/A%</strong>
                    </div>
                </div>
                {state.payload && state.payload.chart && (
                    <Chart
                        chart={state.payload}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                )}
            </WithSpinner>
        </>
    );
}

const Chart = ({
    chart,
    activeIndex,
    setActiveIndex,
}: {
    chart: ProjectChart;
    activeIndex: number;
    setActiveIndex: (index: number) => void;
}) => {
    const [isStateOpen, setIsStateOpen] = useState(false);

    const hasChart = chart.chart ? HasChartComponent(chart.chart) : false;

    return (
        <div className={css(Styles.modelOutput)}>
            <div className={css(Styles.blockTop)}>
                <h2 className={css(Styles.blockTitle)}>Donut Chart</h2>
                <div className={css(Styles.blockSettings)}>
                    <Menu
                        place="left-start"
                        onClick={e => e.stopPropagation()}
                        trigger={
                            <button className={css(Styles.blockSettingsBtn)}>
                                <SettingsIcon
                                    width="16"
                                    height="16"
                                    role="img"
                                    aria-label="Settings"
                                />
                            </button>
                        }
                        settings={{
                            closeOn: {
                                clickFloatingBlock: true,
                            },
                            placement: {
                                useSize: true,
                            },
                        }}
                        event="click"
                    >
                        <MenuItem onClick={() => {}}>Main</MenuItem>
                    </Menu>
                </div>
            </div>
            <div className={css(Styles.modelOutputContainer)}>
                <div className={css(Styles.modelOutputBlock, Styles.modelOutputBlockChart)}>
                    {chart.chart ? (
                        hasChart ? (
                            <ChartComponent chart={chart.chart} />
                        ) : (
                            <TableComponent chart={chart.chart} />
                        )
                    ) : null}
                    <div className={css(Styles.modelOutputOptions)}>
                        <Menu
                            onStatusChange={setIsStateOpen}
                            trigger={
                                <div
                                    className={css(
                                        MenuStyles.select,
                                        MenuStyles.selectSmall,
                                        Styles.modelOutputOptionsSelect
                                    )}
                                >
                                    <span>Select</span>
                                    <ArrowDownIcon
                                        className={css(
                                            Styles.dropdownTriggerIcon,
                                            isStateOpen && Styles.dropdownTriggerIconOpened
                                        )}
                                    />
                                </div>
                            }
                            settings={{
                                closeOn: {
                                    clickFloatingBlock: true,
                                },
                                placement: {
                                    useSize: true,
                                    useWidth: true,
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() => {}}
                                settings={{
                                    big: true,
                                }}
                            >
                                All time
                            </MenuItem>
                        </Menu>
                        <div className={css(Styles.modelOutputOptionsItem)}>
                            <div className={css(Styles.modelOutputOptionsLabel)}>Example slider</div>
                            <Range />
                        </div>
                        <div className={css(Styles.modelOutputOptionsItem)}>
                            <div className={css(Styles.modelOutputOptionsLabel)}>Example badge</div>
                            <div className={css(Styles.name)}>
                                <div
                                    className={css(Styles.nameColor)}
                                    style={{background: '#0088FE'}}
                                />
                                <span>badge</span>
                            </div>
                            <div className={css(Styles.name)}>
                                <div
                                    className={css(Styles.nameColor)}
                                    style={{background: '#0088FE'}}
                                />
                                <span>badge</span>
                            </div>
                            <div className={css(Styles.name)}>
                                <div
                                    className={css(Styles.nameColor)}
                                    style={{background: '#0088FE'}}
                                />
                                <span>badge</span>
                            </div>
                            <div className={css(Styles.name)}>
                                <div
                                    className={css(Styles.nameColor)}
                                    style={{background: '#0088FE'}}
                                />
                                <span>badge</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function FilterMenu() {
    const [isStateOpen, setIsStateOpen] = useState(false);

    return (
        <div className={css(Styles.dropdownBlock)}>
            <label className={css(Styles.dropdownBlockLabel)}>Compare to</label>
            <Menu
                onStatusChange={setIsStateOpen}
                trigger={
                    <div className={css(MenuStyles.select, MenuStyles.selectSmall)}>
                        <span>All time</span>
                        <ArrowDownIcon
                            className={css(Styles.dropdownTriggerIcon, isStateOpen && Styles.dropdownTriggerIconOpened)}
                        />
                    </div>
                }
                settings={{
                    closeOn: {
                        clickFloatingBlock: true,
                    },
                    placement: {
                        useSize: true,
                        useWidth: true,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {}}
                    settings={{
                        big: true,
                    }}
                >
                    All time
                </MenuItem>
            </Menu>
        </div>
    );
}
