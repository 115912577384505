import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        padding: '15px 30px',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: 500,
        color: GetColor('TextColor'),
        background: GetColor('PageBg'),
        zIndex: 100,
        borderBottom: `1px solid ${GetColor('StrokeColor')}`,
        '@media(max-width: 800px)': {
            padding: '10px 20px',
        },
    },
    headerNoBorder: {
        border: 'none',
    },
    headerGrow: {
        flexGrow: 1,
    },
    logo: {
        display: 'flex',
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1,
        color: GetColor('TextColor'),
        alignItems: 'center',
        textDecoration: 'none',
        gap: 15,
        ':nth-child(1n) svg': {
            display: 'block',
        },
    },
    menu: {
        display: 'flex',
        margin: '0 0 0 30px',
        gap: 30,
        padding: 0,
        alignItems: 'center',
        flexGrow: 1,
        listStyle: 'none',
        '@media(max-width: 640px)': {
            margin: '0 0 0 20px',
        },
    },
    menuItemLink: {
        display: 'block',
        fontSize: '1rem',
        fontWeight: 500,
        color: GetColor('Secondary'),
        textDecoration: 'none',
        transition: 'color .15s ease-out',
        ':hover': {
            color: GetColor('Primary'),
        },
    },
    menuItemLinkActive: {
        color: GetColor('Primary'),
    },
    myProjectBtn: {
        whiteSpace: 'nowrap',
        height: 35,
        minHeight: 'none',
        padding: '8px 14px',
        transition: 'background .15s ease-out',
        ':hover': {
            background: `${GetColor('Accent1Hover')} !important`,
        },
    },
    btnDisabled: {
        cursor: 'default',
    },
    headerPart: {
        display: 'flex',
        alignItems: 'center',
    },
    menuBtn: {
        position: 'relative',
        display: 'none',
        width: 20,
        height: 20,
        margin: '0 0 0 25px',
        padding: 0,
        color: '#fff',
        borderRadius: 0,
        border: 0,
        boxShadow: 'none',
        background: 'transparent',
        cursor: 'pointer',
        opacity: 0.3,
        transition: 'opacity 0.2s ease-out',
        ':hover': {
            opacity: 1,
        },
        '@media(max-width: 800px)': {
            display: 'block',
        },
    },
    menuBtnIcon: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        transition: 'opacity 0.2s ease-out',
    },
    menuBtnIconActive: {
        opacity: 1,
    },
    menuFull: {
        position: 'fixed',
        display: 'none',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px 25px 70px 25px',
        opacity: 0,
        transform: 'translateX(100%)',
        transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
        background: GetColor('PageBg'),
        zIndex: 9,
        '@media(max-width: 800px)': {
            display: 'block',
        },
    },
    menuFullActive: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    menuFullList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    menuFullLink: {
        display: 'flex',
        width: '100%',
        padding: '20px 0',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        alignItems: 'center',
        justifyContent: 'space-between',
        textDecoration: 'none',
        borderBottom: `1px solid ${GetColor('StrokeColor')}`,
        transition: 'color 0.2s ease-out',
        ':nth-child(1n) svg': {
            display: 'block',
            marginLeft: 20,
            opacity: 0.15,
            transition: 'opacity 0.2s ease-out',
        },
        ':hover': {
            ':nth-child(1n) svg': {
                opacity: 1,
            },
        },
    },
    footerBlock: {
        background: GetColor('PageBg'),
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px 25px 40px 25px',
    },
    footerContact: {
        marginTop: 20,
        display: 'flex',
        fontSize: '1rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contactLink: {
        marginLeft: 20,
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        textDecoration: 'none',
    },
    user: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
    },
    menuIconBtn: {
        flexShrink: 0,
        width: 40,
        height: 40,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        borderRadius: 50,
        background: GetColor('PageBg'),
        color: GetColor('Primary'),
        cursor: 'pointer',
        border: 'none',
        boxShadow: 'none',
        ':hover': {
            background: GetColor('ButtonBgHover'),
        },
    },
    menuIconBtnDisabled: {
        pointerEvents: 'none',
    },
    // TODO: move to separate component when need to reuse, discuss possible states and layout with Masha
    customTip: {
        borderRadius: 14,
        padding: 15,
        background: GetColor('TopLayerObjectBg'),
        border: `1px solid ${GetColor('Border')}`,
        maxWidth: 200,

        boxShadow: '0px 5px 20px 0px rgba(48, 48, 48, 0.10) !important',
    },
    customTipContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    customTipContentIcon: {
        height: 16,
        width: 16,
        color: GetColor('Accent1Text'),
    },
    customTipContentText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginBottom: 5,
    },
    customTipContentTitle: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: GetColor('Primary'),
        lineHeight: '130%',
    },
    customTipContentHint: {
        fontSize: '0.8125rem',
        fontWeight: 500,
        color: GetColor('Secondary'),
        lineHeight: '130%',
    },
});
