export type ThirdPartyLoginState = {
    AllowSignUp?: boolean;
    IsDataSourceConnect?: boolean;
};

export function BuildState(data: ThirdPartyLoginState) {
    return encodeURIComponent(
        [
            typeof data.AllowSignUp === 'boolean' ? `allow-sign-up:${data.AllowSignUp ? 'yes' : 'no'}` : '',
            typeof data.IsDataSourceConnect === 'boolean'
                ? `data-source-connect:${data.IsDataSourceConnect ? 'yes' : 'no'}`
                : '',
        ]
            .filter(Boolean)
            .join(';')
    );
}
