import {CreateSheet, GeneratedStyles, GetColor, css} from 'aphrodite';
import {ReactComponent as MenuIcon} from 'src/icons/menu.svg';

type MenuButtonProps = {
    isActive: boolean;
    onClick?: () => void;
    styles?: {
        root?: GeneratedStyles;
    };
};

export const MenuButton: React.FC<MenuButtonProps> = ({isActive, onClick, styles}) => {
    return (
        <button
            type="button"
            className={css(Styles.menuUserBtn, isActive && Styles.menuUserBtnActive, styles?.root)}
            onClick={onClick}
        >
            <MenuIcon
                width="16"
                height="16"
                role="img"
                title="Menu"
            />
        </button>
    );
};

const Styles = CreateSheet({
    menuUserBtn: {
        display: 'flex',
        width: 44,
        height: 44,
        fontFamily: "'Nunito Sans', sans-serif",
        fontSize: '0.875rem',
        fontWeight: 600,
        color: GetColor('Primary'),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        border: `1px solid ${GetColor('StrokeColor')}`,
        background: GetColor('TopLayerObjectBg'),
        boxShadow: 'none',
        transition: 'border 0.15s ease-out, box-shadow 0.15s ease-out',
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
            flexShrink: 0,
        },
        ':hover': {
            border: `1px solid ${GetColor('Border')}`,
            boxShadow: `0px 3px 15px 0px rgba(48, 48, 48, 0.05)`, // FIXME: add lib color
        },
    },
    menuUserBtnActive: {
        border: `1px solid ${GetColor('Border')}`,
        boxShadow: `0px 3px 15px 0px rgba(48, 48, 48, 0.05)`, // FIXME: add lib color
    },
});
