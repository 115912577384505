import {CreateSheet, GetColor, css} from 'lib/aphrodite/aphrodite';
import NavCard from 'packages/elements/cards/NavCard';
import {session} from 'src/runtime/session';
import {ReactComponent as AdminsIcon} from 'src/icons/admins.svg';
import {ReactComponent as PayoutsIcon} from 'src/icons/payouts.svg';
import {ReactComponent as EditIcon} from 'src/icons/edit.svg';
import {ReactComponent as SignOutIcon} from 'src/icons/sign-out.svg';
import {MakeButton} from 'packages/elements/button';
import {showLightbox} from 'lib/lightbox/lightbox';
import EditAccountInfoLB from '../components/EditAccountInfoLB';
import {GeneralStyles} from './GeneralStyles.jss';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';
import {ComingSoon} from 'packages/elements/cards/ComingSoon';

const AccountIndex: React.FC = () => {
    const company = session.extra.companies?.[0];

    const onEdit = () => {
        if (company) {
            showLightbox({
                selector: 'edit-account-info',
                Component: EditAccountInfoLB,
                content: {
                    fullName: session.user.full_name,
                    company_guid: company.guid,
                    company: company.name,
                    industry: company.industry,
                    size: company.size,
                },
                layout: {
                    fullScreenOnMobile: true,
                },
            });
        }
    };

    return (
        <div className={css(GeneralStyles.container, HeaderUtilityStyles().relatedFullHeightPage)}>
            <div className={css(GeneralStyles.content)}>
                <div className={css(Styles.contentHead)}>
                    <h1 className={css(GeneralStyles.pageTitle)}>Account</h1>
                    <h3 className={css(Styles.contentHeadEmail)}>{session.user.email}</h3>
                </div>
                <div className={css(Styles.contentAccount)}>
                    <div className={css(Styles.contentAccountInfo)}>
                        <div className={css(Styles.contentAccountInfoCompanyName)}>{company?.name}</div>
                        <div className={css(Styles.contentAccountInfoRepresentative)}>{session.user.full_name}</div>
                        <div className={css(Styles.contentAccountInfoAttributes)}>
                            <div className={css(Styles.contentAccountInfoAttribute)}>
                                <span className={css(Styles.contentAccountInfoAttributeTitle)}>Industry</span>
                                {company?.industry}
                            </div>
                            <div className={css(Styles.contentAccountInfoAttribute)}>
                                <span className={css(Styles.contentAccountInfoAttributeTitle)}>Size</span>
                                {company?.size}
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={onEdit}
                        disabled={!company}
                        className={MakeButton('Outlined-Default', Styles.contentAccountInfoEditButton)}
                    >
                        <EditIcon />
                        Edit
                    </button>
                </div>
                <div className={css(Styles.contentSettings)}>
                    <h2 className={css(Styles.contentSettingsTitle)}>Settings</h2>
                    <div className={css(Styles.contentSettingsCards)}>
                        <NavCard
                            title="Login & Security"
                            description="Manage your account credentials, update passwords, and security settings."
                            href="/account/security"
                            icon={className => <AdminsIcon className={className} />}
                            styles={{container: [Styles.contentSettingsCard]}}
                        />

                        <ComingSoon
                            styles={{wrapper: Styles.contentSettingsCard, childrenContainer: Styles.fullHeight}}
                        >
                            <NavCard
                                title="Subscriptions"
                                description="View and manage your active subscriptions and review billing details."
                                href="/account/subscriptions"
                                icon={className => <PayoutsIcon className={className} />}
                                styles={{container: [Styles.contentSettingsCard]}}
                            />
                        </ComingSoon>
                    </div>
                </div>
                <div className={css(Styles.alreadyFinishedContainer)}>
                    Already finished?
                    <a
                        className={MakeButton('Primary-Default')}
                        href={'/sign-out'}
                    >
                        <SignOutIcon />
                        Log out
                    </a>
                </div>
            </div>
        </div>
    );
};

const Styles = CreateSheet({
    contentHead: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    contentHeadEmail: {
        color: GetColor('Primary'),
        fontSize: '1.125rem',
        lineHeight: 'normal',
        fontWeight: 600,
    },
    contentAccount: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 20,

        padding: 20,
        borderRadius: 20,
        background: GetColor('PageBg'),
        border: `1px solid ${GetColor('StrokeColor')}`,

        '@media(max-width: 640px)': {
            flexDirection: 'column',
        },
    },
    contentAccountInfo: {
        flexGrow: 1,

        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    contentAccountInfoCompanyName: {
        fontSize: '1.375rem',
        fontWeight: 700,
        lineHeight: 'normal',
        color: GetColor('Primary'),
        '@media(max-width: 800px)': {
            fontSize: '1.125rem',
        },
    },
    contentAccountInfoRepresentative: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 'normal',
        color: GetColor('Primary'),
    },
    contentAccountInfoAttributes: {
        display: 'flex',
        gap: 25,
    },
    contentAccountInfoAttribute: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,

        color: GetColor('Primary'),
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 'normal',
        '@media(max-width: 800px)': {
            fontSize: '1rem',
        },
    },
    contentAccountInfoAttributeTitle: {
        color: GetColor('Secondary'),
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '120%',
    },
    contentAccountInfoEditButton: {
        '@media(max-width: 640px)': {
            width: '100%',
        },
    },
    contentSettings: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    contentSettingsTitle: {
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: 'normal',
        '@media(max-width: 800px)': {
            fontSize: '1.375rem',
        },
    },
    contentSettingsCards: {
        display: 'flex',
        gap: 20,
        '@media(max-width: 800px)': {
            flexDirection: 'column',
        },
    },
    contentSettingsCard: {
        height: '100%',
        width: '100%',
    },
    fullHeight: {
        height: '100%',
    },
    alreadyFinishedContainer: {
        marginTop: 'auto',
        paddingTop: 30,
        borderTop: `1px solid ${GetColor('StrokeColor')}`,

        display: 'flex',
        gap: 30,

        fontSize: '1rem',
        color: GetColor('Primary'),
        fontWeight: 700,
        lineHeight: 'normal',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export default AccountIndex;
