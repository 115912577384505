import {Getter, Setter} from './creator';

export type Errors<Key extends string, Value = string> = Partial<Readonly<{[key in Key]?: Value}>>;
export function hasError<Key extends string, Value = string>(errors: Errors<Key, Value>, key: Key): boolean {
    return typeof errors[key] === 'string';
}

export function hasErrors<Key extends string, Value = string>(errors: Errors<Key, Value>): boolean {
    return Object.keys(errors).length > 0;
}

export function getError<Key extends string, Value = string>(errors: Errors<Key, Value>, key: Key): Value | undefined {
    return errors[key];
}

export function setError<Key extends string, Value = string>(
    errors: Errors<Key, Value>,
    key: Key,
    error: Value | undefined,
    preserve?: boolean
): Errors<Key, Value> {
    const clone = {...errors};
    if (typeof error === 'undefined' && !preserve) {
        delete clone[key];
    } else {
        clone[key] = error;
    }

    return clone;
}

export function setErrors<Key extends string, Value = string>(
    errors: Errors<Key, Value>,
    newError: Errors<Key, Value | undefined>
): Errors<Key, Value> {
    const clone = {...errors};
    Object.entries(newError).forEach(([key, value]) => {
        if (typeof value === 'undefined') {
            //@ts-ignore
            delete clone[key];
        } else {
            //@ts-ignore
            clone[key] = value;
        }
    });

    return clone;
}

export interface ZustandErrors<Key extends string, Value = string> {
    errors: Errors<Key, Value>;
    hasError: (key: Key) => boolean;
    hasErrors: () => boolean;
    getError: (key: Key) => Value | undefined;
    getErrors: () => Errors<Key, Value>;
    setError: (key: Key, val: Value | undefined) => void;
    setErrors: (value: Errors<Key, Value | undefined>) => void;
    clearErrors: () => void;
    overwriteErrors: (value: Errors<Key, Value | undefined>) => void;
}

export const createErrors = <Key extends string, Value = string>(
    set: Setter<ZustandErrors<Key, Value>>,
    get: Getter<ZustandErrors<Key, Value>>,
    ...rest: unknown[]
): ZustandErrors<Key, Value> => ({
    errors: {},
    hasError: (key: Key) => hasError(get().errors, key),
    getError: (key: Key) => getError(get().errors, key),
    setError: (key: Key, error: Value | undefined) => set({errors: setError(get().errors, key, error)}),
    hasErrors: () => hasErrors(get().errors),
    getErrors: () => get().errors,
    setErrors: (errors: Errors<Key, Value | undefined>) => set({errors: {...get().errors, ...errors}}),
    clearErrors: () => set({errors: {}}),
    overwriteErrors: (errors: Errors<Key, Value | undefined>) => set({errors: {...errors}}),
});
