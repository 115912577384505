import {CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';

export const GeneralStyles = CreateSheet({
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: '50px 30px',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    content: {
        maxWidth: 1100,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
    },
    pageTitle: {
        color: GetColor('Primary'),
        fontSize: '1.875rem',
        lineHeight: 'normal',
        fontWeight: 600,
        '@media(max-width: 800px)': {
            fontSize: '1.625rem',
        },
    },
});
