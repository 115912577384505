import {css} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import {ReactComponent as GridViewIcon} from 'src/icons/grid-view.svg';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import Styles from './MobileSidebar.jss';
import {useState} from 'react';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';
import {session} from 'src/runtime/session';
import {getBasePath, MenuItem as MenuItemType, MenuSections} from '../../menuItems';
import {WithCustomScrollbar} from 'packages/elements/scrollbar/scrollbar';
// import {UpgradeNow} from './components/UpgradeNow/UpgradeNow';
import Portal from 'lib/portal/portal.react';
import {useDashboardStore} from '../../DashboardStore';
import {useScrollLocker} from 'lib/hooks/useScrollLocker';
import NavCard from 'packages/elements/cards/NavCard';
import {ReactComponent as SettingsIcon} from 'src/icons/settings.svg';

export const MobileSidebar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [lock, unlock] = useScrollLocker();

    const open = () => {
        setIsOpen(true);
        lock();
    };

    const close = () => {
        setIsOpen(false);
        unlock();
    };

    return (
        <div className={css(Styles.trigger, HeaderUtilityStyles().relatedTop)}>
            <Overlay
                close={close}
                isOpen={isOpen}
            />
            <button
                onClick={open}
                className={MakeButton('Flat-Default', Styles.triggerButton)}
            >
                <GridViewIcon />
                Navigation
            </button>
        </div>
    );
};

type OverlayProps = {
    isOpen: boolean;
    close: () => void;
};

const Overlay: React.FC<OverlayProps> = ({close, isOpen}) => {
    const [company_uri, project_uri] = useDashboardStore(store => [store.company_uri, store.project_uri]);

    return (
        <div
            className={css(
                Styles.overlay,
                Styles.withSlideAnimation,
                isOpen && Styles.withSlideAnimationShown,
                HeaderUtilityStyles().relatedTop,
                WithCustomScrollbar
            )}
        >
            <Portal>
                <div
                    className={css(
                        Styles.overlayHeader,
                        Styles.withSlideAnimation,
                        isOpen && Styles.withSlideAnimationShown,
                        HeaderUtilityStyles().relatedTop
                    )}
                >
                    <div className={css(Styles.overlayHeaderUser)}>
                        Hi, <span className={css(Styles.overlayHeaderUserName)}>{session.user.first_name}</span>
                    </div>
                    <div
                        className={css(Styles.overlayHeaderClose)}
                        onClick={close}
                    >
                        <CrossIcon className={css(Styles.overlayHeaderCloseIcon)} />
                    </div>
                </div>
            </Portal>
            <Menu close={close} />
            {/* <UpgradeNow style={Styles.upgradeNow} /> */}
            <NavCard
                onClick={close}
                title={'Settings'}
                icon={className => <SettingsIcon className={className} />}
                href={getBasePath(company_uri, project_uri) + '/settings'}
                styles={{container: [Styles.navCardContainer, Styles.navCardSettings]}}
            />
        </div>
    );
};

type MenuProps = {
    close: () => void;
};

const Menu: React.FC<MenuProps> = ({close}) => {
    return (
        <>
            {Object.entries(MenuSections).map(([section, items], key) => (
                <MenuSection
                    close={close}
                    section={section}
                    items={items}
                    key={key}
                />
            ))}
        </>
    );
};

type MenuSectionProps = {
    section: string;
    items: MenuItemType[];
    close: () => void;
};

const MenuSection: React.FC<MenuSectionProps> = ({section, items, close}) => {
    const [company_uri, project_uri] = useDashboardStore(s => [s.company_uri, s.project_uri]);

    return (
        <div className={css(Styles.menuSection)}>
            {section && <div className={css(Styles.menuSectionTitle)}>{section}</div>}
            <div className={css(Styles.menuItems)}>
                {items.map((item, key) => (
                    <NavCard
                        key={key}
                        onClick={close}
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        href={item.href(company_uri, project_uri)}
                        styles={{container: [Styles.navCardContainer]}}
                    />
                ))}
            </div>
        </div>
    );
};
