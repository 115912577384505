import React, {forwardRef} from 'react';
import {MenuRef} from 'lib/floating/FloatingMenu';
import {MenuElementProps} from './model';
import {Menu, MenuProps} from './Menu';

export interface MenuWrapperProps extends Omit<MenuProps, 'trigger'> {
    menus?: React.ReactNode; // react node with menus, same as children for <Menu />
}

// MenuWrapper switched trigger and children e.g. for easy context menu
/*
    <MenuWrapper menus={(
        <MenuItem>one</MenuItem>
        <MenuItem>two</MenuItem>
    )}>
        <div>element triggers the menu</div>
    </MenuWrapper>
*/

export const MenuWrapper = forwardRef<MenuRef, MenuWrapperProps & MenuElementProps>(
    ({menus, children, ...rest}, forwaredRef) => {
        return (
            <Menu
                {...rest}
                ref={forwaredRef}
                trigger={children}
            >
                {menus}
            </Menu>
        );
    }
);
