import {useState, useMemo, useEffect, useRef} from 'react';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    arrow,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    useClick,
    safePolygon,
    useTransitionStyles,
} from '@floating-ui/react';
import {TooltipSettings} from './model';

export default function useFloatingTooltip(props: TooltipSettings) {
    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(props.open || false);

    const distance = typeof props.distance === 'undefined' ? 4 : props.distance;
    const data = useFloating({
        placement: props.place || 'top',
        open: props.disabled ? false : isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        strategy: 'fixed',
        middleware: [
            offset(distance),
            flip({
                crossAxis: props.place?.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: distance,
            }),
            shift({padding: distance}),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    let enableHover = false,
        enableFocus = false,
        enableClick = false;

    if (typeof props.open === 'undefined') {
        enableHover = true;
        if (props.event === 'focus') {
            enableFocus = true;
        }
    }

    const hover = useHover(data.context, {
        enabled: enableHover,
        move: false,
        delay: {open: props.delayShow, close: props.delayHide},
        handleClose: props.interactive ? safePolygon() : null,
    });
    const focus = useFocus(data.context, {enabled: enableFocus});
    const click = useClick(data.context, {enabled: enableClick});
    const dsmis = useDismiss(data.context, {enabled: typeof props.open === 'undefined'});
    const role = useRole(data.context, {role: 'tooltip'});
    const transitions = useTransitionStyles(data.context, {
        duration: 200,
    });

    const interactions = useInteractions([hover, focus, click, dsmis, role]);

    useEffect(() => {
        if (typeof props.open === 'boolean') {
            setIsOpen(props.open);
        }
    }, [props.open]);

    return useMemo(
        () => ({
            arrowRef: arrowRef,
            open: props.disabled ? false : isOpen,
            transitions,
            ...interactions,
            ...data,
        }),
        [isOpen, props.disabled, interactions, data, transitions]
    );
}
