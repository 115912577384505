import {useEffect, useRef, useState} from 'react';
// api
import StoreLandingDAtaAPI from 'packages/landing/API/StoreLandingDataAPI';
// components
import {showLightbox} from 'lib/lightbox/lightbox';
import TextField from 'packages/elements/textfields/TextField';
import Captcha from 'packages/recaptcha/components/recaptcha.react';
import Footer from '../footer/footer';
import Header from '../header/header';

// img
import ChatImg1 from './img/chat/img.png';
import UserImg from './img/chat/user.png';
import SecureImg from './img/secure.png';
import EfficientImg from './img/efficient.png';
import StrategicImg from './img/strategic.png';
import InsightsImg from './img/insights.png';
import InterfaceImg from './img/interface.png';
// icons
import {ReactComponent as CsvIcon} from 'src/icons/files/csv.svg';
import {ReactComponent as VerifiedIcon} from 'src/icons/verified1.svg';
import {ReactComponent as LogoRound} from 'src/icons/logo/logo-round.svg';
import {ReactComponent as ArrowUpLongIcon} from 'src/icons/arrow-up-long.svg';
import {ReactComponent as SparkIcon} from 'src/icons/spark.svg';
import {ReactComponent as RocketIcon} from 'src/icons/rocket.svg';
import {ReactComponent as GrowIcon} from 'src/icons/grow.svg';
import {ReactComponent as SendIcon} from 'src/icons/send.svg';
import {ReactComponent as ArrowLeftLongIcon} from 'src/icons/arrow-left-long.svg';
//logos
import {ReactComponent as Activision} from './img/logo/ActivisionBlizzard.svg';
import {ReactComponent as BlackRock} from './img/logo/BlackRock.svg';
import {ReactComponent as GameSpot} from './img/logo/GameSpot.svg';
import {ReactComponent as N3twork} from './img/logo/n3twork.svg';
import {ReactComponent as Quantlab} from './img/logo/Quantlab.svg';
import {ReactComponent as SpringerNature} from './img/logo/SpringerNature.svg';
import {ReactComponent as Yahoo} from './img/logo/Yahoo.svg';
import {ReactComponent as Apple} from './img/logo/Apple.svg';
import {ReactComponent as Imangi} from './img/logo/Imangi.svg';
// styles
import {css} from 'aphrodite';
import Styles from './landing.jss';
import PicturePreview from 'packages/files/components/PreviewPicture';
import {PreviewPicture} from 'packages/files/components';

export default function Landing() {
    const [isSent, setSent] = useState(false);

    const changeIsSent = (value: boolean) => {
        setSent(value);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    if (isSent) {
        return <ThankYou reset={() => changeIsSent(false)} />;
    }

    return <Main onSent={() => changeIsSent(true)} />;
}

const Main = ({onSent}: {onSent: () => void}) => {
    const goTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <Header styles={{container: Styles.customHeaderBg}} />
            <div className={css(Styles.content)}>
                <div className={css(Styles.top)}>
                    <div className={css(Styles.hero)}>
                        <h1 className={css(Styles.title)}>
                            Unlock Your Best Ads
                            <strong className={css(Styles.titleStrong)}>with AI Precision</strong>
                        </h1>
                        <p className={css(Styles.heroText)}>
                            Transform your ad performance with data-driven creative insights.
                        </p>
                        <Form onSent={onSent} />
                    </div>
                    <Chat />
                </div>
                <From />
                <div className={css(Styles.work)}>
                    <div className={css(Styles.container)}>
                        <div className={css(Styles.partTitleEyeBrow)}>
                            <SparkIcon
                                width="20"
                                height="20"
                            />
                            <span>Guide</span>
                        </div>
                        <h2 className={css(Styles.partTitle)}>How it works</h2>
                        <p className={css(Styles.partText)}>
                            Effortlessly transform your data into actionable insights through Whitetail AI's secure data
                            collection, AI analysis, and expert-led strategic impact detection.
                        </p>
                        <div className={css(Styles.workCard)}>
                            <div className={css(Styles.workCardInfo)}>
                                <div className={css(Styles.workCardNumber)}>01</div>
                                <div className={css(Styles.workCardTitle)}>
                                    Secure Data Collection and AI Processing
                                </div>
                                <p className={css(Styles.workCardText)}>
                                    Start your journey by securely collecting data, processed swiftly by advanced AI for
                                    integrity and security.
                                </p>
                            </div>
                            <div className={css(Styles.workCardImage)}>
                                <PicturePreview
                                    image={{
                                        file_name: 'Secure Data Collection and AI Processing.png',
                                        url: SecureImg,
                                    }}
                                    img_tag={{
                                        width: '530',
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.workCard)}>
                            <div className={css(Styles.workCardInfo)}>
                                <div className={css(Styles.workCardNumber)}>02</div>
                                <div className={css(Styles.workCardTitle)}>
                                    Efficient Data Analysis for User Insights
                                </div>
                                <p className={css(Styles.workCardText)}>
                                    Explore your data's core using efficient analysis tools, gaining real-time insights
                                    into user behaviors and trends.
                                </p>
                            </div>
                            <div className={css(Styles.workCardImage)}>
                                <PicturePreview
                                    image={{
                                        file_name: 'Efficient Data Analysis for User Insights.png',
                                        url: EfficientImg,
                                    }}
                                    img_tag={{
                                        width: '570',
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.workCard)}>
                            <div className={css(Styles.workCardInfo)}>
                                <div className={css(Styles.workCardNumber)}>03</div>
                                <div className={css(Styles.workCardTitle)}>
                                    Strategic Impact Detection by Expert Team and AI
                                </div>
                                <p className={css(Styles.workCardText)}>
                                    Our expert team, enhanced by cutting-edge AI, uncovers strategic impacts in your
                                    data, providing actionable recommendations for business growth.
                                </p>
                            </div>
                            <div className={css(Styles.workCardImage)}>
                                <PicturePreview
                                    image={{
                                        file_name: 'Strategic Impact Detection by Expert Team and AI.png',
                                        url: StrategicImg,
                                    }}
                                    img_tag={{
                                        width: '560',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(Styles.why)}>
                    <div className={css(Styles.container)}>
                        <div className={css(Styles.partTitleEyeBrow)}>
                            <RocketIcon
                                width="20"
                                height="20"
                            />
                            <span>Advantages</span>
                        </div>
                        <h2 className={css(Styles.partTitle)}>Why Whitetail AI</h2>
                        <p className={css(Styles.partText)}>
                            Experience the competitive edge with Whitetail AI's user-friendly interface, prioritizing
                            security and delivering real-time analytics, empowering confident, data-driven decisions.
                        </p>
                        <div className={css(Styles.whyBlock)}>
                            <div className={css(Styles.whyBlockCard1, Styles.whyCard)}>
                                <div className={css(Styles.whyCardInfo)}>
                                    <div className={css(Styles.whyCardTitle)}>Instantaneous Insights</div>
                                    <div className={css(Styles.whyCardText)}>
                                        No more waiting for lengthy reports. Whitetail AI provides instant insights,
                                        allowing you to stay ahead of the curve.
                                    </div>
                                </div>
                                <PicturePreview
                                    image={{
                                        file_name: 'Instantaneous Insights.png',
                                        url: InsightsImg,
                                    }}
                                    img_tag={{
                                        className: css(Styles.whyCardImg),
                                    }}
                                />
                            </div>
                            <div className={css(Styles.whyBlockCard2, Styles.whyCard)}>
                                <div className={css(Styles.whyCardInfo)}>
                                    <div className={css(Styles.whyCardTitle)}>User-Friendly Interface</div>
                                    <div className={css(Styles.whyCardText)}>
                                        Our chat-based platform makes complex analytics easy to understand and act upon.
                                    </div>
                                </div>
                                <PicturePreview
                                    image={{
                                        file_name: 'User-Friendly Interface.png',
                                        url: InterfaceImg,
                                    }}
                                    img_tag={{
                                        className: css(Styles.whyCardImg),
                                    }}
                                />
                            </div>
                            <div className={css(Styles.whyBlockCard3, Styles.whyCard)}>
                                <div className={css(Styles.whyCardInfo)}>
                                    <div className={css(Styles.whyCardTitle)}>Security First</div>
                                    <div className={css(Styles.whyCardText)}>
                                        Rest easy knowing that your data is handled with the utmost security. Our robust
                                        encryption protocols ensure confidentiality.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(Styles.formBottom)}>
                    <div className={css(Styles.container)}>
                        <div className={css(Styles.partTitleEyeBrow, Styles.formTitleEyeBrow)}>
                            <GrowIcon
                                width="20"
                                height="20"
                            />
                            <span>Grow</span>
                        </div>
                        <h2 className={css(Styles.partTitle)}>Transform Your Business Today</h2>
                        <p className={css(Styles.partText)}>What used to take a team months you can now get in a day</p>
                        <div className={css(Styles.formBottomBlock)}>
                            <div className={css(Styles.formBottomBlockTitle)}>Start Your Free Trial</div>
                            <p className={css(Styles.formBottomBlockText)}>
                                Experience the power of Whitetail AI firsthand. Sign up for a free trial and witness the
                                transformative impact on your business.
                            </p>
                            <Form
                                onSent={onSent}
                                simple
                            />
                        </div>
                    </div>
                </div>
                <Footer styles={Styles.footer} />
            </div>
            <button
                type="button"
                className={css(Styles.goTop)}
                onClick={goTop}
            >
                <ArrowUpLongIcon
                    width="16"
                    height="16"
                />
            </button>
        </>
    );
};

const ThankYou = ({reset}: {reset: () => void}) => {
    return (
        <>
            <Header />
            <div className={css(Styles.content)}>
                <div className={css(Styles.thanks)}>
                    <div>
                        <div className={css(Styles.partTitleEyeBrow, Styles.thanksTitleEyeBrow)}>
                            <VerifiedIcon
                                width="20"
                                height="20"
                            />
                            <span>Success</span>
                        </div>
                        <h2 className={css(Styles.partTitle)}>Thank You, We'll reach out shortly!</h2>
                        {/* <p className={css(Styles.partText)}>
                            We've got your details and will keep you in the loop with the latest updates. Have a
                            fantastic day!
                        </p> */}
                        <button
                            type="button"
                            className={css(Styles.thanksBtn)}
                            onClick={reset}
                        >
                            <ArrowLeftLongIcon
                                width="16"
                                height="16"
                            />
                            <span>Back to Home</span>
                        </button>
                    </div>
                    <div>
                        <div className={css(Styles.formBottomBlock, Styles.thanksForm)}>
                            <div className={css(Styles.formBottomBlockTitle)}>Feel free to contact us</div>
                            <p className={css(Styles.formBottomBlockText)}>
                                We look forward to serving you on your data-driven journey.
                            </p>
                            <a
                                href="mailto:info@whitetail.ai"
                                type="button"
                                className={css(Styles.thanksMail)}
                            >
                                info@whitetail.ai
                            </a>
                        </div>
                    </div>
                </div>
                <Footer styles={Styles.footerTransparent} />
            </div>
        </>
    );
};

const Form = ({onSent, simple}: {onSent: () => void; simple?: boolean}) => {
    const [email, setEmail] = useState<string>('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (!isValidEmail) {
            setIsValidEmail(true);
        }
    };

    const send = async () => {
        const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
        setIsValidEmail(isValid);
        if (!isValid) {
            return;
        }

        const res = await StoreLandingDAtaAPI({
            email: email,
        });
        if (res[1] && res[1].status_code === 429) {
            showLightbox({
                selector: 'captcha-lb',
                Component: Captcha,
                content: {
                    key: res[1].payload.payload,
                    on_success: (token: string) => {
                        send();
                    },
                },
            });
        } else {
            onSent();
        }
    };

    return (
        <div
            id={`form${simple ? '-simple' : ''}`}
            className={css(Styles.form)}
        >
            <div className={css(Styles.inputContainer)}>
                <TextField
                    type="text"
                    label="Email"
                    labelHidden
                    placeholder="Enter email"
                    value={email}
                    onChange={onInputChange}
                    error={!isValidEmail ? 'Please enter a valid email address' : undefined}
                    styles={{
                        element: Styles.input,
                    }}
                />
            </div>
            <button
                className={css(Styles.btn, simple && Styles.btnSimple)}
                type="button"
                onClick={send}
                disabled={email.trim() === ''}
            >
                <div className={css(Styles.btnText)}>Try now for free</div>
            </button>
        </div>
    );
};

const Chat = () => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleScroll = (e: WheelEvent) => {
            e.preventDefault();

            if (!scrollContainerRef.current) {
                return;
            }

            let delta = e.deltaY;
            const container = scrollContainerRef.current;
            const containerScrollTop = container.scrollTop || 0;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const notInView =
                container.getBoundingClientRect().bottom < 0 || container.getBoundingClientRect().top > windowHeight;

            const moveToStartPosition = () => {
                if (delta > 0) {
                    if (Math.round(container.getBoundingClientRect().top) !== 80) {
                        if (container.getBoundingClientRect().top - delta <= 80) {
                            container.scrollIntoView({
                                behavior: 'auto',
                                block: 'start',
                            });
                            window.scrollBy(0, -80);
                        } else {
                            window.scrollBy(0, delta);
                        }
                    }
                } else {
                    if (Math.round(container.getBoundingClientRect().top) !== 80) {
                        if (container.getBoundingClientRect().top - delta >= 80) {
                            container.scrollIntoView({
                                behavior: 'auto',
                                block: 'start',
                            });
                            window.scrollBy(0, -80);
                        } else {
                            window.scrollBy(0, delta);
                        }
                    }
                }
            };

            const resetInsideScroll = () => {
                if (delta > 0 && container.getBoundingClientRect().bottom < 0) {
                    container.scrollBy(0, Math.round(container.scrollHeight) - Math.round(containerScrollTop));
                } else if (delta < 0 && container.getBoundingClientRect().top > windowHeight) {
                    container.scrollBy(0, 0);
                }
            };

            const setInsideScroll = () => {
                if (
                    (delta > 0 &&
                        Math.round(container.scrollHeight) - Math.round(containerScrollTop) !==
                            Math.round(container.clientHeight)) ||
                    (delta < 0 && containerScrollTop !== 0)
                ) {
                    container.scrollBy(0, delta);
                } else {
                    window.scrollBy(0, delta);
                }
            };

            if (notInView) {
                resetInsideScroll();
                window.scrollBy(0, delta);
            } else {
                if (
                    Math.round(container.getBoundingClientRect().top) !== 80 &&
                    ((containerScrollTop === 0 && delta > 0) ||
                        (delta < 0 &&
                            Math.round(container.scrollHeight) - Math.round(containerScrollTop) ===
                                Math.round(container.clientHeight)))
                ) {
                    moveToStartPosition();
                } else {
                    setInsideScroll();
                }
            }
        };
        window.addEventListener('wheel', handleScroll, {passive: false});

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [scrollContainerRef]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                if (scrollContainerRef.current) {
                    scrollContainerRef.current.scrollTo(0, 0);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={css(Styles.chat)}>
            <div className={css(Styles.chatScreen)}>
                <div
                    ref={scrollContainerRef}
                    className={css(Styles.chatConversation)}
                >
                    <div className={css(Styles.message, Styles.messageUser)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <span>You</span>
                            <div className={css(Styles.messageImg, Styles.messageImgUser)}>
                                <PreviewPicture
                                    image={{
                                        file_name: 'user.png',
                                        url: UserImg,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.messageBodyUser)}>
                            <div className={css(Styles.messageName)}>You</div>
                            <div className={css(Styles.messageBlobContainer)}>
                                <div
                                    className={css(
                                        Styles.messageBlob,
                                        Styles.messageBlobUser,
                                        Styles.messageBlobFirst,
                                        Styles.messageBlobFirstUser
                                    )}
                                >
                                    Hey Whitetail, need help improving first-time conversion rates
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <div className={css(Styles.messageImg)}>
                                <LogoRound />
                            </div>
                            <span>Whitetail AI</span>
                        </div>
                        <div>
                            <div className={css(Styles.messageName)}>Whitetail AI</div>
                            <div className={css(Styles.messageBlobContainer)}>
                                <div className={css(Styles.messageBlob, Styles.messageBlobFirst)}>
                                    Sure thing! Three actions to focus on:
                                </div>
                            </div>
                            <div className={css(Styles.messageBlobContainer)}>
                                <div className={css(Styles.messageBlob)}>
                                    <ol>
                                        <li>Visit purchase page 2+ times (30% higher spending).</li>
                                        <li>Log in 3+ times in 1st 3 days (25% more likely to spend).</li>
                                        <li>Check "About Me" in 1st session (42% more likely to spend).</li>
                                    </ol>
                                </div>
                            </div>
                            <div className={css(Styles.messageBlobContainer)}>
                                <div className={css(Styles.messageBlob)}>
                                    Would you like some recommendations to improve these actions?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message, Styles.messageUser)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <span>You</span>
                            <div className={css(Styles.messageImg, Styles.messageImgUser)}>
                                <PicturePreview
                                    image={{
                                        file_name: 'user.png',
                                        url: UserImg,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.messageBodyUser)}>
                            <div className={css(Styles.messageName)}>You</div>
                            <div
                                className={css(
                                    Styles.messageBlob,
                                    Styles.messageBlobUser,
                                    Styles.messageBlobFirst,
                                    Styles.messageBlobFirstUser
                                )}
                            >
                                Definitely!
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <div className={css(Styles.messageImg)}>
                                <LogoRound />
                            </div>
                            <span>Whitetail AI</span>
                        </div>
                        <div>
                            <div className={css(Styles.messageName)}>Whitetail AI</div>
                            <div className={css(Styles.messageBlob, Styles.messageBlobFirst)}>
                                Great! 🚀 Can you upload your data for a more in-depth analysis?
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message, Styles.messageUser)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <span>You</span>
                            <div className={css(Styles.messageImg, Styles.messageImgUser)}>
                                <PicturePreview
                                    image={{
                                        file_name: 'user.png',
                                        url: UserImg,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.messageBodyUser)}>
                            <div className={css(Styles.messageName)}>You</div>
                            <div
                                className={css(
                                    Styles.messageBlob,
                                    Styles.messageBlobUser,
                                    Styles.messageBlobFirst,
                                    Styles.messageBlobFirstUser
                                )}
                            >
                                Sure, here it is.
                            </div>
                            <div className={css(Styles.messageFile)}>
                                <CsvIcon
                                    className={css(Styles.messageFileIcon)}
                                    width="40"
                                    height="50"
                                />
                                <div className={css(Styles.messageFileName)}>Company data.csv</div>
                                <div className={css(Styles.messageFileSize)}>1.47Mb</div>
                            </div>
                            <div className={css(Styles.messageBlob, Styles.messageBlobUser)}>
                                What is the first purchase somebody made?
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <div className={css(Styles.messageImg)}>
                                <LogoRound />
                            </div>
                            <span>Whitetail AI</span>
                        </div>
                        <div>
                            <div className={css(Styles.messageName)}>Whitetail AI</div>
                            <div className={css(Styles.messageBlob, Styles.messageBlobFirst)}>
                                Awesome! 📊 Analyzing now. Here are the first purchase by item by date for the past
                                week:
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <div className={css(Styles.messageImg)}>
                                <LogoRound />
                            </div>
                            <span>Whitetail AI</span>
                        </div>
                        <div>
                            <div className={css(Styles.messageName)}>Whitetail AI</div>
                            <PreviewPicture
                                image={{
                                    file_name: 'chat1.png',
                                    url: ChatImg1,
                                }}
                                img_tag={{
                                    className: css(Styles.messagePicture),
                                    width: '450',
                                }}
                            />
                            <div className={css(Styles.messageBlob)}>
                                It looks like there's a big difference in lifetime value depending on what users
                                purchase first
                            </div>
                            <div className={css(Styles.messageBlob)}>
                                <ul className={css(Styles.messageList)}>
                                    <li>Optimex Whey : 47% of purchases ($30.13 avg. LTV)</li>
                                    <li>Creatine Rx 40% of purchases ($35.28 avg. LTV)</li>
                                    <li>Amino Load 10% of purchases ($31.18 avg LTV)</li>
                                    <li>Mass Gainer: 3% of purchases ($29.17 avg. LTV)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message, Styles.messageUser)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <span>You</span>
                            <div className={css(Styles.messageImg, Styles.messageImgUser)}>
                                <PreviewPicture
                                    image={{
                                        file_name: 'user.png',
                                        url: UserImg,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.messageBodyUser)}>
                            <div className={css(Styles.messageName)}>You</div>
                            <div
                                className={css(
                                    Styles.messageBlob,
                                    Styles.messageBlobUser,
                                    Styles.messageBlobFirst,
                                    Styles.messageBlobFirstUser
                                )}
                            >
                                What can I do to increase Item B purchases?
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <div className={css(Styles.messageImg)}>
                                <LogoRound />
                            </div>
                            <span>Whitetail AI</span>
                        </div>
                        <div>
                            <div className={css(Styles.messageName)}>Whitetail AI</div>
                            <div className={css(Styles.messageBlob, Styles.messageBlobFirst)}>
                                Here are 3 things you can do (ordered by expected ROI):
                                <ol>
                                    <li>Create a limited time offer pop-up for Item B</li>
                                    <li>Make sure to highlight Item B the most in the store</li>
                                    <li>
                                        Improve merchandising on page (strike through, % off, anything to show value)
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className={css(Styles.message, Styles.messageUser)}>
                        <div className={css(Styles.messageImgBlock)}>
                            <span>You</span>
                            <div className={css(Styles.messageImg, Styles.messageImgUser)}>
                                <PreviewPicture
                                    image={{
                                        file_name: 'user.png',
                                        url: UserImg,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css(Styles.messageBodyUser)}>
                            <div className={css(Styles.messageName)}>You</div>
                            <div
                                className={css(
                                    Styles.messageBlob,
                                    Styles.messageBlobUser,
                                    Styles.messageBlobFirst,
                                    Styles.messageBlobFirstUser
                                )}
                            >
                                Thanks!
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(Styles.chatNav)}>
                    <div className={css(Styles.inputContainer)}>
                        <TextField
                            type="text"
                            label="Ask me anything"
                            labelHidden
                            placeholder="Ask me anything"
                            defaultValue=""
                            styles={{
                                element: Styles.input,
                            }}
                        />
                    </div>
                    <button
                        className={css(Styles.chatNavBtn)}
                        type="button"
                    >
                        <SendIcon
                            width="22"
                            height="22"
                        />
                    </button>
                </div>
                <div className={css(Styles.chatConversationShadow)} />
            </div>
        </div>
    );
};

const From = () => {
    const listItems = () => (
        <ul className={css(Styles.fromList)}>
            <li className={css(Styles.fromItem)}>
                <Apple />
            </li>
            <li className={css(Styles.fromItem)}>
                <Yahoo />
            </li>
            <li className={css(Styles.fromItem)}>
                <Activision />
            </li>
            <li className={css(Styles.fromItem)}>
                <SpringerNature />
            </li>
            <li className={css(Styles.fromItem)}>
                <GameSpot />
            </li>
            <li className={css(Styles.fromItem)}>
                <BlackRock />
            </li>
            <li className={css(Styles.fromItem)}>
                <N3twork />
            </li>
            <li className={css(Styles.fromItem)}>
                <Imangi />
            </li>
            <li className={css(Styles.fromItem)}>
                <Quantlab />
            </li>
        </ul>
    );

    return (
        <div className={css(Styles.from)}>
            <div className={css(Styles.fromBlock)}>
                <div className={css(Styles.fromTitle)}>Built by Folks from</div>
                <div className={css(Styles.marqueeBlock)}>
                    <div className={css(Styles.marquee)}>{listItems()}</div>
                    <div className={css(Styles.marquee, Styles.marqueeSecond)}>{listItems()}</div>
                </div>
            </div>
        </div>
    );
};
