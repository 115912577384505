import {put} from 'lib/fetch';

export type ResendVerificationCodeAPIResponse = {
    token: string;
};

export default function ResendVerificationCodeAPI(email: string) {
    return put<ResendVerificationCodeAPIResponse>({
        url: '/session/emails/verifications',
        body: {email},
    });
}
