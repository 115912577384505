import {put} from 'lib/fetch';
import {SessionRuntype} from 'src/runtime/session/model';

export type ProfileDetails = Partial<{
    first_name: string;
    last_name: string;
    company_guid: string; // required to update the fields below
    company_name: string;
    company_industry: string;
    company_size: string;
}>;

export default function UpdateProfileDetailsAPI(data: ProfileDetails) {
    return put(
        {
            url: '/session/profile',
            body: data,
        },
        SessionRuntype
    );
}
