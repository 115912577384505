import {DraftTheme, DraftLayout} from 'packages/draftjs/Draft.theme';
import {GetColor} from 'aphrodite';

export const DraftDefaultLayout: DraftLayout = {
    editor_padding: 5,
    editor_min_height: 100,
    editor_max_height: 'initial',
    editor_font_size: '1rem',
    editor_border_top_left_radius: 0,
    editor_border_top_right_radius: 0,
    editor_border_bottom_left_radius: 6,
    editor_border_bottom_right_radius: 6,
    nav_border_top_left_radius: 6,
    nav_border_top_right_radius: 6,
    nav_border_bottom_left_radius: 0,
    nav_border_bottom_right_radius: 0,
};

export const PrimaryDraft: DraftTheme = {
    editor_background: GetColor('PageBg'),
    editor_color: GetColor('TextColor'),
    editor_border: `1px solid ${GetColor('Primary')}`,
    nav_background: GetColor('PageBg'),
    nav_border: `1px solid ${GetColor('Primary')}`,
    nav_border_bottom: 'none',
    nav_item_background: GetColor('PageBg'),
    nav_item_hover_background: GetColor('PageBg'),
    nav_item_color: GetColor('Primary'),
};

export const PrimaryNoBorders: DraftTheme = {...PrimaryDraft, editor_border: 'none', nav_border: 'none'};

export const BlackAndWhiteDraft: DraftTheme = {
    editor_background: 'white',
    editor_color: 'black',
    editor_border: `1px solid ${GetColor('Primary')}`,
    nav_background: GetColor('Primary'),
    nav_border: `1px solid ${GetColor('Primary')}`,
    nav_border_bottom: 'none',
    nav_item_background: GetColor('Primary'),
    nav_item_hover_background: GetColor('Primary'),
    nav_item_color: 'white',
};
