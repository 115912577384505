import {CSSProperties, CreateSheet, css} from 'aphrodite';
import Spinner, {SpinnerTheme} from 'packages/spinners/spinner.react';
import {ButtonStyleType, ButtonVariant, MakeButton} from './button';
import {LoadButtonTheme} from 'packages/spinners/spinner.theme';

export type LoadButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    loading: boolean;
    spinnerTheme?: SpinnerTheme;
    styles?: ButtonStyleType[];
    variant: ButtonVariant;
};

export default function LoadButton({children, loading, spinnerTheme, variant, ...rest}: LoadButtonProps) {
    const dsiabled = loading || rest.disabled;
    const styles = rest.styles ? (Array.isArray(rest.styles) ? rest.styles : [rest.styles]) : [];
    return (
        <button
            {...{
                ...rest,
                disabled: dsiabled,
                className: MakeButton(variant, Styles.button, ...styles),
            }}
        >
            {children}
            {loading && (
                <aside className={css(Styles.spinner, SpinnerBlock('inherit'))}>
                    <Spinner
                        {...{
                            theme: spinnerTheme ? spinnerTheme : LoadButtonTheme,
                            height: '100%',
                        }}
                    />
                </aside>
            )}
        </button>
    );
}

const Styles = CreateSheet({
    button: {
        position: 'relative',
    },
    spinner: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        ':nth-child(1n) svg': {
            marginRight: 0,
        },
    },
});

const SpinnerBlock = (color: CSSProperties['backgroundColor']) => {
    return CreateSheet({
        base: {
            backgroundColor: color ? color : 'white',
            borderRadius: 'inherit',
        },
    }).base;
};
