import {css} from 'aphrodite';
import useSimpleError from 'lib/hooks/useSimpleError';
import useWaitFor from 'lib/hooks/useWaitFor';
import {LoadButton} from 'packages/elements/button';
import CodeInput from 'packages/elements/textfields/CodeInput';
import TextField from 'packages/elements/textfields/TextField';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {SmoothSteper, SmoothSteperItem} from 'packages/motion/SmoothStepper.react';
import {useRef, useState} from 'react';
import {routing} from 'src/runtime/router';
import Styles from './common.jss';
import {useAuthStore} from './store';

export default function ResetPasswordPage() {
    const [email, setEmail] = useAuthStore(state => [state.email, state.setEmail]);
    const [resetPasswordRequest, confirmPasswordReset] = useAuthStore(state => [
        state.resetPasswordRequest,
        state.confirmPasswordReset,
    ]);
    const [activeStep, setActiveStep] = useState<'email' | 'verification'>('email');
    const [code, setCode] = useState<string>('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [hasErr, setErr] = useSimpleError<'email' | 'password' | 'common', string>();
    const verificationToken = useRef('');
    const [isLoading, withLoading] = useWaitFor();

    async function onSubmitResetPassword(e: React.FormEvent) {
        e.preventDefault();
        if (activeStep === 'email') {
            if (email.trim() === '') {
                setErr('email', 'Email is required');
            } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
                setErr('email', 'Please enter a valid email address');
            } else {
                const res = await withLoading(resetPasswordRequest(email));
                if (res[1]) {
                    setErr('common', res[1].message);
                } else {
                    verificationToken.current = res[0].token;
                    setActiveStep('verification');
                }
            }
        } else {
            if (code.length !== 6) {
                setErr('common', 'Please enter a valid code');
            } else if (password.trim() === '') {
                setErr('password', 'Password is required');
            } else {
                const res = await withLoading(confirmPasswordReset(verificationToken.current, code, password));
                if (res) {
                    setErr('common', res.message);
                } else {
                    routing.push('/login');
                }
            }
        }
    }

    function onChangeCode(code: string) {
        setErr('common', false);
        setCode(code);
        if (code.length === 6 && showPassword !== true) {
            setShowPassword(true);
        }
    }

    return (
        <div className={css(Styles.containerMiddle)}>
            <form
                className={css(Styles.form)}
                onSubmit={onSubmitResetPassword}
            >
                <h1 className={css(Styles.formTitle)}>Reset password</h1>
                <div className={css(Styles.reset)}>
                    <SmoothSteper
                        active={activeStep === 'email' ? 0 : 1}
                        duration={300}
                        grow={true}
                        motion={['swipe-in-up', 'fade']}
                    >
                        <SmoothSteperItem>
                            <div className={css(Styles.inputContainer)}>
                                <TextField
                                    type="text"
                                    label="Email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={e => {
                                        setErr('email', false);
                                        setEmail(e.target.value);
                                    }}
                                    error={hasErr('email') || undefined}
                                    styles={{
                                        element: Styles.input,
                                    }}
                                />
                            </div>
                        </SmoothSteperItem>
                        <SmoothSteperItem>
                            <p className={css(Styles.codeText)}>
                                Enter the verification code we send to:{' '}
                                <strong className={css(Styles.codeTextStrong)}>{email}</strong>
                            </p>
                            <div className={css(Styles.inputContainer)}>
                                <CodeInput
                                    {...{
                                        inputMode: 'numeric',
                                        fields: 6,
                                        value: code,
                                        onChange: onChangeCode,
                                    }}
                                />
                            </div>
                            <SmoothLine>
                                {showPassword ? (
                                    <div className={css(Styles.inputContainer)}>
                                        <TextField
                                            type="text"
                                            label="Password"
                                            placeholder="Enter new password"
                                            value={password}
                                            onChange={e => {
                                                setErr('password', false);
                                                setPassword(e.target.value);
                                            }}
                                            error={hasErr('password') || undefined}
                                            styles={{
                                                element: Styles.input,
                                            }}
                                        />
                                    </div>
                                ) : undefined}
                            </SmoothLine>
                        </SmoothSteperItem>
                    </SmoothSteper>
                    <SmoothLine>
                        {hasErr('common') ? <div className={css(Styles.errorText)}>{hasErr('common')}</div> : undefined}
                    </SmoothLine>
                    <LoadButton
                        type="submit"
                        variant="FilledAccent1-Big"
                        styles={[Styles.button, Styles.verifyBtn]}
                        loading={isLoading}
                    >
                        Continue
                    </LoadButton>
                </div>
            </form>
        </div>
    );
}
