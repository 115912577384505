import {get, makeAPIHook} from 'lib/fetch';
import {ProjectChatRuntype} from '../model';

export default async function LoadProjectChatAPI(project_uri: string) {
    return await get(
        {
            url: `projects/${project_uri}/chat`,
        },
        ProjectChatRuntype
    );
}

export const useLoadProjectChatAPI = makeAPIHook(LoadProjectChatAPI);
