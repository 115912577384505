import {CreateSheet, css} from 'aphrodite';
import useWaitFor from 'lib/hooks/useWaitFor';
import {Body, ComponentProps, Footer, Header, Lightbox} from 'lib/lightbox/lightbox';
import {showErrorLightbox} from 'lib/lightbox/warning.react';
import {LoadButton, MakeButton} from 'packages/elements/button';
import TextField from 'packages/elements/textfields/TextField';
import {Project} from 'packages/projects';
import UpdateProjectAPI from 'packages/projects/api/UpdateProjectAPI';
import {useState} from 'react';

type EditProjectTitleLBData = {
    title: string;
    uri: string;
    onSave?: (newProject: Project) => void;
};

const EditProjectTitleLB = (data: ComponentProps<EditProjectTitleLBData>) => {
    const [title, setTitle] = useState(data.content.title);
    const [isLoading, withLoading] = useWaitFor();

    const onSubmit = async () => {
        const response = await withLoading(UpdateProjectAPI(data.content.uri, {name: title}));
        if (response[1]) {
            showErrorLightbox(response[1].message);
            return;
        }

        data.content.onSave?.(response[0]);
        data.close();
    };

    return (
        <Lightbox {...{close: data.close}}>
            <Header>Edit project</Header>
            <Body>
                <TextField
                    type="text"
                    label="Title"
                    placeholder="Enter your project title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    styles={{
                        element: Styles.input,
                    }}
                />
            </Body>
            <Footer styles={Styles.footer}>
                <div className={css(Styles.grow)}></div>
                <button
                    className={MakeButton('FilledPageBg-Big')}
                    onClick={() => data.close(true)}
                >
                    Cancel
                </button>
                <LoadButton
                    onClick={onSubmit}
                    loading={isLoading}
                    variant="FilledAccent1-Big"
                >
                    Save
                </LoadButton>
            </Footer>
        </Lightbox>
    );
};
export default EditProjectTitleLB;

const Styles = CreateSheet({
    input: {
        width: '100%',
    },
    footer: {
        display: 'flex',
        gap: 10,
    },
    grow: {
        flexGrow: 1,
    },
});
