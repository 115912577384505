import {CreateSheet, GeneratedStyles, GetColor, css} from 'aphrodite';
import useOnce from 'lib/hooks/useOnce';
import {showErrorLightbox} from 'lib/lightbox/warning.react';
import {hasRequestedFieldInMessage, useProjectChatStore} from './ProjectChatStore';
import {ReactComponent as OverviewIcon} from 'src/icons/overview.svg';
import {Fragment, useEffect, useRef} from 'react';
import {
    ChatComponent,
    ChatTextInput,
    ChatAIMessages,
    ChatUserMessages,
    ChatMessage,
    ChatSystemMessages,
} from 'packages/chat';
import {ProjectChatMessage} from 'packages/projects/generalchat/model';
import {routing} from 'src/runtime/router';
import CreateNATSConnection from 'src/runtime/nats';
import {ServerToUserMessages} from 'src/runtime/nats/ServerToUserMessages';
import {FacebookConnectButton} from '../../pages/Onboarding/parts/FacebookConnect';
import {ProjectStatus} from 'packages/projects';

export default function ProjectChat({
    company_uri,
    project_uri,
    styles,
    hideAvatars,
    isOverlay,
}: {
    company_uri: string;
    project_uri: string;
    styles?: {
        messages?: GeneratedStyles[];
        input?: GeneratedStyles[];
        systemMessage?: GeneratedStyles[];
        component?: GeneratedStyles[];
    };
    hideAvatars?: boolean;
    isOverlay?: boolean;
}) {
    const [initProjectChat, reloadChat, isLoading] = useProjectChatStore(s => [s.init, s.reload, s.isLoading]);
    const [messages, canSubmit, sendNewMessage] = useProjectChatStore(s => [
        s.messages,
        s.canSubmitNewMessage,
        s.sendNewMessage,
    ]);

    const messagesRef = useRef<HTMLDivElement | null>(null);

    async function onSubmitNewMessage(message: string) {
        const err = await sendNewMessage(message);
        if (err !== null) {
            showErrorLightbox(err.message);
        }
    }

    function OnPersonalMessage(message: ServerToUserMessages['server.user.personal.user_token']) {
        if (message.marker === 'general-changed') {
            const messages = useProjectChatStore.getState().messages;
            // no need to update anything
            if (messages.length > 0 && messages[messages.length - 1].guid === message.payload.last_message_guid) {
                return;
            }
            reloadChat();
        }
    }

    useOnce(() => {
        async function init() {
            const err = await initProjectChat(company_uri, project_uri);
            if (err !== null) {
                showErrorLightbox(err.message);
            }
        }

        init();
    });

    useOnce(() => {
        const nats = CreateNATSConnection();
        nats.ListenPersonal(OnPersonalMessage);

        return () => {
            nats.StopListenPersonal(OnPersonalMessage);
        };
    });

    useEffect(() => {
        if (isOverlay) {
            setTimeout(() => {
                messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
            }, 0);
            return;
        }

        window.scrollTo(0, document.body.scrollHeight);
    }, [isOverlay, messages.length]);

    return (
        <ChatComponent
            ref={messagesRef}
            messages={
                <>
                    {messages.map((msg, i) => {
                        let Component = msg.role === 'user' ? ChatUserMessages : ChatAIMessages;

                        return (
                            <Fragment key={i}>
                                <Component hideAvatars={hideAvatars}>
                                    <ChatMessage
                                        isFirst
                                        isUser={msg.role === 'user'}
                                    >
                                        {msg.content}
                                    </ChatMessage>
                                </Component>
                                <MessageAdditionalContent
                                    message={msg}
                                    company_uri={company_uri}
                                    project_uri={project_uri}
                                    styles={{systemMessage: styles?.systemMessage}}
                                />
                            </Fragment>
                        );
                    })}
                </>
            }
            input={
                <ChatTextInput
                    onSubmit={onSubmitNewMessage}
                    props={{
                        textarea: {
                            disabled: !canSubmit || isLoading,
                            placeholder: 'Message whitetail.ai',
                        },
                        button: {
                            loading: isLoading,
                            disabled: !canSubmit,
                        },
                    }}
                />
            }
            styles={styles}
        />
    );
}

function MessageAdditionalContent({
    company_uri,
    project_uri,
    message,
    styles,
}: {
    company_uri: string;
    project_uri: string;
    message: ProjectChatMessage;
    styles?: {
        systemMessage?: GeneratedStyles[];
    };
}) {
    if (hasRequestedFieldInMessage(message, 'data_source_type')) {
        return (
            <ChatSystemMessages styles={{message: styles?.systemMessage}}>
                <ConnectedDataSourceBlock />
            </ChatSystemMessages>
        );
    } else if (message.parsed_payload?.is_project_ready) {
        return (
            <ChatSystemMessages styles={{message: styles?.systemMessage}}>
                <div
                    className={css(Styles.link)}
                    onClick={routing.OpenPage(`/ai/${company_uri}/${project_uri}`)}
                >
                    <OverviewIcon
                        width={16}
                        height={16}
                        className={css(Styles.linkIcon)}
                    />{' '}
                    View Dashboard
                </div>
            </ChatSystemMessages>
        );
    }

    return null;
}

function ConnectedDataSourceBlock() {
    const [project] = useProjectChatStore(s => [s.project]);

    // TODO with more datasource would have to parse them first and verify that FB is connected
    return <FacebookConnectButton isConnected={project?.status_id === ProjectStatus.Ready} />;
}

const Styles = CreateSheet({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 870,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 40px 120px 40px',
        '@media(max-width: 800px)': {
            padding: '0 20px',
        },
    },
    link: {
        display: 'inline-flex',
        borderRadius: 14,
        padding: '10px 14px 10px 14px',
        margin: '7px 0 0 0',
        color: GetColor('NegativeTextColor'),
        background: GetColor('Primary'),
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
    },
    linkIcon: {
        color: GetColor('NegativeTextColor'),
        marginRight: 8,
    },
    chatTextInputContainer: {
        // position: 'fixed',
    },
});
