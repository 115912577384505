import {get} from 'lib/fetch';
import {OnboardingMessageRuntype} from '../model';
import * as r from 'runtypes';

export default async function LoadOnboardingInitialMessagesAPI(company_uri: string) {
    return await get(
        {
            url: `companies/${company_uri}/projects/onboarding`,
        },
        r.Record({
            messages: r.Array(OnboardingMessageRuntype),
        })
    );
}
