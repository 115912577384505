// icons
import {ReactComponent as StarIcon} from 'src/icons/star-filled.svg';
// styles
import {css, CreateSheet, GetColor, GeneratedStyles} from 'aphrodite';
import {PropsWithChildren, useEffect, useRef} from 'react';

type ComingSoonProps = {
    styles?: {
        wrapper?: GeneratedStyles;
        container?: GeneratedStyles;
        childrenContainer?: GeneratedStyles;
    };
};

export const ComingSoon: React.FC<PropsWithChildren<ComingSoonProps>> = ({styles, children}) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = elementRef.current;
        if (el) {
            if (el.children.length > 0 && el.children[0].nodeType === Node.ELEMENT_NODE) {
                setTimeout(() => {
                    const child = el.children[0] as HTMLElement;
                    child.className += ' ' + css(Styles.child);
                }, 0);
            }
        }
    }, [elementRef]);
    return (
        <>
            {children ? (
                <div
                    ref={wrapperRef}
                    className={css(Styles.containerWrapper, styles?.wrapper)}
                >
                    <div className={css(Styles.container, styles?.container)}>
                        <StarIcon
                            width="13"
                            height="13"
                            className={css(Styles.icon)}
                        />
                        <div className={css(Styles.label)}>Coming soon</div>
                    </div>
                    <div
                        ref={elementRef}
                        className={css(styles?.childrenContainer)}
                    >
                        {children}
                    </div>
                </div>
            ) : (
                <div className={css(Styles.container, Styles.standalone, styles?.container)}>
                    <StarIcon
                        width="13"
                        height="13"
                        className={css(Styles.icon)}
                    />
                    <div className={css(Styles.label)}>Coming soon</div>
                </div>
            )}
        </>
    );
};

const Styles = CreateSheet({
    containerWrapper: {
        position: 'relative',
        pointerEvents: 'none',
    },
    container: {
        position: 'absolute',
        zIndex: 4,
        top: 10,
        right: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 7px',
        gap: 5,
        opacity: 1,
        borderRadius: 8,
        background: GetColor('StrokeColor'),
    },
    standalone: {
        position: 'relative',
        top: 'unset',
        right: 'unset',
    },
    child: {
        opacity: 0.5,
        cursor: 'default',
        ':hover': {
            boxShadow: 'none',
        },
    },
    icon: {
        display: 'flex',
        marginTop: -2,
        color: GetColor('Primary'),
    },
    label: {
        fontSize: '0.8125rem',
        fontWeight: 600,
        lineHeight: '100%',
        color: GetColor('Primary'),
    },
});
