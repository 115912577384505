import {css} from 'aphrodite';
import {routing} from 'src/runtime/router';
import {getBasePath, MenuItem, MenuSections, NON_COLLAPSIBLE_SECTIONS} from '../../menuItems';
import {useState} from 'react';
import SmoothLine from 'packages/motion/SmoothLine.react';
import {ReactComponent as ArrowDownIcon} from 'src/icons/arrow-down.svg';
import {ReactComponent as SettingsIcon} from 'src/icons/settings.svg';
import Styles from './Sidebar.jss';
import {WithCustomScrollbar} from 'packages/elements/scrollbar/scrollbar';
import {useDashboardStore} from '../../DashboardStore';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';
import {GetProjectName} from 'packages/projects/helpers/GetProjectName';
// import {UpgradeNow} from './components/UpgradeNow/UpgradeNow';

export const Sidebar: React.FC = () => {
    const [company_uri, project_uri, project] = useDashboardStore(store => [
        store.company_uri,
        store.project_uri,
        store.project,
    ]);

    return (
        <div className={css(Styles.sidebar, HeaderUtilityStyles().relatedTop)}>
            <div className={css(Styles.sidebarMenu, WithCustomScrollbar)}>
                {project && (
                    <div className={css(Styles.projectContainer)}>
                        Project
                        <div className={css(Styles.projectTitle, !project?.name && Styles.projectTitlePlaceholder)}>
                            {GetProjectName(project?.name)}
                        </div>
                    </div>
                )}
                <Menu />
            </div>
            <div className={css(Styles.sidebarInfo)}>
                {/* <UpgradeNow /> */}
                <a
                    href={getBasePath(company_uri, project_uri) + '/settings'}
                    onClick={routing.OpenPage(getBasePath(company_uri, project_uri) + '/settings')}
                    className={css(
                        Styles.linkItem,
                        window.location.pathname === getBasePath(company_uri, project_uri) + '/settings' &&
                            Styles.linkItemActive
                    )}
                >
                    <SettingsIcon
                        width={16}
                        height={16}
                        className={css(
                            Styles.linkItemIcon,
                            window.location.pathname === getBasePath(company_uri, project_uri) + '/settings' &&
                                Styles.linkItemIconActive
                        )}
                    />
                    <span>Settings</span>
                </a>
            </div>
        </div>
    );
};

const Menu: React.FC = () => {
    return (
        <div className={css(Styles.menu)}>
            {Object.entries(MenuSections).map(([section, items], i) => (
                <MenuSection
                    section={section}
                    items={items}
                    key={i}
                />
            ))}
        </div>
    );
};

type MenuSectionProps = {
    section: string;
    items: MenuItem[];
};

const MenuSection: React.FC<MenuSectionProps> = ({section, items}) => {
    const [company_uri, project_uri] = useDashboardStore(s => [s.company_uri, s.project_uri]);
    const [isClosed, setIsClosed] = useState(false);

    const canCollapse = !NON_COLLAPSIBLE_SECTIONS.includes(section);
    return (
        <>
            {section.length > 0 && (
                <button
                    type="button"
                    className={css(Styles.linkItem, Styles.linkItemSection, !canCollapse && Styles.linkItemDisabled)}
                    onClick={() => setIsClosed(!isClosed)}
                >
                    <span>{section}</span>
                    {canCollapse && (
                        <ArrowDownIcon
                            width="14"
                            height="14"
                            role="img"
                            aria-label="Arrow"
                            className={css(Styles.arrowIcon, isClosed && Styles.arrowIconClosed)}
                        />
                    )}
                </button>
            )}
            <SmoothLine initialState={true}>
                {!isClosed ? (
                    <div className={css(Styles.subLinkItem)}>
                        {items.map((item, key) => (
                            <a
                                href={item.href(company_uri, project_uri)}
                                onClick={routing.OpenPage(item.href(company_uri, project_uri))}
                                className={css(
                                    Styles.linkItem,
                                    window.location.pathname === item.href(company_uri, project_uri) &&
                                        Styles.linkItemActive
                                )}
                                key={key}
                            >
                                {item.icon(
                                    css(
                                        Styles.linkItemIcon,
                                        window.location.pathname === item.href(company_uri, project_uri) &&
                                            Styles.linkItemIconActive
                                    )
                                )}
                                <span>{item.title}</span>
                            </a>
                        ))}
                    </div>
                ) : undefined}
            </SmoothLine>
        </>
    );
};
