import {forwardRef} from 'react';
import Styles from 'packages/elements/menu/menu.jss';
import {StyleDeclaration} from 'aphrodite';
import {MenuElementProps} from '../menu/model';
import {css} from 'aphrodite';

export interface DropDownItemProps {
    disabled?: boolean;
    styles?: Array<StyleDeclaration | null>;
}

const FloatingDropDownItem = forwardRef<HTMLDivElement, MenuElementProps & DropDownItemProps>(
    ({styles, children, ...rest}, forwaredRef) => {
        return (
            <div
                {...rest}
                ref={forwaredRef}
                className={css(Styles.menu_item, rest.disabled ? Styles.menu_item_disabled : null, styles || null)}
            >
                {children}
            </div>
        );
    }
);

export default FloatingDropDownItem;
