import {ExternalFileRuntype} from 'packages/files/models';
import * as r from 'runtypes';

export const BlogPostMetadataRuntype = r.Record({
    guid: r.String,
    created_on: r.Number,
    uri: r.String,
    title: r.String,
    published_on: r.Number.Or(r.Null),
    author_name: r.String,
    is_hidden: r.Boolean,
    description: r.String,
    image: ExternalFileRuntype.Or(r.Null),
    read_duration: r.Number,
});
export type BlogPostMetadata = r.Static<typeof BlogPostMetadataRuntype>;

export const BlogPostRuntype = r.Record({
    guid: r.String,
    created_on: r.Number,
    uri: r.String,
    title: r.String,
    published_on: r.Number.Or(r.Null),
    author_name: r.String,
    is_hidden: r.Boolean,
    is_author: r.Boolean,
    body: r.String,
    image: ExternalFileRuntype.Or(r.Null),
});
export type BlogPost = r.Static<typeof BlogPostRuntype>;

export const SortByTitles = {
    published_on: 'Posted date',
    title: 'Title',
} as const;

export type CreateBlogPostInput = {
    guid: string;
    author_name: string;
    title: string;
    body: string;
    image_guid?: string;
    is_hidden: boolean;
};

export type UpdateBlogPostInput = {
    guid: string;
    author_name?: string;
    title?: string;
    body?: string;
    image_guid?: string;
    is_hidden?: boolean;
};
