import {CreateSheet, GetColor} from 'aphrodite';

export default CreateSheet({
    container: {
        width: 1140,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 40px',
        '@media(max-width: 640px)': {
            padding: '0 15px',
        },
    },
    containerMiddle: {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px 20px',
    },
    embeddedContainer: {
        padding: 0,
    },
    form: {
        width: 400,
        maxWidth: '100%',
    },
    formTitle: {
        margin: 0,
        fontSize: '2rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        textAlign: 'center',
        '@media(max-width: 640px)': {
            fontSize: '1.5rem',
        },
    },
    socialLoginBtn: {
        display: 'flex',
        width: '100%',
        height: 50,
        margin: '15px 0 0 0',
        padding: '10px 24px',
        fontFamily: "'Figtree', sans-serif",
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
        boxShadow: 'none',
        border: `1px solid ${GetColor('StrokeColor')}`,
        borderRadius: 14,
        background: GetColor('TopLayerObjectBg'),
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.2s cubic-bezier(.4,0,.2,1)',
        ':hover': {
            backgroundColor: GetColor('ButtonBgHover'),
        },
    },
    socialLoginBtnIcon: {
        display: 'block',
        marginRight: 14,
    },
    inputs: {
        marginTop: 30,
    },
    inputsTitle: {
        margin: '0 0 10px 0',
        fontSize: '0.875rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        textAlign: 'center',
    },
    inputContainer: {
        paddingBottom: 20,
    },
    noPaddingBottom: {
        paddingBottom: 0,
    },
    input: {
        width: '100%',
    },
    agree: {
        maxWidth: 300,
        margin: '10px auto 0 auto',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: GetColor('Secondary'),
        textAlign: 'center',
    },
    agreeLink: {
        fontWeight: 700,
        color: GetColor('Secondary'),
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
    button: {
        width: '100%',
        marginTop: 10,
        justifyContent: 'center',
    },
    disabledBtn: {
        opacity: 0.5,
        cursor: 'default',
    },
    switch: {
        marginTop: 50,
        fontSize: '1rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
        textAlign: 'center',
    },
    switchLink: {
        display: 'inline-block',
        marginLeft: 10,
        fontWeight: 700,
        color: GetColor('Primary'),
        textDecoration: 'underline',
    },
    containerTitle: {
        margin: '0 0 30px 0',
        fontSize: '1.375rem',
        fontWeight: 700,
        color: GetColor('TextColor'),
        textAlign: 'center',
    },
    errorContainer: {
        display: 'flex',
        paddingBottom: 20,
    },
    errorContainerIcon: {
        display: 'block',
        marginTop: 1,
        marginRight: 10,
        color: GetColor('Danger'),
    },
    errorText: {
        fontSize: '0.8125rem',
        color: GetColor('Danger'),
        fontWeight: 600,
        lineHeight: 1.2,
    },
    codeText: {
        margin: '30px 0 0 0',
        fontSize: '1rem',
        fontWeight: 600,
        color: GetColor('Secondary'),
    },
    embeddedCodeText: {
        margin: 0,
    },
    codeTextStrong: {
        display: 'block',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: GetColor('TextColor'),
    },
    codeInput: {
        padding: '20px 0',
    },
    resend: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5,
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.1,
        color: GetColor('Secondary'),
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    resendCode: {
        fontFamily: "'Figtree', sans-serif",
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: 1.1,
        color: GetColor('TextColor'),
        boxShadow: 'none',
        border: 0,
        padding: 0,
        background: 'transparent',
        textDecoration: 'underline',
        cursor: 'pointer',
        ':disabled': {
            color: GetColor('Gray'),
            cursor: 'default',
        },
    },
    reset: {
        marginTop: 30,
    },
    verifyBtn: {
        marginTop: 10,
    },
    embeddedVerificationFormFooter: {
        marginTop: 15,
        display: 'flex',
        gap: 10,
        justifyContent: 'flex-end',
    },
    extraMarginTop: {
        marginTop: 30,
    },
    verifyError: {
        marginTop: 15,
    },
    resetPasswordLinkContainer: {
        marginTop: 10,
        textAlign: 'right',
        fontSize: '0.875rem',
    },
    selectInput: {
        position: 'relative',
        width: '100%',
        paddingRight: 45,
    },
    select: {
        maxHeight: 250,
        zIndex: 999999,
    },
    selectArrow: {
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: 15,
        marginTop: -8,
        color: GetColor('Secondary'),
        transform: 'rotate(0)',
        transition: 'transform 0.2s cubic-bezier(.4,0,.2,1)',
        zIndex: 2,
    },
    selectPlaceholder: {
        color: GetColor('Secondary'),
    },
    selectArrowOpened: {
        transform: 'rotate(-180deg)',
    },
    inputError: {
        position: 'absolute',
        top: '100%',
        left: 0,
    },
    errorIcon: {
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: 15,
        marginTop: -8,
        color: GetColor('Danger'),
        zIndex: 2,
    },
    subLabel: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.2,
        color: GetColor('Secondary'),
    },
    chips: {
        display: 'flex',
        margin: '12px 0 0 0',
        flexWrap: 'wrap',
        position: 'relative',
    },
    chip: {
        marginRight: 12,
        marginBottom: 10,
        fontSize: '0.875rem',
        padding: '6px 16px',
    },
});
