import HistoryEvents from './HistoryEvents';
import {Path} from 'react-router-dom';

const routing = {
    __current_path: window.location.pathname + window.location.search,
    __prev_path: window.location.pathname + window.location.search,
    push: (path: string | Partial<Path> | number, state?: any) => {
        if (typeof path !== 'string' && typeof path !== 'number') {
            if (!path.pathname) {
                path.pathname = window.location.pathname;
            }
        }

        HistoryEvents.emit('navigate', path === '' ? '/' : path, state ? {state: state} : undefined);
    },
    replace: (path: string | Partial<Path>, state?: any) => {
        if (typeof path !== 'string') {
            if (!path.pathname) {
                path.pathname = window.location.pathname;
            }
        }

        HistoryEvents.emit('navigate', path === '' ? '/' : path, {replace: true, state});
    },
    OpenPage: (path: string | Partial<Path> | number) => (e?: React.MouseEvent) => {
        e?.preventDefault();
        HistoryEvents.emit('navigate', path === '' ? '/' : path, undefined);
        window.scrollTo(0, 0);
    },
};

export {routing};
