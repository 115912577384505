import {FloatingArrow} from '@floating-ui/react';
import React, {useContext} from 'react';
import {css} from 'aphrodite';
import RenderOutside from 'lib/portal/RenderOutside';
import TooltipContext from './TooltipContext';
import Styles, {ColorVariants} from './Tooltip.jss';
import {ContentProps} from './model';
import useOnce from 'lib/hooks/useOnce';
import {useDetachedEffect} from 'packages/useDetached';

export default function TooltipContent({
    selector,
    color,
    styles,
    showArrow,
    children,
    tooltipClassName,
    onShown,
    onHide,
}: React.PropsWithChildren<ContentProps>) {
    const context = useContext(TooltipContext);

    useDetachedEffect(() => {
        if (typeof onHide !== 'undefined' && !context!.transitions.isMounted) {
            onHide();
        }
    }, [context!.transitions.isMounted]);

    if (!context!.transitions.isMounted) return null;

    return (
        <RenderOutside selector={selector}>
            <div
                ref={context!.refs.setFloating}
                style={{
                    ...context!.transitions.styles,
                    ...context!.floatingStyles,
                }}
                className={`${css(
                    Styles.tooltip_container,
                    typeof children === 'string' ? Styles.tooltip_string : null,
                    ColorVariants[color || 'dark'],
                    styles || null
                )} ${tooltipClassName || ''}`}
                {...context!.getFloatingProps()}
            >
                {showArrow && (
                    <FloatingArrow
                        ref={context!.arrowRef}
                        context={context!.context}
                        className="tooltip-arrow"
                    />
                )}
                {children}
            </div>
            {onShown && <OnShownCallback onShown={onShown} />}
        </RenderOutside>
    );
}

function OnShownCallback({onShown}: {onShown: () => void}) {
    useOnce(() => {
        onShown();
    });

    return null;
}
