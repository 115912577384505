import {ComponentType} from 'react';
import {Lightbox, Header, Body, Footer, ComponentProps, showLightbox, LightboxTheme} from './lightbox';
import {MouseEvent} from 'react';
import CreateGUID from 'lib/helpers/CreateGUID';
import {CreateSheet} from 'lib/aphrodite/aphrodite';
import {MakeButton} from 'packages/elements/button';

/* 
    buttons is an array of html button props with extra attr: text which can be string or JSX lement
    examples:
    1. buttons: [{ text: "close", onClick: (e) => { my on click logic } }]
    2. buttons: [{ text: "close", className: "my-class-name" }]
    3. buttons: [{ text: (<div>jsx inside</div>), className: "my-class-name" }]
*/

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type Button = ButtonProps & {text: string | JSX.Element};

interface config {
    guid?: string;
    title?: string;
    buttons?: Array<
        Omit<Button, 'onClick'> & {
            preventClose?: boolean;
            onClick?: (e: MouseEvent<HTMLButtonElement>, close: () => void) => void;
        }
    >;
    OnClose?: () => void;
}

type props = config & {
    body: ComponentType | JSX.Element | string;
};

export default function showWarning(
    Component: ComponentType | JSX.Element | string,
    theme?: LightboxTheme,
    config?: config
) {
    const selector = config?.guid ? config.guid : CreateGUID();

    showLightbox<props>({
        selector: selector,
        Component: Warning,
        theme: theme,
        content: {
            ...config,
            body: Component,
        },
    });

    return selector;
}

export function showErrorLightbox(err: string) {
    showWarning(<div>Error: {err}</div>, undefined, {
        buttons: [
            {
                text: 'Close',
                className: MakeButton('Flat-Big'),
            },
        ],
    });
}

function Warning({selector, content, close}: ComponentProps<props>) {
    const Componet = content.body;

    return (
        <Lightbox {...{close}}>
            {content.title && <Header>{content.title}</Header>}
            <Body {...{styles: [Styles.body, !content.title ? Styles.bodyPadding : undefined]}}>
                {typeof Componet === 'function' ? <Componet /> : Componet}
            </Body>
            <Footer {...{styles: Styles.footer}}>
                {content.buttons &&
                    content.buttons.map(({text, preventClose, ...button}, i) => (
                        <button
                            {...button}
                            {...{
                                key: i,
                                onClick: e => {
                                    !preventClose && close();
                                    if (button.onClick) button.onClick(e, close);
                                },
                            }}
                        >
                            {text}
                        </button>
                    ))}
            </Footer>
        </Lightbox>
    );
}

const Styles = CreateSheet({
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        transition: 'all 0.1',
        ':nth-child(1n) button': {
            marginLeft: 10,
        },
    },
    body: {
        fontWeight: 600,
    },
    bodyPadding: {
        paddingRight: 50,
    },
});
