import {ProjectAccessRuntype} from 'generated/AccessPolicies';
import {DataSourceRuntype} from 'packages/projects/datasources';
import * as r from 'runtypes';
import {RuntypeFromEnum} from 'lib/types/RuntypeFromEnum';

export enum ProjectStatus {
    Created = 'created',
    Analyzing = 'analyzing',
    Ready = 'ready',
    Paused = 'paused',
}
export const ProjectStatusRuntype = RuntypeFromEnum(ProjectStatus);

export const ProjectRuntype = r.Record({
    guid: r.String,
    name: r.String,
    uri: r.String,
    company_guid: r.String,
    status_id: ProjectStatusRuntype,
    created_at: r.Number,
    policy: ProjectAccessRuntype,
});
export type Project = r.Static<typeof ProjectRuntype>;

export const ProjectWithDetailsRuntype = ProjectRuntype.extend({
    data_sources: r.Array(DataSourceRuntype),
});
export type ProjectWithDetails = r.Static<typeof ProjectWithDetailsRuntype>;
