import React, {ComponentType} from 'react';
import {ReactComponent as ArrowUpLongIcon} from 'src/icons/arrow-up-long.svg';
import {ReactComponent as ArrowDownLongIcon} from 'src/icons/arrow-down-long.svg';
import {StyleDeclaration, css} from 'aphrodite';
import Styles from './PrettyTable.css';

type TableElement = string | number | undefined | null | ComponentType | JSX.Element;

type SortableHeaderItem<K extends string> = {
    Element: TableElement;
    key: K;
    direction?: string | false;
    onChange: (key: K, direction: 'ASC' | 'DESC') => void;
    defaultOrder?: 'ASC' | 'DESC';
};

function IsSortable<K extends string = ''>(head: TableElement | SortableHeaderItem<K>): head is SortableHeaderItem<K> {
    return (head as SortableHeaderItem<K>).direction !== undefined;
}

export type TableHeader<K extends string = ''> = Array<TableElement | SortableHeaderItem<K>>;
export type TableBodyItem = Array<TableElement>;
export type TableBody = Array<TableBodyItem>;

interface props<T, S extends string = ''> {
    thead?: TableHeader<S>;
    tbody?: TableBody;
    rowsStyles?: Array<StyleDeclaration | undefined>;
    columnsSizes?: Array<number | 'auto'>;
    onItemClick?: (item: T, e: React.MouseEvent) => void;
    items?: Array<T>;
}

// !!!
// PLEASE DON'T JUST CHANGE STYLES
// YOU CAN MAKE THEM CONFIGURABLE
// E.G. IF YOU NEED TO HIDE BORDER
// MAKE A NEW FLAG INSIDE CFG
// AND CHANGE MakeTableCSS FUNCTION

export default function PrettyTable<T, S extends string = ''>({
    thead,
    tbody,
    rowsStyles,
    columnsSizes,
    items,
    onItemClick,
}: props<T, S>) {
    const TSize = tbody && tbody.length > 0 ? Math.max(...tbody.map(trows => trows.length)) : 0;

    return (
        <div className={css(Styles.table)}>
            <div className={css(Styles.row, Styles.headerRow)}>
                {thead &&
                    thead.map((HColumn, key) => (
                        <div
                            key={key}
                            className={css(Styles.col, Styles.headerCol)}
                            style={{width: columnsSizes && columnsSizes[key] ? columnsSizes[key] : undefined}}
                        >
                            {IsSortable(HColumn) ? (
                                <SortableHeader
                                    direction={
                                        HColumn.direction ? (HColumn.direction === 'ASC' ? 'ASC' : 'DESC') : false
                                    }
                                    onClick={() => {
                                        HColumn.onChange(
                                            HColumn.key,
                                            HColumn.direction
                                                ? HColumn.direction === 'ASC'
                                                    ? 'DESC'
                                                    : 'ASC'
                                                : HColumn.defaultOrder || 'DESC'
                                        );
                                    }}
                                >
                                    {typeof HColumn.Element === 'function' ? <HColumn.Element /> : HColumn.Element}
                                </SortableHeader>
                            ) : typeof HColumn === 'function' ? (
                                <HColumn />
                            ) : (
                                HColumn
                            )}
                        </div>
                    ))}
            </div>

            {tbody?.map((TColumns, key) => (
                <div
                    key={key}
                    className={css(
                        Styles.row,
                        rowsStyles && rowsStyles[key] && rowsStyles[key],
                        typeof onItemClick === 'function' && Styles.clickable
                    )}
                    onClick={e => {
                        if (items && typeof onItemClick === 'function') {
                            e.persist();
                            onItemClick(items[key], e);
                        }
                    }}
                >
                    {[...new Array(TSize)].map((k, i) => {
                        const TColumn = TColumns[i];

                        return (
                            <div
                                key={i}
                                className={css(Styles.col)}
                                style={{width: columnsSizes && columnsSizes[key] ? columnsSizes[key] : undefined}}
                            >
                                {typeof TColumn === 'function' ? <TColumn /> : TColumn}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export function SortableHeader({
    children,
    direction,
    onClick,
}: React.PropsWithChildren<{
    direction: 'ASC' | 'DESC' | false;
    onClick: () => void;
}>) {
    return (
        <span
            onClick={onClick}
            className={css(Styles.sort)}
        >
            <span>{children}</span>
            <button
                type="button"
                onClick={onClick}
                className={css(Styles.sortBtn)}
            >
                {direction === 'ASC' ? (
                    <ArrowUpLongIcon
                        width="12"
                        height="12"
                        role="img"
                        aria-label="Ascending"
                    />
                ) : null}
                {direction === 'DESC' ? (
                    <ArrowDownLongIcon
                        width="12"
                        height="12"
                        role="img"
                        aria-label="Descending"
                    />
                ) : null}
            </button>
        </span>
    );
}
