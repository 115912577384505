import EventEmitter from 'eventemitter3';
import {LoadSessionAPI} from './API/LoadSessionAPI';
import {SignOutAPI} from './API/SignOutAPI';
import {Session, SessionUser} from './model';
import CreateGUID from 'lib/helpers/CreateGUID';
import UpdateProfileDetailsAPI, {ProfileDetails} from './API/UpdateProfileDetailsAPI';
import {FetchResponseError} from 'lib/fetch';
import SubmitProfileDataAPI, {SubmitProfileDataInput} from './API/SubmitProfileDataAPI';

export const Events = new EventEmitter<{
    'sign-in': () => void;
    'sign-out': () => void;
    'session-changed': () => void;
}>();

const SessionEmpty: Session = {
    user: {
        guid: CreateGUID(),
        is_verified: false,
        email: null,
        first_name: '',
        last_name: '',
        full_name: '',
        nats_user_token: '',
    },
    extra: {},
};

let session: Session = {...SessionEmpty};

function SetSession(newSession: Partial<Session>) {
    session = {...session, ...newSession};
}

function SetSessionUser(newUserData: Partial<SessionUser>) {
    session = {...session, user: {...session.user, ...newUserData}};
}

// usually such functions should not be located here
// but session is a bit different case
export async function LoadSession(): Promise<Session> {
    const res = await LoadSessionAPI();
    if (res[1] !== null) {
        console.error('session error: ', res[1].message);
    } else {
        SetSession(res[0]);
    }

    return session;
}

export async function ReloadSession() {
    await LoadSession();
    Events.emit('session-changed');
}

export function UpdateSession(data: Partial<Session>) {
    SetSession(data);
    Events.emit('session-changed');
}

export function UpdateSessionUser(data: Partial<SessionUser>) {
    SetSessionUser(data);
    Events.emit('session-changed');
}

export async function UpdateSessionProfileDetails(data: ProfileDetails): Promise<FetchResponseError | null> {
    const res = await UpdateProfileDetailsAPI(data);
    if (res[0]) {
        UpdateSession(res[0]);
        Events.emit('session-changed');
    }

    return res[1];
}

// used after initial singup to update user firs/last name and pre-create company
export async function SubmitProfileData(data: SubmitProfileDataInput): Promise<FetchResponseError | null> {
    const res = await SubmitProfileDataAPI(data);
    if (res[0]) {
        UpdateSession(res[0]);
        Events.emit('session-changed');
    }

    return res[1];
}

export async function SignOut() {
    const res = await SignOutAPI();
    if (res[1] !== null) {
        console.error('session error: ', res[1].message);
    } else {
        window.location.href = '/';
        SetSession(res[0]);
    }

    Events.emit('sign-out');
}

export function listenOnSignIn(func: () => void) {
    Events.addListener('sign-in', func);
}

export function unlistenOnSignIn(func: () => void) {
    Events.removeListener('sign-in', func);
}

export function listenOnSignOut(func: () => void) {
    Events.addListener('sign-out', func);
}

export function unlistenOnSignOut(func: () => void) {
    Events.removeListener('sign-out', func);
}

export function listenOnSessionChanges(func: () => void) {
    Events.addListener('session-changed', func);
}

export function unlistenOnSessionChanges(func: () => void) {
    Events.removeListener('session-changed', func);
}

export {session};
