import {ThirdPartyLoginResponse} from 'src/jsx/auth/store';
import ConnectFacebookWindow from 'src/jsx/auth/store/socials/facebook';

export function ConnectFacebookUserDataSource() {
    return new Promise<ThirdPartyLoginResponse<{user_data_source_guid?: string}>>(resolve => {
        ConnectFacebookWindow<{user_data_source_guid?: string}>({IsDataSourceConnect: true}, 'email,ads_read', res => {
            resolve(res);
        });
    });
}
