import {connect, Authenticator, NatsConnection} from 'nats.ws';

export type {NatsConnection};
export default async function InitNATS(
    server: string,
    user: string,
    authenticator: Authenticator
): Promise<NatsConnection> {
    return await connect({
        reconnect: true,
        waitOnFirstConnect: false,
        maxReconnectAttempts: -1,
        timeout: 60000,
        servers: [server],
        authenticator,
        name: `User connection: ${user}`,
    });
}
