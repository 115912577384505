import {CreateSheet, css, GetColor} from 'aphrodite';
import {MakeButton} from 'packages/elements/button';
import {ReactComponent as EditIcon} from 'src/icons/edit.svg';
import EditProjectTitleLB from './components/EditProjectTitle';
import {showLightbox} from 'lib/lightbox/lightbox';
import {useDashboardStore} from '../../DashboardStore';
import {showErrorLightbox} from 'lib/lightbox/warning.react';
import DashboardStyles from '../../DashboardPage.jss';
import {GetProjectName} from 'packages/projects/helpers/GetProjectName';

export default function DashboardProjectSettings() {
    const [project, reloadProject] = useDashboardStore(store => [store.project, store.reload]);

    const onEditProjectTitleClick = () => {
        if (!project) {
            showErrorLightbox('No project created yet');
            return;
        }

        showLightbox({
            selector: 'edit-project-title',
            Component: EditProjectTitleLB,
            content: {
                title: project.name,
                uri: project.uri,
                onSave: newProject => {
                    reloadProject({project_uri: newProject.uri});
                },
            },
        });
    };

    return (
        <div className={css(Styles.page)}>
            <h1 className={css(DashboardStyles.title)}>Settings</h1>
            <div className={css(Styles.content)}>
                <div className={css(Styles.setting)}>
                    <div className={css(Styles.settingInfo)}>
                        Project
                        <span className={css(Styles.settingValue, !project?.name && Styles.settingValuePlaceholder)}>
                            {GetProjectName(project?.name)}
                        </span>
                    </div>
                    <button
                        className={MakeButton('Outlined-Default')}
                        onClick={onEditProjectTitleClick}
                    >
                        <EditIcon />
                        Edit
                    </button>
                </div>
            </div>
        </div>
    );
}

const Styles = CreateSheet({
    page: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
    },
    content: {
        maxWidth: 1000,
    },
    setting: {
        padding: 20,
        border: `1px solid ${GetColor('StrokeColor')}`,
        borderRadius: 20,

        display: 'flex',
        gap: 20,
        alignItems: 'center',
    },
    settingInfo: {
        flexGrow: 1,

        display: 'flex',
        gap: 5,
        flexDirection: 'column',

        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 'normal',
        color: GetColor('Secondary'),
    },
    settingValue: {
        fontSize: '1.375rem',
        fontWeight: 500,
        lineHeight: 'normal',
        color: GetColor('Primary'),
    },
    settingValuePlaceholder: {
        color: GetColor('Placeholder'),
    },
});
