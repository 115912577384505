import {put} from 'lib/fetch';
import {BlogPostRuntype, UpdateBlogPostInput} from '../model';

export default async function UpdateBlogPostAPI(input: UpdateBlogPostInput) {
    return await put(
        {
            url: `blog/${input.guid}`,
            body: input,
        },
        BlogPostRuntype
    );
}
