import {useEffect, useRef, useState} from 'react';

export default function useScrollDirection(threshold?: number): 'up' | 'down' {
    const prevScrollY = useRef(window.scrollY);
    const scrollDirRef = useRef<'up' | 'down'>('down');
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');

    function onScroll() {
        const currScrollY = window.scrollY;
        if (currScrollY - prevScrollY.current > (threshold ?? 0)) {
            if (scrollDirRef.current !== 'down') {
                setScrollDirection('down');
            }
            scrollDirRef.current = 'down';
            prevScrollY.current = currScrollY;
        } else if (prevScrollY.current - currScrollY > (threshold ?? 0)) {
            if (scrollDirRef.current !== 'up') {
                setScrollDirection('up');
            }
            scrollDirRef.current = 'up';
            prevScrollY.current = currScrollY;
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    });

    return scrollDirection;
}
