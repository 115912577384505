import {matchRoutes, useLocation, Location, Path} from 'react-router-dom';
import {BasePageWithID, PageIDs, pagemap} from 'src/runtime/router/pagelist';
import BrowserRouter from 'src/runtime/router/BrowserRouter';
import {routing} from '.';

export function routerGetActivePageIDs(location?: Location): PageIDs[] {
    const routes = matchRoutes(BrowserRouter.routes, location || window.location);
    if (routes && routes?.length > 0) {
        return routes
            .map(r => {
                const parts = r.route.id.split(':::');
                return parts[0];
            })
            .filter(route_id => Boolean(pagemap[route_id as PageIDs])) as PageIDs[];
    }
    return [];
}

export function routerIsActivePage(page_id: PageIDs) {
    const pages = routerGetActivePageIDs();
    return pages.includes(page_id);
}

export function useRouterGetActivePagesIDs() {
    const location = useLocation();
    return routerGetActivePageIDs(location);
}

export function useRouterGetActivePages(): BasePageWithID[] {
    const ids = useRouterGetActivePagesIDs();
    return Object.values(pagemap).filter(page => ids.includes(page.id));
}

export function useRouterIsActivePage(page_id: PageIDs) {
    return routerIsActivePage(page_id);
}

export function useHistory(): {
    push: (path: string | Partial<Path>, state?: any) => void;
    replace: (path: string | Partial<Path>, state?: any) => void;
    location: Location;
} {
    const location = useLocation();

    return {
        location,
        push: routing.push,
        replace: routing.replace,
    };
}
