import {CreateSheet, css} from 'lib/aphrodite/aphrodite';
import BreadCrumbs from 'packages/navigation/Breadcrumbs';
import {HeaderUtilityStyles} from 'src/jsx/header/sizes';
import {GeneralStyles} from '../GeneralStyles.jss';
import {Login} from './sections/Login';
import {Account} from './sections/Account';
import {useState} from 'react';

const LoginSecuritySettings: React.FC = () => {
    const [isFocus, setIsFocus] = useState(false);

    return (
        <div className={css(Styles.container, HeaderUtilityStyles().relatedFullHeightPage)}>
            <div className={css(GeneralStyles.content)}>
                <div className={css(Styles.contentHead)}>
                    <BreadCrumbs
                        parts={[
                            {title: 'Account', href: '/account'},
                            {title: 'Login & Security', href: '/account/security'},
                        ]}
                    />
                    <h1 className={css(GeneralStyles.pageTitle)}>Login & Security</h1>
                </div>
                <section className={css(Styles.settings)}>
                    <Login
                        isFocus={isFocus}
                        setIsFocus={setIsFocus}
                    />
                    <Account isFocus={isFocus} />
                </section>
            </div>
        </div>
    );
};

const Styles = CreateSheet({
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: '25px 30px 50px',
        '@media(max-width: 800px)': {
            padding: '25px 20px 50px',
        },
    },
    contentHead: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    settings: {
        maxWidth: 760,
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
    },
});

export default LoginSecuritySettings;
