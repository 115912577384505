import {CreateSheet, css} from 'aphrodite';
import {SectionsStyles} from './sections.jss';
import {MakeButton} from 'packages/elements/button';
import showWarning, {showErrorLightbox} from 'lib/lightbox/warning.react';
import {DeleteAccountAPI} from 'src/runtime/session/API/DeleteAccountAPI';
import {ReloadSession} from 'src/runtime/session';
import {routing} from 'src/runtime/router';

type AccountProps = {
    isFocus: boolean;
};
export const Account: React.FC<AccountProps> = ({isFocus}) => {
    const onClick = () => {
        showWarning(
            <div className={css(Styles.warning)}>
                Are you sure you want to delete account?
                <br />
                This action can't be undone.
            </div>,
            undefined,
            {
                buttons: [
                    {
                        text: 'Cancel',
                        className: MakeButton('FilledPageBg-Big'),
                    },
                    {
                        text: 'Delete account',
                        className: MakeButton('FilledDanger-Big'),
                        onClick: async () => {
                            const [, err] = await DeleteAccountAPI();
                            if (err !== null) {
                                showErrorLightbox('Failed to delete account');
                            } else {
                                ReloadSession();
                                routing.push('');
                            }
                        },
                    },
                ],
            }
        );
    };
    return (
        <div className={css(isFocus && SectionsStyles.outsideFocus)}>
            <h2 className={css(SectionsStyles.sectionTitle)}>Account</h2>
            <div className={css(SectionsStyles.setting)}>
                <h3 className={css(SectionsStyles.settingInfo)}>Delete your account</h3>
                <button
                    onClick={onClick}
                    className={MakeButton('Outlined-Default', SectionsStyles.dangerBtn)}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

const Styles = CreateSheet({
    warning: {
        fontWeight: 500,
        marginRight: 35,
    },
});
