import {GetListParams, get} from 'lib/fetch';
import {WithPagination} from 'packages/pagination/pagination';
import {BlogPostMetadataRuntype} from '../model';

export default async function LoadBlogListAPI(params: GetListParams) {
    return await get(
        {
            url: 'blog',
            query: {
                ...params,
                page_id: params.page_id ?? 1,
            },
        },
        WithPagination(BlogPostMetadataRuntype)
    );
}
