import {ThirdPartyLoginResponse} from '..';
import {BuildState, ThirdPartyLoginState} from './state';

export default function LoginWithGoogle(
    state: ThirdPartyLoginState,
    onComplete: (res: ThirdPartyLoginResponse) => void
) {
    let OAuthWindow: Window | null = null;
    const windowName = 'Google Login';
    const windowOptions = 'location=0,status=0,width=500,height=800,left=100,top=100';
    OAuthWindow = window.open(`${GOOGLE_OAUTH_URL}&state=${BuildState(state)}`, windowName, windowOptions);

    if (OAuthWindow) {
        // @ts-ignore
        window.AuthCallack = function (payload: string) {
            if (OAuthWindow && !OAuthWindow.closed) {
                OAuthWindow.close();
            }

            const res: ThirdPartyLoginResponse = JSON.parse(payload);
            onComplete(res);
        };
    }
}
