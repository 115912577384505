import {CreateSheet, GetColor} from 'aphrodite';

export const animatedTime = 100;
export default CreateSheet({
    menu_block: {
        padding: 6,
        borderRadius: 8,
        background: GetColor('TopLayerObjectBg'),
        border: `1px solid ${GetColor('Border')}`,
        boxShadow: '0px 5px 15px 0px rgba(48, 48, 48, 0.10)',
        outline: 0,
        zIndex: 1000,
        ':nth-child(1n) .menu-item-divider:first-child': {
            display: 'none',
        },
    },
    floatingOverflow: {
        overflowY: 'auto',
    },
    menu_item: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 12px',
        fontFamily: 'Figtree',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 400,
        minHeight: 35,
        lineHeight: 1.2,
        color: GetColor('TextColor'),
        background: GetColor('TopLayerObjectBg'),
        textDecoration: 'none',
        outline: 0,
        borderRadius: 4,
        userSelect: 'none',
        cursor: 'pointer',
        ':hover': {
            background: GetColor('ButtonBgHover'),
        },
        ':focus': {
            outline: `1px solid ${GetColor('ButtonBgHover')}`,
        },
    },
    menu_item_big: {
        padding: '10px 12px',
        fontSize: '1rem',
    },
    menu_item_disabled: {
        opacity: 0.5,
        cursor: 'default',
    },
    menu_item_active: {
        backgroundColor: GetColor('ButtonBg'),
    },
    menu_item_sub: {
        justifyContent: 'space-between',
    },
    trigger_sub_menu: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    menu_item_arrow: {
        marginLeft: 10,
        ':nth-child(1n) svg': {
            display: 'block',
            color: '#757575',
        },
    },
    divider: {
        height: 1,
        margin: '6px 0',
        border: 0,
        outline: 0,
        background: '#ECECEC',
        ':nth-child(1n) + .menu-item-divider': {
            display: 'none',
        },
        ':not(:has(~ :not(.menu-item-divider)))': {
            display: 'none',
        },
    },
    select: {
        display: 'inline-flex',
        minWidth: 100,
        maxWidth: 250,
        height: 50,
        padding: '16px 14px',
        fontSize: '1rem',
        fontWeight: 500,
        color: GetColor('Placeholder'),
        borderRadius: 14,
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${GetColor('Border')}`,
        background: GetColor('InputBg'),
        ':nth-child(1n) svg': {
            display: 'block',
            width: 16,
            height: 16,
            marginLeft: 14,
            color: GetColor('Secondary'),
        },
    },
    selectSmall: {
        height: 36,
        padding: '7px 14px',
        fontSize: '0.875rem',
        borderRadius: 10,
        ':nth-child(1n) svg': {
            display: 'block',
            width: 12,
            height: 12,
            marginLeft: 10,
            color: GetColor('Secondary'),
        },
    },
});
