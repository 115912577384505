import update, {Spec} from 'immutability-helper';
import CreateGUID from 'lib/helpers/CreateGUID';
import {Loadable} from 'lib/types/Loadable';
import {hasErrors, setError} from 'lib/zustand-extend/createErrors';
import {ExternalFile} from 'packages/files/models';
import {StateCreator} from 'zustand';
import {CreatePostErrors, CreatePostErrorsList} from './model';
import {CreatePostStore} from './useCreatePostStore';
import LoadBlogPostByGUIDAPI from 'packages/blog/API/LoadBlogPostByGUIDAPI';
import CreateBlogPostAPI from 'packages/blog/API/CreateBlogPostAPI';
import UpdateBlogPostAPI from 'packages/blog/API/UpdateBlogPostAPI';
import {routing} from 'src/runtime/router';

type Metadata = {
    is_saving: boolean;
    description_max_length: number;
};

export type Details = {
    guid: string;
    author_name: string;
    title: string;
    body: string;
    image: ExternalFile | null;
};

export type General = {
    init: (resourceGUID?: string) => Promise<void>;
    details: Loadable<Details>;
    setDetails: (spec: Spec<Details, never>) => void;
    metadata: Metadata;
    setMetadata: (spec: Spec<Metadata, never>) => void;
    save: () => Promise<void>;
};

const emptyGeneral = {
    details: {
        is_loading: true,
        is_loaded: false,
    },
    metadata: {
        is_saving: false,
        description_max_length: 500,
    },
} satisfies Partial<General>;

export const createGeneral: StateCreator<CreatePostStore, [], [], General> = (set, get) => ({
    ...emptyGeneral,
    init: async (postGUID?: string) => {
        set({
            ...emptyGeneral,
        });

        if (postGUID) {
            const [post, err] = await LoadBlogPostByGUIDAPI(postGUID);
            if (err !== null) {
                console.error('Error loading post');
            } else if (post !== null) {
                set({
                    details: {
                        ...post,
                        is_loaded: true,
                        is_loading: false,
                    },
                });
            }
        } else {
            set({
                details: {
                    guid: '',
                    is_loading: false,
                    is_loaded: true,
                    author_name: '',
                    title: '',
                    body: '',
                    image: null,
                },
            });
        }
    },
    setDetails: spec => {
        const details = get().details;
        if (details.is_loaded) {
            set({
                details: {
                    is_loaded: details.is_loaded,
                    is_loading: details.is_loading,
                    ...update(details, spec),
                },
            });

            get().setErrors(detailsSpecToErrorsPath(spec));
        }
    },
    setMetadata: spec => {
        const metadata = get().metadata;
        set({
            metadata: update(metadata, spec),
        });
    },
    save: async () => {
        const details = get().details;
        if (details.is_loaded) {
            let errors: CreatePostErrors = {};

            if (details.title.trim().length === 0) {
                errors = setError(errors, 'title', 'Name is required');
            }

            if (hasErrors(errors)) {
                get().setErrors(errors);
                return;
            }

            set({
                metadata: {
                    ...get().metadata,
                    is_saving: true,
                },
            });

            const f = details.guid.length > 0 ? UpdateBlogPostAPI : CreateBlogPostAPI;
            const res = await f({
                guid: details.guid.length > 0 ? details.guid : CreateGUID(),
                author_name: details.author_name,
                title: details.title,
                body: details.body,
                image_guid: details.image?.guid,
                is_hidden: false,
            });
            if (res[1]) {
                alert(res[1].message);
            } else {
                set({
                    metadata: {
                        ...get().metadata,
                        is_saving: false,
                    },
                });
                routing.push(`/blog/${res[0].uri}`);
            }
        }
    },
});

function detailsSpecToErrorsPath(spec: Spec<Details, never>, parent?: string): CreatePostErrors {
    let res: CreatePostErrors = {};

    for (const key in spec) {
        if (Object.prototype.hasOwnProperty.call(spec, key)) {
            if (key.startsWith('$')) {
                res = setError(res, parent as CreatePostErrorsList, undefined, true);
            } else if (typeof spec[key as keyof typeof spec] === 'object') {
                res = {
                    ...res,
                    ...detailsSpecToErrorsPath(spec[key as keyof typeof spec], `${parent ? `${parent}.` : ''}${key}`),
                };
            }
        }
    }

    return res;
}
