import {post} from 'lib/fetch';
import {Boolean} from 'runtypes';

export type StoreLandingDataInput = {
    email: string;
};

export default async function StoreLandingDAtaAPI(input: StoreLandingDataInput) {
    return await post(
        {
            url: 'landing/signups',
            body: input,
        },
        Boolean
    );
}
