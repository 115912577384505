import {CreateSheet} from 'aphrodite';

const marqueeKeyframes = {
    '0%': {
        transform: 'translateX(0%)',
    },

    '100%': {
        transform: 'translateX(-100%)',
    },
};

const marqueeSecondKeyframes = {
    '0%': {
        transform: 'translateX(0%)',
    },

    '100%': {
        transform: 'translateX(-100%)',
    },
};

export default CreateSheet({
    customHeaderBg: {
        background: '#091116',
    },
    content: {
        padding: '70px 0 0 0',
        background: '#091116',
    },
    top: {
        padding: '50px 40px',
        background: 'linear-gradient(180deg, #091116 0%, #0D2130 93.01%)',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    hero: {
        width: 1200,
        maxWidth: '100%',
        margin: '0 auto',
        textAlign: 'center',
    },
    title: {
        margin: 0,
        fontSize: '4.375rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: '#fff',
        '@media(max-width: 1024px)': {
            fontSize: '3.375rem',
        },
        '@media(max-width: 800px)': {
            fontSize: '2.75rem',
        },
        '@media(max-width: 640px)': {
            fontSize: '1.875rem',
        },
    },
    titleStrong: {
        display: 'block',
        fontWeight: 600,
        background: 'linear-gradient(90deg, #B6F057 0.09%, #61A9B7 100.09%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    heroText: {
        margin: '15px 0 0 0',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: 'rgba(255, 255, 255, 0.70)',
        '@media(max-width: 640px)': {
            fontSize: '1.125rem',
        },
    },
    form: {
        width: 600,
        maxWidth: '100%',
        display: 'flex',
        margin: '30px auto 0 auto',
        alignItems: 'center',
        '@media(max-width: 640px)': {
            display: 'block',
            width: '100%',
        },
    },
    inputContainer: {
        flexGrow: 1,
        marginRight: 15,
        '@media(max-width: 640px)': {
            width: '100%',
            margin: '0 0 15px 0',
        },
    },
    input: {
        width: '100%',
        border: '1px solid rgba(255, 255, 255, 0.20)',
        color: '#ABABB2',
        background: 'rgba(25, 43, 57, 0.40)',
    },
    btn: {
        display: 'block',
        margin: 0,
        padding: 7,
        fontFamily: "'Figtree', sans-serif",
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: '#202022',
        borderRadius: 20,
        border: '1px solid rgba(220, 255, 164, 0.20)',
        textAlign: 'center',
        boxShadow: 'none',
        flexGrow: 0,
        background: 'transparent',
        cursor: 'pointer',
        '@media(max-width: 640px)': {
            width: '100%',
        },
    },
    btnSimple: {
        border: 0,
        padding: 0,
    },
    btnText: {
        height: 50,
        padding: '14px 24px',
        borderRadius: 14,
        background: '#B6F057',
        transition: 'background 0.2s ease-out',
        ':hover': {
            background: '#A5DE47',
        },
    },
    chat: {
        width: 1060,
        maxWidth: '100%',
        display: 'flex',
        margin: '50px auto 0 auto',
        padding: 30,
        borderRadius: 34,
        border: '1px solid rgba(255, 255, 255, 0.05)',
        background: 'rgba(255, 255, 255, 0.05)',
        '@media(max-width: 640px)': {
            margin: '30px auto 0 auto',
            padding: 10,
        },
    },
    chatScreen: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: 680,
        flexDirection: 'column',
        borderRadius: 24,
        border: '1px solid rgba(255, 255, 255, 0.10)',
        background: '#091116',
        boxShadow: '0px 5px 50px 0px rgba(0, 0, 0, 0.50)',
        '@media(max-width: 800px)': {
            height: 440,
        },
    },
    chatConversationShadow: {
        position: 'absolute',
        display: 'none',
        bottom: -3,
        left: 0,
        right: 0,
        height: 40,
        background: 'linear-gradient(180deg, rgba(10, 22, 31, 0.00) 5.92%, #091116 82.87%)',
        borderRadius: '0 0 24px 24px',
        zIndex: 2,
        '@media(max-width: 640px)': {
            display: 'block',
        },
    },
    chatConversation: {
        padding: 30,
        flexGrow: 1,
        overflow: 'auto',
        '::-webkit-scrollbar': {
            width: 0,
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
            borderRadius: 8,
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '1px solid rgba(255, 255, 255, 0.10)',
            borderRadius: 8,
        },
        '@media(max-width: 640px)': {
            padding: '20px 10px',
        },
    },
    chatNav: {
        display: 'flex',
        padding: '20px 30px',
        alignItems: 'center',
        pointerEvents: 'none',
        '@media(max-width: 640px)': {
            display: 'none',
        },
    },
    chatNavBtn: {
        height: 50,
        margin: 0,
        padding: '14px 17px',
        borderRadius: 14,
        border: 0,
        color: '#202022',
        background: '#b6f057',
        boxShadow: 'none',
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
        },
    },
    message: {
        display: 'flex',
        margin: '0 50px 10px 0',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#fff',
        '@media(max-width: 640px)': {
            flexDirection: 'column',
        },
    },
    messageUser: {
        margin: '0 0 10px 50px',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        '@media(max-width: 640px)': {
            alignItems: 'flex-end',
            flexDirection: 'column',
        },
    },
    messageBodyUser: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    messageImgBlock: {
        display: 'flex',
        ':nth-child(1n) span': {
            display: 'none',
        },
        '@media(max-width: 640px)': {
            alignItems: 'center',
            ':nth-child(1n) span': {
                display: 'block',
            },
        },
    },
    messageImg: {
        width: 46,
        height: 46,
        marginRight: 15,
        padding: 3,
        flexShrink: 0,
        border: '1px solid rgba(255, 255, 255, 0.20)',
        borderRadius: '50%',
        ':nth-child(1n) img': {
            display: 'block',
            width: '100%',
            borderRadius: '50%',
        },
        '@media(max-width: 640px)': {
            marginRight: 10,
        },
    },
    messageImgUser: {
        margin: '0 0 0 15px',
        '@media(max-width: 640px)': {
            margin: '0 0 0 10px',
        },
    },
    messageName: {
        '@media(max-width: 640px)': {
            display: 'none',
        },
    },
    messageBlobContainer: {
        display: 'flex',
    },
    messageBlob: {
        display: 'inline-block',
        margin: '7px 0 0 0',
        padding: '8px 14px',
        fontSize: '1rem',
        fontWeight: 400,
        color: '#fff',
        borderRadius: 16,
        background: '#1D2226',
        ':nth-child(1n) ol': {
            margin: 0,
            padding: '0 0 0 15px',
        },
    },
    messageBlobFirst: {
        borderRadius: '4px 16px 16px 16px',
    },
    messageBlobFirstUser: {
        borderRadius: '16px 4px 16px 16px',
    },
    messagePicture: {
        display: 'block',
        maxWidth: '100%',
        margin: '7px 0 0 0',
    },
    messageList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },
    messageFile: {
        position: 'relative',
        width: 170,
        height: 70,
        margin: '7px 0 0 0',
        padding: '10px 10px 10px 60px',
        fontSize: '1rem',
        fontWeight: 400,
        color: '#fff',
        borderRadius: 10,
        border: '1px solid rgba(255, 255, 255, 0.10)',
        background: 'rgba(255, 255, 255, 0.05)',
    },
    messageFileIcon: {
        position: 'absolute',
        top: 10,
        left: 10,
    },
    messageFileName: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: '#fff',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    messageFileSize: {
        fontSize: '0.8125rem',
        fontWeight: 400,
        color: '#c5c5cd',
    },
    messageBlobUser: {
        background: 'linear-gradient(90deg, #227F7F 0%, #2A6896 100%)',
        ':first-child': {
            borderRadius: '16px 4px 16px 16px',
        },
    },
    work: {
        padding: '50px 40px',
        background: 'linear-gradient(180deg, #0D2130 13.17%, #0A161F 100%)',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    container: {
        width: 1200,
        position: 'relative',
        maxWidth: '100%',
        margin: '0 auto',
    },
    partTitle: {
        margin: '30px 0 0 0',
        fontSize: '3rem',
        fontWeight: 600,
        color: '#fff',
        '@media(max-width: 800px)': {
            fontSize: '2.125rem',
        },
        '@media(max-width: 640px)': {
            fontSize: '1.75rem',
        },
    },
    partTitleEyeBrow: {
        display: 'flex',
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: '#b6f057',
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 10,
        },
    },
    formTitleEyeBrow: {
        justifyContent: 'center',
    },
    partText: {
        margin: 0,
        padding: '15px 0',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: 'rgba(255, 255, 255, 0.50)',
        '@media(max-width: 640px)': {
            fontSize: '1.125rem',
        },
    },
    workCard: {
        display: 'flex',
        marginTop: 25,
        padding: 16,
        borderRadius: 24,
        border: '1px solid rgba(255, 255, 255, 0.10)',
        background: 'rgba(255, 255, 255, 0.05)',
        '@media(max-width: 800px)': {
            display: 'block',
        },
    },
    workCardInfo: {
        display: 'flex',
        maxWidth: 450,
        padding: 10,
        flexDirection: 'column',
        flex: '1 0 0',
        '@media(max-width: 800px)': {
            padding: 0,
        },
    },
    workCardNumber: {
        fontSize: '3.125rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: 'rgba(255, 255, 255, 0.15)',
        '@media(max-width: 640px)': {
            fontSize: '1.375rem',
        },
    },
    workCardTitle: {
        margin: '16px 0',
        fontSize: '2.125rem',
        fontWeight: 600,
        color: '#fff',
        flexGrow: 1,
        '@media(max-width: 800px)': {
            margin: '5px 0',
        },
        '@media(max-width: 640px)': {
            fontSize: '1.5rem',
        },
    },
    workCardText: {
        margin: 0,
        fontSize: '1.125rem',
        fontWeight: 400,
        color: 'rgba(255, 255, 255, 0.50)',
        '@media(max-width: 640px)': {
            fontSize: '1rem',
        },
    },
    workCardImage: {
        display: 'flex',
        marginLeft: 30,
        height: 400,
        padding: 30,
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 0 0',
        borderRadius: 14,
        border: '1px solid rgba(255, 255, 255, 0.10)',
        background: 'rgba(10, 22, 31, 0.70)',
        boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.30) inset',
        ':nth-child(1n) img': {
            display: 'block',
            maxWidth: '100%',
        },
        '@media(max-width: 800px)': {
            margin: '16px 0 0 0',
        },
    },
    why: {
        padding: '50px 40px',
        background: '#0A161F',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    whyBlock: {
        display: 'grid',
        marginTop: 25,
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridColumnGap: 25,
        gridRowGap: 25,
        '@media(max-width: 800px)': {
            display: 'block',
            margin: 0,
        },
    },
    whyBlockCard1: {gridArea: '1 / 1 / 4 / 2'},
    whyBlockCard2: {gridArea: '1 / 2 / 3 / 3'},
    whyBlockCard3: {gridArea: '3 / 2 / 4 / 3'},
    whyCardInfo: {
        padding: 10,
        '@media(max-width: 640px)': {
            padding: 0,
        },
    },
    whyCardTitle: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.2,
        color: '#b6f057',
        flexGrow: 1,
        '@media(max-width: 640px)': {
            fontSize: '1.5rem',
        },
    },
    whyCardText: {
        margin: '10px 0 0 0',
        fontSize: '1.125rem',
        fontWeight: 500,
        color: '#fff',
        '@media(max-width: 640px)': {
            fontSize: '1rem',
        },
    },
    whyCardImg: {
        display: 'block',
        width: '100%',
        marginTop: 15,
    },
    whyCard: {
        display: 'flex',
        padding: 16,
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 24,
        border: '1px solid rgba(255, 255, 255, 0.20)',
        boxShadow: '0px 5px 20px 0px rgba(48, 48, 48, 0.10)',
        '@media(max-width: 800px)': {
            marginTop: 25,
        },
    },
    marqueeBlock: {
        position: 'relative',
        margin: '70px 0',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '@media(max-width: 640px)': {
            margin: '17.5px 0',
        },
    },
    marquee: {
        display: 'inline-block',
        animationName: [marqueeKeyframes],
        animationDuration: '70s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        '@media(max-width: 640px)': {
            animation: 'none',
        },
    },
    marqueeSecond: {
        animationName: [marqueeSecondKeyframes],
        animationDuration: '70s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        '@media(max-width: 640px)': {
            animation: 'none',
        },
    },
    from: {
        background: '#0D2130',
    },
    fromBlock: {
        position: 'relative',
        width: 1200,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '50px 40px',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
        '@media(max-width: 640px)': {
            padding: '30px 20px',
            ':before': {
                display: 'none',
            },
            ':after': {
                display: 'none',
            },
        },
        ':before': {
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 200,
            background: 'linear-gradient(90deg, #0D2130 25.17%, rgba(13, 33, 48, 0.00) 100%)',
            zIndex: 2,
        },
        ':after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            width: 200,
            background: 'linear-gradient(-90deg, #0D2130 25.17%, rgba(13, 33, 48, 0.00) 100%)',
            zIndex: 2,
        },
    },
    fromList: {
        display: 'flex',
        margin: 0,
        padding: 0,
        alignItems: 'center',
        listStyle: 'none',
        '@media(max-width: 640px)': {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    fromItem: {
        margin: '0 35px',
        ':nth-child(1n) svg': {
            display: 'block',
        },
        '@media(max-width: 640px)': {
            width: '50%',
            minWidth: '50%',
            margin: 0,
            padding: '12.5px 25px',
            ':nth-child(1n) svg': {
                maxWidth: '100%',
                height: 'auto',
                margin: '0 auto',
            },
        },
    },
    fromTitle: {
        margin: '0 0 30px 0',
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'rgba(255, 255, 255, 0.50)',
        textAlign: 'center',
    },
    formBottom: {
        padding: '50px 40px',
        background: '#0A161F',
        textAlign: 'center',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    formBottomBlock: {
        width: '100%',
        maxWidth: 600,
        margin: '25px auto 0 auto',
        padding: 30,
        fontSize: '1.125rem',
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 0.50)',
        borderRadius: 24,
        textAlign: 'left',
        border: '1px solid rgba(255, 255, 255, 0.10)',
        background: 'rgba(255, 255, 255, 0.05)',
        boxShadow: '0px 5px 50px 0px rgba(0, 0, 0, 0.25)',
        '@media(max-width: 640px)': {
            padding: 20,
        },
    },
    formBottomBlockTitle: {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#fff',
    },
    formBottomBlockText: {
        margin: '10px 0 25px 0',
    },
    footer: {
        background: 'linear-gradient(180deg, #0A161F 0%, #0E2232 100%)',
    },
    footerTransparent: {
        background: 'transparent',
    },
    thanks: {
        display: 'flex',
        minHeight: 'calc(100vh - 70px)',
        padding: '50px 40px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'linear-gradient(180deg, #091116 38.71%, #0D2231 100%)',
        textAlign: 'center',
        '@media(max-width: 800px)': {
            padding: '50px 20px',
        },
    },
    thanksTitleEyeBrow: {
        justifyContent: 'center',
    },
    thanksBtn: {
        display: 'inline-flex',
        height: 50,
        margin: '15px auto 0 auto',
        padding: '14px 24px',
        borderRadius: 14,
        border: 0,
        fontFamily: "'Figtree', sans-serif",
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#202022',
        alignItems: 'center',
        background: '#b6f057',
        boxShadow: 'none',
        cursor: 'pointer',
        ':nth-child(1n) svg': {
            display: 'block',
            marginRight: 8,
        },
    },
    thanksMail: {
        display: 'block',
        width: '100%',
        padding: 20,
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#fff',
        textDecoration: 'none',
        borderRadius: 14,
        border: '1px solid #DCFFA4',
        textAlign: 'center',
    },
    thanksFooter: {
        marginTop: 50,
        justifyContent: 'center',
    },
    thanksForm: {
        marginTop: 80,
    },
    goTop: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        width: 44,
        height: 44,
        display: 'flex',
        margin: 0,
        padding: 0,
        color: '#091116',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.10)',
        border: 0,
        borderRadius: '50%',
        boxShadow: 'none',
        cursor: 'pointer',
        transition: 'background 0.2s ease-out',
        zIndex: 30,
        ':nth-child(1n) svg': {
            display: 'block',
        },
        ':hover': {
            background: '#B6F057',
        },
        '@media(max-width: 800px)': {
            display: 'none',
        },
    },
});
