import {post} from 'lib/fetch';
import {SessionRuntype} from 'src/runtime/session/model';

export type SubmitProfileDataInput = {
    first_name: string;
    last_name: string;
    company_name: string;
    company_industry: string;
    company_size: string;
};

// used after initial singup to update user firs/last name and pre-create company
export default function SubmitProfileDataAPI(data: SubmitProfileDataInput) {
    return post(
        {
            url: '/session/profile',
            body: data,
        },
        SessionRuntype
    );
}
