import {CreateSheet} from 'lib/aphrodite/aphrodite';

export default CreateSheet({
    block: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1300,
    },
    blockKeepScrolling: {
        top: 0,
        bottom: 'auto',
        left: 0,
        right: 0,
    },
    bg: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0,
        zIndex: -1,
        transition: 'opacity 0.3s',
    },
    bgOpened: {
        opacity: 1,
    },
    content: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: 400,
        transition: 'transform 0.3s',
        zIndex: 2,
    },
    contentOpened: {
        transform: 'translate(0, 0)',
    },
    contentLeft: {
        left: 0,
        transform: 'translate(-100%, 0)',
    },
    contentRight: {
        right: 0,
        transform: 'translate(100%, 0)',
    },
    contentTop: {
        left: 0,
        right: 0,
        bottom: 'auto',
        transform: 'translate(0, -100%)',
    },
    contentBottom: {
        top: 'auto',
        bottom: 0,
        transform: 'translate(0, +100%)',
    },
});
