import {PieChart, Pie, Cell, ResponsiveContainer, Sector} from 'recharts';
import {css, CreateSheet, GetColor} from 'lib/aphrodite/aphrodite';
import {PieSectorDataItem} from 'recharts/types/polar/Pie';
import {DonutChart} from '.';

const Colors = [
    '#E1A8E5',
    '#BB6EC1',
    '#E87CB0',
    '#A7D0F2',
    '#459EE4',
    '#459EE4',
    '#92DDBD',
    '#BBEA6E',
    '#7B9453',
    '#3F6205',
    '#FF868D',
    '#E75961',
    '#F4B842',
    '#ABA0EF',
    '#7665DA',
];

const renderActiveShape = (props: PieSectorDataItem) => {
    const {cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill} = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={innerRadius! - 10}
                outerRadius={innerRadius! - 6}
                fill={fill}
            />
        </g>
    );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            className={css(Styles.labelStyles)}
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default function DonutChartComponent({
    chart,
    activeIndex,
    setActiveIndex,
}: {
    chart: DonutChart;
    activeIndex?: number;
    setActiveIndex?: (index: number) => void;
}) {
    const onPieClick = (_: any, index: number, e: React.MouseEvent) => {
        e.stopPropagation();
        setActiveIndex && setActiveIndex(index);
    };

    return (
        <ResponsiveContainer
            minWidth={200}
            minHeight={200}
        >
            <PieChart
                width={800}
                height={800}
                className={css(Styles.donut)}
            >
                <Pie
                    data={chart.data.map(item => ({label: item.name, value: item.value}))}
                    cx="50%"
                    cy="50%"
                    innerRadius="80%"
                    outerRadius="100%"
                    dataKey="value"
                    label={renderCustomizedLabel}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    onClick={onPieClick}
                >
                    {chart.data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={Colors[index % Colors.length]}
                            strokeWidth={0}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}

const Styles = CreateSheet({
    donut: {
        ':nth-child(1n) .recharts-pie-sector:focus': {
            outline: 0,
        },
        ':nth-child(1n) .recharts-sector:focus': {
            outline: 0,
        },
    },
    labelStyles: {
        fontFamily: 'Figtree',
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1,
        fill: GetColor('Gray'),
    },
});
