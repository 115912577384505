import {GetColor} from 'lib/aphrodite/aphrodite';
import {LightboxTheme} from './model';

export const LightTheme: LightboxTheme = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        closeIconColor: GetColor('WhiteTextColor'),
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    lightbox: {
        backgroundColor: GetColor('PageBg'),
        color: GetColor('TextColor'),
        boxShadow: '0px 10px 20px rgba(48, 48, 48, 0.15)',
        closeIconColor: GetColor('Primary'),
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    header: {
        background: GetColor('PageBg'),
        color: GetColor('TextColor'),
        borderBottom: undefined,
    },
    body: {
        backgroundColor: undefined,
        color: undefined,
    },
    footer: {
        backgroundColor: undefined,
        color: undefined,
        botderTop: `1px solid ${GetColor('StrokeColor')}`,
    },
};
